import { Box, List, Menu, MenuItem } from '@material-ui/core';
import React, { createRef, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createChoiceAction } from '../../../../actions/choicesAction';
import { createOptionAction } from '../../../../actions/optionsAction';
import { SecondaryButton } from '../Button/SecondaryButton';

const AddFromFavourites = ({
  options,
  choices,
  restaurantId,
  createOptionAction,
  createChoiceAction,
}) => {
  const ref = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeButton, setActiveButton] = useState(null);

  const [elRefs, setElRefs] = React.useState([]);

  const handleOpenDropdown = (index) => {
    setAnchorEl(elRefs[index].current);
    setActiveButton(index);
    // setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
    setActiveButton(null);
  };

  const handleClickChoice = (favourite, choiceIndex) => {
    const newOptionId = `option_${new Date().getTime()}`;

    let newChoiceIds = [];

    favourite?.choices[choiceIndex].forEach((choice) => {
      const newChoiceId = `choice_${new Date().getTime()}`;

      createChoiceAction(newChoiceId, choice, 0);

      newChoiceIds = [...newChoiceIds, newChoiceId];
    });

    createOptionAction(
      newOptionId,
      favourite.name,
      null,
      0,
      0,
      false,
      restaurantId,
      null,
      newChoiceIds,
    );

    handleCloseMenu();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const favourites = [
    {
      name: 'Protein',
      choices: [['Chicken', 'Beef', 'Pork', 'Vegetarian']],
    },
    {
      name: 'Base',
      choices: [['Small', 'Medium', 'Large']],
    },
    {
      name: 'Alternative Milk',
      choices: [['Soy', 'Oat', 'Almond']],
    },
    {
      name: 'How would you like your steak?',
      choices: [['Rare', 'Medium rare', 'Medium', 'Medium well', 'Well done']],
    },
    {
      name: 'Gluten free option',
      choices: [['Gluten free']],
    },
    {
      name: 'Vegan option',
      choices: [['Vegan']],
    },
    {
      name: 'Vegetarian option',
      choices: [['Vegetarian']],
    },
    {
      name: 'Style of eggs',
      choices: [['Poached', 'Fried', 'Scrambled']],
    },
    {
      name: 'Size',
      choices: [
        ['Small', 'Medium', 'Large'],
        ['Half portion', 'Full portion'],
        ['2 pieces', '4 pieces'],
        ['Entree', 'Main'],
      ],
    },
  ];

  React.useEffect(() => {
    setElRefs((elRefs) =>
      Array(favourites.length)
        .fill()
        .map((_, i) => elRefs[i] || createRef()),
    );
  }, []);

  return (
    <Box>
      <Box style={{ fontWeight: 'bold' }}>Add from favourites</Box>
      <List
        style={{
          display: 'inline-flex',
          rowGap: '10px',
          columnGap: '10px',
          flexWrap: 'wrap',
        }}
      >
        {favourites.map((favourite, index) => (
          <>
            <SecondaryButton
              onClick={() => handleOpenDropdown(index)}
              style={{
                marginRight: 0,
                button: { color: '#212121', borderColor: '#E3E3E3' },
                position: 'relative',
              }}
            >
              {favourite.name}
              <div style={{ position: 'absolute', top: '80px' }} ref={elRefs[index]} />
            </SecondaryButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl) && activeButton === index}
              onClose={handleCloseDropdown}
              // classes={classes}
            >
              {favourite.choices.map((choice, index) => (
                <MenuItem key={index} onClick={() => handleClickChoice(favourite, index)}>
                  {choice.join(', ')}
                </MenuItem>
              ))}
            </Menu>
          </>
        ))}
      </List>
    </Box>
  );
};

AddFromFavourites.defaultProps = {};

AddFromFavourites.propTypes = {};

const mapStateToProps = (state) => ({
  options: state.options,
  choices: state.choices,
  restaurantId: state.activeRestaurant.data.objectId,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createChoiceAction,
      createOptionAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddFromFavourites);
