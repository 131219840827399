import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import { Provider as RollbarProvider, ErrorBoundary, historyContext } from '@rollbar/react';
import { createBrowserHistory } from 'history';
import * as serviceWorker from './serviceWorker';
import theme from './AppTheme';
import AppWithAuth from './auth/AppWithAuth';
import configureRollbar from './rollbar';
import store from './store';

// NOTE: enable moment in window to test in browser
window.moment = moment;

const { rollbar } = configureRollbar();

const history = createBrowserHistory();
history.listen(historyContext(rollbar));

ReactDOM.render(
  <RollbarProvider instance={rollbar}>
    <ErrorBoundary>
      <Provider store={store}>
        <CssBaseline>
          <ThemeProvider theme={theme}>
            <AppWithAuth />
          </ThemeProvider>
        </CssBaseline>
      </Provider>
    </ErrorBoundary>
  </RollbarProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
