import React, { useEffect, useState } from 'react';
import { TextField } from '@eatclub-apps/ec-component-library';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchRestaurantsByNameAction } from '../../actions/restaurantsAction';

const RestaurantSearch = ({
  fetchRestaurantsByNameAction,
  onRestaurantsUpdated,
  onSearchInitiated,
  restaurants,
}) => {
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [textValue, setTextValue] = useState(null);

  const handleChange = (value) => {
    setTextValue(value);
    onSearchInitiated(value);

    // Don't search if blank
    if (value === '') {
      onRestaurantsUpdated([]);
      return;
    }

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(
      setTimeout(() => {
        fetchRestaurantsByNameAction(value);
      }, 300),
    );
  };

  // Tell the parent class when the list has changed
  useEffect(() => {
    if (!restaurants?.fetching) {
      onRestaurantsUpdated(restaurants.data);
    }
  }, [restaurants]);

  return (
    <TextField
      label='Search restaurant name'
      variant='standard'
      onChange={handleChange}
      value={textValue}
      style={{ marginBottom: '30px' }}
    />
  );
};

RestaurantSearch.defaultProps = {
  onRestaurantsUpdated: () => {},
  onSearchInitiated: () => {},
};

const mapStateToProps = (state) => ({
  restaurants: state.restaurants,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchRestaurantsByNameAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantSearch);
