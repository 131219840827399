import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  IconButton,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Box,
  ListItemAvatar,
  Avatar,
  Tooltip,
  Checkbox,
} from '@material-ui/core';
import { ShoppingCart, RemoveShoppingCart, Delete, Image } from '@material-ui/icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  deleteProductAction,
  disableLocalProductForDateAction,
  disableLocalProductUntilDateAction,
  disableProductForDateAction,
  restockProductAction,
} from '../../../../actions/productsAction';
import { pluralise, startsWith } from '../../../../helpers/Helpers';
import ProductEditDialog from './ProductEditDialog';
import ReviewLabel from '../ReviewButton/ReviewLabel';
import useStyles from './ProductStyles';
import { dialogContexts } from '../../../../constants/constants';

const Product = ({
  product,
  deleteProduct,
  selected,
  onSelect,
  menuVersion,
  restockProduct,
  disableProductForDate,
  disableLocalProductForDate,
  disableLocalProductUntilDate,
  hideImages,
  setOpenDialog,
}) => {
  const classes = useStyles();
  const [isDisabled, setIsDisabled] = useState(
    moment(product.disableDate, 'yyyy-MM-DD').isSame(moment(), 'day') ||
      moment(product.disableUntilDate, 'yyyy-MM-DD').isAfter(moment(), 'day'),
  );

  useEffect(() => {
    setIsDisabled(
      moment(product?.disableDate, 'yyyy-MM-DD').isSame(moment(), 'day') ||
        moment(product?.disableUntilDate, 'yyyy-MM-DD').isAfter(moment(), 'day'),
    );
  }, [product, product?.disableDate, product?.disableUntilDate]);

  const formatCurrency = (num) => {
    if (Number.isNaN(num)) {
      return '';
    }

    return `$${num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
  };

  const handleDelete = () => {
    deleteProduct(product.objectId, product.category);
  };

  const toggleIsDisabled = () => {
    if (isDisabled) {
      if (startsWith(product.objectId, 'product_')) {
        disableLocalProductForDate(product.objectId, null);
        disableLocalProductUntilDate(product.objectId, null);
      } else {
        restockProduct(product.objectId);
      }
      return;
    }

    const newDisableDate = moment().format('yyyy-MM-DD');
    if (startsWith(product.objectId, 'product_')) {
      disableLocalProductForDate(product.objectId, newDisableDate);
    } else {
      disableProductForDate(product.objectId, newDisableDate);
    }
  };

  return (
    <Box className={`${classes.draggableProduct}`}>
      <ListItem button onClick={() => setOpenDialog(true)}>
        <Checkbox
          style={{ color: selected ? '#E54439' : '#BDBDBD' }}
          checked={selected}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => onSelect(e.target.checked)}
        />
        <ListItemAvatar>
          {!hideImages && product.imageLink ? (
            <Avatar alt='Image' src={product.imageLink} />
          ) : (
            <Avatar>
              <Image />
            </Avatar>
          )}
        </ListItemAvatar>
        <ListItemText
          primary={
            <Box display='flex' gridColumnGap='20px'>
              <span>{product.productTitle}</span>
              <ReviewLabel importStatus={product?.importStatus} />
            </Box>
          }
          secondary={hideImages ? '' : product.productDescription}
          style={{ paddingRight: menuVersion.posEnabled ? '569px' : '404px' }}
        />
        <ListItemSecondaryAction style={{ pointerEvents: 'none' }}>
          <Box display='flex' gridColumnGap='15px' style={{ pointerEvents: 'none' }}>
            <Box
              style={{
                padding: '20px 0',
                textAlign: 'left',
                width: '100px',
              }}
            >
              {product?.optionIds?.length > 0 &&
                `${product?.optionIds?.length} ${pluralise('option', product?.optionIds?.length)}`}
            </Box>
            <span
              style={{
                padding: '20px 0',
                textAlign: 'left',
                width: '100px',
              }}
            >
              {formatCurrency(product?.price)}
            </span>
            <Button
              variant='text'
              size='medium'
              color={isDisabled ? 'primary' : 'default'}
              startIcon={isDisabled ? <RemoveShoppingCart /> : <ShoppingCart />}
              className={`${classes.listButton} ${isDisabled ? 'listButtonDisabled' : ''}`}
              onClick={toggleIsDisabled}
              title='Toggle stock level'
              style={{
                fontSize: isDisabled ? '12px' : '14px',
                pointerEvents: 'auto',
              }}
            >
              {isDisabled ? 'Out of Stock' : 'In Stock'}
            </Button>
            <Tooltip title='Delete menu item'>
              <IconButton
                edge='end'
                aria-label='delete'
                onClick={handleDelete}
                style={{
                  pointerEvents: 'auto',
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        </ListItemSecondaryAction>
      </ListItem>
    </Box>
  );
};

Product.propTypes = {
  product: PropTypes.object.isRequired,
  deleteProduct: PropTypes.func.isRequired,
  restockProduct: PropTypes.func.isRequired,
  disableProductForDate: PropTypes.func.isRequired,
  disableLocalProductForDate: PropTypes.func.isRequired,
  disableLocalProductUntilDate: PropTypes.func.isRequired,
  setOpenDialog: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  menuVersion: state.menuVersion,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteProduct: deleteProductAction,
      restockProduct: restockProductAction,
      disableProductForDate: disableProductForDateAction,
      disableLocalProductForDate: disableLocalProductForDateAction,
      disableLocalProductUntilDate: disableLocalProductUntilDateAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Product);
