import React, { useEffect, useState } from 'react';
import { SecondaryButton } from '../Button/SecondaryButton';
import BundleOptionAddDialog from './BundleOptionAddDialog';

const BundleOptionAdd = ({
  setSelectedOptionId,
  setDialogContext,
  bundleId,
  selectedOptions,
  setProductOptions,
}) => {
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <>
      <SecondaryButton
        onClick={() => {
          setOpenDialog(true);
          setSelectedOptionId('Add');
          setDialogContext('Add');
        }}
        style={{ text: { fontWeight: 'bold' } }}
      >
        ADD FROM EXISTING
      </SecondaryButton>
      <BundleOptionAddDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        bundleId={bundleId}
        defaultSelectedItems={selectedOptions}
        setProductOptions={setProductOptions}
      />
    </>
  );
};

export default BundleOptionAdd;
