import { removeEmpty } from '../helpers/Helpers';
import store from '../store';
import * as type from './types';
import { menuDataForRestaurant, menusForRestaurant } from '../graphql/queries';
import {
  copyLegacyMenu,
  copyMenu,
  createMenus,
  removeMenus,
  syncMenuCache,
  updateMenus,
} from '../graphql/mutations';
import { devLog } from '../helpers/devLog';
import { makeApiCall } from './actions';

// V1 menus fetch
export const fetchMenusAction = (restaurantId) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'MENUS',
  });

  dispatch({
    type: type.FETCH_MENUS_PENDING,
    payload: {},
  });

  (async () => {
    try {
      const response = await makeApiCall(menusForRestaurant, {
        restId: restaurantId,
      });

      devLog('success', 'menus for restaurant', response);

      dispatch({
        type: type.FETCH_MENUS_SUCCESS,
        payload: {
          menus: response.data.menusForRestaurant,
          restaurantId,
        },
      });
    } catch (error) {
      devLog('error', 'menus for restaurant', error);

      dispatch({
        type: type.SET_TOAST,
        payload: {
          id: `FETCH_MENUS_${new Date().getTime()}`,
          message: `Unable to fetch menus: ${error}`,
          type: 'error',
        },
      });

      dispatch({
        type: type.FETCH_MENUS_FAILURE,
        payload: `Unable to retrieve menus: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'MENUS',
      });
    }
  })();
};

// V2 menus fetch
export const fetchMenuDataAction = (restaurantId, pos) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'MENU_DATA',
  });

  dispatch({
    type: type.FETCH_MENUS_PENDING,
    payload: {},
  });

  (async () => {
    try {
      const response = await makeApiCall(menuDataForRestaurant, {
        restId: restaurantId,
        pos,
      });

      devLog('success', 'menu data for restaurant', response?.data?.menuDataForRestaurant);

      dispatch({
        type: type.FETCH_MENUS_SUCCESS,
        payload: {
          menus: response?.data?.menuDataForRestaurant?.menus?.map((menu) => ({
            ...menu,
            categories: menu.categories || [],
          })),
          restaurantId,
        },
      });

      dispatch({
        type: type.FETCH_CHOICES_SUCCESS,
        payload: response?.data?.menuDataForRestaurant?.choices,
      });

      dispatch({
        type: type.FETCH_OPTIONS_SUCCESS,
        payload: response?.data?.menuDataForRestaurant?.options,
      });

      dispatch({
        type: type.FETCH_PRODUCTS_SUCCESS,
        payload: response?.data?.menuDataForRestaurant?.products,
      });

      dispatch({
        type: type.FETCH_BUNDLE_OPTIONS_SUCCESS,
        payload: response?.data?.menuDataForRestaurant?.bundleOptions,
      });

      dispatch({
        type: type.FETCH_BUNDLES_SUCCESS,
        payload: response?.data?.menuDataForRestaurant?.bundles,
      });

      const categories = response?.data?.menuDataForRestaurant?.menus
        .map((menu) =>
          menu?.categories?.map((cateogry) => ({
            ...cateogry,
            menuId: menu.objectId,
          })),
        )
        .flat()
        .filter((category) => category);

      dispatch({
        type: type.FETCH_CATEGORIES_SUCCESS,
        payload: categories,
      });
    } catch (error) {
      devLog('error', 'menus data restaurant', error);

      dispatch({
        type: type.SET_TOAST,
        payload: {
          id: `FETCH_MENUS_${new Date().getTime()}`,
          message: `Unable to fetch menu data: ${error}`,
          type: 'error',
        },
      });

      dispatch({
        type: type.FETCH_MENUS_FAILURE,
        payload: `Unable to retrieve menu data: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'MENU_DATA',
      });
    }
  })();
};

export const createMenuAction = (newMenu, restaurantId) => (dispatch) => {
  dispatch({
    type: type.CREATE_MENU,
    payload: { newMenu, restaurantId },
  });
};

export const updateMenuAction = (saveMenu) => (dispatch) => {
  dispatch({
    type: type.UPDATE_MENU,
    payload: {
      saveMenu,
    },
  });
};

export const deleteMenuAction = (menuId) => (dispatch) => {
  dispatch({
    type: type.DELETE_MENU,
    payload: { menuId },
  });
};

export const clearMenusAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_MENUS,
  });
};

export const copyLegacyMenuAction =
  (restId, menuId, menuIndex, menuTitle, menuDescription, startTime, endTime, platform, menuType) =>
  (dispatch) => {
    dispatch({
      type: type.SET_ID_APP_LOADING,
      payload: 'LEGACY_MENU_COPY',
    });

    dispatch({
      type: type.COPY_LEGACY_MENU_PENDING,
    });

    (async () => {
      try {
        const response = await makeApiCall(
          copyLegacyMenu,
          removeEmpty({
            menuId,
            restId,
            menuIndex,
            menuTitle,
            menuDescription,
            startTime,
            endTime,
            platform,
            type: menuType,
          }),
        );

        devLog('success', 'copy legacy menu', response.data.copyLegacyMenu);

        dispatch({
          type: type.COPY_LEGACY_MENU_SUCCESS,
          payload: { copiedMenu: response.data.copyLegacyMenu },
        });
      } catch (error) {
        devLog('error', 'copy legacy menu', error);

        dispatch({
          type: type.SET_TOAST,
          payload: {
            id: `LEGACY_MENU_COPY_${new Date().getTime()}`,
            message: `Unable to copy menu: ${error}`,
            type: 'error',
          },
        });

        dispatch({
          type: type.COPY_LEGACY_MENU_FAILURE,
          payload: `Unable to copy legacy menu: ${error}`,
        });
      } finally {
        dispatch({
          type: type.REMOVE_ID_APP_LOADING,
          payload: 'LEGACY_MENU_COPY',
        });
      }
    })();
  };

export const copyMenuAction =
  (restId, menuId, menuIndex, menuTitle, menuDescription, startTime, endTime, menuType) =>
  (dispatch) => {
    dispatch({
      type: type.SET_ID_APP_LOADING,
      payload: 'MENU_COPY',
    });

    dispatch({
      type: type.COPY_MENU_PENDING,
    });

    (async () => {
      try {
        const response = await makeApiCall(
          copyMenu,
          removeEmpty({
            menuId,
            restId,
            menuIndex,
            menuTitle,
            menuDescription,
            startTime,
            endTime,
            type: menuType,
          }),
        );

        devLog('success', 'copy menu', response.data.copyMenu);

        dispatch({
          type: type.COPY_MENU_SUCCESS,
          payload: { copiedMenu: response.data.copyMenu },
        });
      } catch (error) {
        devLog('error', 'copy menu', error);

        dispatch({
          type: type.SET_TOAST,
          payload: {
            id: `MENU_COPY_${new Date().getTime()}`,
            message: `Unable to copy menu: ${error}`,
            type: 'error',
          },
        });

        dispatch({
          type: type.COPY_MENU_FAILURE,
          payload: `Unable to copy menu: ${error}`,
        });
      } finally {
        dispatch({
          type: type.REMOVE_ID_APP_LOADING,
          payload: 'MENU_COPY',
        });
      }
    })();
  };

export const setMenuTabAction = (index) => (dispatch) => {
  dispatch({
    type: type.UPDATE_MENU_TAB,
    payload: index,
  });
};

export const dbRemoveMenus = async (menuIds, restaurantId, dispatch) => {
  try {
    const response = await makeApiCall(removeMenus, {
      restId: restaurantId,
      menuIds,
    });

    devLog('success', 'remove menus', response.data.removeMenus);

    return null;
  } catch (error) {
    devLog('error', 'unable to remove menus', error);

    dispatch({
      type: type.SET_TOAST,
      payload: {
        id: `MENUS_REMOVE_${new Date().getTime()}`,
        message: `Unable to remove menus: ${error}`,
        type: 'error',
      },
    });

    return error;
  }
};

export const dbCreateMenus = async (menus, restaurantId, dispatch) => {
  try {
    const response = await makeApiCall(createMenus, {
      menus: menus.map((menu) =>
        removeEmpty({
          restId: restaurantId,
          type: menu.type,
          enabled: menu.enabled,
          startTime: menu.startTime,
          endTime: menu.endTime,
          menuTitle: menu.menuTitle,
          menuDescription: menu.menuDescription,
          pos: menu.pos,
          platform: menu.platform,
          version: 1,
        }),
      ),
    });

    if (response?.data?.createMenus === undefined || response?.data?.createMenus === null) {
      throw new Error('Response is undefined');
    }

    devLog('success', 'create menus', response.data.createMenus);

    // Also return the original objectId
    return response?.data?.createMenus?.map((createdMenu, index) => {
      dispatch({
        type: type.BACKEND_SAVE_MENU_SUCCESS,
        payload: {
          savedItem: createdMenu,
          itemObjectId: menus[index]._objectId,
        },
      });

      return {
        ...createdMenu,
        _objectId: menus[index]._objectId,
      };
    });
  } catch (error) {
    devLog('error', 'unable to create menus', error);

    dispatch({
      type: type.SET_TOAST,
      payload: {
        id: `MENUS_CREATE_${new Date().getTime()}`,
        message: `Unable to create menus: ${error}`,
        type: 'error',
      },
    });

    return error;
  }
};

export const dbUpdateMenus = async (menus, restaurantId, dispatch) => {
  try {
    const response = await makeApiCall(updateMenus, {
      restId: restaurantId,
      menus: menus.map((menu) =>
        removeEmpty({
          objectId: menu.objectId,
          type: menu.type,
          enabled: menu.enabled,
          startTime: menu.startTime,
          endTime: menu.endTime,
          menuTitle: menu.menuTitle,
          menuDescription: menu.menuDescription,
          platform: menu.platform,
          version: menus.version,
        }),
      ),
    });

    if (response?.data?.updateMenus === undefined || response?.data?.updateMenus === null) {
      throw new Error('Response is undefined');
    }

    devLog('success', 'update menus', response.data.updateMenus);

    return response?.data?.updateMenus?.map((updatedMenu, index) => {
      dispatch({
        type: type.BACKEND_SAVE_MENU_SUCCESS,
        payload: {
          savedItem: updatedMenu,
          itemObjectId: menus[index]._objectId,
        },
      });

      // Also return the original objectId
      return { ...updatedMenu, _objectId: menus[index]._objectId };
    });
  } catch (error) {
    devLog('error', 'unable to update menus', error);

    dispatch({
      type: type.SET_TOAST,
      payload: {
        id: `MENUS_UPDATE_${new Date().getTime()}`,
        message: `Unable to update menus: ${error}`,
        type: 'error',
      },
    });

    return error;
  }
};

export const syncMenuCacheAction = async (restaurantId, pos, syncLocal, dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: `SYNC_MENU_CACHE`,
  });

  try {
    const response = await makeApiCall(syncMenuCache, {
      restId: restaurantId,
      pos,
    });

    if (response?.data?.syncMenuCache === undefined || response?.data?.syncMenuCache === null) {
      throw new Error('Response is undefined');
    }

    devLog('success', 'sync menu cache', response.data.syncMenuCache);

    // TODO NOTE: this could have potentially been where items are disappearing after a bad save

    if (syncLocal) {
      dispatch({
        type: type.FETCH_MENUS_SUCCESS,
        payload: {
          menus: response?.data?.syncMenuCache?.menus?.map((menu) => ({
            ...menu,
            categories: menu.categories || [],
          })),
          restaurantId,
        },
      });

      dispatch({
        type: type.FETCH_CHOICES_SUCCESS,
        payload: response?.data?.syncMenuCache?.choices,
      });

      dispatch({
        type: type.FETCH_OPTIONS_SUCCESS,
        payload: response?.data?.syncMenuCache?.options,
      });

      dispatch({
        type: type.FETCH_PRODUCTS_SUCCESS,
        payload: response?.data?.syncMenuCache?.products,
      });

      dispatch({
        type: type.FETCH_BUNDLE_OPTIONS_SUCCESS,
        payload: response?.data?.syncMenuCache?.bundleOptions,
      });

      dispatch({
        type: type.FETCH_BUNDLES_SUCCESS,
        payload: response?.data?.syncMenuCache?.bundles,
      });

      const categories = response?.data?.syncMenuCache?.menus
        .map((menu) =>
          menu?.categories?.map((cateogry) => ({
            ...cateogry,
            menuId: menu.objectId,
          })),
        )
        .flat()
        .filter((category) => category);

      dispatch({
        type: type.FETCH_CATEGORIES_SUCCESS,
        payload: categories,
      });
    }

    dispatch({
      type: type.SET_TOAST,
      payload: {
        id: `SAVE_ALL_${new Date().getTime()}`,
        message: 'Active menus have been successfully synced.',
        type: 'success',
      },
    });
  } catch (error) {
    devLog('error', 'unable to sync menu cache', error);

    dispatch({
      type: type.SET_TOAST,
      payload: {
        id: `SYNC_MENU_CACHE_${new Date().getTime()}`,
        message: `Unable to sync menu cache: ${error}`,
        type: 'error',
      },
    });

    return error;
  } finally {
    dispatch({
      type: type.REMOVE_ID_APP_LOADING,
      payload: `SYNC_MENU_CACHE`,
    });
  }
};

export const syncMenu = async (dispatch) => {
  const state = store.getState();
  const { posEnabled } = state?.menuVersion;
  const restaurantId = state?.activeRestaurant?.data?.objectId;

  if (posEnabled === state?.activeRestaurant?.data?.posEnabled) {
    await syncMenuCacheAction(restaurantId, posEnabled, false, dispatch);
  }
};

export const setShouldFetchMenusAction = (shouldFetch) => (dispatch) => {
  dispatch({
    type: type.SET_SHOULD_FETCH_MENUS,
    payload: shouldFetch,
  });
};
