import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List, ListItem, ListItemAvatar, ListItemText, Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useRollbar } from '@rollbar/react';
import { addOptionsToProductAction } from '../../../../actions/productsAction';
import { trimString } from '../../../../helpers/Helpers';
import OptionAdd from '../menuOption/OptionAdd';
import OptionCreate from '../menuOption/OptionCreate';
import useStyles from './ProductOptionsStyles';
import ProductOption from './ProductOption';
import OptionEditDialog from '../menuOption/OptionEditDialog';
import { dialogContexts } from '../../../../constants/constants';

const ProductOptions = ({
  create,
  addOptionsToProductAction,
  options,
  productId,
  products,
  choices,
  menuVersion,
}) => {
  const rollbar = useRollbar();
  const classes = useStyles();
  const [storedOptions, setStoredOptions] = useState(products.storedOptions || []);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContext, setDialogContext] = useState('');
  const [selectedOptionId, setSelectedOptionId] = useState(false);

  // Get choices for each option
  const [processedOptions, setProcessedOptions] = useState([]);
  useEffect(() => {
    setProcessedOptions(
      options?.localData.map((option) => {
        let choicesForOption = [];

        choices?.localData?.forEach((choice) => {
          if (option?.choiceIds?.includes(choice.objectId)) {
            choicesForOption = [...choicesForOption, choice?.choiceTitle];
          }
        });

        return {
          ...option,
          labelledChoices: choicesForOption,
        };
      }),
    );
  }, [options?.localData]);

  // Update stored options to reflect state
  useEffect(() => {
    setStoredOptions(products?.storedOptions);
  }, [products?.storedOptions, productId]);

  const product = products?.localData?.find(
    (productToCheck) => productId === productToCheck.objectId,
  );

  const onDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    // dropped in same position
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    /*
     * Re-order options
     */

    const newStoredOptions = Array.from(storedOptions);

    // Remove choice being dragged, and store it
    const dragOption = newStoredOptions.splice(source.index, 1)[0];

    // Add dragged choice back at the new index
    newStoredOptions.splice(destination.index, 0, dragOption);

    setStoredOptions(newStoredOptions);
    addOptionsToProductAction(newStoredOptions);
  };

  if (products.fetching && !create) {
    return (
      <Box
        style={{
          borderTop: '1px solid rgba(0, 0, 0, 0.23) !important',
          marginTop: '1rem',
        }}
      >
        <List>
          <ListItem>
            <ListItemAvatar>
              <Skeleton variant='circle' width={40} height={40} />
            </ListItemAvatar>
            <ListItemText>
              <Skeleton height={10} width='100%' style={{ marginBottom: 6 }} />
              <Skeleton height={10} width='80%' />
            </ListItemText>
          </ListItem>
        </List>
      </Box>
    );
  }

  if (products?.error) {
    return (
      <Box
        style={{
          borderTop: '1px solid rgba(0, 0, 0, 0.23)',
          marginTop: '1rem',
        }}
      >
        {products?.errorMessage}
      </Box>
    );
  }

  return (
    <>
      <Box className={`${classes.boxOutlined}`}>
        <List dense={false}>
          <DragDropContext onDragEnd={onDragEnd}>
            {storedOptions?.length === 0 && (
              <Box className={classes.emptyText}>
                There are currently no options attached to this menu item
              </Box>
            )}
            {storedOptions?.length > 0 && (
              <Droppable droppableId={productId} type='menu-item-option-choice'>
                {(provided, snapshot) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={`${classes.droppableMenuItemOption} ${
                      snapshot.isDraggingOver ? 'dragging-over' : ''
                    } `}
                  >
                    {storedOptions?.map((productOptionId, index) => {
                      const productOption = processedOptions.find(
                        (option) => productOptionId === option.objectId,
                      );

                      if (!productOption) {
                        rollbar.error(`Error: Missing menu item option ${productOptionId}`);
                        return <></>;
                      }

                      return (
                        <ProductOption
                          dialogContext={dialogContext}
                          index={index}
                          key={productOption.objectId}
                          option={productOption}
                          setDialogContext={setDialogContext}
                          setOpenDialog={setOpenDialog}
                          setSelectedOptionId={setSelectedOptionId}
                          labelledChoices={trimString(
                            productOption?.labelledChoices?.join(', '),
                            15,
                          )}
                          product={product}
                        />
                      );
                    })}
                  </Box>
                )}
              </Droppable>
            )}
            {!menuVersion.posEnabled && (
              <Box display='flex' style={{ marginTop: '20px' }}>
                <OptionAdd
                  productId={productId}
                  setOpenDialog={setOpenDialog}
                  setDialogContext={setDialogContext}
                  setSelectedOptionId={setSelectedOptionId}
                  selectedOptions={products.storedOptions}
                />
                <OptionCreate buttonLabel='+ CREATE NEW' buttonType='text' productId={productId} />
              </Box>
            )}
            {selectedOptionId && (
              <OptionEditDialog
                option={
                  processedOptions.find((option) => option.objectId === selectedOptionId) || {}
                }
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                dialogContext={dialogContexts.EDIT}
                setDialogContext={setDialogContext}
              />
            )}
          </DragDropContext>
        </List>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  options: state.options,
  products: state.products,
  choices: state.choices,
  menuVersion: state.menuVersion,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addOptionsToProductAction,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapDispatchToProps)(ProductOptions);
