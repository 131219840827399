import { devLog } from '../helpers/devLog';
import { importDoshiiMenu, setPosId } from '../graphql/mutations';
import * as type from './types';
import { makeApiCall } from './actions';

export const importDoshiiAction = (restaurantId) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'IMPORT_DOSHII',
  });

  dispatch({
    type: type.IMPORT_DOSHII_PENDING,
  });

  (async () => {
    try {
      const response = await makeApiCall(importDoshiiMenu, {
        restId: restaurantId,
      });

      devLog('success', 'import doshii menu', response.data.importDoshiiMenu);

      dispatch({
        type: type.IMPORT_DOSHII_SUCCESS,
        payload: response.data.importDoshiiMenu,
      });

      dispatch({
        type: type.SET_TOAST,
        payload: {
          id: `IMPORT_DOSHII_SUCCESS_${new Date().getTime()}`,
          message: 'Successfully imported all items from Doshii. Please review them to continue.',
          type: 'success',
        },
      });
    } catch (error) {
      devLog('error', 'import doshii menu', error);

      dispatch({
        type: type.SET_TOAST,
        payload: {
          id: `IMPORT_DOSHII_${new Date().getTime()}`,
          message: `Unable to import doshii: ${error}`,
          type: 'error',
        },
      });

      dispatch({
        type: type.IMPORT_DOSHII_FAILURE,
        payload: `Unable to import doshii: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'IMPORT_DOSHII',
      });
    }
  })();
};

export const setPosIdAction = (restId, posId) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'SET_POS_ID',
  });

  dispatch({
    type: type.SET_POS_ID_PENDING,
  });

  (async () => {
    try {
      const response = await makeApiCall(setPosId, { restId, posId });

      devLog('success', 'setPosId', response.data.setPosId);

      dispatch({
        type: type.SET_POS_ID_SUCCESS,
        payload: response.data.setPosId,
      });

      dispatch({
        type: type.SET_TOAST,
        payload: {
          id: `SET_POS_ID_SUCCESS_${new Date().getTime()}`,
          message: 'The POS ID has been successfully set.',
          type: 'success',
        },
      });
    } catch (error) {
      devLog('error', 'setPosId', error);

      dispatch({
        type: type.SET_TOAST,
        payload: {
          id: `SET_POS_ID_${new Date().getTime()}`,
          message: `Unable to set the POS ID: ${error}`,
          type: 'error',
        },
      });

      dispatch({
        type: type.SET_POS_ID_FAILURE,
        payload: `Unable to set the POS ID: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'SET_POS_ID',
      });
    }
  })();
};
