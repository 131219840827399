import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Box,
  ListItemAvatar,
  Avatar,
  Tooltip,
} from '@material-ui/core';
import { Delete, Image } from '@material-ui/icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { deleteOptionAction } from '../../../../actions/optionsAction';
import OptionEditDialog from './OptionEditDialog';
import ReviewLabel from '../ReviewButton/ReviewLabel';
import useStyles from './OptionStyles';
import { dialogContexts } from '../../../../constants/constants';

const Option = ({ option, deleteOptionAction }) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);

  const handleDelete = () => {
    deleteOptionAction(option.objectId);
  };

  return (
    <Box>
      <ListItem button onClick={() => setOpenDialog(true)}>
        <Box display='flex' gridColumnGap='20px'>
          <ListItemText primary={option.optionTitle} secondary={option.optionDescription} />
          <ReviewLabel importStatus={option?.importStatus} />
        </Box>
        <ListItemSecondaryAction>
          <Tooltip title='Delete option'>
            <IconButton edge='end' aria-label='delete' onClick={handleDelete}>
              <Delete />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
      <OptionEditDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        option={option}
        dialogContext={dialogContexts.EDIT}
      />
    </Box>
  );
};

Option.propTypes = {
  deleteOptionAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({ deleteOptionAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Option);
