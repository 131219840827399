import React from 'react';
// import PropTypes from "prop-types";
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { List, ListSubheader, Divider, Box } from '@material-ui/core';
import MenuItem from '../menuItem/MenuItem';
import useStyles from './MenuCategoryStyles';
import MenuCategoryOptionButton from '../menuCategoryOptions/MenuCategoryOptionButton';
import MenuItemCreate from '../menuItemCreate/MenuItemCreate';

const MenuCategory = ({ category, index }) => {
  const classes = useStyles();
  return (
    <Draggable draggableId={category.category} index={index}>
      {(providedCategory, snapshot) => (
        <Box
          className={`${classes.draggableCategory} ${snapshot.isDragging ? 'dragging' : ''} `}
          ref={providedCategory.innerRef}
          {...providedCategory.draggableProps}
          boxShadow={snapshot.isDragging ? 3 : 0}
        >
          <List
            dense={false}
            subheader={
              <ListSubheader
                component='div'
                id='category-list-subheader'
                color='primary'
                className={classes.listSubheader}
                {...providedCategory.dragHandleProps}
              >
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                  <span>{category.category}</span>
                  <MenuCategoryOptionButton category={category} />
                </Box>
              </ListSubheader>
            }
          >
            <Droppable droppableId={category.category} type='menu-item'>
              {(provided, snapshot) => (
                <Box
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={`${classes.droppableMenuItem} ${
                    snapshot.isDraggingOver ? 'dragging-over' : ''
                  } `}
                >
                  {category.menuItems
                    .filter((mItem) => !mItem._placeholder)
                    .sort((a, b) => a.itemIndex - b.itemIndex)
                    .map((menuItem, index) => (
                      <MenuItem key={menuItem.objectId} menuItem={menuItem} index={index} />
                    ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
            <MenuItemCreate category={category} />
          </List>
          <Divider />
        </Box>
      )}
    </Draggable>
  );
};

// MenuCategory.propTypes = {};

export default MenuCategory;
