import React, { useState } from 'react';
import {
  ListItem,
  ListItemIcon,
  Box,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Button,
} from '@material-ui/core';
import { Draggable } from 'react-beautiful-dnd';
import { Delete, RemoveShoppingCart, ShoppingCart } from '@material-ui/icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import useStyles from './MenuItemOptionChoiceStyles';
import { removeSpaces } from '../../helpers/Helpers';
import { ReactComponent as Icon } from '../../assets/two-bars-icon.svg';
import {
  disableMenuItemOptionChoiceAction,
  deleteChoiceAction,
} from '../../actions/menuItemOptionChoicesAction';

const formatCurrency = (num) => `$${num.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;

const MenuItemOptionChoice = ({
  choice,
  deleteChoiceAction,
  disableMenuItemOptionChoiceAction,
  index,
  menuItemId,
  menuItemOptionId,
  setDialogContext,
  setOpenDialog,
  setSelectedChoiceIndex,
}) => {
  const classes = useStyles();

  const [isDisabled, setIsDisabled] = useState(
    moment(choice.disableDate, 'yyyy-MM-DD').isSame(moment(), 'day'),
  );

  const toggleIsDisabled = () => {
    if (isDisabled) {
      setIsDisabled(false);
      disableMenuItemOptionChoiceAction(index, 'clear');
      return;
    }

    const newDisableDate = moment().format('yyyy-MM-DD');
    setIsDisabled(newDisableDate);
    disableMenuItemOptionChoiceAction(index, newDisableDate);
  };

  const handleDelete = () => {
    deleteChoiceAction(index);
  };

  return (
    <Draggable draggableId={`${removeSpaces(choice.title)}`} index={index}>
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={`${classes.draggableMenuItemOptionChoice} ${
            snapshot.isDragging ? 'dragging' : ''
          } ${snapshot.isDropAnimating ? 'animating' : ''}`}
          boxShadow={snapshot.isDragging ? 3 : 0}
        >
          <ListItem
            button
            onClick={() => {
              setOpenDialog(true);
              setDialogContext('Edit');
              setSelectedChoiceIndex(index);
            }}
            style={{ paddingRight: '270px' }}
          >
            <ListItemIcon title='Drag to re-order' {...provided.dragHandleProps}>
              <Icon style={{ fill: 'rgba(0, 0, 0, 0.54)' }} />
            </ListItemIcon>
            <ListItemText primary={choice.title} />
            <ListItemSecondaryAction>
              {choice.price && (
                <span style={{ padding: '0 1rem' }}>{formatCurrency(choice.price)}</span>
              )}
              <Button
                variant='text'
                size='medium'
                className={`${classes.listButton} ${isDisabled ? 'listButtonDisabled' : ''}`}
                startIcon={isDisabled ? <RemoveShoppingCart /> : <ShoppingCart />}
                onClick={toggleIsDisabled}
                title='Toggle stock level'
                style={{ fontSize: isDisabled ? '12px' : '14px' }}
              >
                {isDisabled ? 'Out of Stock' : 'In Stock'}
              </Button>
              <IconButton
                edge='end'
                aria-label='delete'
                onClick={handleDelete}
                title='Delete menu item'
              >
                <Delete />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </Box>
      )}
    </Draggable>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ deleteChoiceAction, disableMenuItemOptionChoiceAction }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(MenuItemOptionChoice);
