import { filterUnique } from '../helpers/ArrayUtils';
import { noDiffToNull, parseJson, removeEmpty, startsWith } from '../helpers/Helpers';
import * as type from './types';
import { removeMenus, saveMenu, saveMenuItem, saveMenuItemOption } from '../graphql/mutations';
import { isEmpty } from '../constants/constants';
import { devLog } from '../helpers/devLog';
import { dbCreateChoices, dbRemoveChoices, dbUpdateChoices } from './choicesAction';
import { dbCreateOptions, dbRemoveOptions, dbUpdateOptions } from './optionsAction';
import { dbCreateProducts, dbRemoveProducts, dbUpdateProducts } from './productsAction';
import { dbCreateMenus, dbRemoveMenus, dbUpdateMenus, syncMenuCacheAction } from './menusAction';
import { dbCreateCategories, dbRemoveCategories, dbUpdateCategories } from './categoriesAction';
import { dbCreateBundles, dbRemoveBundles, dbUpdateBundles } from './bundlesAction';
import {
  dbCreateBundleOptions,
  dbRemoveBundleOptions,
  dbUpdateBundleOptions,
} from './bundleOptionsAction';
import { getMenuItemImageUploadUrlAction } from './menuItemImageAction';
import { makeApiCall } from './actions';
import store from '../store';

/*
 * SAVE MENU
 */

const dbSaveMenu = async (menu, restaurantId, version, dispatch) => {
  try {
    // Delete item if flagged for deletion
    if (menu?._deleted) {
      const respsonse = await makeApiCall(removeMenus, {
        restId: restaurantId,
        menuIds: [menu?.objectId],
      });

      devLog('success', 'remove menu', respsonse.data.removeMenus);

      return null;
    }

    const response = await makeApiCall(
      saveMenu,
      removeEmpty({
        objectId: menu.objectId,
        restaurantIds: menu.restaurantIds,
        menuTitle: menu.menuTitle,
        menuDescription: menu.menuDescription,
        enabled: menu.enabled,
        menuIndex: menu.menuIndex,
        startTime: menu.startTime,
        endTime: menu.endTime,
        type: menu.type,
        platform: menu.platform,
        version,
        restId: restaurantId,
      }),
    );

    devLog('success', 'save menu', response.data.saveMenu);

    // Also return the original objectId
    return { ...response.data.saveMenu, _objectId: menu._objectId };
  } catch (error) {
    devLog('error', 'unable to save menu', error);

    dispatch({
      type: type.SET_TOAST,
      payload: {
        id: `MENU_SAVE_${new Date().getTime()}`,
        message: `Unable to save menu: ${error}`,
        type: 'error',
      },
    });

    throw error;
  }
};

/*
 * SAVE ITEM
 */

const dbSaveMenuItem = async (item, savedMenus, restaurantId, dispatch) => {
  try {
    let imageLink = null;
    if (!isEmpty(item._image) && !item._deleted) {
      imageLink = await getMenuItemImageUploadUrlAction(item, restaurantId);
    }

    const parentMenu = savedMenus.find((menu) => menu._objectId === item.menuId);

    const menuId = parentMenu ? parentMenu.objectId : item.menuId;

    const newMenuItem = {
      ...item,
      ...(imageLink && { imageLink }),
      menuId,
    };

    const response = await makeApiCall(saveMenuItem, {
      objectId: newMenuItem.objectId,
      price: newMenuItem.price,
      imageLink: newMenuItem.imageLink,
      itemIndex: newMenuItem.itemIndex,
      itemDescription: newMenuItem.itemDescription,
      itemTitle: newMenuItem.itemTitle,
      category: newMenuItem.category,
      enabled: newMenuItem.enabled,
      menuId: newMenuItem.menuId,
      disableDate: newMenuItem.disableDate,
      disableUntilDate: newMenuItem.disableUntilDate,
      noDiscount: newMenuItem.noDiscount,
    });

    devLog('success', 'save menu item', response.data.saveMenuItem);

    if (!item._deleted) {
      dispatch({
        type: type.BACKEND_SAVE_MENU_ITEM_SUCCESS,
        payload: {
          savedItem: response.data.saveMenuItem,
          itemObjectId: item._objectId,
        },
      });
    }

    // Also return the original objectId
    return { ...response.data.saveMenuItem, _objectId: item._objectId };
  } catch (error) {
    devLog('error', 'unable to save menu item', error);

    dispatch({
      type: type.SET_TOAST,
      payload: {
        id: `MENU_ITEM_SAVE_${new Date().getTime()}`,
        message: `Unable to save menu item: ${error}`,
        type: 'error',
      },
    });
    throw error;
  }
};

/*
 * SAVE OPTION
 */

const dbSaveOption = async (option, savedItems, dispatch) => {
  try {
    const parentItem = savedItems.find((item) => item._objectId === option.menuItemId);

    if (!parentItem) {
      console.error('item is missing its parent');
      return;
    }

    // TODO: bug, sometimes parentItem is undefined before the "savedItems" are empty

    if (!parentItem?.menuId) {
      console.error('Error: cannot find menuId!');
      return;
    }

    const menuItemId = parentItem ? parentItem.objectId : option.menuItemId;

    const response = await makeApiCall(
      saveMenuItemOption,
      removeEmpty({
        objectId: option.objectId,
        menuId: parentItem.menuId,
        menuItemId,
        optionIndex: option.optionIndex,
        optionDescription: option.optionDescription,
        optionTitle: option.optionTitle,
        minSelections: option.minSelections,
        maxSelections: option.maxSelections,
        mandatory: option.mandatory,
        enabled: option.enabled,
        choices: JSON.stringify(option.choices),
      }),
    );

    devLog('success', 'save menu item option', response.data.saveMenuItemOption);

    dispatch({
      type: type.BACKEND_SAVE_MENU_ITEM_OPTION_SUCCESS,
      payload: {
        savedOption: response.data.saveMenuItemOption,
        optionObjectId: option._objectId,
        itemId: option.menuItemId,
      },
    });

    return { ...response.data.saveMenuItemOption, _objectId: option._objectId };
  } catch (error) {
    devLog('error', 'unable to save menu item option', error);

    dispatch({
      type: type.SET_TOAST,
      payload: {
        id: `MENU_ITEM_OPTION_SAVE_${new Date().getTime()}`,
        message: `Unable to save menu item option: ${error}`,
        type: 'error',
      },
    });
    throw error;
  }
};

/*
 * SAVE MENUS, ITEMS, AND OPTIONS
 */

export const dbSaveAllAction =
  (rollbar, shouldFetch = false) =>
  (dispatch) => {
    dispatch({
      type: type.BACKEND_SAVE_PENDING,
    });

    const state = store.getState();
    const { choices, options, products, bundleOptions, bundles, categories, menus } = state;
    const { posEnabled } = state?.menuVersion;
    const restaurantId = state?.activeRestaurant?.data?.objectId;

    const activeMenus = menus?.localData?.filter((menu) => menu?.enabled);
    const activeCategories = [...categories?.localData, ...categories?.deleted]?.filter(
      (category) => activeMenus.some((menu) => menu.objectId === category.menuId),
    );
    const activeBundles = [...bundles?.localData, ...bundles?.deleted]?.filter((bundle) =>
      activeCategories.some(
        (category) => parseJson(category?.bundleIds)?.bundleId === bundle.objectId,
      ),
    );
    const activeBundleOptions = [...bundleOptions?.localData, ...bundleOptions?.deleted]?.filter(
      (bundleOption) =>
        activeBundles.some((bundle) => bundle.bundleOptionIds?.includes(bundleOption.objectId)),
    );
    const activeProducts = [...products?.localData, ...products?.deleted]?.filter((product) =>
      [...activeBundleOptions, ...activeCategories].some((activeParent) =>
        activeParent.productIds?.includes(product.objectId),
      ),
    );
    const activeOptions = [...options?.localData, ...options?.deleted]?.filter((option) =>
      activeProducts.some((product) => product.optionIds?.includes(option.objectId)),
    );
    const activeChoices = [...choices?.localData, ...choices?.deleted]?.filter((choice) =>
      activeOptions.some((option) => option.choiceIds?.includes(choice.objectId)),
    );

    devLog(
      'info',
      'activeMenus, activeCategories, activeBundles, activeBundleOptions, activeProducts, activeOptions, activeChoices',
      [
        activeMenus,
        activeCategories,
        activeBundles,
        activeBundleOptions,
        activeProducts,
        activeOptions,
        activeChoices,
      ],
    );

    // get CHOICES to save
    const createdChoices = choices.localData
      .filter((choice) => choice._created)
      .map((choice) => ({
        ...choice,
        _objectId: choice.objectId,
      }));

    const alteredChoices = choices.localData
      .filter((choice) => choice._altered && !choice._created)
      .map((choice) => {
        const dbChoice = choices.data.find((x) => x.objectId === choice.objectId);

        return {
          objectId: choice.objectId,
          choiceTitle: noDiffToNull(choice?.choiceTitle, dbChoice?.choiceTitle),
          price: noDiffToNull(choice?.price, dbChoice?.price),
          disableDate: noDiffToNull(choice?.disableDate, dbChoice?.disableDate),
          disableUntilDate: noDiffToNull(choice?.disableUntilDate, dbChoice?.disableUntilDate),
          importStatus: noDiffToNull(choice?.importStatus, dbChoice?.importStatus),
          _objectId: choice.objectId,
        };
      });

    const deletedChoiceIds = choices.deleted
      .filter((choice) => !choice._created)
      .map((choice) => choice.objectId);

    // get OPTIONS to save
    const createdOptions = options.localData
      .filter((option) => option._created)
      .map((option) => ({
        ...option,
        _objectId: option.objectId,
      }));

    const alteredOptions = options.localData
      .filter((option) => option._altered && !option._created)
      .map((option) => {
        const dbOption = options.data.find((x) => x.objectId === option.objectId);

        return {
          objectId: option.objectId,
          optionTitle: noDiffToNull(option?.optionTitle, dbOption?.optionTitle),
          optionDescription: noDiffToNull(option?.optionDescription, dbOption?.optionDescription),
          mandatory: noDiffToNull(option?.mandatory, dbOption?.mandatory),
          minSelections: noDiffToNull(option?.minSelections, dbOption?.minSelections),
          maxSelections: noDiffToNull(option?.maxSelections, dbOption?.maxSelections),
          importStatus: noDiffToNull(option?.importStatus, dbOption?.importStatus),
          choiceIds: noDiffToNull(option?.choiceIds, dbOption?.choiceIds),
          _objectId: option.objectId,
        };
      });

    const deletedOptionIds = options?.deleted
      ?.filter((option) => !option._created)
      ?.map((option) => option.objectId);

    // Get PRODUCTS to save
    const createdProducts = products.localData
      .filter((product) => product._created)
      .map((product) => ({
        ...product,
        _objectId: product.objectId,
      }));

    const alteredProducts = products.localData
      .filter((product) => product._altered && !product._created)
      .map((product) => {
        const dbProduct = products.data.find((x) => x.objectId === product.objectId);

        return {
          objectId: product.objectId,
          productTitle: noDiffToNull(product?.productTitle, dbProduct?.productTitle),
          productDescription: noDiffToNull(
            product?.productDescription,
            dbProduct?.productDescription,
          ),
          imageLink: noDiffToNull(product?.imageLink, dbProduct?.imageLink),
          price: noDiffToNull(product?.price, dbProduct?.price),
          noDiscount: noDiffToNull(product?.noDiscount, dbProduct?.noDiscount),
          disableDate: noDiffToNull(product?.disableDate, dbProduct?.disableDate),
          disableUntilDate: noDiffToNull(product?.disableUntilDate, dbProduct?.disableUntilDate),
          importStatus: noDiffToNull(product?.importStatus, dbProduct?.importStatus),
          optionIds: noDiffToNull(product?.optionIds, dbProduct?.optionIds),
          _image: product._image,
          _objectId: product.objectId,
        };
      });

    const deletedProductIds = products.deleted
      .filter((product) => !product._created)
      .map((product) => product.objectId);

    // Get BUNDLE OPTIONS to save
    const createdBundleOptions = bundleOptions.localData
      .filter((bundleOption) => bundleOption._created)
      .map((bundleOption) => ({
        ...bundleOption,
        _objectId: bundleOption.objectId,
      }));

    const alteredBundleOptions = bundleOptions.localData
      .filter((bundleOption) => bundleOption._altered && !bundleOption._created)
      .map((bundleOption) => {
        const dbBundleOption = bundleOptions.data.find((x) => x.objectId === bundleOption.objectId);

        return {
          objectId: bundleOption.objectId,
          bundleOptionTitle: noDiffToNull(
            bundleOption?.bundleOptionTitle,
            dbBundleOption?.bundleOptionTitle,
          ),
          bundleOptionDescription: noDiffToNull(
            bundleOption?.bundleOptionDescription,
            dbBundleOption?.bundleOptionDescription,
          ),
          mandatory: noDiffToNull(bundleOption?.mandatory, dbBundleOption?.mandatory),
          minSelections: noDiffToNull(bundleOption?.minSelections, dbBundleOption?.minSelections),
          maxSelections: noDiffToNull(bundleOption?.maxSelections, dbBundleOption?.maxSelections),
          importStatus: noDiffToNull(bundleOption?.importStatus, dbBundleOption?.importStatus),
          productIds: noDiffToNull(bundleOption?.productIds, dbBundleOption?.productIds),
          _objectId: bundleOption.objectId,
        };
      });

    const deletedBundleOptionIds = bundleOptions.deleted
      .filter((bundleOption) => !bundleOption._created)
      .map((bundleOption) => bundleOption.objectId);

    // Get BUNDLES to save
    const createdBundles = bundles.localData
      .filter((bundle) => bundle._created)
      .map((bundle) => ({
        ...bundle,
        _objectId: bundle.objectId,
      }));

    const alteredBundles = bundles.localData
      .filter((bundle) => bundle._altered && !bundle._created)
      .map((bundle) => {
        const dbBundle = bundles.data.find((x) => x.objectId === bundle.objectId);

        return {
          objectId: bundle.objectId,
          bundleTitle: noDiffToNull(bundle?.bundleTitle, dbBundle?.bundleTitle),
          bundleDescription: noDiffToNull(bundle?.bundleDescription, dbBundle?.bundleDescription),
          imageLink: noDiffToNull(bundle?.imageLink, dbBundle?.imageLink),
          price: noDiffToNull(bundle?.price, dbBundle?.price),
          noDiscount: noDiffToNull(bundle?.noDiscount, dbBundle?.noDiscount),
          disableDate: noDiffToNull(bundle?.disableDate, dbBundle?.disableDate),
          disableUntilDate: noDiffToNull(bundle?.disableUntilDate, dbBundle?.disableUntilDate),
          importStatus: noDiffToNull(bundle?.importStatus, dbBundle?.importStatus),
          bundleOptionIds: noDiffToNull(bundle?.bundleOptionIds, dbBundle?.bundleOptionIds),
          _image: bundle._image,
          _objectId: bundle.objectId,
        };
      });

    const deletedBundleIds = bundles.deleted
      .filter((bundle) => !bundle._created)
      .map((bundle) => bundle.objectId);

    // Get CATEGORIES to save
    const createdCategories = categories.localData
      .filter((category) => category._created)
      .map((category) => ({
        ...category,
        pos: posEnabled,
        _objectId: category.objectId,
      }));

    const alteredCategories = categories.localData
      .filter((category) => category._altered && !category._created)
      .map((category) => {
        const dbCateogry = categories.data.find((x) => x.objectId === category.objectId);

        const productsWithoutDuplicates = (category?.productIds || []).filter(filterUnique);
        const bundlesWithoutDuplicates = (category?.bundleIds || []).filter(filterUnique);

        return {
          objectId: category.objectId,
          menuId: category.menuId,
          categoryTitle: noDiffToNull(category?.categoryTitle, dbCateogry?.categoryTitle),
          categoryIndex: noDiffToNull(category?.categoryIndex, dbCateogry?.categoryIndex),
          productIds: noDiffToNull(productsWithoutDuplicates, dbCateogry?.productIds),
          bundleIds: noDiffToNull(bundlesWithoutDuplicates, dbCateogry?.bundleIds),
          _objectId: category.objectId,
        };
      });

    const deletedCategoryIds = categories.deleted
      .filter((category) => !category._created)
      .map((category) => category.objectId);

    // Get MENUS to save
    const createdMenus = menus.localData
      .filter((menu) => menu._created)
      .map((menu) => ({ ...menu, pos: posEnabled, _objectId: menu.objectId }));

    const alteredMenus = menus.localData
      .filter((menu) => menu._altered && !menu._created)
      .map((menu) => {
        const dbMenu = menus.data.find((x) => x.objectId === menu.objectId);

        return {
          objectId: menu.objectId,
          type: noDiffToNull(menu?.type, dbMenu?.type),
          enabled: noDiffToNull(menu?.enabled, dbMenu?.enabled),
          startTime: noDiffToNull(menu?.startTime, dbMenu?.startTime),
          endTime: noDiffToNull(menu?.endTime, dbMenu?.endTime),
          menuTitle: noDiffToNull(menu?.menuTitle, dbMenu?.menuTitle),
          menuDescription: noDiffToNull(menu?.menuDescription, dbMenu?.menuDescription),
          platform: noDiffToNull(menu?.platform, dbMenu?.platform),
          version: noDiffToNull(menu?.version, dbMenu?.version),
          _objectId: menu.objectId,
        };
      });

    const deletedMenuIds = menus.deleted
      .filter((menu) => !menu._created)
      .map((menu) => menu.objectId);

    (async () => {
      try {
        /* CHOICES */
        const savedChoices = (
          await Promise.all([
            new Promise((resolve) =>
              deletedChoiceIds.length > 0
                ? resolve(dbRemoveChoices(deletedChoiceIds, restaurantId, dispatch))
                : resolve(null),
            ),
            new Promise((resolve) =>
              createdChoices.length > 0
                ? resolve(dbCreateChoices(createdChoices, restaurantId, dispatch))
                : resolve(null),
            ),
            new Promise((resolve) =>
              alteredChoices.length > 0
                ? resolve(dbUpdateChoices(alteredChoices, restaurantId, dispatch))
                : resolve(null),
            ),
          ])
        )
          .flat()
          .filter((choice) => choice !== null);

        if (savedChoices.filter((savedChoice) => savedChoice instanceof Error).length > 0) {
          throw new Error('Error saving choices');
        }

        dispatch({
          type: type.BACKEND_SAVE_CHOICES_SUCCESS,
        });

        // Replace options that have unsaved choice IDs with the correct database ID
        const choiceIdMap = savedChoices.reduce((mappedChoices, savedChoice) => {
          const newMappedChoices = { ...mappedChoices };
          newMappedChoices[savedChoice?._objectId] = savedChoice?.objectId;
          return newMappedChoices;
        }, []);

        const updatedCreatedOptions = createdOptions.map((option) => {
          const optionChoices = option.choiceIds?.map((choiceId) =>
            choiceId in choiceIdMap ? choiceIdMap[choiceId] : choiceId,
          );

          return {
            ...option,
            choiceIds: optionChoices?.filter((choiceId) => {
              if (!startsWith(choiceId, 'choice_')) {
                return true;
              }

              rollbar.error(`Error: Unable to map choice id ${choiceId}`);
              return false;
            }),
          };
        });

        const updatedAlteredOptions = alteredOptions.map((option) => {
          const optionChoices = option.choiceIds?.map((choiceId) =>
            choiceId in choiceIdMap ? choiceIdMap[choiceId] : choiceId,
          );

          return {
            ...option,
            choiceIds: optionChoices?.filter((choiceId) => {
              if (!startsWith(choiceId, 'choice_')) {
                return true;
              }

              rollbar.error(`Error: Unable to map choice id ${choiceId}`);
              return false;
            }),
          };
        });

        /* OPTIONS */
        const savedOptions = (
          await Promise.all([
            new Promise((resolve) =>
              deletedOptionIds.length > 0
                ? resolve(dbRemoveOptions(deletedOptionIds, restaurantId, dispatch))
                : resolve(null),
            ),
            new Promise((resolve) =>
              updatedCreatedOptions.length > 0
                ? resolve(dbCreateOptions(updatedCreatedOptions, restaurantId, dispatch))
                : resolve(null),
            ),
            new Promise((resolve) =>
              updatedAlteredOptions.length > 0
                ? resolve(dbUpdateOptions(updatedAlteredOptions, restaurantId, dispatch))
                : resolve(null),
            ),
          ])
        )
          .flat()
          .filter((option) => option !== null);

        if (savedOptions.filter((savedOption) => savedOption instanceof Error).length > 0) {
          throw new Error('Error saving options');
        }

        dispatch({
          type: type.BACKEND_SAVE_OPTIONS_SUCCESS,
        });

        // Replace products that have unsaved option IDs with the correct database ID
        const optionIdMap = savedOptions.reduce((mappedOptions, savedOption) => {
          const newMappedOptions = { ...mappedOptions };
          newMappedOptions[savedOption?._objectId] = savedOption?.objectId;
          return newMappedOptions;
        }, []);

        const updatedCreatedProducts = createdProducts.map((product) => {
          const productOptions = product.optionIds.map((optionId) =>
            optionId in optionIdMap ? optionIdMap[optionId] : optionId,
          );

          return {
            ...product,
            optionIds: productOptions?.filter((optionId) => {
              if (!startsWith(optionId, 'option_')) {
                return true;
              }

              rollbar.error(`Error: Unable to map option id ${optionId}`);
              return false;
            }),
          };
        });

        const updatedAlteredProducts = alteredProducts?.map((product) => {
          const productOptions = product?.optionIds?.map((optionId) =>
            optionId in optionIdMap ? optionIdMap[optionId] : optionId,
          );

          return {
            ...product,
            optionIds: productOptions?.filter((optionId) => {
              if (!startsWith(optionId, 'option_')) {
                return true;
              }

              rollbar.error(`Error: Unable to map option id ${optionId}`);
              return false;
            }),
          };
        });

        /* PRODUCTS */
        const savedProducts = (
          await Promise.all([
            new Promise((resolve) =>
              deletedProductIds.length > 0
                ? resolve(dbRemoveProducts(deletedProductIds, restaurantId, dispatch))
                : resolve(null),
            ),
            new Promise((resolve) =>
              updatedCreatedProducts.length > 0
                ? resolve(dbCreateProducts(updatedCreatedProducts, restaurantId, dispatch))
                : resolve(null),
            ),
            new Promise((resolve) =>
              updatedAlteredProducts.length > 0
                ? resolve(dbUpdateProducts(updatedAlteredProducts, restaurantId, dispatch))
                : resolve(null),
            ),
          ])
        )
          .flat()
          .filter((product) => product !== null);

        // Error checking
        if (savedProducts.filter((savedProduct) => savedProduct instanceof Error).length > 0) {
          throw new Error('Error saving products');
        }

        dispatch({
          type: type.BACKEND_SAVE_PRODUCTS_SUCCESS,
        });

        // Replace products that have unsaved option IDs with the correct database ID
        const productIdMap = savedProducts.reduce((mappedProducts, savedProduct) => {
          const newMappedProducts = { ...mappedProducts };
          newMappedProducts[savedProduct?._objectId] = savedProduct?.objectId;
          return newMappedProducts;
        }, []);

        const updatedCreatedBundleOptions = createdBundleOptions.map((bundleOption) => {
          const bundleOptionProductIds = bundleOption.productIds.map((productId) =>
            productId in productIdMap ? productIdMap[productId] : productId,
          );

          return {
            ...bundleOption,
            productIds: bundleOptionProductIds?.filter((productId) => {
              if (!startsWith(productId, 'product_')) {
                return true;
              }

              rollbar.error(`Error: Unable to map product id ${productId}`);
              return false;
            }),
          };
        });

        const updatedAlteredBundleOptions = alteredBundleOptions?.map((bundleOption) => {
          const bundleOptionProductIds = bundleOption?.productIds?.map((productId) =>
            productId in productIdMap ? productIdMap[productId] : productId,
          );

          return {
            ...bundleOption,
            productIds: bundleOptionProductIds?.filter((productId) => {
              if (!startsWith(productId, 'product_')) {
                return true;
              }

              rollbar.error(`Error: Unable to map product id ${productId}`);
              return false;
            }),
          };
        });

        /* BUNDLE OPTIONS */
        const savedBundleOptions = (
          await Promise.all([
            new Promise((resolve) =>
              deletedBundleOptionIds.length > 0
                ? resolve(dbRemoveBundleOptions(deletedBundleOptionIds, restaurantId, dispatch))
                : resolve(null),
            ),
            new Promise((resolve) =>
              updatedCreatedBundleOptions.length > 0
                ? resolve(
                    dbCreateBundleOptions(updatedCreatedBundleOptions, restaurantId, dispatch),
                  )
                : resolve(null),
            ),
            new Promise((resolve) =>
              updatedAlteredBundleOptions.length > 0
                ? resolve(
                    dbUpdateBundleOptions(updatedAlteredBundleOptions, restaurantId, dispatch),
                  )
                : resolve(null),
            ),
          ])
        )
          .flat()
          .filter((bundle) => bundle !== null);

        // Error checking
        if (
          savedBundleOptions.filter((savedBundleOption) => savedBundleOption instanceof Error)
            .length > 0
        ) {
          throw new Error('Error saving bundle options');
        }

        dispatch({
          type: type.BACKEND_SAVE_BUNDLE_OPTIONS_SUCCESS,
        });

        // Replace products that have unsaved option IDs with the correct database ID
        const bundleOptionIdMap = savedBundleOptions.reduce(
          (mappedBundleOptions, savedBundleOption) => {
            const newMappedBundleOptions = { ...mappedBundleOptions };
            newMappedBundleOptions[savedBundleOption?._objectId] = savedBundleOption?.objectId;
            return newMappedBundleOptions;
          },
          [],
        );

        const updatedCreatedBundles = createdBundles.map((bundle) => {
          const bundleOptionIds = bundle.bundleOptionIds.map((bundleOptionId) =>
            bundleOptionId in bundleOptionIdMap
              ? bundleOptionIdMap[bundleOptionId]
              : bundleOptionId,
          );

          return {
            ...bundle,
            bundleOptionIds: bundleOptionIds?.filter((bundleOptionId) => {
              if (!startsWith(bundleOptionId, 'bundle_option_')) {
                return true;
              }

              rollbar.error(`Error: Unable to map bundle option id ${bundleOptionId}`);
              return false;
            }),
          };
        });

        const updatedAlteredBundles = alteredBundles?.map((bundle) => {
          const bundleOptionIds = bundle?.bundleOptionIds?.map((bundleOptionId) =>
            bundleOptionId in bundleOptionIdMap
              ? bundleOptionIdMap[bundleOptionId]
              : bundleOptionId,
          );

          return {
            ...bundle,
            bundleOptionIds: bundleOptionIds?.filter((bundleOptionId) => {
              if (!startsWith(bundleOptionId, 'bundle_option_')) {
                return true;
              }

              rollbar.error(`Error: Unable to map bundle option id ${bundleOptionId}`);
              return false;
            }),
          };
        });

        /* BUNDLES */
        const savedBundles = (
          await Promise.all([
            new Promise((resolve) =>
              deletedBundleIds.length > 0
                ? resolve(dbRemoveBundles(deletedBundleIds, restaurantId, dispatch))
                : resolve(null),
            ),
            new Promise((resolve) =>
              updatedCreatedBundles.length > 0
                ? resolve(dbCreateBundles(updatedCreatedBundles, restaurantId, dispatch))
                : resolve(null),
            ),
            new Promise((resolve) =>
              updatedAlteredBundles.length > 0
                ? resolve(dbUpdateBundles(updatedAlteredBundles, restaurantId, dispatch))
                : resolve(null),
            ),
          ])
        )
          .flat()
          .filter((bundle) => bundle !== null);

        // Error checking
        if (savedBundles.filter((savedBundle) => savedBundle instanceof Error).length > 0) {
          throw new Error('Error saving bundles');
        }

        dispatch({
          type: type.BACKEND_SAVE_BUNDLES_SUCCESS,
        });

        // Replace products that have unsaved option IDs with the correct database ID
        const bundleIdMap = savedBundles.reduce((mappedBundles, savedBundle) => {
          const newMappedBundles = { ...mappedBundles };
          newMappedBundles[savedBundle?._objectId] = savedBundle?.objectId;
          return newMappedBundles;
        }, []);

        /* MENUS */
        const savedMenus = (
          await Promise.all([
            new Promise((resolve) =>
              deletedMenuIds.length > 0
                ? resolve(dbRemoveMenus(deletedMenuIds, restaurantId, dispatch))
                : resolve(null),
            ),
            new Promise((resolve) =>
              createdMenus.length > 0
                ? resolve(dbCreateMenus(createdMenus, restaurantId, dispatch))
                : resolve(null),
            ),
            new Promise((resolve) =>
              alteredMenus.length > 0
                ? resolve(dbUpdateMenus(alteredMenus, restaurantId, dispatch))
                : resolve(null),
            ),
          ])
        )
          .flat()
          .filter((menu) => menu !== null);

        if (savedMenus.filter((savedMenu) => savedMenu instanceof Error).length > 0) {
          throw new Error('Error saving menus');
        }

        dispatch({
          type: type.BACKEND_SAVE_MENUS_SUCCESS,
        });

        // Replace products that have unsaved option IDs with the correct database ID
        const menuIdMap = savedMenus.reduce((mappedMenus, savedMenu) => {
          const newMappedMenus = { ...mappedMenus };
          newMappedMenus[savedMenu?._objectId] = savedMenu?.objectId;
          return newMappedMenus;
        }, []);

        const updatedCreatedCategories = createdCategories.map((category) => {
          const categoryProductIds = category?.productIds?.map((productId) =>
            productId in productIdMap ? productIdMap[productId] : productId,
          );

          const categoryBundleIds = category?.bundleIds?.map((bundleId) => {
            const parsed = parseJson(bundleId);

            return parsed?.bundleId in bundleIdMap
              ? JSON.stringify({
                  bundleId: bundleIdMap[parsed?.bundleId],
                  index: 0,
                })
              : bundleId;
          });

          const updatedMenuId =
            category.menuId in menuIdMap ? menuIdMap[category.menuId] : category.menuId;

          return {
            ...category,
            menuId: updatedMenuId,
            productIds: categoryProductIds?.filter((productId) => {
              if (!startsWith(productId, 'product_')) {
                return true;
              }

              rollbar.error(`Error: Unable to map product id ${productId}`);
              return false;
            }),
            bundleIds: categoryBundleIds?.filter((bundleId) => {
              const id = parseJson(bundleId)?.bundleId;
              if (!startsWith(id, 'bundle_')) {
                return true;
              }

              rollbar.error(`Error: Unable to map bundle id ${id}`);
              return false;
            }),
          };
        });

        const updatedAlteredCategories = alteredCategories.map((category) => {
          const categoryProductIds = category?.productIds?.map((productId) =>
            productId in productIdMap ? productIdMap[productId] : productId,
          );

          const categoryBundleIds = category?.bundleIds?.map((bundleId) => {
            const parsed = parseJson(bundleId);

            return parsed?.bundleId in bundleIdMap
              ? JSON.stringify({
                  bundleId: bundleIdMap[parsed?.bundleId],
                  index: 0,
                })
              : bundleId;
          });

          const updatedMenuId =
            category.menuId in menuIdMap ? menuIdMap[category.menuId] : category.menuId;

          return {
            ...category,
            menuId: updatedMenuId,
            productIds: categoryProductIds?.filter((productId) => {
              if (!startsWith(productId, 'product_')) {
                return true;
              }

              rollbar.error(`Error: Unable to map product id ${productId}`);
              return false;
            }),
            bundleIds: categoryBundleIds?.filter((bundleId) => {
              const id = parseJson(bundleId || null)?.bundleId;
              if (!startsWith(id, 'bundle_')) {
                return true;
              }

              rollbar.error(`Error: Unable to map bundle id ${id}`);
              return false;
            }),
          };
        });

        /* CATEGORIES */
        const savedCategories = (
          await Promise.all([
            new Promise((resolve) =>
              deletedCategoryIds.length > 0
                ? resolve(dbRemoveCategories(deletedCategoryIds, restaurantId, dispatch))
                : resolve(null),
            ),
            new Promise((resolve) =>
              updatedCreatedCategories.length > 0
                ? resolve(dbCreateCategories(updatedCreatedCategories, restaurantId, dispatch))
                : resolve(null),
            ),
            new Promise((resolve) =>
              updatedAlteredCategories.length > 0
                ? resolve(dbUpdateCategories(updatedAlteredCategories, restaurantId, dispatch))
                : resolve(null),
            ),
          ])
        )
          .flat()
          .filter((category) => category !== null);

        if (savedCategories.filter((savedCategory) => savedCategory instanceof Error).length > 0) {
          throw new Error('Error saving categories');
        }

        dispatch({
          type: type.BACKEND_SAVE_CATEGORIES_SUCCESS,
        });

        devLog('success', 'choices, options, products, bundleOptions, bundles, categories, menus', [
          savedChoices,
          savedOptions,
          savedProducts,
          savedBundleOptions,
          savedBundles,
          savedCategories,
          savedMenus,
        ]);

        dispatch({
          type: type.SET_TOAST,
          payload: {
            id: `SAVE_ALL_${new Date().getTime()}`,
            message: 'All items have been successfully saved.',
            type: 'success',
          },
        });

        if (posEnabled === state?.activeRestaurant?.data?.posEnabled) {
          await syncMenuCacheAction(restaurantId, posEnabled, false, dispatch);
        }

        dispatch({
          type: type.SET_SHOULD_FETCH_MENUS,
          payload: shouldFetch,
        });
      } catch (error) {
        rollbar.error(error);
        devLog('error', 'save all', error);

        dispatch({
          type: type.SET_TOAST,
          payload: {
            id: `SAVE_ACTION_${new Date().getTime()}`,
            message: `Unable to save action: ${error}`,
            type: 'error',
          },
        });

        dispatch({
          type: type.BACKEND_SAVE_FAILURE,
          payload: `Unable to Save: ${error}`,
        });
      } finally {
        dispatch({
          type: type.BACKEND_SAVE_FINISH,
        });
      }
    })();
  };

export const dbSaveAction =
  (menus, items, options, restaurantId, shouldFetch = false) =>
  (dispatch) => {
    dispatch({
      type: type.BACKEND_SAVE_PENDING,
    });

    (async () => {
      try {
        // wait until all menus finish saving
        const savedMenus = await Promise.all(
          menus.map((menu) => dbSaveMenu(menu, restaurantId, 0, dispatch)),
        );

        // wait until all items finish saving
        const savedItems = await Promise.all(
          items.map((item) => dbSaveMenuItem(item, savedMenus, restaurantId, dispatch)),
        );

        // wait until all options finish saving
        const savedOptions = await Promise.all(
          options.map((option) => dbSaveOption(option, savedItems, dispatch)),
        );

        dispatch({
          type: type.BACKEND_SAVE_MENU_ITEMS_SUCCESS,
        });

        dispatch({
          type: type.BACKEND_SAVE_MENUS_SUCCESS,
        });

        devLog('success', 'menus, items, options', [savedMenus, savedItems, savedOptions]);

        dispatch({
          type: type.SET_TOAST,
          payload: {
            id: `SAVE_ALL_${new Date().getTime()}`,
            message: 'All items have been successfully saved.',
            type: 'success',
          },
        });

        // Sync if edited a non-pos menu and restaurant is not setup for pos
        const state = store.getState();
        const { posEnabled } = state?.menuVersion;
        if (posEnabled === state?.activeRestaurant?.data?.posEnabled) {
          await syncMenuCacheAction(restaurantId, posEnabled, false, dispatch);
        }

        dispatch({
          type: type.SET_SHOULD_FETCH_MENUS,
          payload: shouldFetch,
        });
      } catch (error) {
        devLog('error', 'save', error);

        dispatch({
          type: type.SET_TOAST,
          payload: {
            id: `SAVE_ACTION_${new Date().getTime()}`,
            message: `Unable to save action: ${error}`,
            type: 'error',
          },
        });

        dispatch({
          type: type.BACKEND_SAVE_FAILURE,
          payload: `Unable to Save: ${error}`,
        });
      } finally {
        dispatch({
          type: type.BACKEND_SAVE_FINISH,
        });
      }
    })();
  };
