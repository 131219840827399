import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  draggableMenuItemOption: {
    transition: 'background-color 200ms ease-in-out',

    '&.dragging': {
      backgroundColor: '#f1f2f3',
    },
  },

  boxOutlined: {
    borderTop: '1px solid rgba(0, 0, 0, 0.23)',
    marginTop: '1rem',
    paddingLeft: '1px',
    paddingRight: '1px',
    position: 'relative',
    height: '100%',
    '&.boxError': {
      borderColor: theme.palette.primary.main,
    },
    '&.boxError > label': {
      color: theme.palette.primary.main,
    },
  },
  reviewIcon: {
    backgroundColor: '#F39A47',
    borderRadius: '10px',
    width: '10px',
    height: '10px',
    marginRight: '20px',
  },
}));

export default useStyles;
