import Rollbar from 'rollbar';
import packageInfo from '../package.json';

export default () => {
  const rollbarConfig = {
    accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: process.env.REACT_APP_MODE || 'unknown',
    code_version: packageInfo.version,
  };
  const rollbar = new Rollbar(rollbarConfig);
  return { rollbar };
};
