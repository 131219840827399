import React, { useState } from 'react';
import { Box, Popover } from '@material-ui/core';
import { ReactComponent as IconInfo } from '../../assets/icon_info.svg';

const InfoPopover = ({ info, style }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <>
      {info && (
        <IconInfo
          onClick={(e) => setAnchorEl(e.currentTarget)}
          style={{ cursor: 'pointer', ...style }}
        />
      )}

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        style={{ marginTop: '-15px' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Box
          style={{
            padding: '10px',
          }}
        >
          {info}
        </Box>
      </Popover>
    </>
  );
};

export default InfoPopover;
