import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import MenuEditDialog from './MenuEditDialog';
import MenuCopyDialog from './MenuCopyDialog';
import MenuDeleteDialog from './MenuDeleteDialog';
import MenuCreateDialog from './MenuCreateDialog';
import DiscardDialog from '../DiscardDialog/DiscardDialog';

const MenuOptionsButton = ({ activeMenu, activeRestaurant }) => {
  const history = useHistory();
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openCopyDialog, setOpenCopyDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const [discardCallback, setDiscardCallback] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);

  const handleOnClick = () => {
    if (!hasChanges) {
      showDuplicationOptions();
      return;
    }

    setDiscardCallback(() => () => showDuplicationOptions());
  };

  const handleOpenDropdown = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  const handleClickOption = (action) => {
    action(true);
    handleCloseMenu();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const showDuplicationOptions = () => {
    // history.push(`/duplicate/${activeRestaurant.data.objectId}?version=0`);
  };

  let options = [
    { name: 'Edit', action: setOpenEditDialog },
    { name: 'Create', action: setOpenCreateDialog },
    { name: 'Copy', action: setOpenCopyDialog },
    { name: 'Delete', action: setOpenDeleteDialog },
    // { name: 'Duplicate to other venue', action: handleOnClick },
  ];

  if (!activeMenu) {
    const createIndex = options.findIndex((option) => option.name === 'Create');
    options = options.slice(createIndex, createIndex + 1);
  }

  return (
    <>
      <IconButton style={{ marginRight: '4px' }} onClick={handleOpenDropdown}>
        <MoreVert />
      </IconButton>

      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseDropdown}>
        {options.map((option, index) => (
          <MenuItem key={index} onClick={() => handleClickOption(option.action)}>
            {option.name}
          </MenuItem>
        ))}
      </Menu>

      {activeMenu && (
        <>
          <MenuEditDialog
            activeMenu={activeMenu}
            openDialog={openEditDialog}
            setOpenDialog={setOpenEditDialog}
          />

          <MenuCopyDialog
            activeMenu={activeMenu}
            openDialog={openCopyDialog}
            setOpenDialog={setOpenCopyDialog}
          />

          <MenuDeleteDialog
            activeMenu={activeMenu}
            openDialog={openDeleteDialog}
            setOpenDialog={setOpenDeleteDialog}
          />
        </>
      )}

      <MenuCreateDialog openDialog={openCreateDialog} setOpenDialog={setOpenCreateDialog} />

      <DiscardDialog
        callback={discardCallback}
        handleClose={() => setDiscardCallback(null)}
        setHasChanges={setHasChanges}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  activeRestaurant: state.activeRestaurant,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MenuOptionsButton);
