import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  item: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    width: '100%',
  },
}));

export default useStyles;
