import * as type from './types';

export const setToastAction = (id, message, messageType) => (dispatch) => {
  dispatch({
    type: type.SET_TOAST,
    payload: {
      id,
      message,
      type: messageType,
    },
  });
};

export const unsetToastAction = (id) => (dispatch) => {
  dispatch({
    type: type.UNSET_TOAST,
    payload: { id },
  });
};
