import * as type from './types';
import { menuItemOptionsForMenuItem } from '../graphql/queries';
import { devLog } from '../helpers/devLog';
import { makeApiCall } from './actions';

export const fetchMenuItemOptionsAction = (menuItemId) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'MENU_ITEM_OPTIONS',
  });

  dispatch({
    type: type.FETCH_MENU_ITEM_OPTIONS_PENDING,
  });

  (async () => {
    try {
      const response = await makeApiCall(menuItemOptionsForMenuItem, {
        menuItemId,
        pageSize: 500,
        offset: 0,
      });

      devLog(
        'success',
        'menu item options for menu item',
        response.data.menuItemOptionsForMenuItem,
      );

      dispatch({
        type: type.FETCH_MENU_ITEM_OPTIONS_SUCCESS,
        payload: {
          data: response.data.menuItemOptionsForMenuItem,
          menuItemId,
        },
      });
    } catch (error) {
      devLog('error', 'menu item options for menu item', error);

      dispatch({
        type: type.SET_TOAST,
        payload: {
          id: `MENU_ITEM_OPTIONS_${new Date().getTime()}`,
          message: `Unable to fetch menu item options: ${error}`,
          type: 'error',
        },
      });

      dispatch({
        type: type.FETCH_MENU_ITEM_OPTIONS_FAILURE,
        payload: `Unable to retrieve menu item options: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'MENU_ITEM_OPTIONS',
      });
    }
  })();
};

export const createMenuItemOptionAction =
  (menuItemId, optionId, name, desc, minSelect, maxSelect, mandatory, choices, lastOptionIndex) =>
  (dispatch) => {
    dispatch({
      type: type.CREATE_MENU_ITEM_OPTION,
      payload: {
        menuItemId,
        optionId,
        name,
        desc,
        minSelect,
        maxSelect,
        mandatory,
        choices,
        lastOptionIndex,
      },
    });
  };

export const updateMenuItemOptionAction =
  (menuItemId, optionId, name, desc, minSelect, maxSelect, mandatory, choices) => (dispatch) => {
    dispatch({
      type: type.UPDATE_MENU_ITEM_OPTION,
      payload: {
        menuItemId,
        optionId,
        name,
        desc,
        minSelect,
        maxSelect,
        mandatory,
        choices,
      },
    });
  };

export const deleteMenuItemOptionAction = (menuItemId, optionId) => (dispatch) => {
  dispatch({
    type: type.DELETE_MENU_ITEM_OPTION,
    payload: { menuItemId, optionId },
  });
};

export const reorderMenuItemOptionsAction = (menuItemId, source, destination) => (dispatch) => {
  dispatch({
    type: type.REORDER_MENU_ITEM_OPTIONS,
    payload: { menuItemId, source, destination },
  });
};

export const saveInitialMenuItemOptionsAction = () => (dispatch) => {
  dispatch({
    type: type.SAVE_INITIAL_MENU_ITEM_OPTIONS,
  });
};

export const clearInitialMenuItemOptionsAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_INITIAL_MENU_ITEM_OPTIONS,
  });
};

export const cancelMenuItemOptionsAction = () => (dispatch) => {
  dispatch({
    type: type.CANCEL_MENU_ITEM_OPTIONS,
  });
};
