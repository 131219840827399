import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { useState } from 'react';
import { Box, Paper, ListSubheader, List } from '@material-ui/core';
import { deleteProductAction, reviewProductAction } from '../../../../actions/productsAction';
import { dialogContexts } from '../../../../constants/constants';
import ReviewNotificationBanner from '../NotificationBanner/ReviewNotificationBanner';
import MultiProductEditDialog from './MultiProductEditDialog';
import ProductCreate from './ProductCreate';
import Product from './Product';
import ProductEditDialog from './ProductEditDialog';
import ProductGroupActionButton from './ProductGroupActionButton';
import useStyles from './ProductsStyles';
import SelectionControls from '../SelectionControls/SelectionControls';

/**
 * A list of global menu items.
 * Similar to the "menuCategories" component and its wrapper "MenuTabPanel", but these items do not belong to a category.
 */
const Products = ({ products, menuVersion, deleteProduct, reviewProduct }) => {
  const classes = useStyles();
  const [showAddOptionsDialog, setShowAddOptionsDialog] = useState(false);

  // products with checkboxes selected
  const [selectedItems, setSelectedItems] = useState([]);

  // Dialog optimisation, having it here instead of on every product
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogProduct, setDialogProduct] = useState(null);

  const selectItem = (itemId, selected) => {
    const isSelected = selectedItems?.filter((item) => item === itemId)?.length > 0;
    if (selected && !isSelected) {
      setSelectedItems([...selectedItems, itemId]);
    }

    // if deselected
    if (!selected) {
      setSelectedItems(selectedItems.filter((item) => item !== itemId));
    }
  };

  /**
   * Select every item
   */
  const selectAllItems = () => {
    let newSelectedItems = selectedItems;
    products?.localData?.forEach((product) => {
      const isSelected =
        selectedItems?.filter((selectedItem) => selectedItem.itemId === product.objectId)?.length >
        0;
      if (!isSelected) {
        newSelectedItems = [...newSelectedItems, product.objectId];
      }
    });

    setSelectedItems(newSelectedItems);
  };

  const onSelect = (productId, selected) => {
    selectItem(productId, selected);
  };

  const onSelectAll = () => {
    selectAllItems();
  };

  const filteredProducts = products.localData?.filter((product) => !product.bundleOnly);

  return (
    <>
      {products?.data.length > 200 && (
        <Box mt='40px' style={{ padding: '20px', backgroundColor: '#fff8e5', color: '#8d5806' }}>
          Note: This is a very large menu. Images and item descriptions are currently hidden to
          improve performance. Clicking a menu item will show the image and description.
        </Box>
      )}
      {menuVersion.posEnabled && <ReviewNotificationBanner />}
      <Paper style={{ marginTop: '1rem' }}>
        <List
          dense={false}
          subheader={
            <ListSubheader
              component='div'
              id='category-list-subheader'
              color='primary'
              className={classes.listSubheader}
            >
              <Box display='flex' alignItems='center' justifyContent='space-between'>
                <span>
                  {filteredProducts.length === 0 ? 'No menu items yet' : 'All menu items'}
                </span>
                <ProductGroupActionButton selectAllItems={onSelectAll} />
              </Box>
              {filteredProducts.length !== 0 && (
                <Box
                  style={{
                    height: '20px',
                    fontSize: '13px',
                    lineHeight: '20px',
                    color: '#9F9F9F',
                    columnGap: '15px',
                  }}
                  display='flex'
                  justifyContent='flex-end'
                  alignItems='baseline'
                >
                  <span style={{ width: '100px' }}>Options</span>
                  <span style={{ width: '100px' }}>Price</span>
                  <span style={{ width: '140px' }} />
                  <span style={{ width: '36px' }} />
                </Box>
              )}
            </ListSubheader>
          }
        >
          {filteredProducts.map((product) => (
            <Product
              key={product.objectId}
              product={product}
              selected={selectedItems?.filter((item) => item === product.objectId)?.length > 0}
              onSelect={(value) => onSelect(product.objectId, value)}
              hideImages={products?.localData?.length > 200}
              setOpenDialog={() => {
                setDialogProduct(product);
                setOpenDialog(true);
              }}
            />
          ))}
        </List>
        {selectedItems.length > 0 && (
          <SelectionControls
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            removeItem={deleteProduct}
            reviewItem={reviewProduct}
            showAddOptionsDialog={() => setShowAddOptionsDialog(true)}
          />
        )}
        <MultiProductEditDialog
          openDialog={showAddOptionsDialog}
          setOpenDialog={setShowAddOptionsDialog}
          selectedProducts={selectedItems}
        />
      </Paper>

      {!menuVersion.posEnabled && (
        <Paper style={{ marginTop: '1rem' }}>
          <ProductCreate products={products} />
        </Paper>
      )}

      <ProductEditDialog
        openDialog={openDialog}
        dialogContext={dialogContexts.EDIT}
        setOpenDialog={setOpenDialog}
        product={dialogProduct}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  products: state.products,
  menuVersion: state.menuVersion,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteProduct: deleteProductAction,
      reviewProduct: reviewProductAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Products);
