import React from 'react';
import { Redirect } from 'react-router-dom';
import DuplicateMenus from '../components/duplicateMenus/DuplicateMenus';
import SelectRestaurant from '../components/selectRestaurant/SelectRestaurant';
import Restaurant from '../components/restaurant/Restaurant';

// Routes are re-used in SwitchRoutes.js for routing, and in header.js to display the pageTitle
const routes = [
  {
    path: '/',
    exact: true,
    pageTitle: 'Select a Restaurant',
    main: () => <Redirect to='/restaurants' />,
  },
  {
    path: '/restaurants',
    exact: true,
    pageTitle: 'Select a Restaurant',
    main: () => <SelectRestaurant />,
  },
  {
    path: '/restaurants/:restaurantId',
    pageTitle: 'Restaurant',
    main: () => <Restaurant />,
  },
  // {
  //   path: '/duplicate/:restaurantId',
  //   pageTitle: 'Duplicate Restaurant Menus',
  //   main: () => <DuplicateMenus />,
  // },
];

export default routes;
