import React from 'react';
import {
  ListItem,
  ListItemIcon,
  Box,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core';
import { Draggable } from 'react-beautiful-dnd';
import { Delete } from '@material-ui/icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ReactComponent as Icon } from '../../../../assets/two-bars-icon.svg';
import { removeBundleOptionFromBundleAction } from '../../../../actions/bundlesAction';
import { removeSpaces } from '../../../../helpers/Helpers';
import useStyles from './BundleOptionStyles';

const BundleOption = ({
  menuVersion,
  removeBundleOptionFromBundleAction,
  index,
  bundleOption,
  setDialogContext,
  setOpenDialog,
  setSelectedOptionId,
  labelledChoices,
  bundle,
}) => {
  const classes = useStyles();

  const handleRemove = () => {
    removeBundleOptionFromBundleAction(bundleOption.objectId);
  };

  return (
    <Draggable draggableId={`${removeSpaces(bundleOption.objectId)}`} index={index}>
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={`${classes.draggableMenuItemOption} ${snapshot.isDragging ? 'dragging' : ''} ${
            snapshot.isDropAnimating ? 'animating' : ''
          }`}
          boxShadow={snapshot.isDragging ? 3 : 0}
        >
          <ListItem
            button
            onClick={() => {
              setSelectedOptionId(bundleOption.objectId);
              setOpenDialog(true);
              setDialogContext('Edit');
            }}
          >
            <ListItemIcon title='Drag to re-order' {...provided.dragHandleProps}>
              <Icon style={{ fill: 'rgba(0, 0, 0, 0.54)' }} />
            </ListItemIcon>
            {menuVersion.posEnabled && bundle?.importStatus !== null && (
              <Box
                className={classes.reviewIcon}
                style={{
                  backgroundColor:
                    bundleOption?.importStatus === 'needsReview' ? '#F39A47' : '#77C76A',
                }}
              />
            )}
            <ListItemText
              primary={bundleOption?.bundleOptionTitle}
              secondary={bundleOption?.bundleOptionDescription}
            />
            <ListItemText
              secondary={labelledChoices}
              style={{ maxWidth: '150px', textAlign: 'left' }}
            />
            <ListItemSecondaryAction>
              <IconButton
                edge='end'
                aria-label='delete'
                onClick={(e) => {
                  e.preventDefault();
                  handleRemove();
                }}
                title='Remove option from item'
              >
                <Delete />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </Box>
      )}
    </Draggable>
  );
};

const mapStateToProps = (state) => ({
  menuVersion: state.menuVersion,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ removeBundleOptionFromBundleAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BundleOption);
