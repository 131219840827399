import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addBreadcrumbAction, popBreadcrumbAction } from '../../../../actions/breadcrumbsAction';
import {
  createBundleOptionAction,
  updateBundleOptionAction,
  clearProductsFromBundleOptionAction,
  addProductsToBundleOptionAction,
} from '../../../../actions/bundleOptionsAction';
import { addBundleOptionToBundleAction } from '../../../../actions/bundlesAction';
import ReviewButton from '../ReviewButton/ReviewButton';
import useStyles from './BundleOptionEditStyles';
import BundleOptionProducts from './BundleOptionProducts';
import { dialogContexts } from '../../../../constants/constants';
import InfoPopover from '../../../InfoPopover/InfoPopover';

const BundleOptionEditDialog = ({
  dialogContext,
  bundleOptions,
  bundleOption,
  bundleId,
  bundleIds,
  products,
  openDialog,
  setOpenDialog,
  createBundleOptionAction,
  updateBundleOptionAction,
  clearProductsFromBundleOptionAction,
  addProductsToBundleOptionAction,
  addBundleOptionToBundleAction,
  addBreadcrumbAction,
  popBreadcrumbAction,
  restaurantId,
  menuVersion,
}) => {
  const classes = useStyles();

  const [save, setSave] = useState(false);
  const [name, setName] = useState(bundleOption?.bundleOptionTitle || '');
  const [nameError, setNameError] = useState('');
  const [description, setDesc] = useState(bundleOption?.bundleOptionDescription || '');
  const [descError, setDescError] = useState('');
  const [minSelections, setMinSelect] = useState(bundleOption?.minSelections || 0);
  const [minSelectError, setMinSelectError] = useState('');
  const [maxSelections, setMaxSelect] = useState(bundleOption?.maxSelections || 0);
  const [maxSelectError, setMaxSelectError] = useState('');
  const [mandatory, setMandatory] = useState(bundleOption?.mandatory || false);
  const [bundleOptionProducts, setBundleOptionProducts] = useState(
    bundleOptions?.storedProducts || [],
  );
  const [productError, setProductError] = useState('');

  const [importStatus, setImportStatus] = useState(bundleOption?.importStatus || null);
  const [reviewError, setReviewError] = useState('');

  const getUnreviewedProducts = () =>
    products?.localData?.filter(
      (product) =>
        bundleOptionProducts.includes(product?.objectId) && product.importStatus === 'needsReview',
    );

  const updateImportStatus = (newValue) => {
    if (newValue === 'reviewed') {
      const unreviewedProducts = getUnreviewedProducts();

      if (unreviewedProducts?.length > 0) {
        setReviewError(
          'You cannot set the bundle option as reviewed until all of its menu items have been reviewed.',
        );
        return;
      }

      setImportStatus(newValue);
      setReviewError('');
    }
  };

  useEffect(() => {
    setBundleOptionProducts(bundleOptions?.storedProducts || []);
  }, [bundleOption?.choiceIds, bundleOptions?.storedProducts]);

  // breadcrumbs
  const [hasBreadcrumb, setHasBreadcrumb] = useState(false); // To ensure we don't pop some other breadcrumb if re-rendered while closed
  useEffect(() => {
    if (openDialog && !hasBreadcrumb) {
      addBreadcrumbAction(`${dialogContext} bundle option`);
      setHasBreadcrumb(true);
    } else if (!openDialog && hasBreadcrumb) {
      popBreadcrumbAction();
      setHasBreadcrumb(false);
    }
  }, [openDialog]);

  const newBundleOptionId = `bundle_option_${new Date().getTime()}`;
  const [bundleOptionId, setBundleOptionId] = useState(bundleOption?.objectId || newBundleOptionId);

  useEffect(() => {
    validateForm();
  }, [bundleOptionProducts]);

  const closeDialog = () => {
    setOpenDialog(false);
  };

  const validateForm = () => {
    let formError = false;
    if (!name) {
      setNameError('Please enter a bundle option name.');
      formError = true;
    } else if (nameError) {
      setNameError('');
    }

    if (minSelections === '') {
      setMinSelectError('Please enter a minimum selection.');
      formError = true;
    }
    if (minSelections > maxSelections) {
      setMinSelectError('Cannot be greater than maximum selections.');
      formError = true;
    }
    if (minSelections > bundleOptions?.storedProducts?.length) {
      setMinSelectError('Cannot be greater than minimum menu items available.');
      formError = true;
    }
    if (minSelections === 0 && mandatory) {
      setMinSelectError('If mandatory is set, must have at least one min selection.');
      formError = true;
    }
    if (
      !(
        minSelections === '' ||
        minSelections > bundleOptions?.storedProducts.length ||
        minSelections > maxSelections ||
        (minSelections === 0 && mandatory)
      )
    ) {
      if (minSelectError) {
        setMinSelectError('');
      }
    }

    if (maxSelections === '') {
      setMaxSelectError('Please enter a maximum selection.');
      formError = true;
    } else if (maxSelectError) {
      setMaxSelectError('');
    }

    if (!bundleOptions?.storedProducts.length) {
      setProductError('Please add at least one menu item');
      formError = true;
    } else if (productError) {
      setProductError('');
    }

    return formError;
  };

  const submit = (event) => {
    event.preventDefault();

    const formError = validateForm();

    if (formError) {
      return;
    }

    if (dialogContext === dialogContexts.CREATE) {
      createBundleOptionAction(
        bundleOptionId,
        name,
        description,
        Number(minSelections),
        Number(maxSelections),
        mandatory,
        restaurantId,
        bundleOption?.posId,
        bundleOptionProducts,
      );
    } else {
      updateBundleOptionAction(
        bundleOption?.objectId,
        name,
        description,
        Number(minSelections),
        Number(maxSelections),
        mandatory,
        restaurantId,
        bundleOption?.posId,
        bundleOptionProducts,
        importStatus,
      );
    }

    if (bundleId) {
      addBundleOptionToBundleAction(bundleOptionId || bundleOption?.objectId);
    }

    if (bundleIds) {
      bundleIds.forEach((bundle) =>
        addBundleOptionToBundleAction(bundleOptionId || bundleOption?.objectId),
      );
    }

    setSave(true);
    closeDialog();
  };

  const objectId = bundleOptions?.data?.find(
    (apiBundleOption) => apiBundleOption?.objectId === bundleOption?.objectId,
  )?.objectId;

  return (
    <Dialog
      open={openDialog}
      onClose={closeDialog}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth
      maxWidth='lg'
      className={classes.dialog}
      onEnter={() => {
        const enteredBundleOptionId = bundleOption?.objectId || newBundleOptionId;
        setBundleOptionId(enteredBundleOptionId);

        addProductsToBundleOptionAction(bundleOption?.productIds || []);

        setName(bundleOption?.bundleOptionTitle || '');
        setDesc(bundleOption?.bundleOptionDescription || '');
        setMinSelect(bundleOption?.minSelections || 0);
        setMaxSelect(bundleOption?.maxSelections || 0);
        setMandatory(bundleOption?.mandatory || false);
        setImportStatus(bundleOption?.importStatus || null);

        setNameError('');
        setDescError('');
        setMinSelectError('');
        setMaxSelectError('');
        setProductError('');
        setSave(false);
      }}
      onExited={() => {
        clearProductsFromBundleOptionAction();
      }}
    >
      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12} className={classes.leftColumn}>
            <Box display='flex' justifyContent='space-between'>
              <Box className={classes.modalTitle}>{dialogContext} Bundle Option</Box>
              {menuVersion.posEnabled && (
                <ReviewButton importStatus={importStatus} setStatus={updateImportStatus} />
              )}
            </Box>
            {reviewError && <Box style={{ color: 'red', paddingTop: '20px' }}>{reviewError}</Box>}
            <Box className={classes.subtitle}>Bundle option details</Box>
            <form onSubmit={submit} id='edit-menu-item-option-form'>
              <TextField
                autoFocus={!name}
                id='menu-item-option-name'
                label='Bundle Option Name'
                placeholder='eg. Choose 1st Pizza'
                variant='outlined'
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
                onBlur={(e) => {
                  setName(e.target.value.trim());
                  validateForm();
                }}
                InputLabelProps={{ shrink: true }}
                margin='normal'
                required
                error={Boolean(nameError)}
                helperText={nameError}
              />

              <TextField
                multiline
                id='menu-item-option-desc'
                label='Description'
                placeholder='eg. Choose 1 Pizza from our selected range.'
                variant='outlined'
                fullWidth
                value={description}
                onChange={(e) => setDesc(e.target.value)}
                onBlur={(e) => {
                  setDesc(e.target.value.trim());
                  validateForm();
                }}
                InputLabelProps={{ shrink: true }}
                margin='normal'
                error={Boolean(descError)}
                helperText={descError}
              />

              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <TextField
                    id='menu-item-min-selection'
                    label='Minimum Selections'
                    placeholder='0'
                    variant='outlined'
                    fullWidth
                    value={minSelections}
                    type='number'
                    onChange={(e) => setMinSelect(Number(e.target.value))}
                    onBlur={(e) => {
                      validateForm();
                    }}
                    InputLabelProps={{ shrink: true }}
                    min={0}
                    inputProps={{ min: 0, step: 1 }}
                    margin='normal'
                    required
                    error={Boolean(minSelectError)}
                    helperText={minSelectError}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    id='menu-item-max-selection'
                    label='Maximum Selections'
                    placeholder='0'
                    variant='outlined'
                    fullWidth
                    value={maxSelections}
                    type='number'
                    onChange={(e) => setMaxSelect(Number(e.target.value))}
                    onBlur={(e) => {
                      validateForm();
                    }}
                    InputLabelProps={{ shrink: true }}
                    min={0}
                    inputProps={{ min: 0, step: 1 }}
                    margin='normal'
                    required
                    error={Boolean(maxSelectError)}
                    helperText={maxSelectError}
                  />
                </Grid>
              </Grid>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color='primary'
                      checked={mandatory}
                      onChange={(e) => setMandatory(e.target.checked)}
                      name='mandatory'
                    />
                  }
                  label='Is Mandatory'
                />
              </FormGroup>

              {menuVersion.posEnabled && (
                <TextField
                  id='option-pos-id'
                  label='POS ID'
                  variant='outlined'
                  fullWidth
                  disabled
                  value={bundleOption?.posId}
                  onBlur={(e) => {
                    validateForm();
                  }}
                  InputLabelProps={{ shrink: true }}
                  margin='normal'
                  InputProps={{ style: { backgroundColor: '#EFEFEF' } }}
                />
              )}
            </form>
          </Grid>
          <Grid item md={6} xs={12} className={classes.rightColumn}>
            <Box className={classes.columnTitle}>Menu items</Box>
            <BundleOptionProducts
              bundleOptionProducts={bundleOptionProducts}
              setBundleOptionProducts={setBundleOptionProducts}
              bundleOption={bundleOption}
              bundleOptionId={bundleOptionId}
              productError={productError}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ borderTop: '1px solid #DCDCDB', minHeight: '50px' }}>
        <Box
          style={{ minHeight: '60px', margin: '0 20px', width: '100%' }}
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <Button onClick={closeDialog} color='default'>
            Cancel
          </Button>

          <InfoPopover
            info={objectId}
            style={{ position: 'absolute', left: 0, right: 0, margin: '0 auto' }}
          />

          <Button onClick={submit} color='primary' type='submit' form='edit-menu-item-option-form'>
            {dialogContext === dialogContexts.CREATE ? 'Create' : 'Save'} Bundle Option
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  bundleOptions: state.bundleOptions,
  restaurantId: state.activeRestaurant.data.objectId,
  products: state.products,
  menuVersion: state.menuVersion,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createBundleOptionAction,
      clearProductsFromBundleOptionAction,
      updateBundleOptionAction,
      addProductsToBundleOptionAction,
      addBreadcrumbAction,
      popBreadcrumbAction,
      addBundleOptionToBundleAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(BundleOptionEditDialog);
