import { getMenuItemImageUploadUrl } from '../graphql/queries';
import { devLog } from '../helpers/devLog';
import { parseJson } from '../helpers/Helpers';
import { makeApiCall } from './actions';

export const getMenuItemImageUploadUrlAction = async (item, restaurantId) => {
  try {
    const response = await makeApiCall(getMenuItemImageUploadUrl, {
      restId: restaurantId,
      fileName: item._image.name,
    });

    devLog('success', 'get menu item image upload url', response.data.getMenuItemImageUploadUrl);

    const parsedResponse = parseJson(response.data.getMenuItemImageUploadUrl);

    if (parsedResponse?.status !== 'success') {
      throw new Error('no success');
    }

    const resUploadImage = await fetch(
      new Request(parsedResponse.uploadUrl, {
        method: 'PUT',
        headers: new Headers({
          Accept: 'image/*',
          'Content-Type': 'image/*',
          // "Access-Control-Allow-Origin": "*",
          // Origin: "http://localhost:3000",
        }),
        body: item._image.file,
      }),
    );

    if (resUploadImage.status !== 200) {
      throw new Error('Unable to upload image');
    }

    return parsedResponse.link;
  } catch (error) {
    devLog('error', 'unable to get menu item image upload url', error);
  }
};
