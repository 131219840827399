import * as type from '../actions/types';

const initialState = {
  data: [],
  fetching: false,
  error: false,
  errorMessage: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case type.FETCH_LAST_UPDATED_RESTAURANTS_PENDING: {
      return {
        ...state,
        data: [],
        fetching: true,
        error: false,
        errorMessage: false,
      };
    }

    case type.FETCH_LAST_UPDATED_RESTAURANTS_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        fetching: false,
        error: false,
        errorMessage: '',
      };
    }

    case type.FETCH_LAST_UPDATED_RESTAURANTS_FAILURE: {
      return {
        ...state,
        error: true,
        errorMessage: action.payload,
        fetching: false,
      };
    }

    default: {
      return state;
    }
  }
}
