import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '.875em',
      },
    },
    MuiInputLabel: {
      root: {
        '&$focused': {
          color: '#313131',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&$focused fieldset': {
          borderColor: '#313131 !important',
        },
      },
    },
  },
  typography: {
    fontFamily: "Gordita, 'Open Sans', 'Helvetica', 'sans-serif' !important",
    h2: {
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: '24px',
    },
  },
  palette: {
    primary: {
      light: '#ff456a',
      main: '#E54439',
      dark: '#b21030',
      mainShades: {
        200: 'hsl(348, 100%, 85%)',
        300: 'hsl(348, 100%, 75%)',
        400: 'hsl(348, 100%, 65%)',
        500: 'hsl(348, 100%, 55%)',
        600: 'hsl(348, 100%, 45%)',
      },
      contrastText: '#fff',
    },
    secondary: {
      light: '#959595',
      main: '#757575',
      dark: '#555555',
      contrastText: '#fff',
    },
    // secondary: {
    //   light: "#995b67",
    //   main: "#803241",
    //   dark: "#59232d",
    //   contrastText: "#fff"
    // },
    error: {
      light: '#ff456a',
      main: '#E54439',
      dark: '#b21030',
      contrastText: '#fff',
    },
    // error: {
    //   light: "#c13f59",
    //   main: "#b21030",
    //   dark: "#7c0b21",
    //   contrastText: "#fff"
    // }
  },
});

export default theme;
