import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  draggableChoice: {
    backgroundColor: 'none',
    transition: 'background-color 200ms ease-in-out',

    '&.dragging': {
      backgroundColor: '#f1f2f3',
    },
  },
  listButton: {
    color: 'rgba(0, 0, 0, 0.54)',
    width: '141px',

    '&.listButtonDisabled': {
      color: theme.palette.primary.main,
    },
  },
}));

export default useStyles;
