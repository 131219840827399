import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    width: '100%',
    gap: '15px',
    justifyContent: 'space-between',
    display: 'flex',
    marginTop: '50px',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  modalHeader: {
    fontSize: '28px',
  },
  modalSubheader: {
    color: '#6E6E6D',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '28px',
    verticalAlign: 'baseline',
    maxWidth: '600px',
  },
}));

export default useStyles;
