import * as type from '../actions/types';
import {
  deleteItemsInArray,
  filterUnique,
  getItemInArray,
  insertItemInArrayAtIndex,
  updateItemInArray,
} from '../helpers/ArrayUtils';

const initialState = {
  data: [],
  localData: [],
  storedProducts: [],
  deleted: [],
  fetching: false,
  error: false,
  errorMessage: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case type.FETCH_BUNDLE_OPTIONS_PENDING: {
      return {
        ...initialState,
        fetching: true,
      };
    }

    case type.FETCH_BUNDLE_OPTIONS_SUCCESS: {
      return {
        ...state,
        data: action.payload || [],
        localData: action.payload || [],
        fetching: false,
        error: false,
        errorMessage: '',
      };
    }

    case type.FETCH_BUNDLE_OPTIONS_FAILURE: {
      return {
        ...state,
        data: [],
        localData: [],
        deleted: [],
        fetching: false,
        error: true,
        errorMessage: action.payload,
      };
    }

    case type.CREATE_BUNDLE_OPTION: {
      const {
        objectId,
        bundleOptionTitle,
        bundleOptionDescription,
        mandatory,
        minSelections,
        maxSelections,
        restaurantId,
        posId,
        productIds,
      } = action.payload;

      const productIdsProvided = productIds && productIds?.length > 0;

      const newBundleOption = {
        objectId,
        bundleOptionTitle,
        bundleOptionDescription,
        mandatory,
        minSelections,
        maxSelections,
        restId: restaurantId,
        posId,
        importStatus: null,
        productIds: productIdsProvided ? productIds : state.storedProducts,
        _created: true,
      };

      const newOptions = [...state.localData, newBundleOption];

      return { ...state, localData: newOptions, storedProducts: [] };
    }

    case type.UPDATE_BUNDLE_OPTION: {
      const {
        objectId,
        title,
        description,
        minSelections,
        maxSelections,
        mandatory,
        restaurantId,
        posId,
        productIds,
        importStatus,
      } = action.payload;

      const newBundleOptions = updateItemInArray(
        state.localData,
        'objectId',
        objectId,
        (bundleOption) => ({
          ...bundleOption,
          bundleOptionDescription: description,
          bundleOptionTitle: title,
          restId: restaurantId,
          minSelections,
          maxSelections,
          mandatory,
          posId,
          productIds: state.storedProducts,
          importStatus,
          _altered: true,
        }),
      );

      return { ...state, localData: newBundleOptions, storedProducts: [] };
    }

    case type.DELETE_BUNDLE_OPTION: {
      const { bundleOptionId } = action.payload;

      const deleted = getItemInArray(state.localData, 'objectId', bundleOptionId);

      const newDeleted = { ...deleted, _deleted: true };

      const newBundleOptions = deleteItemsInArray(state.localData, 'objectId', bundleOptionId);

      const newDeletedItems = insertItemInArrayAtIndex(state.deleted, 0, newDeleted);

      return {
        ...state,
        localData: newBundleOptions,
        deleted: newDeletedItems,
      };
    }

    case type.ADD_PRODUCT_TO_BUNDLE_OPTION: {
      const { productId } = action.payload;

      let productIds = Array.from(state.storedProducts);
      productIds = [...state.storedProducts, productId].filter(filterUnique);

      return { ...state, storedProducts: productIds };
    }

    case type.ADD_PRODUCTS_TO_BUNDLE_OPTION: {
      const { productIds } = action.payload;

      return { ...state, storedProducts: productIds || [] };
    }

    case type.REMOVE_PRODUCT_FROM_BUNDLE_OPTION: {
      const { productId } = action.payload;

      const newProducts = state.storedProducts.filter((product) => product !== productId);

      return { ...state, storedProducts: newProducts || [] };
    }

    case type.CLEAR_PRODUCTS_FROM_BUNDLE_OPTION: {
      return {
        ...state,
        storedProducts: [],
      };
    }

    case type.DELETE_PRODUCT: {
      const { productId } = action.payload;

      const updatedBundleOptions = state.localData.map((bundleOption) => {
        if (!bundleOption?.productIds?.includes(productId)) {
          return bundleOption;
        }

        const newProducts = bundleOption?.productIds?.filter(
          (productOptionId) => productOptionId !== productId,
        );
        const updatedProduct = {
          ...bundleOption,
          productIds: newProducts,
          _altered: true,
        };
        return updatedProduct;
      });

      // Also remove from stored products
      const newStoredProducts = state.storedProducts.filter(
        (storedOption) => storedOption !== productId,
      );

      return {
        ...state,
        localData: updatedBundleOptions,
        storedProducts: newStoredProducts,
      };
    }

    case type.BULK_REVIEW_BUNDLE_OPTIONS: {
      const { bundleOptionIds } = action.payload;

      const newBundleOptions = state.localData.map((bundleOption) => {
        if (bundleOptionIds?.includes(bundleOption.objectId)) {
          return { ...bundleOption, importStatus: 'reviewed', _altered: true };
        }
        return bundleOption;
      });

      return {
        ...state,
        localData: newBundleOptions,
      };
    }

    /*
     * BACKEND SAVES
     */

    case type.BACKEND_SAVE_BUNDLE_OPTION_SUCCESS: {
      const { savedItem, itemObjectId } = action.payload;

      const newBundleOptions = state.localData.map((item) => {
        if (item.objectId === itemObjectId) {
          return savedItem;
        }

        return item;
      });

      return {
        ...state,
        data: newBundleOptions,
        localData: newBundleOptions,
      };
    }

    case type.BACKEND_SAVE_BUNDLE_OPTIONS_SUCCESS: {
      const newOptions = state.localData.map(({ _altered, _created, _objectId, ...rest }) => rest);

      return {
        ...state,
        data: newOptions,
        localData: newOptions,
        storedOptions: [],
        deleted: [],
      };
    }

    default: {
      return state;
    }
  }
}
