/* eslint-disable react/prop-types */
import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { filterUnique, findById } from '../../../../helpers/ArrayUtils';
import { parseJson, pluralise } from '../../../../helpers/Helpers';
import NotificationBanner from './NotificationBanner';

const ReviewNotificationBanner = ({ menuId, bundles, products, categories, menus }) => {
  // TODO more efficient way to get review state
  const [incompleteProducts, setIncompleteProducts] = useState([]);
  const [incompleteBundles, setIncompleteBundles] = useState([]);

  useEffect(() => {
    if (!menuId) {
      setIncompleteProducts(
        products.localData?.filter((product) => product?.importStatus === needsReview) || [],
      );
    } else {
      const categoriesForMenu =
        categories.localData?.filter((category) => category?.menuId === menuId) || [];

      const productsForReview =
        categoriesForMenu?.reduce((acc, category) => {
          const unreviewedProducts = category?.productIds?.filter(
            (productId) => findById(products.localData, productId)?.importStatus === 'needsReview',
          );

          return [...acc, ...(unreviewedProducts || [])].filter(filterUnique);
        }, []) || [];

      const bundlesForReview =
        categoriesForMenu?.reduce((acc, category) => {
          const unreviewedBundles = category?.bundleIds?.filter(
            (bundleId) =>
              findById(bundles.localData, parseJson(bundleId).bundleId)?.importStatus ===
              'needsReview',
          );

          return [...acc, ...(unreviewedBundles || [])].filter(filterUnique);
        }, []) || [];

      setIncompleteProducts(productsForReview);
      setIncompleteBundles(bundlesForReview);
    }
  }, [products, menuId, categories, menus?.data]);

  const needsReview = incompleteProducts?.length !== 0 || incompleteBundles?.length !== 0;

  return (
    <Box>
      {needsReview && (
        <NotificationBanner
          text={
            <span>
              There {pluralise('is', incompleteProducts?.length + incompleteBundles.length, 'are')}{' '}
              <b>{incompleteProducts?.length + incompleteBundles.length}</b>{' '}
              {pluralise('item', incompleteProducts?.length + incompleteBundles.length)} to review
            </span>
          }
        />
      )}
    </Box>
  );
};

ReviewNotificationBanner.propTypes = {};

const mapStateToProps = (state) => ({
  categories: state.categories,
  menus: state.menus,
  products: state.products,
  bundles: state.bundles,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ReviewNotificationBanner);
