/* eslint-disable react/prop-types */
import { connect } from 'react-redux';
import React, { useMemo } from 'react';
// import PropTypes from "prop-types";
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { List, ListSubheader, Divider, Box } from '@material-ui/core';
import MenuItemAdd from './MenuCategoryAdd';
import useStyles from './MenuCategoryStyles';
import MenuCategoryOptionButton from './MenuCategoryOptionButton';
import MenuProduct from '../menuProduct/MenuProduct';
import MenuBundle from '../menuBundle/MenuBundle';
import { itemTypes } from '../../../../constants/constants';
import { insertItemInArrayAtIndex } from '../../../../helpers/ArrayUtils';
import { parseJson } from '../../../../helpers/Helpers';

const MenuCategory = ({
  category,
  index,
  selectItem,
  selectedItems,
  selectAllItems,
  selectAllBundles,
  products,
  bundles,
  selectedType,
  setOpenDialog,
}) => {
  const classes = useStyles();

  const onSelect = (itemId, selected, type) => {
    selectItem(itemId, category.objectId, selected, type);
  };

  const onSelectAll = () => {
    selectAllItems(category);
  };

  const onSelectAllBundles = () => {
    selectAllBundles(category);
  };

  // const [categoryItems, setCategoryItems] = useState([]);

  const categoryItems = useMemo(() => {
    // Populate the products based on the product IDs in this category
    const productsWithData =
      category?.productIds?.map((productId) => ({
        ...products.localData.find((product) => product?.objectId === productId),
        type: itemTypes.PRODUCT,
      })) || [];

    // Populate the bundles based on the bundle IDs in this category
    const bundlesWithData =
      category?.bundleIds?.map((bundle) => {
        const parsed = parseJson(bundle);

        return {
          ...bundles.localData.find((localBundle) => localBundle?.objectId === parsed?.bundleId),
          bundleIndex: parsed?.index,
          type: itemTypes.BUNDLE,
        };
      }) || [];

    let newCategoryItems = productsWithData;
    bundlesWithData.forEach((bundle) => {
      newCategoryItems = insertItemInArrayAtIndex(newCategoryItems, bundle.bundleIndex, bundle);
    });

    return newCategoryItems;
  }, [products.localData, category.productIds, bundles.localData, category.bundleIds]);

  return (
    <Draggable draggableId={category.objectId} index={index}>
      {(providedCategory, snapshot) => (
        <Box
          className={`${classes.draggableCategory} ${snapshot.isDragging ? 'dragging' : ''} `}
          ref={providedCategory.innerRef}
          {...providedCategory.draggableProps}
          boxShadow={snapshot.isDragging ? 3 : 0}
        >
          <List
            dense={false}
            subheader={
              <ListSubheader
                component='div'
                id='category-list-subheader'
                color='primary'
                className={classes.listSubheader}
                {...providedCategory.dragHandleProps}
              >
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                  <span>{category.categoryTitle}</span>
                  <MenuCategoryOptionButton
                    category={category}
                    selectAllItems={onSelectAll}
                    selectAllBundles={onSelectAllBundles}
                  />
                </Box>
                {categoryItems?.length !== 0 && (
                  <Box
                    style={{
                      height: '20px',
                      fontSize: '13px',
                      lineHeight: '20px',
                      color: '#9F9F9F',
                      columnGap: '15px',
                    }}
                    display='flex'
                    justifyContent='flex-end'
                    alignItems='baseline'
                  >
                    {/* <span style={{marginRight: "50px"}}>Pos ID</span> */}
                    <span style={{ width: '100px' }}>Options</span>
                    <span style={{ width: '100px' }}>Price</span>
                    <span style={{ width: '140px' }} />
                    <span style={{ width: '36px' }} />
                  </Box>
                )}
              </ListSubheader>
            }
          >
            <Droppable droppableId={category.objectId} type='menu-item'>
              {(provided, droppableSnapshot) => (
                <Box
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={`${classes.droppableMenuItem} ${
                    droppableSnapshot.isDraggingOver ? 'dragging-over' : ''
                  } `}
                >
                  {categoryItems?.length > 0 ? (
                    <Box>
                      {categoryItems
                        .filter((categoryItem) => categoryItem?.objectId)
                        .map((categoryItem, categoryIndex) =>
                          categoryItem?.type === itemTypes.PRODUCT ? (
                            <MenuProduct
                              key={categoryItem?.objectId}
                              product={categoryItem}
                              index={categoryIndex}
                              selected={
                                selectedItems?.filter(
                                  (item) => item.itemId === categoryItem.objectId,
                                )?.length > 0
                              }
                              onSelect={(value) =>
                                onSelect(categoryItem.objectId, value, categoryItem?.type)
                              }
                              category={category}
                              disabled={selectedType === itemTypes.BUNDLE}
                              hideImages={products?.data?.length > 200}
                              setOpenDialog={() => {
                                setOpenDialog(categoryItem);
                              }}
                            />
                          ) : (
                            <MenuBundle
                              key={categoryItem?.objectId}
                              bundle={categoryItem}
                              index={categoryIndex}
                              selected={
                                selectedItems?.filter(
                                  (item) => item.itemId === categoryItem.objectId,
                                )?.length > 0
                              }
                              onSelect={(value) =>
                                onSelect(categoryItem.objectId, value, categoryItem?.type)
                              }
                              category={category}
                              disabled={selectedType === itemTypes.PRODUCT}
                            />
                          ),
                        )}
                      {provided.placeholder}
                    </Box>
                  ) : (
                    <Box
                      style={{
                        fontSize: '16px',
                        marginLeft: '20px',
                        marginBottom: '10px',
                      }}
                    >
                      No items in {category.categoryTitle?.toLowerCase()}
                    </Box>
                  )}
                </Box>
              )}
            </Droppable>

            <MenuItemAdd category={category} />
          </List>
          <Divider />
        </Box>
      )}
    </Draggable>
  );
};

const mapStateToProps = (state) => ({
  activeRestaurant: state.activeRestaurant,
  bundles: state.bundles,
  products: state.products,
});

export default connect(mapStateToProps)(MenuCategory);
