import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  ListItem,
  List,
  ListItemText,
  ListSubheader,
  Typography,
  Divider,
  Box,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  fetchImportOptions,
  fetchImportOptionsBeforeCreated,
} from '../../actions/importOptionsAction';
import useStyles from './ImportOptionsDialogStyles';

const ImportOptionsDialog = ({
  fetchImportOptions,
  fetchImportOptionsBeforeCreated,
  items,
  menuItemId,
  openDialog,
  setOpenDialog,
}) => {
  const classes = useStyles();

  const [itemsByCategory, setItemsByCategory] = useState([]);

  const closeDialog = () => {
    setOpenDialog(false);
  };

  const submit = (event) => {
    event.preventDefault();

    const formError = false;

    if (formError) {
      return;
    }

    // importOptionsActions();
    closeDialog();
  };

  const getOptions = (item) => {
    if (item._created) {
      fetchImportOptionsBeforeCreated(menuItemId, item.objectId);
      closeDialog();
      return;
    }

    fetchImportOptions(menuItemId, item.objectId);
    closeDialog();
  };

  const itemsNoPlaceholder = itemsByCategory.filter((category) => {
    const hasItems = category.menuItems.filter((item) => !item._placeholder);
    return hasItems.length > 0;
  });

  return (
    <Dialog
      open={openDialog}
      onClose={closeDialog}
      fullWidth
      maxWidth='sm'
      onEnter={() => {
        const itemsByCategoryReduced = items.localData.reduce((accumByCategory, menuItem) => {
          // skip deleted items
          if (!menuItem.enabled) {
            return accumByCategory;
          }

          // check if menuItem's category is already reduced
          const menuItemIndex = accumByCategory.findIndex(
            (item) => item.category === menuItem.category,
          );

          // store initital menuItem
          if (menuItemIndex === -1) {
            accumByCategory.push({
              category: menuItem.category,
              menuItems: [menuItem],
            });
          } else {
            accumByCategory[menuItemIndex].menuItems.push(menuItem);
          }
          return accumByCategory;
        }, []);
        setItemsByCategory(itemsByCategoryReduced);
      }}
      onExited={() => {}}
    >
      <DialogTitle>Select an Item to import options from</DialogTitle>
      <DialogContent>
        <form onSubmit={submit} id='edit-menu-item-form'>
          {itemsNoPlaceholder.length > 0 ? (
            <Box className={classes.boxOutlined}>
              <Box
                className={`MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-shrink MuiInputLabel-outlined ${classes.boxLabel}`}
              >
                Items
              </Box>

              {itemsNoPlaceholder.map((category, index) => (
                <React.Fragment key={index}>
                  <List
                    key={category.category}
                    subheader={<ListSubheader color='primary'>{category.category}</ListSubheader>}
                  >
                    {category.menuItems.map((item) => (
                      <ListItem key={item.objectId} button onClick={() => getOptions(item)}>
                        <ListItemText primary={item.itemTitle} />
                      </ListItem>
                    ))}
                  </List>
                  {index < itemsNoPlaceholder.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </Box>
          ) : (
            <Typography>There are no items to import options from.</Typography>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color='default'>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  items: state.menuItems,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchImportOptions, fetchImportOptionsBeforeCreated }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ImportOptionsDialog);
