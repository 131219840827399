import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    marginBottom: '100px',
  },
  '@global': {
    [theme.breakpoints.down('sm')]: {
      '.MuiPaper-root': {
        borderRadius: [0, '!important'],
      },
    },
    '.MuiButton-root': {
      borderRadius: '20px',
    },
  },
}));

export default useStyles;
