import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List, ListItem, ListItemText, Paper } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
  fetchRestaurantByIdAction,
  fetchRestaurantsLastUpdatedAction,
} from '../../actions/restaurantsAction';
import Error from '../error/Error';

const RestaurantsUpdated = ({
  fetchRestaurantById,
  fetchRestaurantsLastUpdated,
  restaurantsLastUpdated,
}) => {
  const history = useHistory();

  useEffect(() => {
    // fetch restaurants by name sorted by lastUpdated
    fetchRestaurantsLastUpdated();
  }, [fetchRestaurantsLastUpdated]);

  const restaurantSelected = (selectedRestaurant) => {
    fetchRestaurantById(selectedRestaurant?.objectId);

    history.push(`/restaurants/${selectedRestaurant.objectId}`);
  };

  if (restaurantsLastUpdated.fetching) {
    return (
      <Paper style={{ marginTop: '1rem' }}>
        <Skeleton variant='rect' height={40} style={{ marginBottom: 6 }} />
      </Paper>
    );
  }

  if (restaurantsLastUpdated.error) {
    return (
      <Error
        style={{ marginTop: '1rem' }}
        error={restaurantsLastUpdated.error}
        message={restaurantsLastUpdated.errorMessage}
      />
    );
  }

  return (
    <Paper style={{ marginTop: '1rem' }}>
      <List component='nav'>
        {restaurantsLastUpdated.data.map((restaurant) => (
          <ListItem key={restaurant.objectId} button onClick={() => restaurantSelected(restaurant)}>
            <ListItemText primary={restaurant.name} />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  restaurantsLastUpdated: state.restaurantsLastUpdated,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchRestaurantById: fetchRestaurantByIdAction,
      fetchRestaurantsLastUpdated: fetchRestaurantsLastUpdatedAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantsUpdated);
