import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Box } from '@material-ui/core';
import useStyles from './NotificationBannerStyles';

const NotificationBanner = ({ text }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.innerContainer} display='flex'>
        <Box className={classes.icon} />
        <Box className={classes.content}>{text}</Box>
      </Box>
    </Box>
  );
};

NotificationBanner.propTypes = {};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(NotificationBanner);
