import moment from 'moment';
import packageInfo from '../../package.json';

export const minutesToTime = (minutes, format = 'h:mm a') =>
  moment().startOf('day').add(minutes, 'minutes').format(format);

export const formatCurrency = (num) => {
  if (Number.isNaN(num)) {
    return '';
  }

  return `$${(+num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
};

export const removeSpaces = (string) => {
  if (!string) {
    return '';
  }

  return string.replace(/\s/g, '');
};

export const pluralise = (string, number, pluralString) => {
  if (number === 1) {
    return string;
  }

  if (pluralString) {
    return pluralString;
  }

  return `${string}s`;
};

export const trimString = (string, maxLength = 30, suffix = '...') => {
  if (string.length <= maxLength) {
    return string;
  }

  return string.substring(0, maxLength) + suffix;
};

export const emptyStringToNull = (string) => {
  if (string === '') {
    return null;
  }

  return string;
};

export const toCapitalizedWords = (name) => {
  const words = name.match(/[A-Za-z][a-z]*/g) || [];

  return words.map(capitalize).join(' ');
};

export const capitalize = (word) => word.charAt(0).toUpperCase() + word.substring(1);

export const removeEmpty = (obj) =>
  // eslint-disable-next-line no-unused-vars
  Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== null && v !== undefined));

/* eslint-disable */
export const arraysEqual = (a, b) => {
  if (a === b) return true;
  if (a.length !== b.length) return false;

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

export const noDiffToNull = (localData, data) => {
  if (localData instanceof Array && data instanceof Array) {
    return arraysEqual(localData, data) ? null : localData;
  }
  return localData === data ? null : localData;
};

export const getAsString = (name1, value1, name2, value2) => {
  if (name1 && name2) {
    return 'all';
  }

  if (name1) {
    return value1;
  }

  if (name2) {
    return value2;
  }

  return null;
};

/**
 * Returns the app version for use in API calls
 */
export const getAppVersion = () => {
  return `web_${packageInfo.version.substring(0, packageInfo.version.lastIndexOf('.'))}`;
};

/**
 * Safely checks if a string starts with a value
 * returns false if it doesn't, or if the param is not a string
 */
export const startsWith = (str, value) => {
  if (typeof str !== 'string') {
    return false;
  }

  return str.startsWith(value);
};
/**
 * Safely JSON parses undefined values
 */
export const parseJson = (value) => JSON.parse(value || '{}');

/**
 * Useful for checking whether a string has any value or is empty
 * Now works with arrays too.
 * Avoids accidentally checking falsy values like 0
 * */
export const isEmpty = (item) => {
  if (item === null || item === undefined) {
    return true;
  }

  // empty object
  if (Object.getPrototypeOf(item) === Object.prototype) {
    return item && Object.keys(item).length === 0;
  }

  // empty array
  if (item.constructor === Array) {
    return item?.length === 0;
  }

  // empty string
  return item === '';
};

/**
 * Check if both arrays have the same values, even if ordered differently
 */
export const arraysMatch = (arr1, arr2) => {
  if (isEmpty(arr1) && isEmpty(arr2)) {
    return true;
  }

  if (isEmpty(arr1) || isEmpty(arr2)) {
    return false;
  }

  if (arr1?.length !== arr2?.length) {
    return false;
  }

  return [...arr1].sort().join(',') === [...arr2].sort().join(',');
};
