import React from 'react';
import { Box } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import useStyles from './ReviewButtonStyles';
import { ReactComponent as CheckIcon } from '../../../../assets/small_check.svg';

const ReviewButton = ({ importStatus, setStatus, menuVersion }) => {
  if (!menuVersion.posEnabled || importStatus === null) {
    return <></>;
  }

  const classes = useStyles();

  const toggleCompletion = () => {
    if (importStatus !== 'needsReview') {
      setStatus('needsReview');
    } else {
      setStatus('reviewed');
    }
  };

  return (
    <Box
      className={classes.reviewButtonContainer}
      style={{ cursor: 'pointer' }}
      onClick={toggleCompletion}
    >
      {importStatus === 'needsReview' ? (
        <Box display='flex' gridColumnGap='12px'>
          <Box className={classes.reviewButtonIcon} />
          <Box>To review</Box>
        </Box>
      ) : (
        <Box display='flex' gridColumnGap='12px'>
          <CheckIcon />
          <Box>Reviewed</Box>
        </Box>
      )}
    </Box>
  );
};

ReviewButton.propTypes = {};

const mapStateToProps = (state) => ({
  menuVersion: state.menuVersion,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ReviewButton);
