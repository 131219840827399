import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Snackbar } from '@material-ui/core';
import { Alert as MuiAlert } from '@material-ui/lab';
import { unsetToastAction } from '../../../../actions/toastAction';

const Alert = (props) => <MuiAlert elevation={6} variant='filled' {...props} />;

const ToastHandler = ({ toasts }) => {
  // TODO remove error id when handleExited fires.

  const [allToasts, setAllToasts] = useState(toasts);
  const [open, setOpen] = useState(false);
  const [activeToast, setActiveToast] = useState(undefined);

  useEffect(() => {
    setAllToasts(toasts);
  }, [toasts]);

  useEffect(() => {
    if (allToasts.length && !activeToast) {
      // Set a new error when we don't have an active one
      setActiveToast({ ...allToasts[0] });
      setAllToasts((prev) => prev.slice(1));
      setOpen(true);
    } else if (allToasts.length && activeToast && open) {
      // Close an active error when a new one is added
      setOpen(false);
    }
  }, [allToasts, activeToast, open]);

  // const handleClick = (message) => () => {
  //   setSnackPack((prev) => [...prev, { message, id: new Date().getTime() }]);
  // };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setActiveToast(undefined);
  };

  // By default, hide success messages after a few seconds
  let autoHideDuration = null;
  if (activeToast?.type === 'success') {
    autoHideDuration = 8000;
  }

  return (
    <Snackbar
      id={activeToast?.id}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      onExited={handleExited}
      message={activeToast?.message}
    >
      <Alert onClose={handleClose} severity={activeToast?.type}>
        {activeToast?.message}
      </Alert>
    </Snackbar>
  );
};

const mapStateToProps = (state) => ({ toasts: state.toasts.messages });
const mapDispatchToProps = (dispatch) => bindActionCreators({ unsetToastAction }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ToastHandler);
