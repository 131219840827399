import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Box } from '@material-ui/core';
import { saveMenuItemsLocalAction } from '../../actions/menuItemsAction';
import MenuCategory from '../menuCategory/MenuCategory';
import useStyles from './MenuCategoriesStyles';

const MenuCategories = ({ menuItems, menuItemsByCategory, saveMenuItemsLocalAction }) => {
  const classes = useStyles();

  const onDragEnd = (result) => {
    /*
     * MenuItems and Categories Re-Ordering
     */

    const { destination, source, type } = result;

    if (!destination) {
      return;
    }

    // dropped in same position
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    // Helper functions
    const prevCategoryLastItemIndex = (categoriesArray, categoryIndex) =>
      categoriesArray[categoryIndex - 1].menuItems.slice(-1)[0].itemIndex;

    const nextCategoryFirstItemIndex = (categoriesArray, categoryIndex) =>
      categoriesArray[categoryIndex + 1].menuItems.slice(0, 1)[0].itemIndex;

    const prevItemIndex = (menuItemsArray, menuItemsIndex) =>
      menuItemsArray[menuItemsIndex - 1].itemIndex;

    const nextItemIndex = (menuItemsArray, menuItemIndex) =>
      menuItemsArray[menuItemIndex + 1].itemIndex;

    const getIndexFirstCategoryManyItems = (categoriesArray, categoryIndex, countIndex, count) =>
      (countIndex / (count + 1)) * nextCategoryFirstItemIndex(categoriesArray, categoryIndex);

    const getIndexFirstCategoryOneItem = (categoriesArray, categoryIndex) =>
      nextCategoryFirstItemIndex(categoriesArray, categoryIndex) / 2;

    const getIndexLastCategoryManyItems = (categoriesArray, categoryIndex, countIndex, count) =>
      (2 / (count + 1)) * countIndex + prevCategoryLastItemIndex(categoriesArray, categoryIndex);

    const getIndexLastCategoryOneItem = (categoriesArray, categoryIndex) =>
      prevCategoryLastItemIndex(categoriesArray, categoryIndex) + 1;

    const getAnyCategoryFirstItem = (
      categoriesArray,
      categoryIndex,
      menuItemsArray,
      menuItemIndex,
    ) =>
      (prevCategoryLastItemIndex(categoriesArray, categoryIndex) +
        nextItemIndex(menuItemsArray, menuItemIndex)) /
      2;

    const getAnyCategoryLastItem = (
      categoriesArray,
      categoryIndex,
      menuItemsArray,
      menuItemIndex,
    ) =>
      (prevItemIndex(menuItemsArray, menuItemIndex) +
        nextCategoryFirstItemIndex(categoriesArray, categoryIndex)) /
      2;

    const getAnyCategoryMiddleItem = (menuItemsArray, menuItemIndex) =>
      (prevItemIndex(menuItemsArray, menuItemIndex) +
        nextItemIndex(menuItemsArray, menuItemIndex)) /
      2;

    const getIndexMiddleCategoryManyItems = (categoriesArray, categoryIndex, countIndex, count) =>
      ((nextCategoryFirstItemIndex(categoriesArray, categoryIndex) -
        prevCategoryLastItemIndex(categoriesArray, categoryIndex)) /
        (count + 1)) *
        countIndex +
      prevCategoryLastItemIndex(categoriesArray, categoryIndex);

    const getIndexMiddleCategoryOneItem = (categoriesArray, categoryIndex) =>
      (prevCategoryLastItemIndex(categoriesArray, categoryIndex) +
        nextCategoryFirstItemIndex(categoriesArray, categoryIndex)) /
      2;

    const getMenuItemIndex = (
      finishMenuItemIndex,
      finishCategoryIndex,
      finishCategoryMenuItems,
      menuItemsByCategory,
      newMenuItemsByCategory,
    ) => {
      // FIRST Category, FIRST MenuItem
      if (finishMenuItemIndex === 0 && finishCategoryIndex === 0) {
        const index = nextItemIndex(finishCategoryMenuItems, finishMenuItemIndex) / 2;

        return index;

        // LAST Category, LAST MenuItem
      }
      if (
        finishMenuItemIndex === finishCategoryMenuItems.length - 1 &&
        finishCategoryIndex === menuItemsByCategory.length - 1
      ) {
        const index = prevItemIndex(finishCategoryMenuItems, finishMenuItemIndex) + 1;
        return index;

        // ANY Category
      }
      // ANY Category, FIRST MenuItem
      if (finishMenuItemIndex === 0) {
        const index = getAnyCategoryFirstItem(
          menuItemsByCategory,
          finishCategoryIndex,
          finishCategoryMenuItems,
          finishMenuItemIndex,
        );
        return index;
      }

      // ANY Category, LAST MenuItem
      if (finishMenuItemIndex === finishCategoryMenuItems.length - 1) {
        const index = getAnyCategoryLastItem(
          menuItemsByCategory,
          finishCategoryIndex,
          finishCategoryMenuItems,
          finishMenuItemIndex,
        );
        return index;
      }

      const index = getAnyCategoryMiddleItem(finishCategoryMenuItems, finishMenuItemIndex);
      return index;
    };

    const getCategoryIndex = (
      countIndex,
      menuItemsLength,
      categoriesArray,
      categoryIndex,
      menuItemsByCategory,
    ) => {
      // FIRST Category
      if (destination.index === 0) {
        // WITH 1 Item
        if (menuItemsLength === 1) {
          return getIndexFirstCategoryOneItem(categoriesArray, categoryIndex);

          // WITH Many Items
        }
        if (menuItemsLength > 1) {
          return getIndexFirstCategoryManyItems(
            categoriesArray,
            categoryIndex,
            countIndex + 1,
            menuItemsLength,
          );
        }
        // WITH Zero Items

        // LAST Category
      } else if (categoryIndex === menuItemsByCategory.length - 1) {
        // WITH 1 Item
        if (menuItemsLength === 1) {
          return getIndexLastCategoryOneItem(categoriesArray, categoryIndex);

          // WITH Many Items
        }
        if (menuItemsLength > 1) {
          return getIndexLastCategoryManyItems(
            categoriesArray,
            categoryIndex,
            countIndex + 1,
            menuItemsLength,
          );
        }
        // WITH Zero Items

        // MIDDLE Category
      } else {
        // WITH 1 Item
        if (menuItemsLength === 1) {
          return getIndexMiddleCategoryOneItem(categoriesArray, categoryIndex);

          // WITH Many Items
        }
        if (menuItemsLength > 1) {
          return getIndexMiddleCategoryManyItems(
            categoriesArray,
            categoryIndex,
            countIndex + 1,
            menuItemsLength,
          );
        }
        // WITH Zero Items
      }
    };

    /*
     * Re-order Category
     */

    if (type === 'category') {
      const newMenuItemsByCategory = Array.from(menuItemsByCategory);

      // Remove category being dragged, and store it
      const dragCategory = newMenuItemsByCategory.splice(source.index, 1)[0];

      // Add dragged category back at the new index
      newMenuItemsByCategory.splice(destination.index, 0, dragCategory);

      /*
       *  Update index of menuItems by Category:
       */

      dragCategory.menuItems = dragCategory.menuItems.map((menuItem, index) => {
        const newIndex = getCategoryIndex(
          index,
          dragCategory.menuItems.length,
          newMenuItemsByCategory,
          destination.index,
          menuItemsByCategory,
        );

        return { ...menuItem, itemIndex: newIndex, _altered: true };
      });

      // Update the dragged category AFTER making index updates
      newMenuItemsByCategory.splice(destination.index, 1);
      newMenuItemsByCategory.splice(destination.index, 0, dragCategory);

      // Flatten the array from categories
      const newMenuItems = newMenuItemsByCategory.reduce((flattenByCategory, menuItems) => {
        flattenByCategory.push(...menuItems.menuItems);
        return flattenByCategory;
      }, []);
      saveMenuItemsLocalAction(newMenuItems);
      return;
    }

    const startCategory = menuItemsByCategory.find((cat) => cat.category === source.droppableId);

    const finishCategory = menuItemsByCategory.find(
      (cat) => cat.category === destination.droppableId,
    );

    /*
     * Re-order Menu items - within same category
     */

    if (startCategory.category === finishCategory.category) {
      const newFinishCategoryMenuItems = Array.from(finishCategory.menuItems);

      // Remove menu item being dragged, and store it
      let dragMenuItem = newFinishCategoryMenuItems.splice(source.index, 1)[0];

      // Add dragged menu item back at the new index
      newFinishCategoryMenuItems.splice(destination.index, 0, dragMenuItem);

      const finishCategoryIndex = menuItemsByCategory.findIndex(
        (cat) => cat.category === destination.droppableId,
      );

      const newIndex = getMenuItemIndex(
        destination.index,
        finishCategoryIndex,
        newFinishCategoryMenuItems,
        menuItemsByCategory,
      );

      // update item index
      dragMenuItem = {
        ...dragMenuItem,
        itemIndex: newIndex,
        _altered: true,
      };

      // Update the dragged category AFTER making index updates
      newFinishCategoryMenuItems.splice(destination.index, 1);
      newFinishCategoryMenuItems.splice(destination.index, 0, dragMenuItem);

      // Put the changed current menu items back into the categories array
      const newCategories = menuItemsByCategory.map((category) => {
        if (category.category !== startCategory.category) {
          return category;
        }
        const updatedItem = {
          ...category,
          menuItems: [...newFinishCategoryMenuItems],
        };
        return updatedItem;
      });

      // Flatten the array from categories
      const flattenMenuItemsByCategories = newCategories.reduce((flattenByCategory, menuItems) => {
        flattenByCategory.push(...menuItems.menuItems);
        return flattenByCategory;
      }, []);
      saveMenuItemsLocalAction(flattenMenuItemsByCategories);

      // saveMenuItemOrderAction(menuItemId, newMenuItemIndex)

      return;
    }

    /*
     * Re-order Menu items - within different categories
     */

    const startCategoryIndex = menuItemsByCategory.findIndex(
      (cat) => cat.category === source.droppableId,
    );

    const finishCategoryIndex = menuItemsByCategory.findIndex(
      (cat) => cat.category === destination.droppableId,
    );

    const newStartMenuItems = Array.from(startCategory.menuItems);

    // Remove MenuItem being dragged, and store it
    let dragMenuItem = newStartMenuItems.splice(source.index, 1)[0];

    const newFinishCategoryMenuItems = Array.from(finishCategory.menuItems);

    // Insert the dragged MenuItems into the Finish Category
    // but check the index ?!
    newFinishCategoryMenuItems.splice(destination.index, 0, dragMenuItem);

    // Check if start category is empty
    if (newStartMenuItems.length === 0) {
      // add a placeholder menuitem

      const categoryPlaceholder = {
        category: startCategory.category,
        itemIndex: startCategory.menuItems[0].itemIndex,
        menuId: startCategory.menuItems[0].menuId,
        enabled: true,
        _placeholder: true,
      };

      newStartMenuItems.splice(source.index, 0, categoryPlaceholder);
    }

    // Calculate the MenuItem new index
    let newIndex = getMenuItemIndex(
      destination.index,
      finishCategoryIndex,
      newFinishCategoryMenuItems,
      menuItemsByCategory,
    );

    // Check if finish category was previously empty
    const placeholderIndex = newFinishCategoryMenuItems.findIndex(
      (menuItem) => menuItem._placeholder,
    );
    if (placeholderIndex > -1) {
      // set MenuItem's index as the placeholder!
      newIndex = newFinishCategoryMenuItems[placeholderIndex].itemIndex;

      // remove placeholder
      newFinishCategoryMenuItems.splice(placeholderIndex, 1);
    }

    // Update the dragMenuItem here
    dragMenuItem = {
      ...dragMenuItem,
      itemIndex: newIndex,
      category: finishCategory.category,
      _altered: true,
    };

    // Update the dragged category AFTER making index updates
    newFinishCategoryMenuItems.splice(destination.index, 1);
    newFinishCategoryMenuItems.splice(destination.index, 0, dragMenuItem);

    const newMenuItemsByCategory = menuItemsByCategory.map((cat, index) => {
      if (index === startCategoryIndex) {
        return {
          ...cat,
          menuItems: newStartMenuItems,
        };
      }
      if (index === finishCategoryIndex) {
        return {
          ...cat,
          menuItems: newFinishCategoryMenuItems,
        };
      }
      return cat;
    });

    // Flatten the array into MenuItems
    const newMenuItems = newMenuItemsByCategory.reduce((flattenByCategory, menuItems) => {
      flattenByCategory.push(...menuItems.menuItems);
      return flattenByCategory;
    }, []);

    saveMenuItemsLocalAction(newMenuItems);
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='categories' type='category'>
          {(provided, snapshot) => (
            <Box
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={`${classes.droppableCategory} ${
                snapshot.isDraggingOver ? 'dragging-over' : ''
              } `}
            >
              {menuItemsByCategory.map((category, index) => (
                <MenuCategory key={category.category} index={index} category={category} />
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

MenuCategories.propTypes = {
  menuItemsByCategory: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  menuItems: state.menuItems,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ saveMenuItemsLocalAction }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(MenuCategories);
