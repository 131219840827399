import { devLog } from './devLog';

export const filterUnique = (value, index, self) => self.indexOf(value) === index;

export const sortAlphabetical = (a, b) => a.toLowerCase().localeCompare(b.toLowerCase());

export const findById = (items, id, idPropertyName = 'objectId') =>
  items.find((item) => item[idPropertyName] === id);

export const groupObjects = (objects, groupBy) =>
  objects?.reduce((accum, object) => {
    const updated = { ...accum };
    if (Object.hasOwnProperty.call(updated, object[groupBy])) {
      updated[object[groupBy]].push(object);
    } else {
      updated[object[groupBy]] = [object];
    }

    return updated;
  }, {});

export const insertItemInArrayAtIndex = (array, insertIndex, item) => {
  const newArray = Array.from(array);
  newArray.splice(insertIndex, 0, item);
  return newArray;
};

export const deleteItemsInArray = (array, arrayKey, itemKey) =>
  array.filter((item) => item[arrayKey] !== itemKey);

export const getItemInArray = (array, arrayKey, itemKey) =>
  array.find((item) => item[arrayKey] === itemKey);

export const updateItemInArray = (array, arrayKey, itemKey, updateItemCallback) => {
  const updatedItems = array.map((item) => {
    if (item[arrayKey] !== itemKey) {
      // preserve other items
      return item;
    }

    // create an updated item
    const updatedItem = updateItemCallback(item);
    return updatedItem;
  });

  return updatedItems;
};

export const insertItemInArray = (array, arrayKey, itemKey, item) => {
  const newArray = Array.from(array);

  const insertIndex = array.findIndex((item) => item[arrayKey] === itemKey);

  if (insertIndex === -1) {
    devLog('info', '', "Insert index doesn't exist");
  }

  newArray.splice(insertIndex + 1, 0, item);
  return newArray;
};

export const prevItemIndex = (array, index) => array[index]?.itemIndex;

export const nextItemIndex = (array, index) => array[index + 1]?.itemIndex;

export const getIndexMiddleItem = (array, index) =>
  (prevItemIndex(array, index) + nextItemIndex(array, index)) / 2;

export const getIndexLastItem = (array, index) => prevItemIndex(array, index) + 1;

export const getNewIndex = (array, index) => {
  const prevItem = array[index];

  // No prev item, this is first
  if (!prevItem) {
    return 0;
  }

  // Prev item is the first item
  if (prevItem?._placeholder) {
    return prevItem.itemIndex;
  }

  // Prev item is the last item
  if (index === array.length - 1) {
    return getIndexLastItem(array, index);
  }
  // Prev item is a middle item
  return getIndexMiddleItem(array, index);
};
