import { completeChangeRequest } from '../graphql/mutations';
import * as type from './types';
import { activeChangeRequests, completedChangeRequests } from '../graphql/queries';
import { devLog } from '../helpers/devLog';
import { makeApiCall } from './actions';

export const fetchActiveChangeRequestsForRestaurantAction = (restaurantId) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: `ACTIVE_CHANGE_REQUESTS_FOR_RESTAURANT_${restaurantId}`,
  });

  dispatch({
    type: type.FETCH_ACTIVE_CHANGE_REQUESTS_FOR_RESTAURANT_PENDING,
    payload: restaurantId,
  });

  (async () => {
    try {
      const response = await makeApiCall(activeChangeRequests, { restId: restaurantId });

      devLog(
        'success',
        'active change requests for restaurant',
        response.data.activeChangeRequests,
      );

      dispatch({
        type: type.FETCH_ACTIVE_CHANGE_REQUESTS_FOR_RESTAURANT_SUCCESS,
        payload: {
          data: response.data.activeChangeRequests,
          restaurantId,
        },
      });
    } catch (error) {
      devLog('error', 'active change requests', error);

      dispatch({
        type: type.SET_TOAST,
        payload: {
          id: `FETCH_ACTIVE_CHANGE_REQUESTS_FOR_RESTAURANT_${new Date().getTime()}`,
          message: `Unable to fetch active change requests for restaurant: ${error}`,
          type: 'error',
        },
      });

      dispatch({
        type: type.FETCH_ACTIVE_CHANGE_REQUESTS_FOR_RESTAURANT_FAILURE,
        payload: `Unable to retrieve active change requests for restaurant: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: `ACTIVE_CHANGE_REQUESTS_FOR_RESTAURANT_${restaurantId}`,
      });
    }
  })();
};

export const fetchActiveChangeRequestsAction = () => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: `ACTIVE_CHANGE_REQUESTS`,
  });

  dispatch({
    type: type.FETCH_ACTIVE_CHANGE_REQUESTS_PENDING,
  });

  (async () => {
    try {
      const response = await makeApiCall(activeChangeRequests, {});

      devLog('success', 'active change requests', response.data.activeChangeRequests);

      dispatch({
        type: type.FETCH_ACTIVE_CHANGE_REQUESTS_SUCCESS,
        payload: response.data.activeChangeRequests,
      });
    } catch (error) {
      devLog('error', 'active change requests', error);

      dispatch({
        type: type.SET_TOAST,
        payload: {
          id: `FETCH_ACTIVE_CHANGE_REQUESTS_${new Date().getTime()}`,
          message: `Unable to fetch active change requests: ${error}`,
          type: 'error',
        },
      });

      dispatch({
        type: type.FETCH_ACTIVE_CHANGE_REQUESTS_FAILURE,
        payload: `Unable to retrieve active change requests: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: `ACTIVE_CHANGE_REQUESTS`,
      });
    }
  })();
};

export const fetchCompletedChangeRequestsAction = () => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: `COMPLETED_CHANGE_REQUESTS`,
  });

  dispatch({
    type: type.FETCH_COMPLETED_CHANGE_REQUESTS_PENDING,
  });

  (async () => {
    try {
      const response = await makeApiCall(completedChangeRequests, {
        pageSize: 20,
        offset: 0,
      });

      devLog('success', 'completed change requests', response.data.completedChangeRequests);

      dispatch({
        type: type.FETCH_COMPLETED_CHANGE_REQUESTS_SUCCESS,
        payload: response.data.completedChangeRequests,
      });
    } catch (error) {
      devLog('error', 'completed change requests', error);

      dispatch({
        type: type.SET_TOAST,
        payload: {
          id: `FETCH_COMPLETED_CHANGE_REQUESTS_${new Date().getTime()}`,
          message: `Unable to fetch completed change requests: ${error}`,
          type: 'error',
        },
      });

      dispatch({
        type: type.FETCH_COMPLETED_CHANGE_REQUESTS_FAILURE,
        payload: `Unable to retrieve completed change requests: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: `COMPLETED_CHANGE_REQUESTS`,
      });
    }
  })();
};

export const completeChangeRequestAction = (requestId, data) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: `COMPLETE_CHANGE_REQUEST_${requestId}`,
  });

  dispatch({
    type: type.COMPLETE_CHANGE_REQUEST_PENDING,
    payload: requestId,
  });

  (async () => {
    try {
      const response = await makeApiCall(completeChangeRequest, { requestId, data });

      devLog('success', 'complete change request', response);

      dispatch({
        type: type.COMPLETE_CHANGE_REQUEST_SUCCESS,
        payload: {
          requestId,
        },
      });

      dispatch({
        type: type.SET_TOAST,
        payload: {
          id: `COMPLETE_CHANGE_REQUEST_${new Date().getTime()}`,
          message: 'Change request has been completed.',
          type: 'success',
        },
      });
    } catch (error) {
      devLog('error', 'complete change request', error);

      dispatch({
        type: type.SET_TOAST,
        payload: {
          id: `COMPLETE_CHANGE_REQUEST_${new Date().getTime()}`,
          message: `Unable to complete change request: ${error}`,
          type: 'error',
        },
      });

      dispatch({
        type: type.COMPLETE_CHANGE_REQUEST_FAILURE,
        payload: `Unable to complete change request: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: `COMPLETE_CHANGE_REQUEST_${requestId}`,
      });
    }
  })();
};
