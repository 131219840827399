import * as type from '../actions/types';

const initialState = {
  version: 0, // 0 === version 1 menus, 1 === version 2 menus
  posEnabled: false, // Whether we are viewing a pos menu
};

export default function (state = initialState, action) {
  switch (action.type) {
    case type.FETCH_RESTAURANT_SUCCESS: {
      return {
        version: action.payload?.menuVersion,
        posEnabled: action.payload?.posEnabled,
      };
    }

    case type.SET_MENU_VERSION: {
      return {
        version: action.payload?.version,
        posEnabled: action.payload?.posEnabled,
      };
    }

    default: {
      return state;
    }
  }
}
