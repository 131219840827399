import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemAvatar, ListItemText, Paper } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Skeleton } from '@material-ui/lab';
import {
  clearMenuItemsAction,
  fetchMenuItemsAction,
  resetMenuItemsAction,
} from '../../actions/menuItemsAction';
import MenuCategories from '../menuCategories/MenuCategories';
import MenuCategoryCreate from '../menuCategoryCreate/MenuCategoryCreate';

const MenuItemsLoading = () => (
  <List>
    <ListItem>
      <ListItemAvatar>
        <Skeleton variant='circle' width={40} height={40} />
      </ListItemAvatar>
      <ListItemText>
        <Skeleton height={10} width='100%' style={{ marginBottom: 6 }} />
        <Skeleton height={10} width='80%' />
      </ListItemText>
    </ListItem>
  </List>
);

const MenuTabPanel = ({
  clearMenuItemsAction,
  fetchMenuItemsAction,
  menuId,
  menuItems,
  resetMenuItemsAction,
  menuVersion,
}) => {
  const [menuItemsByCategory, setMenuItemsByCategory] = useState([]);

  // Fetch Menu Items, and remove on unmount
  useEffect(() => {
    if (menuId.substring(0, 5) === 'menu_') {
      // NOTE: clear menu items from newly created menus that aren't saved yet
      resetMenuItemsAction();
    } else if (menuVersion !== 1) {
      fetchMenuItemsAction(menuId);
    }

    return () => {
      clearMenuItemsAction();
    };
  }, [clearMenuItemsAction, fetchMenuItemsAction, menuId, resetMenuItemsAction]);

  useEffect(() => {
    if (menuItems.fetching || menuItems.error) {
      return;
    }

    const menuItemsByCategoryReduced = menuItems.localData.reduce((accumByCategory, menuItem) => {
      // skip deleted items
      if (!menuItem.enabled) {
        return accumByCategory;
      }

      // check if menuItem's category is already reduced
      const menuItemIndex = accumByCategory.findIndex(
        (item) => item.category === menuItem.category,
      );

      // store initital menuItem
      if (menuItemIndex === -1) {
        accumByCategory.push({
          category: menuItem.category,
          menuItems: [menuItem],
        });
      } else {
        accumByCategory[menuItemIndex].menuItems.push(menuItem);
      }
      return accumByCategory;
    }, []);
    setMenuItemsByCategory(menuItemsByCategoryReduced);
  }, [menuItems.error, menuItems.fetching, menuItems.localData]);

  return (
    <>
      {menuItems.fetching ? (
        <Paper style={{ marginTop: '1rem' }}>
          <MenuItemsLoading />
        </Paper>
      ) : (
        <>
          <Paper style={{ marginTop: '1rem' }}>
            <MenuCategories menuItemsByCategory={menuItemsByCategory} />
          </Paper>
          <Paper style={{ marginTop: '1rem' }}>
            <MenuCategoryCreate menuId={menuId} />
          </Paper>
        </>
      )}
    </>
  );
};

MenuTabPanel.propTypes = {
  clearMenuItemsAction: PropTypes.func.isRequired,
  fetchMenuItemsAction: PropTypes.func.isRequired,
  menuItems: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  menuItems: state.menuItems,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { clearMenuItemsAction, fetchMenuItemsAction, resetMenuItemsAction },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MenuTabPanel);
