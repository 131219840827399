/* eslint-disable prefer-spread */
/* eslint-disable react/prop-types */
import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useRollbar } from '@rollbar/react';
import { dbSaveAction, dbSaveAllAction } from '../../actions/saveAction';

const SaveButton = ({
  activeRestaurantId,
  dbSave,
  dbSaveAll,
  menuItemOptions,
  menuItems,
  menus,
  save,
  version,
}) => {
  const rollbar = useRollbar();

  const handleSave = () => {
    // Version 2 menus save
    if (version === 1) {
      dbSaveAll(rollbar, true);
      return;
    }

    // Version 1 menus save
    // Get MENUS to save
    const createdMenus = menus.localData
      .filter((menu) => menu._created)
      .map((menu) => ({ ...menu, objectId: null, _objectId: menu.objectId }));

    const alteredMenus = menus.localData
      .filter((menu) => menu._altered && !menu._created)
      .map((menu) => ({ ...menu, _objectId: menu.objectId }));

    const deletedMenus = menus.deleted
      .filter((menu) => !menu._created)
      .map((menu) => ({ ...menu, _objectId: menu.objectId }));

    const updateMenus = [...createdMenus, ...alteredMenus, ...deletedMenus];

    // Get ITEMS to save
    const createdItems = menuItems.localData
      .filter((menuItem) => menuItem._created && !menuItem._placeholder)
      .map((item) => ({ ...item, objectId: null, _objectId: item.objectId }));

    const alteredItems = menuItems.localData
      .filter((menuItem) => menuItem._altered && !menuItem._placeholder && !menuItem._created)
      .map((item) => ({ ...item, _objectId: item.objectId }));

    const deletedItems = menuItems.deleted
      .filter((item) => !item._created)
      .map((item) => ({ ...item, _objectId: item.objectId }));

    const updateItems = [...createdItems, ...alteredItems, ...deletedItems];

    // Get OPTIONS to save
    const optionsArray = Object.keys(menuItemOptions.stored).map(
      (key) => menuItemOptions.stored[key],
    );

    const createdOptions = [].concat
      .apply([], optionsArray) // flatten array
      .filter((option) => option._created)
      .map((option) => ({
        ...option,
        objectId: null, // created should have null id
        _objectId: option.objectId, // store id
      }));

    const alteredOptionsArray = Object.keys(menuItemOptions.stored).map(
      (key) => menuItemOptions.stored[key],
    );

    const alteredOptions = [].concat
      .apply([], alteredOptionsArray) // flatten array
      .filter((option) => option._altered && !option._created)
      .map((option) => ({ ...option, _objectId: option.objectId }));

    const deletedOptions = Object.keys(menuItemOptions.deleted)
      .map((key) => menuItemOptions.deleted[key])
      .filter((option) => !option._created)
      .map((option) => ({ ...option, _objectId: option.objectId }));

    const updateOptions = [...createdOptions, ...alteredOptions, ...deletedOptions];

    if (updateMenus.length > 0 || updateItems.length > 0 || updateOptions.length > 0) {
      dbSave(updateMenus, updateItems, updateOptions, activeRestaurantId, true);
    }
  };

  return (
    <Button
      variant='contained'
      color='primary'
      onClick={handleSave}
      disabled={save.saving}
      style={{ width: '90px' }}
    >
      {save.saving ? <CircularProgress size={22} color='inherit' /> : 'Save'}
    </Button>
  );
};

const mapStateToProps = (state) => ({
  activeRestaurantId: state.activeRestaurant.data.objectId,
  menus: state.menus,
  menuItems: state.menuItems,
  menuItemOptions: state.menuItemOptions,
  save: state.save,
  version: state.menuVersion.version,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      dbSave: dbSaveAction,
      dbSaveAll: dbSaveAllAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SaveButton);
