import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import moment from 'moment';
import { timeIntervals } from '../../constants/constants';

const TextFieldTimePicker = ({ className, label, value, onChange, error, helperText }) => {
  const times = timeIntervals;

  return (
    <Autocomplete
      options={times}
      getOptionLabel={(option) => option}
      fullWidth
      value={value}
      onChange={(event, newInputValue) => {
        const selectedTime = newInputValue ? moment.utc(newInputValue, 'hh:mm a') : null;
        onChange(selectedTime);
      }}
      onBlur={(event) => {
        const { value } = event.target;
        const selectedTime = value ? moment.utc(value, 'hh:mm a') : null;
        onChange(selectedTime);
      }}
      handleHomeEndKeys={false}
      freeSolo
      renderInput={(params) => (
        <TextField
          margin='normal'
          fullWidth
          {...params}
          variant='outlined'
          className={className}
          label={label}
          error={error}
          helperText={helperText}
        />
      )}
      // disableClearable={true}
    />
  );
};

export default TextFieldTimePicker;
