import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  droppableMenuItemOptionChoice: {
    flexGrow: 1,
    transition: 'background-color 200ms ease',

    '&.dragging-over': {
      backgroundColor: '#eff2f3',
    },
  },
  boxLabel: {
    backgroundColor: '#fff',
    padding: '0px 0.5rem',
  },
  boxOutlined: {
    borderTop: '1px solid rgba(0, 0, 0, 0.23)',
    marginTop: '1rem',
    paddingLeft: '1px',
    paddingRight: '1px',
    position: 'relative',
    height: '100%',
    '&.boxError': {
      borderColor: theme.palette.primary.main,
    },
    '&.boxError > label': {
      color: theme.palette.primary.main,
    },
  },
  emptyText: {
    marginTop: '20px',
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#6E6E6D',
  },
}));

export default useStyles;
