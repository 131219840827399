import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress } from '@material-ui/core';
import { connect } from 'react-redux';

const Loading = ({ loading }) => {
  const { loadingIds } = loading;
  return <>{loadingIds.length > 0 && <LinearProgress />}</>;
};

Loading.propTypes = {
  loading: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.loading,
});

export default connect(mapStateToProps, null)(Loading);
