import * as type from './types';

export const saveMenu = /* GraphQL */ `
  mutation SaveMenu(
    $objectId: ID
    $restaurantIds: String
    $menuTitle: String
    $menuDescription: String
    $enabled: Boolean
    $menuIndex: Float
    $startTime: Int
    $endTime: Int
    $type: String
    $platform: String
    $version: Int
    $restId: String
  ) {
    saveMenu(
      objectId: $objectId
      restaurantIds: $restaurantIds
      menuTitle: $menuTitle
      menuDescription: $menuDescription
      enabled: $enabled
      menuIndex: $menuIndex
      startTime: $startTime
      endTime: $endTime
      type: $type
      platform: $platform
      version: $version
      restId: $restId
    ) {
      objectId
      type
      enabled
      restaurantIds
      startTime
      endTime
      menuIndex
      menuTitle
      menuDescription
      platform
    }
  }
`;

export const saveMenuItem = /* GraphQL */ `
  mutation SaveMenuItem(
    $objectId: ID
    $price: Float
    $imageLink: String
    $itemIndex: Float
    $itemDescription: String
    $itemTitle: String
    $category: String
    $enabled: Boolean
    $menuId: String
    $disableDate: String
    $disableUntilDate: String
    $noDiscount: Boolean
  ) {
    saveMenuItem(
      objectId: $objectId
      price: $price
      imageLink: $imageLink
      itemIndex: $itemIndex
      itemDescription: $itemDescription
      itemTitle: $itemTitle
      category: $category
      enabled: $enabled
      menuId: $menuId
      disableDate: $disableDate
      disableUntilDate: $disableUntilDate
      noDiscount: $noDiscount
    ) {
      objectId
      price
      imageLink
      itemIndex
      itemDescription
      itemTitle
      category
      enabled
      menuId
      disableDate
      disableUntilDate
      noDiscount
    }
  }
`;

export const saveMenuItemOption = /* GraphQL */ `
  mutation SaveMenuItemOption(
    $objectId: ID
    $menuId: String
    $menuItemId: String
    $optionIndex: Float
    $optionDescription: String
    $optionTitle: String
    $minSelections: Int
    $maxSelections: Int
    $mandatory: Boolean
    $enabled: Boolean
    $choices: String
  ) {
    saveMenuItemOption(
      objectId: $objectId
      menuId: $menuId
      menuItemId: $menuItemId
      optionIndex: $optionIndex
      optionDescription: $optionDescription
      optionTitle: $optionTitle
      minSelections: $minSelections
      maxSelections: $maxSelections
      mandatory: $mandatory
      enabled: $enabled
      choices: $choices
    ) {
      objectId
      menuItemId
      optionIndex
      optionDescription
      optionTitle
      minSelections
      maxSelections
      mandatory
      enabled
      choices
    }
  }
`;

export const copyLegacyMenu = /* GraphQL */ `
  mutation CopyLegacyMenu(
    $menuId: String!
    $restId: String!
    $menuIndex: Float!
    $menuTitle: String!
    $menuDescription: String
    $startTime: Int!
    $endTime: Int!
    $platform: String!
    $type: String!
  ) {
    copyLegacyMenu(
      menuId: $menuId
      restId: $restId
      menuIndex: $menuIndex
      menuTitle: $menuTitle
      menuDescription: $menuDescription
      startTime: $startTime
      endTime: $endTime
      platform: $platform
      type: $type
    ) {
      objectId
      type
      enabled
      restaurantIds
      startTime
      endTime
      menuIndex
      menuTitle
      menuDescription
      platform
    }
  }
`;

export const copyMenu = /* GraphQL */ `
  mutation CopyMenu(
    $menuId: String!
    $restId: String!
    $menuIndex: Float!
    $menuTitle: String!
    $menuDescription: String
    $startTime: Int!
    $endTime: Int!
    $type: String!
  ) {
    copyMenu(
      menuId: $menuId
      restId: $restId
      menuIndex: $menuIndex
      menuTitle: $menuTitle
      menuDescription: $menuDescription
      startTime: $startTime
      endTime: $endTime
      type: $type
    ) {
      objectId
      type
      enabled
      startTime
      endTime
      menuTitle
      menuDescription
      platform
      version
      categories {
        objectId
        categoryTitle
        categoryIndex
        productIds
        bundleIds
        maxQty
      }
    }
  }
`;

export const createMenus = /* GraphQL */ `
  mutation CreateMenus($menus: [MenuCreateInput]!) {
    createMenus(menus: $menus) {
      objectId
      type
      enabled
      startTime
      endTime
      menuTitle
      menuDescription
      platform
      version
    }
  }
`;

export const updateMenus = /* GraphQL */ `
  mutation UpdateMenus($restId: String!, $menus: [MenuUpdateInput]!) {
    updateMenus(restId: $restId, menus: $menus) {
      objectId
      type
      enabled
      startTime
      endTime
      menuTitle
      menuDescription
      platform
      version
    }
  }
`;

export const createCategories = /* GraphQL */ `
  mutation CreateCategories($categories: [MenuCategoryCreateInput]!) {
    createCategories(categories: $categories) {
      objectId
      categoryTitle
      categoryIndex
      productIds
      bundleIds
      maxQty
    }
  }
`;

export const updateCategories = /* GraphQL */ `
  mutation UpdateCategories($restId: String!, $categories: [MenuCategoryUpdateInput]!) {
    updateCategories(restId: $restId, categories: $categories) {
      objectId
      categoryTitle
      categoryIndex
      productIds
      bundleIds
      maxQty
    }
  }
`;

export const createBundles = /* GraphQL */ `
  mutation CreateBundles($bundles: [MenuBundleCreateInput]!) {
    createBundles(bundles: $bundles) {
      objectId
      bundleTitle
      bundleDescription
      imageLink
      price
      noDiscount
      disableDate
      disableUntilDate
      posId
      importStatus
      bundleOptionIds
    }
  }
`;

export const updateBundles = /* GraphQL */ `
  mutation UpdateBundles($restId: String!, $bundles: [MenuBundleUpdateInput]!) {
    updateBundles(restId: $restId, bundles: $bundles) {
      objectId
      bundleTitle
      bundleDescription
      imageLink
      price
      noDiscount
      disableDate
      disableUntilDate
      posId
      importStatus
      bundleOptionIds
    }
  }
`;

export const restockBundle = /* GraphQL */ `
  mutation RestockBundle($bundleId: String!) {
    restockBundle(bundleId: $bundleId) {
      objectId
      bundleTitle
      bundleDescription
      imageLink
      price
      noDiscount
      disableDate
      disableUntilDate
      posId
      importStatus
      bundleOptionIds
    }
  }
`;

export const disableBundleForDate = /* GraphQL */ `
  mutation DisableBundleForDate($bundleId: String!, $disableDate: AWSDate!) {
    disableBundleForDate(bundleId: $bundleId, disableDate: $disableDate) {
      objectId
      bundleTitle
      bundleDescription
      imageLink
      price
      noDiscount
      disableDate
      disableUntilDate
      posId
      importStatus
      bundleOptionIds
    }
  }
`;

export const disableBundleUntilDate = /* GraphQL */ `
  mutation DisableBundleUntilDate($bundleId: String!, $disableUntilDate: AWSDate!) {
    disableBundleUntilDate(bundleId: $bundleId, disableUntilDate: $disableUntilDate) {
      objectId
      bundleTitle
      bundleDescription
      imageLink
      price
      noDiscount
      disableDate
      disableUntilDate
      posId
      importStatus
      bundleOptionIds
    }
  }
`;

export const createBundleOptions = /* GraphQL */ `
  mutation CreateBundleOptions($options: [MenuBundleOptionCreateInput]!) {
    createBundleOptions(options: $options) {
      objectId
      bundleOptionTitle
      bundleOptionDescription
      mandatory
      minSelections
      maxSelections
      importStatus
      productIds
    }
  }
`;

export const updateBundleOptions = /* GraphQL */ `
  mutation UpdateBundleOptions($restId: String!, $options: [MenuBundleOptionUpdateInput]!) {
    updateBundleOptions(restId: $restId, options: $options) {
      objectId
      bundleOptionTitle
      bundleOptionDescription
      mandatory
      minSelections
      maxSelections
      importStatus
      productIds
    }
  }
`;

export const createProducts = /* GraphQL */ `
  mutation CreateProducts($products: [MenuProductCreateInput]!) {
    createProducts(products: $products) {
      objectId
      productTitle
      productDescription
      imageLink
      price
      noDiscount
      disableDate
      disableUntilDate
      bundleOnly
      posId
      importStatus
      optionIds
    }
  }
`;

export const updateProducts = /* GraphQL */ `
  mutation UpdateProducts($restId: String!, $products: [MenuProductUpdateInput]!) {
    updateProducts(restId: $restId, products: $products) {
      objectId
      productTitle
      productDescription
      imageLink
      price
      noDiscount
      disableDate
      disableUntilDate
      bundleOnly
      posId
      importStatus
      optionIds
    }
  }
`;

export const restockProduct = /* GraphQL */ `
  mutation RestockProduct($productId: String!) {
    restockProduct(productId: $productId) {
      objectId
      productTitle
      productDescription
      imageLink
      price
      noDiscount
      disableDate
      disableUntilDate
      bundleOnly
      posId
      importStatus
      optionIds
    }
  }
`;

export const disableProductForDate = /* GraphQL */ `
  mutation DisableProductForDate($productId: String!, $disableDate: AWSDate!) {
    disableProductForDate(productId: $productId, disableDate: $disableDate) {
      objectId
      productTitle
      productDescription
      imageLink
      price
      noDiscount
      disableDate
      disableUntilDate
      bundleOnly
      posId
      importStatus
      optionIds
    }
  }
`;

export const disableProductUntilDate = /* GraphQL */ `
  mutation disableProductUntilDate($productId: String!, $disableUntilDate: AWSDate!) {
    disableProductUntilDate(productId: $productId, disableUntilDate: $disableUntilDate) {
      objectId
      productTitle
      productDescription
      imageLink
      price
      noDiscount
      disableDate
      disableUntilDate
      bundleOnly
      posId
      importStatus
      optionIds
    }
  }
`;

export const createOptions = /* GraphQL */ `
  mutation CreateOptions($options: [MenuOptionCreateInput]!) {
    createOptions(options: $options) {
      objectId
      optionTitle
      optionDescription
      mandatory
      minSelections
      maxSelections
      bundleOnly
      posId
      importStatus
      choiceIds
    }
  }
`;

export const updateOptions = /* GraphQL */ `
  mutation UpdateOptions($restId: String!, $options: [MenuOptionUpdateInput]!) {
    updateOptions(restId: $restId, options: $options) {
      objectId
      optionTitle
      optionDescription
      mandatory
      minSelections
      maxSelections
      bundleOnly
      posId
      importStatus
      choiceIds
    }
  }
`;

export const createChoices = /* GraphQL */ `
  mutation CreateChoices($choices: [MenuChoiceCreateInput]!) {
    createChoices(choices: $choices) {
      objectId
      choiceTitle
      price
      disableDate
      disableUntilDate
      bundleOnly
      posId
      importStatus
    }
  }
`;

export const updateChoices = /* GraphQL */ `
  mutation UpdateChoices($restId: String!, $choices: [MenuChoiceUpdateInput]!) {
    updateChoices(restId: $restId, choices: $choices) {
      objectId
      choiceTitle
      price
      disableDate
      disableUntilDate
      bundleOnly
      posId
      importStatus
    }
  }
`;

export const restockChoice = /* GraphQL */ `
  mutation RestockChoice($choiceId: String!) {
    restockChoice(choiceId: $choiceId) {
      objectId
      choiceTitle
      price
      disableDate
      disableUntilDate
      bundleOnly
      posId
      importStatus
    }
  }
`;

export const disableChoiceForDate = /* GraphQL */ `
  mutation DisableChoiceForDate($choiceId: String!, $disableDate: AWSDate!) {
    disableChoiceForDate(choiceId: $choiceId, disableDate: $disableDate) {
      objectId
      choiceTitle
      price
      disableDate
      disableUntilDate
      bundleOnly
      posId
      importStatus
    }
  }
`;

export const disableChoiceUntilDate = /* GraphQL */ `
  mutation DisableChoiceUntilDate($choiceId: String!, $disableUntilDate: AWSDate!) {
    disableChoiceUntilDate(choiceId: $choiceId, disableUntilDate: $disableUntilDate) {
      objectId
      choiceTitle
      price
      disableDate
      disableUntilDate
      bundleOnly
      posId
      importStatus
    }
  }
`;

export const removeMenus = /* GraphQL */ `
  mutation RemoveMenus($restId: String!, $menuIds: [String]!) {
    removeMenus(restId: $restId, menuIds: $menuIds)
  }
`;

export const removeCategories = /* GraphQL */ `
  mutation RemoveCategories($restId: String!, $categoryIds: [String]!) {
    removeCategories(restId: $restId, categoryIds: $categoryIds)
  }
`;

export const removeBundles = /* GraphQL */ `
  mutation RemoveBundles($restId: String!, $bundleIds: [String]!) {
    removeBundles(restId: $restId, bundleIds: $bundleIds)
  }
`;

export const removeBundleOptions = /* GraphQL */ `
  mutation RemoveBundleOptions($restId: String!, $bundleOptionIds: [String]!) {
    removeBundleOptions(restId: $restId, bundleOptionIds: $bundleOptionIds)
  }
`;

export const removeProducts = /* GraphQL */ `
  mutation RemoveProducts($restId: String!, $productIds: [String]!) {
    removeProducts(restId: $restId, productIds: $productIds)
  }
`;

export const removeOptions = /* GraphQL */ `
  mutation RemoveOptions($restId: String!, $optionIds: [String]!) {
    removeOptions(restId: $restId, optionIds: $optionIds)
  }
`;

export const removeChoices = /* GraphQL */ `
  mutation RemoveChoices($restId: String!, $choiceIds: [String]!) {
    removeChoices(restId: $restId, choiceIds: $choiceIds)
  }
`;

export const importDoshiiMenu = /* GraphQL */ `
  mutation ImportDoshiiMenu($restId: String!) {
    importDoshiiMenu(restId: $restId)
  }
`;

export const completeChangeRequest = /* GraphQL */ `
  mutation CompleteChangeRequest($requestId: String!, $data: AWSJSON) {
    completeChangeRequest(requestId: $requestId, data: $data)
  }
`;

export const setRestaurantMenuVersion = /* GraphQL */ `
  mutation SetRestaurantMenuVersion($restId: String!, $version: Int!, $pos: Boolean!) {
    setRestaurantMenuVersion(restId: $restId, version: $version, pos: $pos) {
      objectId
      region
      name
      imageLink
      menuVersion
      posId
      posEnabled
      posMenuVersion
      posFilteredMenu
    }
  }
`;

export const setPosId = /* GraphQL */ `
  mutation SetPosId($restId: String!, $posId: String!) {
    setPosId(restId: $restId, posId: $posId) {
      ${type.Restaurant}
    }
  }
`;

export const syncMenuCache = /* GraphQL */ `
  mutation SyncMenuCache($restId: String!, $pos: Boolean) {
    syncMenuCache(restId: $restId, pos: $pos) {
      bundles {
        objectId
        bundleTitle
        bundleDescription
        imageLink
        price
        noDiscount
        disableDate
        disableUntilDate
        posId
        importStatus
        bundleOptionIds
      }
      bundleOptions {
        objectId
        bundleOptionTitle
        bundleOptionDescription
        mandatory
        minSelections
        maxSelections
        importStatus
        productIds
      }
      products {
        objectId
        productTitle
        productDescription
        imageLink
        price
        noDiscount
        disableDate
        disableUntilDate
        bundleOnly
        posId
        importStatus
        optionIds
      }
      options {
        objectId
        optionTitle
        optionDescription
        mandatory
        minSelections
        maxSelections
        bundleOnly
        posId
        importStatus
        choiceIds
      }
      choices {
        objectId
        choiceTitle
        price
        disableDate
        disableUntilDate
        bundleOnly
        posId
        importStatus
      }
      menus {
        objectId
        type
        enabled
        startTime
        endTime
        menuTitle
        menuDescription
        platform
        version
        categories {
          objectId
          categoryTitle
          categoryIndex
          productIds
          bundleIds
          maxQty
        }
      }
    }
  }
`;
