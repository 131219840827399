import { Auth } from 'aws-amplify';
import { devLog } from '../helpers/devLog';
import * as type from './types';

export const setUserAction = (userId, userEmail) => (dispatch) => {
  dispatch({
    type: type.SIGNIN_SUCCESS,
    payload: { id: userId, email: userEmail },
  });
};

export const signOutAction = () => async (dispatch) => {
  try {
    const signOutData = await Auth.signOut();

    devLog('success', 'signed out', signOutData);

    dispatch({
      type: type.SIGNOUT,
    });
  } catch (error) {
    devLog('error', 'signing out', error);
  }
};
