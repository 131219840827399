import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  modalContent: {
    maxWidth: '500px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  modalText: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
  },
  footer: {
    margin: '0 20px',
    width: '100%',
    padding: '12px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  dialogContent: {
    paddingTop: '28px',
    paddingBottom: '12px',
    textAlign: 'center',
  },
}));

export default useStyles;
