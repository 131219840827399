import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  reviewButtonContainer: {
    borderRadius: '4px',
    border: '1px solid #ADADAD',
    padding: '12px',
    height: '34px',
    fontSize: '20px',
    lineHeight: '10px',
    color: '#2E2E2E',
    userSelect: 'none',
  },
  reviewButtonIcon: {
    backgroundColor: '#F39A47',
    borderRadius: '10px',
    width: '10px',
    height: '10px',
  },
  reviewLabelContainer: {
    borderRadius: '4px',
    border: '1px solid #ADADAD',
    backgroundColor: '#FFFFFF',
    padding: '5px 10px',
    height: '24px',
    fontSize: '16px',
    lineHeight: '14px',
    color: '#2E2E2E',
    userSelect: 'none',
    zIndex: 1,
    cursor: 'default',
    whiteSpace: 'nowrap',
  },
  reviewLabelIcon: {
    backgroundColor: '#F39A47',
    borderRadius: '10px',
    width: '10px',
    height: '10px',
    marginTop: '2px',
  },
}));

export default useStyles;
