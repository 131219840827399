import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createCategoryAction } from '../../../../actions/categoriesAction';

const MenuCategoryCreateDialog = ({
  createCategoryAction,
  menuId,
  categories,
  openDialog,
  setOpenDialog,
  category,
}) => {
  const closeDialog = () => {
    setCategoryTitle('');
    setInputError('');
    setOpenDialog(false);
  };

  const newCategoryId = `category_${new Date().getTime()}`;
  const [categoryId, setCategoryId] = useState(category?.objectId || newCategoryId);

  const [categoryTitle, setCategoryTitle] = useState('');
  const [inputError, setInputError] = useState('');

  useEffect(() => {
    // Update the ID
    const newCategoryId = `category_${new Date().getTime()}`;
    setCategoryId(category?.objectId || newCategoryId);
  }, [categories.localData, category?.objectId, openDialog]);

  const submit = (event) => {
    event.preventDefault();

    if (!categoryTitle) {
      setInputError('Please enter a category name.');
      return;
    }
    if (inputError) {
      setInputError(null);
    }

    if (categories?.find((category) => category.categoryTitle === categoryTitle)) {
      setInputError(`'${categoryTitle}' already exists. Please enter unique category name.`);
      return;
    }
    if (inputError) {
      setInputError(null);
    }

    createCategoryAction(categoryId, categoryTitle, menuId);

    closeDialog();
  };

  return (
    <Dialog
      open={openDialog}
      onClose={closeDialog}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth
    >
      <DialogTitle id='alert-dialog-title'>Category Name</DialogTitle>
      <DialogContent>
        <form id='create-category-form' noValidate autoComplete='off' onSubmit={submit}>
          <TextField
            autoFocus
            id='category-name'
            label='Category Name'
            placeholder='eg. Mains, Entrees'
            variant='outlined'
            fullWidth
            value={categoryTitle}
            onChange={(e) => setCategoryTitle(e.target.value)}
            onBlur={(e) => setCategoryTitle(e.target.value.trim())}
            InputLabelProps={{ shrink: true }}
            error={Boolean(inputError)}
            helperText={inputError}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color='default'>
          Cancel
        </Button>
        <Button onClick={submit} color='primary'>
          Save Category
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({ menuItems: state.menuItems });
const mapDispatchToProps = (dispatch) => bindActionCreators({ createCategoryAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MenuCategoryCreateDialog);
