import React, { useState } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PlaylistAdd } from '@material-ui/icons';
import { dialogContexts } from '../../../../constants/constants';
import AddFromFavourites from '../favourites/AddFromFavourites';
import Option from './Option';
import OptionEditDialog from './OptionEditDialog';
import useStyles from './OptionsStyles';

const Options = ({ options, menuVersion }) => {
  const classes = useStyles();

  const [openDialog, setOpenDialog] = useState(false);
  // const [selectedItems, setSelectedItems] = useState([]);

  // const selectItem = (itemId, selected) => {
  //   const isSelected = selectedItems?.filter((item) => item === itemId).length > 0;
  //   if (selected && !isSelected) {
  //     setSelectedItems([...selectedItems, itemId]);
  //   }

  //   // if deselected
  //   if (!selected) {
  //     setSelectedItems(selectedItems.filter((item) => item.itemId !== itemId));
  //   }
  // };

  /**
   * Select every item
   */
  // const selectAllItems = () => {
  //   let newSelectedItems = selectedItems;
  //   options.localData.forEach((option) => {
  //     const isSelected =
  //       selectedItems?.filter((selectedItem) => selectedItem === option.objectId)?.length > 0;
  //     if (!isSelected) {
  //       newSelectedItems = [...newSelectedItems, option.objectId];
  //     }
  //   });

  //   setSelectedItems(newSelectedItems);
  // };

  // const onSelect = (optionId, selected) => {
  //   selectItem(optionId, selected);
  // };

  // const onSelectAll = () => {
  //   selectAllItems();
  // };

  const filteredOptions = options.localData?.filter((option) => !option.bundleOnly);

  return (
    <>
      {!menuVersion.posEnabled && <AddFromFavourites />}

      <Paper style={{ marginTop: '1rem' }}>
        <List
          dense={false}
          subheader={
            <ListSubheader
              component='div'
              id='category-list-subheader'
              color='primary'
              className={classes.listSubheader}
            >
              <Box display='flex' alignItems='center' justifyContent='space-between'>
                <span>{filteredOptions.length === 0 ? 'No options yet' : 'All options'}</span>
              </Box>
            </ListSubheader>
          }
        >
          {filteredOptions.map((option) => (
            <Option
              key={option?.objectId}
              option={option}
              // selected={selectedItems?.filter((item) => item === option.objectId)?.length > 0}
              // onSelect={(value) => onSelect(option.objectId, value)}
            />
          ))}
        </List>
      </Paper>

      {!menuVersion.posEnabled && (
        <Paper style={{ marginTop: '1rem' }}>
          <>
            <ListItem button onClick={() => setOpenDialog(true)}>
              <ListItemIcon>
                <PlaylistAdd />
              </ListItemIcon>
              <ListItemText
                primary='Create new option'
                primaryTypographyProps={{ color: 'textSecondary', variant: 'button' }}
              />
            </ListItem>

            <OptionEditDialog
              openDialog={openDialog}
              setOpenDialog={setOpenDialog}
              dialogContext={dialogContexts.CREATE}
              option={{}}
            />
          </>
        </Paper>
      )}
    </>
  );
};

Options.propTypes = {};

const mapStateToProps = (state) => ({
  products: state.products,
  options: state.options,
  menuVersion: state.menuVersion,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Options);
