import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  updateMenuItemOptionAction,
  createMenuItemOptionAction,
} from '../../actions/menuItemOptionsAction';
import {
  cancelChoicesAction,
  clearInitialChoicesAction,
  saveInitialChoicesAction,
} from '../../actions/menuItemOptionChoicesAction';
import MenuItemOptionChoices from '../menuItemOptionChoices/MenuItemOptionChoices';
import { dialogContexts } from '../../constants/constants';
import InfoPopover from '../InfoPopover/InfoPopover';

const MenuItemOptionEditDialog = ({
  cancelChoicesAction,
  clearInitialChoicesAction,
  createMenuItemOptionAction,
  dialogContext,
  lastOptionIndex,
  menuItemId,
  menuItemOptions,
  openDialog,
  option,
  saveInitialChoicesAction,
  setDialogContext,
  setOpenDialog,
  updateMenuItemOptionAction,
}) => {
  const [save, setSave] = useState(false);
  const [name, setName] = useState(option.optionTitle || null);
  const [nameError, setNameError] = useState('');
  const [desc, setDesc] = useState(option.optionDescription || null);
  const [descError, setDescError] = useState('');
  const [minSelect, setMinSelect] = useState(option.minSelections || null);
  const [minSelectError, setMinSelectError] = useState('');
  const [maxSelect, setMaxSelect] = useState(option.maxSelections || null);
  const [maxSelectError, setMaxSelectError] = useState('');
  const [mandatory, setMandatory] = useState(option.mandatory || false);
  const [choiceError, setChoiceError] = useState('');

  const choices = menuItemOptions.storedChoices;

  const newOptionId = `${menuItemId.substring(0, 10)}-${lastOptionIndex}-${new Date().getTime()}`;

  const [optionId, setOptionId] = useState(option.objectId || newOptionId);

  const closeDialog = () => {
    setOpenDialog(false);
  };

  const validateForm = () => {
    let formError = false;
    if (!name) {
      setNameError('Please enter an option name.');
      formError = true;
    } else if (nameError) {
      setNameError('');
    }

    if (minSelect === '') {
      setMinSelectError('Please enter a minimum selection.');
      formError = true;
    }
    if (minSelect > maxSelect) {
      setMinSelectError('Cannot be greater than maximum selections.');
      formError = true;
    }
    if (minSelect > choices.length) {
      setMinSelectError('Cannot be greater than minimum option choices available.');
      formError = true;
    }
    if (minSelect === 0 && mandatory) {
      setMinSelectError('If mandatory is set, must have at least one min selection.');
      formError = true;
    }
    if (
      !(
        minSelect === '' ||
        minSelect > choices.length ||
        minSelect > maxSelect ||
        (minSelect === 0 && mandatory)
      )
    ) {
      if (minSelectError) {
        setMinSelectError('');
      }
    }

    if (maxSelect === '') {
      setMaxSelectError('Please enter a maximum selection.');
      formError = true;
    } else if (maxSelectError) {
      setMaxSelectError('');
    }

    if (!choices.length) {
      setChoiceError('Please create at least one choice');
      formError = true;
    } else if (choiceError) {
      setChoiceError('');
    }

    return formError;
  };

  const submit = (event) => {
    event.preventDefault();

    const formError = validateForm();

    if (formError) {
      return;
    }

    if (dialogContext === dialogContexts.CREATE) {
      createMenuItemOptionAction(
        menuItemId,
        optionId,
        name,
        desc,
        Number(minSelect),
        Number(maxSelect),
        mandatory,
        choices,
        lastOptionIndex,
      );
    } else {
      updateMenuItemOptionAction(
        menuItemId,
        option.objectId,
        name,
        desc,
        Number(minSelect),
        Number(maxSelect),
        mandatory,
        choices,
      );
    }

    setSave(true);
    closeDialog();
  };

  const objectId = menuItemOptions?.data?.find(
    (apiMenuItemOption) => apiMenuItemOption?.objectId === option?.objectId,
  )?.objectId;

  return (
    <Dialog
      open={openDialog}
      onClose={closeDialog}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth
      maxWidth='lg'
      onEnter={() => {
        const enteredOptionId = option.objectId || newOptionId;
        setOptionId(enteredOptionId);

        // store initial choices, and load into memory
        saveInitialChoicesAction(menuItemId, enteredOptionId);

        // Reset inputs when entering
        setName(option.optionTitle || null);
        setDesc(option.optionDescription || null);
        setMinSelect(option.minSelections || null);
        setMaxSelect(option.maxSelections || null);
        setMandatory(option.mandatory || false);

        setNameError('');
        setDescError('');
        setMinSelectError('');
        setMaxSelectError('');
        setChoiceError('');
        setSave(false);
      }}
      onExited={() => {
        setDialogContext('');

        if (!save) {
          // Revert Options back to as they were on enter
          cancelChoicesAction(menuItemId, optionId);
        }
        clearInitialChoicesAction();
      }}
    >
      <DialogTitle id={`menu-item-option-dialog-${option.objectId}`}>
        {dialogContext} Menu Item Option
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <form onSubmit={submit} id='edit-menu-item-option-form'>
              <TextField
                autoFocus={!name}
                id='menu-item-option-name'
                label='Option Name'
                placeholder='eg. Meat doneness'
                variant='outlined'
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
                onBlur={(e) => {
                  setName(e.target.value.trim());
                  validateForm();
                }}
                InputLabelProps={{ shrink: true }}
                margin='normal'
                required
                error={Boolean(nameError)}
                helperText={nameError}
              />

              <TextField
                multiline
                id='menu-item-option-desc'
                label='Description'
                placeholder='eg. How do you like your steak done?'
                variant='outlined'
                fullWidth
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
                onBlur={(e) => {
                  setDesc(e.target.value.trim());
                  validateForm();
                }}
                InputLabelProps={{ shrink: true }}
                margin='normal'
                error={Boolean(descError)}
                helperText={descError}
              />

              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <TextField
                    id='menu-item-min-selection'
                    label='Minimum Selections'
                    placeholder='0'
                    variant='outlined'
                    fullWidth
                    value={minSelect}
                    type='number'
                    onChange={(e) => setMinSelect(Number(e.target.value))}
                    onBlur={(e) => {
                      validateForm();
                    }}
                    InputLabelProps={{ shrink: true }}
                    // min={0}
                    inputProps={{ min: 0, step: 1 }}
                    margin='normal'
                    required
                    error={Boolean(minSelectError)}
                    helperText={minSelectError}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    id='menu-item-max-selection'
                    label='Maximum Selections'
                    placeholder='0'
                    variant='outlined'
                    fullWidth
                    value={maxSelect}
                    type='number'
                    onChange={(e) => setMaxSelect(Number(e.target.value))}
                    onBlur={(e) => {
                      validateForm();
                    }}
                    InputLabelProps={{ shrink: true }}
                    min={0}
                    inputProps={{ min: 0, step: 1 }}
                    margin='normal'
                    required
                    error={Boolean(maxSelectError)}
                    helperText={maxSelectError}
                  />
                </Grid>
              </Grid>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color='primary'
                      checked={mandatory}
                      onChange={(e) => setMandatory(e.target.checked)}
                      name='mandatory'
                    />
                  }
                  label='Is Mandatory'
                />
              </FormGroup>
            </form>
          </Grid>
          <Grid item md={6} xs={12}>
            <MenuItemOptionChoices
              choices={choices}
              menuItemId={menuItemId}
              menuItemOptionId={optionId}
              choiceError={choiceError}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <InfoPopover
          info={objectId}
          style={{ position: 'absolute', left: 0, right: 0, margin: '0 auto' }}
        />
        <Button onClick={closeDialog} color='default'>
          Cancel
        </Button>
        <Button onClick={submit} color='primary' type='submit' form='edit-menu-item-option-form'>
          {dialogContext === dialogContexts.CREATE ? 'Create' : 'Save'} Option
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({ menuItemOptions: state.menuItemOptions });
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      cancelChoicesAction,
      clearInitialChoicesAction,
      createMenuItemOptionAction,
      saveInitialChoicesAction,
      updateMenuItemOptionAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemOptionEditDialog);
