import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { ListPopover } from '@eatclub-apps/ec-component-library';
import { connect } from 'react-redux';
import { TextButton } from '../Button/TextButton';
import ProductEditDialog from '../menuProduct/ProductEditDialog';
import BundleEditDialog from '../menuBundle/BundleEditDialog';
import MenuCategoryAddProductDialog from './MenuCategoryAddProductDialog';
import MenuCategoryAddBundleDialog from './MenuCategoryAddBundleDialog';
import { dialogContexts } from '../../../../constants/constants';

const MenuItemAdd = ({ category, menuVersion }) => {
  const [openProductEditDialog, setOpenProductEditDialog] = useState(false);
  const [openBundleEditDialog, setOpenBundleEditDialog] = useState(false);
  const [openAddProductDialog, setOpenAddProductDialog] = useState(false);
  const [openAddBundleDialog, setOpenAddBundleDialog] = useState(false);
  const [bundleAnchorEl, setBundleAnchorEl] = useState(null);
  const [menuItemAnchorEl, setMenuItemAnchorEl] = useState(null);

  const openBundleOptions = (event) => {
    setBundleAnchorEl(event.currentTarget);
  };

  const openMenuItemOptions = (event) => {
    setMenuItemAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Box display='inline-flex'>
        <TextButton onClick={(e) => openMenuItemOptions(e)} style={{ text: { fontSize: '16px' } }}>
          + Menu items
        </TextButton>

        <TextButton onClick={(e) => openBundleOptions(e)} style={{ text: { fontSize: '16px' } }}>
          + Bundles
        </TextButton>

        <ListPopover
          items={[
            ...(!menuVersion?.posEnabled
              ? [
                  {
                    label: 'Create new menu item',
                    onClick: () => setOpenProductEditDialog(true),
                  },
                ]
              : []),
            {
              label: 'Add from existing menu items',
              onClick: () => setOpenAddProductDialog(true),
            },
          ]}
          anchor={menuItemAnchorEl}
          isOpen={!!menuItemAnchorEl}
          onClose={() => setMenuItemAnchorEl(null)}
          style={{
            popover: { minWidth: '300px', marginLeft: '10px' },
            item: { height: 'auto' },
          }}
          popoverProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}
        />
      </Box>

      <ListPopover
        items={[
          ...(!menuVersion?.posEnabled
            ? [
                {
                  label: 'Create new bundle',
                  onClick: () => setOpenBundleEditDialog(true),
                },
              ]
            : []),
          {
            label: 'Add from existing bundles',
            onClick: () => setOpenAddBundleDialog(true),
          },
        ]}
        anchor={bundleAnchorEl}
        isOpen={!!bundleAnchorEl}
        onClose={() => setBundleAnchorEl(null)}
        style={{
          popover: { minWidth: '300px', marginLeft: '10px' },
          item: { height: 'auto' },
        }}
        popoverProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      />

      <ProductEditDialog
        newProductIndex={0}
        dialogContext={dialogContexts.CREATE}
        openDialog={openProductEditDialog}
        setOpenDialog={setOpenProductEditDialog}
        category={category}
      />

      <MenuCategoryAddProductDialog
        openDialog={openAddProductDialog}
        setOpenDialog={setOpenAddProductDialog}
        category={category}
      />

      <BundleEditDialog
        newProductIndex={0}
        dialogContext={dialogContexts.CREATE}
        openDialog={openBundleEditDialog}
        setOpenDialog={setOpenBundleEditDialog}
        category={category}
      />

      <MenuCategoryAddBundleDialog
        openDialog={openAddBundleDialog}
        setOpenDialog={setOpenAddBundleDialog}
        category={category}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  menuVersion: state.menuVersion,
});

export default connect(mapStateToProps)(MenuItemAdd);
