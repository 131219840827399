import { combineReducers } from 'redux';
import activeRestaurantReducer from './activeRestaurantReducer';
import activeChangeRequestsReducer from './activeChangeRequestsReducer';
import breadcrumbsReducer from './breadcrumbsReducer';
import categoriesReducer from './categoriesReducer';
import choicesReducer from './choicesReducer';
import completedChangeRequestsReducer from './completedChangeRequestsReducer';
import importOptionsReducer from './importOptionsReducer';
import loadingReducer from './loadingReducer';
import menuItemOptionsReducer from './menuItemOptionsReducer';
import menuItemsReducer from './menuItemsReducer';
import menusReducer from './menusReducer';
import menuVersionReducer from './menuVersionReducer';
import optionsReducer from './optionsReducer';
import productsReducer from './productsReducer';
import restaurantsLastUpdatedReducer from './restaurantsLastUpdatedReducer';
import restaurantsReducer from './restaurantsReducer';
import saveReducer from './saveReducer';
import activeRestaurantVersionReducer from './activeRestaurantVersionReducer';
import toastReducer from './toastReducer';
import authReducer from './authReducer';
import bundlesReducer from './bundlesReducer';
import bundleOptionsReducer from './bundleOptionsReducer';
import completeChangeRequestReducer from './completeChangeRequestReducer';

// App's top-level reducers
const appReducer = combineReducers({
  activeChangeRequests: activeChangeRequestsReducer,
  activeRestaurant: activeRestaurantReducer,
  activeRestaurantVersion: activeRestaurantVersionReducer,
  breadcrumbs: breadcrumbsReducer,
  bundleOptions: bundleOptionsReducer,
  bundles: bundlesReducer,
  categories: categoriesReducer,
  choices: choicesReducer,
  completeChangeRequest: completeChangeRequestReducer,
  completedChangeRequests: completedChangeRequestsReducer,
  importOptions: importOptionsReducer,
  loading: loadingReducer,
  menuItemOptions: menuItemOptionsReducer,
  menuItems: menuItemsReducer,
  menus: menusReducer,
  menuVersion: menuVersionReducer,
  options: optionsReducer,
  products: productsReducer,
  restaurants: restaurantsReducer,
  restaurantsLastUpdated: restaurantsLastUpdatedReducer,
  save: saveReducer,
  toasts: toastReducer,
  auth: authReducer,
});

export default appReducer;
