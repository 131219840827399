import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import MenuCategoryRenameDialog from './MenuCategoryRenameDialog';
import MenuCategoryDeleteDialog from './MenuCategoryDeleteDialog';

const MenuCategoryOptionButton = ({ category }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openRenameDialog, setOpenRenameDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenRenameDialog = () => {
    handleClose();
    setOpenRenameDialog(true);
  };

  const handleOpenDeleteDialog = () => {
    handleClose();
    setOpenDeleteDialog(true);
  };

  return (
    <>
      <IconButton style={{ marginRight: '-12px' }} onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu id='long-menu' anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        <MenuItem onClick={handleOpenRenameDialog}>Rename</MenuItem>
        <MenuItem onClick={handleOpenDeleteDialog}>Delete</MenuItem>
      </Menu>
      <MenuCategoryRenameDialog
        category={category}
        openDialog={openRenameDialog}
        setOpenDialog={setOpenRenameDialog}
      />
      <MenuCategoryDeleteDialog
        category={category}
        openDialog={openDeleteDialog}
        setOpenDialog={setOpenDeleteDialog}
      />
    </>
  );
};

MenuCategoryOptionButton.propTypes = {
  category: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(MenuCategoryOptionButton);
