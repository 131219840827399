import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';

import useStyles from './ErrorStyles';

const Error = ({ error, message, ...rest }) => {
  const classes = useStyles();

  return (
    <>
      {error && (
        <Alert {...rest} elevation={1} severity='error' className={classes.alert}>
          {message}
        </Alert>
      )}
    </>
  );
};

Error.propTypes = {
  error: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
};

export default Error;
