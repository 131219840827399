import {
  deleteItemsInArray,
  getItemInArray,
  insertItemInArrayAtIndex,
  updateItemInArray,
} from '../helpers/ArrayUtils';
import * as type from '../actions/types';
import { sortByName, sortByTwoNames } from '../constants/formats';

const initialState = {
  data: [],
  localData: [],
  deleted: [],
  fetching: false,
  error: false,
  errorMessage: '',
  activeMenuTab: null,
  shouldFetch: false,
  shouldSave: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case type.FETCH_MENUS_PENDING: {
      return {
        ...initialState,
        fetching: true,
        activeMenuTab: state.activeMenuTab,
      };
    }

    case type.FETCH_MENUS_SUCCESS: {
      const { menus } = action.payload;

      let updatedMenus = Array.from(menus);

      updatedMenus = updatedMenus.sort((a, b) =>
        sortByTwoNames(a.platform, b.platform, a.type, b.type),
      );

      updatedMenus = updatedMenus.sort((a, b) => {
        if (a.menuIndex < b.menuIndex) {
          return -1;
        }
        if (a.menuIndex > b.menuIndex) {
          return 1;
        }
        return 0;
      });

      return {
        ...state,
        data: updatedMenus || [],
        localData: updatedMenus || [],
        fetching: false,
        error: false,
        errorMessage: '',
        activeMenuTab:
          state.activeMenuTab ?? updatedMenus.findIndex((menu) => menu.enabled === true),
      };
    }

    case type.FETCH_MENUS_FAILURE: {
      return {
        ...state,
        data: [],
        localData: [],
        deleted: [],
        fetching: false,
        error: true,
        errorMessage: action.payload,
      };
    }

    case type.CREATE_MENU: {
      const { newMenu, restaurantId } = action.payload;

      const updatedMenu = {
        ...newMenu,
        menuIndex: -1,
        objectId: `menu_${new Date().getTime()}`,
        restaurantIds: `["${restaurantId}"]`,
        _created: true,
      };

      const newMenus = [...state.localData, updatedMenu];

      newMenus.sort((a, b) => sortByTwoNames(a.platform, b.platform, a.type, b.type));

      const newIndex = newMenus.findIndex((menu) => menu.objectId === updatedMenu.objectId);

      return {
        ...state,
        localData: newMenus,
        activeMenuTab: newIndex,
        shouldSave: true,
      };
    }

    case type.UPDATE_MENU: {
      const { saveMenu } = action.payload;

      // NOTE: if this menu is enabled, then disable menus with same type
      const dataDisableByType = !saveMenu.enabled
        ? state.localData
        : state.localData.map((menu) => {
            // preserve others
            if (
              (menu.startTime > -1 && // NOTE: if timelimited
                menu.endTime > -1) ||
              menu.type !== saveMenu.type ||
              menu.platform !== saveMenu.platform
            ) {
              return menu;
            }

            return { ...menu, enabled: false, _altered: true };
          });

      const newMenus = updateItemInArray(
        dataDisableByType,
        'objectId',
        saveMenu.objectId,
        (item) => ({
          ...item,
          ...saveMenu,
          _altered: true,
        }),
      );

      newMenus.sort((a, b) => sortByTwoNames(a.platform, b.platform, a.type, b.type));

      // NOTE: set active tab to updated menu
      const newIndex = newMenus.findIndex((menu) => menu.objectId === saveMenu.objectId);

      return {
        ...state,
        localData: newMenus,
        activeMenuTab: newIndex,
      };
    }

    case type.DELETE_MENU: {
      const deleted = getItemInArray(state.localData, 'objectId', action.payload.menuId);

      const newDeleted = { ...deleted, _deleted: true };

      const newMenus = deleteItemsInArray(state.localData, 'objectId', action.payload.menuId);

      // If item hadn't been saved yet, no need to schedule it for deletion
      let newDeletedItems = [...state.deleted];

      if (newDeleted?._created !== true) {
        newDeletedItems = insertItemInArrayAtIndex(state.deleted, 0, newDeleted);
      }

      return {
        ...state,
        localData: newMenus,
        deleted: newDeletedItems,
        activeMenuTab: newMenus.length > 0 ? 0 : -1,
      };
    }

    case type.COPY_LEGACY_MENU_SUCCESS:
    case type.COPY_MENU_SUCCESS: {
      const { copiedMenu } = action.payload;

      const updatedItems = [...state.localData, copiedMenu];

      updatedItems.sort((a, b) => sortByName(a.type, b.type));

      const copiedMenuIndex = updatedItems.findIndex(
        (menu) => menu.objectId === copiedMenu.objectId,
      );

      return {
        ...state,
        data: updatedItems,
        localData: updatedItems,
        activeMenuTab: copiedMenuIndex,
      };
    }

    case type.CLEAR_MENUS: {
      return initialState;
    }

    case type.UPDATE_MENU_TAB: {
      return {
        ...state,
        activeMenuTab: action.payload,
      };
    }

    case type.IMPORT_DOSHII_SUCCESS: {
      return {
        ...state,
        shouldFetch: true,
      };
    }

    /*
     * BACKEND SAVES
     */

    case type.BACKEND_SAVE_MENU_SUCCESS: {
      const { savedItem, itemObjectId } = action.payload;

      const newMenus = state.localData.map((item) => {
        if (item.objectId === itemObjectId) {
          return savedItem;
        }

        return item;
      });

      return {
        ...state,
        data: newMenus,
        localData: newMenus,
      };
    }

    case type.BACKEND_SAVE_MENUS_SUCCESS: {
      const newMenus = state.localData.map(({ _altered, _created, _objectId, ...rest }) => rest);

      return {
        ...state,
        data: newMenus,
        localData: newMenus,
        deleted: [],
        shouldSave: false,
      };
    }

    case type.SET_SHOULD_FETCH_MENUS: {
      return {
        ...state,
        shouldFetch: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
