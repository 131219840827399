import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  tab: {
    '& > span': {
      flexDirection: 'column-reverse',
    },
  },
}));

export default useStyles;
