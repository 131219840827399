import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  draggableCategory: {
    backgroundColor: '#fff',

    '&.dragging': {
      backgroundColor: '#f1f2f3',
    },
  },
  listSubheader: {
    paddingTop: theme.spacing(1),
  },
  droppableMenuItem: {
    flexGrow: 1,
    minHeight: '65px',
    transition: 'background-color 200ms ease',

    '&.dragging-over': {
      backgroundColor: '#eff2f3',
    },
  },
}));

export default useStyles;
