import * as type from '../actions/types';

const initialState = {
  data: [],
  fetching: false,
  fetchingRestaurants: [],
  error: false,
  errorMessage: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_ACTIVE_CHANGE_REQUESTS_PENDING: {
      return {
        ...state,
        fetching: true,
        error: false,
        errorMessage: false,
      };
    }

    case type.FETCH_ACTIVE_CHANGE_REQUESTS_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        fetching: false,
        error: false,
        errorMessage: '',
      };
    }

    case type.FETCH_ACTIVE_CHANGE_REQUESTS_FAILURE: {
      return {
        ...state,
        error: true,
        errorMessage: action.payload,
        fetching: false,
      };
    }

    case type.FETCH_ACTIVE_CHANGE_REQUESTS_FOR_RESTAURANT_PENDING: {
      return {
        ...state,
        fetchingRestaurants: [...state.fetchingRestaurants, action.payload],
        error: false,
        errorMessage: false,
      };
    }

    case type.FETCH_ACTIVE_CHANGE_REQUESTS_FOR_RESTAURANT_SUCCESS: {
      // This gives us more data than the generic fetch, so update any records

      // Replace old data with new api data
      const updatedData = state.data.map((changeRequest) => {
        const apiChangeRequest = action.payload.data.find(
          (request) => request.objectId === changeRequest.objectId,
        );

        if (apiChangeRequest) {
          return apiChangeRequest;
        }
        return changeRequest;
      });

      const newFetchingRestaurants = state.fetchingRestaurants.filter(
        (restaurant) => restaurant !== action.payload.restaurantId,
      );

      return {
        ...state,
        data: updatedData,
        fetchingRestaurants: newFetchingRestaurants,
        error: false,
        errorMessage: '',
      };
    }

    default: {
      return state;
    }
  }
};
