import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import routes from './Routes';

// Routes
import NotFound from '../components/notFound/NotFound';

const SwitchRoutes = () => (
  <Switch>
    <Route exact path='/index.html'>
      <Redirect to='/' />
    </Route>
    {routes.map((route) => (
      <Route key={route?.path} exact={route?.exact} path={route?.path}>
        <route.main />
      </Route>
    ))}
    <Route path='*'>
      <NotFound />
    </Route>
  </Switch>
);

export default SwitchRoutes;
