import React, { useState } from 'react';
import { Box, Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReactComponent as LogoIcon } from '../../assets/logoEatClubIcon.svg';
import useStyles from './HeaderStyles';

const Logo = () => {
  const classes = useStyles();

  const [clickCount, setClickCount] = useState(0);
  const [showEasterEgg, setShowEasterEgg] = useState(false);

  const increaseClickCount = () => {
    let newClickCount = clickCount + 1;

    if (newClickCount >= 10) {
      setShowEasterEgg(true);
      newClickCount = 0;
    }

    setClickCount(newClickCount);
  };

  return (
    <Box
      display='flex'
      className={classes.logo}
      justifyContent='center'
      onClick={increaseClickCount}
    >
      <LogoIcon height={28} width={28} />

      <Dialog
        open={showEasterEgg}
        onClose={() => {
          setClickCount(-1);
          setShowEasterEgg(false);
        }}
      >
        <DialogTitle>EatClub Menu Portal</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ fontWeight: 'bold' }}>
            Made with {'<3'} by the EatClub dev team
          </DialogContentText>
          <DialogContentText>Design: Mark Dohnt</DialogContentText>
          <DialogContentText>Backend: Allen Luo</DialogContentText>
          <DialogContentText>Web: Cameron Darragh</DialogContentText>
          <DialogContentText>iOS: Han Yang Chin</DialogContentText>
          <DialogContentText>Android: Harry Pal</DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Logo);
