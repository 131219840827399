import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  IconButton,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Box,
  ListItemAvatar,
  Avatar,
  Tooltip,
  Checkbox,
} from '@material-ui/core';
import { ShoppingCart, RemoveShoppingCart, Delete, Image } from '@material-ui/icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  deleteBundleAction,
  disableBundleForDateAction,
  disableLocalBundleForDateAction,
  disableLocalBundleUntilDateAction,
  restockBundleAction,
} from '../../../../actions/bundlesAction';
import { formatCurrency, pluralise, startsWith } from '../../../../helpers/Helpers';
import ReviewLabel from '../ReviewButton/ReviewLabel';
import useStyles from './BundleStyles';
import BundleEditDialog from './BundleEditDialog';
import { dialogContexts } from '../../../../constants/constants';

const Bundle = ({
  bundle,
  deleteBundle,
  disableBundleForDate,
  selected,
  onSelect,
  menuVersion,
  restockBundle,
  disableLocalBundleForDate,
  disableLocalBundleUntilDate,
}) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [isDisabled, setIsDisabled] = useState(
    moment(bundle.disableDate, 'yyyy-MM-DD').isSame(moment(), 'day') ||
      moment(bundle.disableUntilDate, 'yyyy-MM-DD').isAfter(moment(), 'day'),
  );

  useEffect(() => {
    setIsDisabled(
      moment(bundle?.disableDate, 'yyyy-MM-DD').isSame(moment(), 'day') ||
        moment(bundle?.disableUntilDate, 'yyyy-MM-DD').isAfter(moment(), 'day'),
    );
  }, [bundle, bundle?.disableDate, bundle?.disableUntilDate]);

  const handleDelete = () => {
    deleteBundle(bundle.objectId, bundle.category);
  };

  const toggleIsDisabled = () => {
    if (isDisabled) {
      if (startsWith(bundle.objectId, 'bundle_')) {
        disableLocalBundleForDate(bundle.objectId, null);
        disableLocalBundleUntilDate(bundle.objectId, null);
      } else {
        restockBundle(bundle.objectId);
      }
      return;
    }

    const newDisableDate = moment().format('yyyy-MM-DD');
    if (startsWith(bundle.objectId, 'bundle_')) {
      disableLocalBundleForDate(bundle.objectId, newDisableDate);
    } else {
      disableBundleForDate(bundle.objectId, newDisableDate);
    }
  };

  return (
    <Box className={`${classes.draggableBundle}`}>
      <ListItem button onClick={() => setOpenDialog(true)}>
        <Checkbox
          style={{ color: selected ? '#E54439' : '#BDBDBD' }}
          checked={selected}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => onSelect(e.target.checked)}
        />
        <ListItemAvatar>
          {bundle.imageLink ? (
            <Avatar alt='Image' src={bundle.imageLink} />
          ) : (
            <Avatar>
              <Image />
            </Avatar>
          )}
        </ListItemAvatar>
        <ListItemText
          primary={
            <Box display='flex' gridColumnGap='20px'>
              <span>{bundle.bundleTitle}</span>
              <ReviewLabel importStatus={bundle?.importStatus} />
            </Box>
          }
          secondary={bundle.bundleDescription}
          style={{ paddingRight: menuVersion.posEnabled ? '569px' : '404px' }}
        />
        <ListItemSecondaryAction style={{ pointerEvents: 'none' }}>
          <Box display='flex' gridColumnGap='15px' style={{ pointerEvents: 'none' }}>
            <Box
              style={{
                padding: '20px 0',
                textAlign: 'left',
                width: '100px',
              }}
            >
              {bundle?.bundleOptionIds?.length > 0 &&
                `${bundle?.bundleOptionIds?.length} ${pluralise(
                  'option',
                  bundle?.bundleOptionIds?.length,
                )}`}
            </Box>
            <span
              style={{
                padding: '20px 0',
                textAlign: 'left',
                width: '100px',
              }}
            >
              {formatCurrency(bundle?.price)}
            </span>
            <Button
              variant='text'
              size='medium'
              color={isDisabled ? 'primary' : 'default'}
              startIcon={isDisabled ? <RemoveShoppingCart /> : <ShoppingCart />}
              className={`${classes.listButton} ${isDisabled ? 'listButtonDisabled' : ''}`}
              onClick={toggleIsDisabled}
              title='Toggle stock level'
              style={{
                fontSize: isDisabled ? '12px' : '14px',
                pointerEvents: 'auto',
              }}
            >
              {isDisabled ? 'Out of Stock' : 'In Stock'}
            </Button>
            <Tooltip title='Delete bundle'>
              <IconButton
                edge='end'
                aria-label='delete'
                onClick={handleDelete}
                style={{
                  pointerEvents: 'auto',
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        </ListItemSecondaryAction>
      </ListItem>
      <BundleEditDialog
        openDialog={openDialog}
        dialogContext={dialogContexts.EDIT}
        setOpenDialog={setOpenDialog}
        bundle={bundle}
      />
    </Box>
  );
};

Bundle.propTypes = {
  bundle: PropTypes.object.isRequired,
  disableBundleForDate: PropTypes.func.isRequired,
  deleteBundle: PropTypes.func.isRequired,
  restockBundle: PropTypes.func.isRequired,
  disableLocalBundleForDate: PropTypes.func.isRequired,
  disableLocalBundleUntilDate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  menuVersion: state.menuVersion,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      disableBundleForDate: disableBundleForDateAction,
      deleteBundle: deleteBundleAction,
      restockBundle: restockBundleAction,
      disableLocalBundleForDate: disableLocalBundleForDateAction,
      disableLocalBundleUntilDate: disableLocalBundleUntilDateAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Bundle);
