import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addBreadcrumbAction, popBreadcrumbAction } from '../../../../actions/breadcrumbsAction';
import { updateChoiceAction, clearChoicesAction } from '../../../../actions/choicesAction';
import {
  createOptionAction,
  updateOptionAction,
  addChoicesToOptionAction,
} from '../../../../actions/optionsAction';
import { addOptionToProductAction } from '../../../../actions/productsAction';
import OptionChoices from './OptionChoices';
import ReviewButton from '../ReviewButton/ReviewButton';
import useStyles from './OptionEditStyles';
import { dialogContexts } from '../../../../constants/constants';
import InfoPopover from '../../../InfoPopover/InfoPopover';

const OptionEditDialog = ({
  dialogContext,
  options,
  option,
  productId,
  productIds,
  choices,
  openDialog,
  setOpenDialog,
  createOptionAction,
  updateOptionAction,
  clearChoicesAction,
  addChoicesToOptionAction,
  addOptionToProductAction,
  updateChoiceAction,
  addBreadcrumbAction,
  popBreadcrumbAction,
  restaurantId,
  menuVersion,
}) => {
  const classes = useStyles();

  const [save, setSave] = useState(false);
  const [name, setName] = useState(option?.optionTitle || '');
  const [nameError, setNameError] = useState('');
  const [description, setDesc] = useState(option?.optionDescription || '');
  const [descError, setDescError] = useState('');
  const [minSelections, setMinSelect] = useState(option?.minSelections || 0);
  const [minSelectError, setMinSelectError] = useState('');
  const [maxSelections, setMaxSelect] = useState(option?.maxSelections || 0);
  const [maxSelectError, setMaxSelectError] = useState('');
  const [mandatory, setMandatory] = useState(option?.mandatory || false);
  const [optionChoices, setOptionChoices] = useState(options?.storedChoices || []);
  const [choiceError, setChoiceError] = useState('');
  const [importStatus, setImportStatus] = useState(option?.importStatus || null);

  const updateImportStatus = (newValue) => {
    setImportStatus(newValue);

    // We also need to update every choice to the new value...
    choices?.localData
      ?.filter((choice) => optionChoices.includes(choice?.objectId))
      .forEach((choice) =>
        updateChoiceAction(
          choice?.objectId,
          choice?.choiceTitle,
          choice?.price,
          choice?.disableDate,
          choice?.disableUntilDate,
          newValue,
        ),
      );
  };

  // Get choices
  useEffect(() => {
    setOptionChoices(options?.storedChoices || []);
  }, [option?.choiceIds, options?.storedChoices]);

  // breadcrumbs
  const [hasBreadcrumb, setHasBreadcrumb] = useState(false); // To ensure we don't pop some other breadcrumb if re-rendered while closed
  useEffect(() => {
    if (openDialog && !hasBreadcrumb) {
      addBreadcrumbAction(`${dialogContext} option`);
      setHasBreadcrumb(true);
    } else if (!openDialog && hasBreadcrumb) {
      popBreadcrumbAction();
      setHasBreadcrumb(false);
    }
  }, [openDialog]);

  const newOptionId = `option_${new Date().getTime()}`;
  const [optionId, setOptionId] = useState(option?.objectId || newOptionId);

  // Validate form when option choices are changed
  useEffect(() => {
    validateForm();
  }, [optionChoices]);

  const closeDialog = () => {
    setOpenDialog(false);
  };

  const validateForm = () => {
    let formError = false;
    if (!name) {
      setNameError('Please enter an option name.');
      formError = true;
    } else if (nameError) {
      setNameError('');
    }

    if (minSelections === '') {
      setMinSelectError('Please enter a minimum selection.');
      formError = true;
    }
    if (minSelections > maxSelections) {
      setMinSelectError('Cannot be greater than maximum selections.');
      formError = true;
    }
    if (minSelections > options?.storedChoices.length) {
      setMinSelectError('Cannot be greater than minimum option choices available.');
      formError = true;
    }
    if (minSelections === 0 && mandatory) {
      setMinSelectError('If mandatory is set, must have at least one min selection.');
      formError = true;
    }
    if (
      !(
        minSelections === '' ||
        minSelections > options?.storedChoices.length ||
        minSelections > maxSelections ||
        (minSelections === 0 && mandatory)
      )
    ) {
      if (minSelectError) {
        setMinSelectError('');
      }
    }

    if (maxSelections === '') {
      setMaxSelectError('Please enter a maximum selection.');
      formError = true;
    } else if (maxSelectError) {
      setMaxSelectError('');
    }

    if (!options?.storedChoices.length) {
      setChoiceError('Please add at least one choice');
      formError = true;
    } else if (choiceError) {
      setChoiceError('');
    }

    return formError;
  };

  const submit = (event) => {
    event.preventDefault();

    const formError = validateForm();

    if (formError) {
      return;
    }

    if (dialogContext === dialogContexts.CREATE) {
      createOptionAction(
        optionId,
        name,
        description,
        Number(minSelections),
        Number(maxSelections),
        mandatory,
        restaurantId,
        option?.posId,
        optionChoices,
      );
    } else {
      updateOptionAction(
        option?.objectId,
        name,
        description,
        Number(minSelections),
        Number(maxSelections),
        mandatory,
        restaurantId,
        option?.posId,
        optionChoices,
        importStatus,
      );
    }

    // If product was provided, ensure we add this option to the product too
    if (productId) {
      addOptionToProductAction(optionId || option?.objectId);
    }

    if (productIds) {
      productIds.forEach((product) => addOptionToProductAction(optionId || option?.objectId));
    }

    setSave(true);
    closeDialog();
  };

  const objectId = options?.data?.find(
    (apiOption) => apiOption?.objectId === option?.objectId,
  )?.objectId;

  return (
    <Dialog
      open={openDialog}
      onClose={closeDialog}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth
      maxWidth='lg'
      className={classes.dialog}
      onEnter={() => {
        const enteredOptionId = option?.objectId || newOptionId;
        setOptionId(enteredOptionId);

        // store initial choices, and load into memory
        addChoicesToOptionAction(option?.choiceIds || []);

        // Reset inputs when entering
        setName(option?.optionTitle || '');
        setDesc(option?.optionDescription || '');
        setMinSelect(option?.minSelections || 0);
        setMaxSelect(option?.maxSelections || 0);
        setMandatory(option?.mandatory || false);
        setImportStatus(option?.importStatus || null);

        setNameError('');
        setDescError('');
        setMinSelectError('');
        setMaxSelectError('');
        setChoiceError('');
        setSave(false);
      }}
      onExited={() => {
        clearChoicesAction();
      }}
    >
      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12} className={classes.leftColumn}>
            <Box display='flex' justifyContent='space-between'>
              <Box className={classes.modalTitle}>{dialogContext} Option</Box>
              {menuVersion.posEnabled && (
                <ReviewButton importStatus={importStatus} setStatus={updateImportStatus} />
              )}
            </Box>
            <Box className={classes.subtitle}>Option details</Box>
            <form onSubmit={submit} id='edit-menu-item-option-form'>
              <TextField
                autoFocus={!name}
                id='menu-item-option-name'
                label='Option Name'
                placeholder='eg. Spice level'
                variant='outlined'
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
                onBlur={(e) => {
                  setName(e.target.value.trim());
                  validateForm();
                }}
                InputLabelProps={{ shrink: true }}
                margin='normal'
                required
                error={Boolean(nameError)}
                helperText={nameError}
              />

              <TextField
                multiline
                id='menu-item-option-desc'
                label='Description'
                placeholder='eg. How spicy do you like your food?'
                variant='outlined'
                fullWidth
                value={description}
                onChange={(e) => setDesc(e.target.value)}
                onBlur={(e) => {
                  setDesc(e.target.value.trim());
                  validateForm();
                }}
                InputLabelProps={{ shrink: true }}
                margin='normal'
                error={Boolean(descError)}
                helperText={descError}
              />

              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <TextField
                    id='menu-item-min-selection'
                    label='Minimum Selections'
                    placeholder='0'
                    variant='outlined'
                    fullWidth
                    value={minSelections}
                    type='number'
                    onChange={(e) => setMinSelect(Number(e.target.value))}
                    onBlur={(e) => {
                      validateForm();
                    }}
                    InputLabelProps={{ shrink: true }}
                    min={0}
                    inputProps={{ min: 0, step: 1 }}
                    margin='normal'
                    required
                    error={Boolean(minSelectError)}
                    helperText={minSelectError}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    id='menu-item-max-selection'
                    label='Maximum Selections'
                    placeholder='0'
                    variant='outlined'
                    fullWidth
                    value={maxSelections}
                    type='number'
                    onChange={(e) => setMaxSelect(Number(e.target.value))}
                    onBlur={(e) => {
                      validateForm();
                    }}
                    InputLabelProps={{ shrink: true }}
                    min={0}
                    inputProps={{ min: 0, step: 1 }}
                    margin='normal'
                    required
                    error={Boolean(maxSelectError)}
                    helperText={maxSelectError}
                  />
                </Grid>
              </Grid>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color='primary'
                      checked={mandatory}
                      onChange={(e) => setMandatory(e.target.checked)}
                      name='mandatory'
                    />
                  }
                  label='Is Mandatory'
                />
              </FormGroup>

              {menuVersion.posEnabled && (
                <TextField
                  id='option-pos-id'
                  label='POS ID'
                  variant='outlined'
                  fullWidth
                  disabled
                  value={option?.posId}
                  // onChange={(e) => setPosId(Number(e.target.value))}
                  onBlur={(e) => {
                    validateForm();
                  }}
                  InputLabelProps={{ shrink: true }}
                  margin='normal'
                  InputProps={{ style: { backgroundColor: '#EFEFEF' } }}
                />
              )}
            </form>
          </Grid>
          <Grid item md={6} xs={12} className={classes.rightColumn}>
            <Box className={classes.columnTitle}>Choices</Box>
            <OptionChoices
              optionChoices={optionChoices}
              setOptionChoices={setOptionChoices}
              option={option}
              optionId={optionId}
              choiceError={choiceError}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ borderTop: '1px solid #DCDCDB', minHeight: '50px' }}>
        <Box
          style={{ minHeight: '60px', margin: '0 20px', width: '100%' }}
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <Button onClick={closeDialog} color='default'>
            Cancel
          </Button>

          <InfoPopover
            info={objectId}
            style={{ position: 'absolute', left: 0, right: 0, margin: '0 auto' }}
          />

          <Button onClick={submit} color='primary' type='submit' form='edit-menu-item-option-form'>
            {dialogContext === dialogContexts.CREATE ? 'Create' : 'Save'} Option
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  options: state.options,
  restaurantId: state.activeRestaurant.data.objectId,
  choices: state.choices,
  menuVersion: state.menuVersion,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createOptionAction,
      clearChoicesAction,
      updateOptionAction,
      addChoicesToOptionAction,
      addBreadcrumbAction,
      popBreadcrumbAction,
      addOptionToProductAction,
      updateChoiceAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(OptionEditDialog);
