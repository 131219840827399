import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import {
  fetchActiveChangeRequestsAction,
  fetchActiveChangeRequestsForRestaurantAction,
  fetchCompletedChangeRequestsAction,
} from '../../actions/changeRequestsAction';
import { pluralise } from '../../helpers/Helpers';
import { TextButton } from '../v2/components/Button/TextButton';
import ChangeRequestModal from './ChangeRequestModal';

const ChangeRequests = ({
  changeRequests,
  canBeActioned,
  fetchActiveChangeRequests,
  fetchActiveChangeRequestsForRestaurant,
  fetchCompletedChangeRequests,
}) => {
  const history = useHistory();
  const tableRef = useRef();
  const [changeRequestsByRestaurant, setChangeRequestsByRestaurant] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [actioningRestaurant, setActioningRestaurant] = useState(null);

  const sortChangeRequestsByRestaurant = () =>
    changeRequests.data?.reduce((accum, changeRequest) => {
      const updated = [...accum];
      const index = updated.findIndex((restaurant) => changeRequest?.restId === restaurant.restId);

      if (index >= 0) {
        updated[index].changeRequests.push(changeRequest);
      } else {
        updated.push({
          restId: changeRequest.restId,
          name: changeRequest?.businessName, // TODO may need to fetch restaurants to get name?
          lastAdded: changeRequest?.added,
          changeRequests: [changeRequest],
        });
      }

      return updated;
    }, []);

  useEffect(() => {
    setChangeRequestsByRestaurant(sortChangeRequestsByRestaurant());
  }, [changeRequests]);

  const showChangeRequestsForRestaurant = (restaurantId) => {
    // TODO avoid render thrashing here
    fetchActiveChangeRequestsForRestaurant(restaurantId);
    setShowModal(true);
    setActioningRestaurant(restaurantId);
  };

  if (changeRequests.fetching) {
    return <Box>Loading change requests...</Box>;
  }

  return (
    <Box>
      {changeRequests.data === null || changeRequests.data.length === 0 ? (
        'No change requests to display'
      ) : (
        <Table tableref={tableRef}>
          <TableHead>
            <TableRow>
              <TableCell>Venue</TableCell>
              <TableCell>Day added</TableCell>
              <TableCell>Number of changes</TableCell>
              {canBeActioned && <TableCell> </TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {changeRequestsByRestaurant.map((restaurantRequest, index) => (
              <TableRow key={restaurantRequest?.restId}>
                <TableCell>{restaurantRequest?.name}</TableCell>
                <TableCell>{restaurantRequest?.lastAdded}</TableCell>
                <TableCell>
                  {`${restaurantRequest?.changeRequests.length} ${pluralise(
                    'change',
                    restaurantRequest?.changeRequests.length,
                  )}`}
                </TableCell>
                {canBeActioned && (
                  <TableCell style={{ color: '#E54439' }}>
                    <TextButton
                      style={{
                        button: {
                          padding: '0 20px',
                        },
                      }}
                      onClick={() => {
                        showChangeRequestsForRestaurant(restaurantRequest?.restId);
                      }}
                    >
                      View
                    </TextButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {canBeActioned && (
        <ChangeRequestModal
          restaurantId={actioningRestaurant}
          isOpen={showModal}
          onClose={() => {
            fetchActiveChangeRequests();
            fetchCompletedChangeRequests();

            setShowModal(false);
          }}
        />
      )}
    </Box>
  );
};

ChangeRequests.propTypes = {
  changeRequests: PropTypes.shape({}).isRequired,
  canBeActioned: PropTypes.bool.isRequired,
  fetchActiveChangeRequests: PropTypes.func.isRequired,
  fetchActiveChangeRequestsForRestaurant: PropTypes.func.isRequired,
  fetchCompletedChangeRequests: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchActiveChangeRequests: fetchActiveChangeRequestsAction,
      fetchActiveChangeRequestsForRestaurant: fetchActiveChangeRequestsForRestaurantAction,
      fetchCompletedChangeRequests: fetchCompletedChangeRequestsAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ChangeRequests);
