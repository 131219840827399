import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormLabel,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { updateMenuAction } from '../../../../actions/menusAction';
import TextFieldTimePicker from '../textFieldTimePicker/TextFieldTimePicker';
import useStyles from './MenuEditDialogStyles';
import { getAsString } from '../../../../helpers/Helpers';

const MenuEditDialog = ({ activeMenu, openDialog, updateMenuAction, setOpenDialog }) => {
  const classes = useStyles();

  const formatTime = (minutes) => {
    if (minutes === 'Invalid Time') {
      return minutes;
    }

    if (minutes === '' || minutes === -1) {
      return '';
    }
    return moment.utc().startOf('day').add(minutes, 'minutes').format('hh:mm a');
  };

  const formatMinutes = (time) => {
    const timeAtMidnight = time.clone().startOf('day');
    const timeInMinutes = time.diff(timeAtMidnight, 'minutes');
    return timeInMinutes;
  };

  const getInitialCheck = (string, check) => (string ? string === check || string === 'all' : true);
  const initialTimeLimited = !(activeMenu.startTime === -1 || activeMenu.endTime === -1);
  const initialStartTime = activeMenu.startTime === -1 ? '' : activeMenu.startTime;
  const initialEndTime = activeMenu.endTime === -1 ? '' : activeMenu.endTime;

  const [menuTitle, setMenuTitle] = useState(activeMenu.menuTitle);
  const [menuTitleError, setMenuTitleError] = useState('');
  const [menuDisabled, setMenuDisabled] = useState(!activeMenu.enabled);
  const [menuDescription, setMenuDescription] = useState(activeMenu.menuDescription);
  const [menuTakeaway, setMenuTakeaway] = useState(getInitialCheck(activeMenu.type, 'takeaway'));
  const [menuDinein, setMenuDinein] = useState(getInitialCheck(activeMenu.type, 'dinein'));
  const [menuTypeError, setMenuTypeError] = useState('');
  const [menuEatclub, setMenuEatclub] = useState(getInitialCheck(activeMenu.platform, 'eatclub'));
  const [menuWhitelabel, setMenuWhitelabel] = useState(
    getInitialCheck(activeMenu.platform, 'whitelabel'),
  );
  const [menuPlatformError, setMenuPlatformError] = useState('');
  const [timeLimited, setTimeLimited] = useState(initialTimeLimited);
  const [startTime, setStartTime] = useState(initialStartTime);
  const [startTimeError, setStartTimeError] = useState('');
  const [endTime, setEndTime] = useState(initialEndTime);
  const [endTimeError, setEndTimeError] = useState('');
  const [reviewStateError, setReviewStateError] = useState('');

  const validateForm = () => {
    let formErrors = false;

    if (!menuTitle || menuTitle === '') {
      setMenuTitleError('Please enter a menu title');
      formErrors = true;
    } else if (menuTitleError) {
      setMenuTitleError('');
    }

    if (!menuEatclub && !menuWhitelabel) {
      setMenuPlatformError('Please select at least one platform.');
      formErrors = true;
    } else if (menuPlatformError) {
      setMenuPlatformError('');
    }

    if (!menuTakeaway && !menuDinein) {
      setMenuTypeError('Please select at least one menu type.');
      formErrors = true;
    } else if (menuTypeError) {
      setMenuTypeError('');
    }

    if (
      (timeLimited && startTime === 'Invalid Time') ||
      (timeLimited && startTime === '') ||
      (timeLimited && startTime && endTime && startTime > endTime)
    ) {
      if (timeLimited && startTime === 'Invalid Time') {
        setStartTimeError('Please enter a valid time');
      }

      if (timeLimited && startTime === '') {
        setStartTimeError('Please enter a start time');
      }

      if (timeLimited && startTime && endTime && startTime > endTime) {
        setStartTimeError('Please enter a start time before the end time');
      }

      formErrors = true;
    } else if (startTimeError) {
      setStartTimeError('');
    }

    if ((timeLimited && endTime === 'Invalid Time') || (timeLimited && endTime === '')) {
      if (timeLimited && endTime === 'Invalid Time') {
        setEndTimeError('Please enter a valid time');
      }

      if (timeLimited && endTime === '') {
        setEndTimeError('Please enter an end time');
      }
      formErrors = true;
    } else if (endTimeError) {
      setEndTimeError('');
    }

    return formErrors;
  };

  const closeDialog = () => {
    setOpenDialog(false);
  };

  const submit = (event) => {
    event.preventDefault();

    const hasErrors = validateForm();

    if (hasErrors) {
      return;
    }

    const menuPlatform = getAsString(menuEatclub, 'eatclub', menuWhitelabel, 'whitelabel');
    const menuType = getAsString(menuTakeaway, 'takeaway', menuDinein, 'dinein');

    updateMenuAction({
      ...activeMenu,
      menuTitle,
      menuDescription,
      platform: menuPlatform,
      type: menuType,
      enabled: !menuDisabled,
      startTime: timeLimited ? startTime : -1,
      endTime: timeLimited ? endTime : -1,
    });

    closeDialog();
  };

  const handleStartTimeChange = (time) => {
    if (!time) {
      setStartTimeError('Please select a time.');
      setStartTime('');
      return;
    }

    if (!time.isValid()) {
      setStartTimeError('Please select a valid time.');
      setStartTime('Invalid Time');
      return;
    }

    if (startTimeError) {
      setStartTimeError('');
    }

    const minutes = formatMinutes(time);
    setStartTime(minutes);
  };

  const handleEndTimeChange = (time) => {
    if (!time) {
      setEndTimeError('Please select a time.');
      setEndTime('');
      return;
    }

    if (!time.isValid()) {
      setEndTimeError('Please select a valid time.');
      setEndTime('Invalid Time');
      return;
    }

    if (startTimeError) {
      setEndTimeError('');
    }

    const minutes = formatMinutes(time);
    setEndTime(minutes);
  };

  return (
    <Dialog
      open={openDialog}
      onClose={closeDialog}
      fullWidth
      maxWidth='sm'
      onEnter={() => {
        // Reset state when entering
        setMenuTitle(activeMenu.menuTitle);
        setMenuDescription(activeMenu.menuDescription);
        setTimeLimited(initialTimeLimited);
        setStartTime(initialStartTime);
        setEndTime(initialEndTime);
        setMenuDisabled(!activeMenu.enabled);

        setMenuTakeaway(getInitialCheck(activeMenu.type, 'takeaway'));
        setMenuDinein(getInitialCheck(activeMenu.type, 'dinein'));
        setMenuTypeError('');

        setMenuEatclub(getInitialCheck(activeMenu.platform, 'eatclub'));
        setMenuWhitelabel(getInitialCheck(activeMenu.platform, 'whitelabel'));
        setMenuPlatformError('');

        setMenuTitleError('');
        setStartTimeError('');
        setEndTimeError('');
      }}
    >
      <DialogTitle>Edit {activeMenu.menuTitle}</DialogTitle>
      <DialogContent>
        <form id='edit-menu-form' onSubmit={submit}>
          <TextField
            autoFocus
            fullWidth
            margin='normal'
            variant='outlined'
            label='Menu Title'
            value={menuTitle}
            onBlur={(e) => setMenuTitle(e.target.value.trim())}
            onChange={(e) => setMenuTitle(e.target.value)}
            error={Boolean(menuTitleError)}
            helperText={menuTitleError}
          />

          <TextField
            multiline
            rows={4}
            rowsMax={8}
            fullWidth
            margin='normal'
            variant='outlined'
            label='Menu Description'
            value={menuDescription}
            onBlur={(e) => setMenuDescription(e.target.value.trim())}
            onChange={(e) => setMenuDescription(e.target.value)}
          />

          <FormControl
            fullWidth
            margin='normal'
            variant='outlined'
            className={classes.selectLabelFix}
          >
            <InputLabel variant='outlined' id='disable-menu'>
              Menu Status
            </InputLabel>
            <Select
              variant='outlined'
              labelId='disable-menu'
              value={menuDisabled}
              onChange={(e) => setMenuDisabled(e.target.value)}
              error={Boolean(reviewStateError)}
            >
              <MenuItem value>Disabled</MenuItem>
              <MenuItem value={false}>Enabled</MenuItem>
            </Select>

            {Boolean(reviewStateError) && <FormHelperText error>{reviewStateError}</FormHelperText>}
          </FormControl>

          {/* <FormControl fullWidth component='fieldset' margin='normal'>
            <FormLabel component='legend'>Menu Platform</FormLabel>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={menuEatclub}
                    onChange={(event) => setMenuEatclub(event.target.checked)}
                    name='eatclub'
                    color='primary'
                  />
                }
                label='Eatclub'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={menuWhitelabel}
                    onChange={(event) => setMenuWhitelabel(event.target.checked)}
                    name='whitelabel'
                    color='primary'
                  />
                }
                label='White Label'
              />
            </FormGroup>
            {Boolean(menuPlatformError) && (
              <FormHelperText error>{menuPlatformError}</FormHelperText>
            )}
          </FormControl> */}

          <FormControl fullWidth component='fieldset' margin='normal'>
            <FormLabel component='legend'>Menu Type</FormLabel>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={menuTakeaway}
                    onChange={(event) => setMenuTakeaway(event.target.checked)}
                    name='takeaway'
                    color='primary'
                  />
                }
                label='Takeaway'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={menuDinein}
                    onChange={(event) => setMenuDinein(event.target.checked)}
                    name='dinein'
                    color='primary'
                  />
                }
                label='Dine In'
              />
            </FormGroup>
            {Boolean(menuTypeError) && <FormHelperText error>{menuTypeError}</FormHelperText>}
          </FormControl>

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color='primary'
                  checked={timeLimited}
                  onChange={(event) => setTimeLimited(event.target.checked)}
                />
              }
              label='Time Limited'
            />
          </FormGroup>

          {timeLimited && (
            <>
              <TextFieldTimePicker
                // className={classes.formControl}
                label='Start time'
                value={startTime !== '' ? formatTime(startTime) : ''}
                onChange={handleStartTimeChange}
                error={Boolean(startTimeError)}
                helperText={startTimeError}
              />

              <TextFieldTimePicker
                // className={classes.formControl}
                label='End time'
                value={endTime !== '' ? formatTime(endTime) : ''}
                onChange={handleEndTimeChange}
                error={Boolean(endTimeError)}
                helperText={endTimeError}
              />
            </>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color='default'>
          Cancel
        </Button>
        <Button onClick={submit} color='primary' type='submit' form='edit-menu-form'>
          Save Menu
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  menus: state.menus,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateMenuAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MenuEditDialog);
