export const COLORS = {
  BRAND_PRIMARY: '#E54439',
  CHARCOAL: '#313131',
  VIOLENT_VIOLET: '#EDEBF7',
};

const FONT_SIZES = {
  XS: '10px',
  S: '14px',
  M: '16px',
  L: '18px',
  XL: '20px',
  XXL: '24px',
};

const LINE_HEIGHTS = {
  XS: '10px',
  S: '14px',
  M: '16px',
  L: '18px',
  XL: '20px',
  XXL: '24px',
};

const FONT_WEIGHTS = {
  REGULAR: 400,
  MEDIUM: 500,
  BOLD: 700,
  BLACK: 900,
};

const TYPOGRAPHY = {
  MODAL_HEADER: {
    fontWeight: FONT_WEIGHTS.BOLD,
    fontSize: FONT_SIZES.XXL,
    lineHeight: LINE_HEIGHTS.XXL,
  },
  MODAL_PROGRESS: {
    fontWeight: FONT_WEIGHTS.REGULAR,
    fontSize: FONT_SIZES.M,
    lineHeight: LINE_HEIGHTS.XXL,
  },
  SMALL_HEADING: {
    fontWeight: FONT_WEIGHTS.BOLD,
    fontSize: FONT_SIZES.M,
    lineHeight: LINE_HEIGHTS.XXL,
    color: '#2E2E2E',
  },
};

const theme = {
  modalHeader: TYPOGRAPHY.MODAL_HEADER,
  modalProgress: TYPOGRAPHY.MODAL_PROGRESS,
  smallHeading: TYPOGRAPHY.SMALL_HEADING,
};

export default theme;
