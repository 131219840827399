import React, { useState } from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { PlaylistAdd } from '@material-ui/icons';
import MenuItemCreateDialog from './MenuItemCreateDialog';

const MenuItemCreate = ({ category }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const lastMenuItemInCategory = category.menuItems.slice(-1)[0];
  return (
    <>
      <ListItem button onClick={() => setOpenDialog(true)}>
        <ListItemIcon>
          <PlaylistAdd />
        </ListItemIcon>
        <ListItemText
          primary='Add New Menu Item'
          primaryTypographyProps={{ color: 'textSecondary', variant: 'button' }}
        />
      </ListItem>
      <MenuItemCreateDialog
        category={lastMenuItemInCategory.category}
        menuId={lastMenuItemInCategory.menuId}
        newMenuItemIndex={lastMenuItemInCategory.itemIndex} // TODO shouldn't this be between the previous and next item index?
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
      />
    </>
  );
};

export default MenuItemCreate;
