import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Box } from '@material-ui/core';
import { Button } from '@eatclub-apps/ec-component-library';
import { ReactComponent as FailureImage } from '../../assets/confirmation_cross_medium.svg';
import useStyles from './DuplicateMenusStyles';

const DuplicateFail = ({ activeRestaurant }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box className={classes.successStateContainer}>
      <FailureImage style={{ height: '76px', width: '76px' }} />
      <Box style={{ fontSize: '60px', fontWeight: 'bold', marginBottom: '30px' }}>
        An error has occurred
      </Box>
      <Box style={{ fontSize: '20px', marginBottom: '50px' }}>
        Menus were not successfully duplicated.
      </Box>
      <Button
        type='tertiary'
        onClick={() => history.push(`/restaurants/${activeRestaurant?.data?.objectId}`)}
      >
        RETURN TO MENU
      </Button>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  activeRestaurant: state.activeRestaurant,
  menuVersion: state.menuVersion?.version,
  menus: state.menus,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateFail);
