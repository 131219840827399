import React from 'react';
import useStyles from './MenuTabPanelStyles';

const MenuTabPanelContainer = ({ index, value, children }) => {
  const classes = useStyles();
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={classes.tabPanelContainer}
    >
      {children}
    </div>
  );
};

MenuTabPanelContainer.propTypes = {};

export default MenuTabPanelContainer;
