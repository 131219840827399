import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: '4px',
    border: '1px solid #D6D6D6',
    padding: '20px',
    marginBottom: '40px',
    fontSize: '20px',
    lineHeight: '24px',
    color: '#2E2E2E',
  },
  innerContainer: {
    columnGap: '15px',
  },
  icon: {
    backgroundColor: '#F39A47',
    borderRadius: '24px',
    width: '24px',
    height: '24px',
    // marginTop: "2px",
  },
  content: {},
}));

export default useStyles;
