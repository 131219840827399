/* eslint-disable */
import React, { useEffect, useCallback, useState } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import moment from 'moment';

const MenusValidation = ({ menus }) => {
  const [errors, setErrors] = useState([]);

  const getInvalidMenus = useCallback((data) => {
    const menusByType = data.reduce((accumByType, menu) => {
      // check if already reduced
      const index = accumByType.findIndex((item) => item.type === menu.type);

      const { startTime, endTime } = menu;

      let time = 'Time Limited';

      if (startTime === -1 || endTime === -1) {
        time = 'All Day';
      }

      // store initital menuItem
      if (index === -1) {
        accumByType.push({
          type: menu.type,
          menus: [{ ...menu, time }],
        });
      } else {
        accumByType[index].menus.push({ ...menu, time });
      }

      // const startTime = moment.utc().startOf("day").add();

      return accumByType;
    }, []);

    const menusByTypeByTime = menusByType.map((menuType) => {
      const { menus, type } = menuType;

      const hasAllDay = menus.findIndex((menu) => menu.time === 'All Day');

      if (hasAllDay > -1) {
        return { type, valid: true, reason: '' };
      }

      // check for overlapping menu times
      const menusByTime = menus.map((menu, index) => {
        const startTime = moment.utc().startOf('day').add(menu.startTime, 'minutes');
        const endTime = moment.utc().startOf('day').add(menu.endTime, 'minutes');

        const overlaps = menus.map((checkMenu, checkIndex) => {
          if (index === checkIndex) {
            return false;
          }

          const checkStartTime = moment.utc().startOf('day').add(checkMenu.startTime, 'minutes');
          const checkEndTime = moment.utc().startOf('day').add(checkMenu.endTime, 'minutes');

          // NOTE: exclusive of data "()"
          const isBetween =
            startTime.isBetween(checkStartTime, checkEndTime, 'minutes', '()') ||
            endTime.isBetween(checkStartTime, checkEndTime, 'minutes', '()');

          return isBetween;
        });

        const hasOverlaps = overlaps.some((el) => el);

        return hasOverlaps;
      });

      const isValid = !menusByTime.some((el) => el);

      return { type, valid: isValid, reason: 'overlapping' };
    });

    const invalidTypes = menusByTypeByTime.filter((menu) => menu.valid === false);

    return invalidTypes;
  }, []);

  useEffect(() => {
    const invalidMenus = getInvalidMenus(menus.localData.map((menu) => menu.enabled));

    if (invalidMenus.length === 0) {
      setErrors([]);
    } else {
      const messages = [];

      invalidMenus.forEach((menu) => {
        const type = menu.type === '' ? 'TAKEAWAY + DINEIN' : menu.type?.toUpperCase();

        if (menu.reason === 'overlapping') {
          messages.push(`Some of your "${type}" menus are ${menu.reason}.`);
        } else {
          messages.push(`${menu.type} menus have ${menu.reason} issue.`);
        }
      });

      setErrors(messages);
    }
  }, [getInvalidMenus, menus]);

  if (errors.length === 0) {
    return null;
  }

  return (
    <Alert variant='filled' severity='error' style={{ marginTop: '1rem' }}>
      <AlertTitle>There&apos;s a menu issue!</AlertTitle>
      {errors.map((error, index) => (
        <div key={index} style={{ marginBottom: index < errors.length - 1 ? '.25rem' : 0 }}>
          {error}
        </div>
      ))}
    </Alert>
  );
};

export default MenusValidation;
