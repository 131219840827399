import * as type from './types';

export const clearBreadcrumbsAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_BREADCRUMBS,
  });
};

export const addBreadcrumbAction = (newBreadcrumb) => (dispatch) => {
  dispatch({
    type: type.ADD_BREADCRUMB,
    payload: newBreadcrumb,
  });
};

export const popBreadcrumbAction = () => (dispatch) => {
  dispatch({
    type: type.POP_BREADCRUMB,
  });
};
