import React, { useEffect, useState } from 'react';
import {
  ListItem,
  ListItemIcon,
  Box,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Button,
} from '@material-ui/core';
import { Draggable } from 'react-beautiful-dnd';
import { Delete, RemoveShoppingCart, ShoppingCart } from '@material-ui/icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { ReactComponent as Icon } from '../../../../assets/two-bars-icon.svg';
import {
  disableLocalProductForDateAction,
  disableLocalProductUntilDateAction,
  disableProductForDateAction,
  restockProductAction,
} from '../../../../actions/productsAction';
import { removeProductFromBundleOptionAction } from '../../../../actions/bundleOptionsAction';
import { formatCurrency, removeSpaces, startsWith } from '../../../../helpers/Helpers';
import useStyles from './BundleOptionProductStyles';

const BundleOptionProduct = ({
  product,
  disableProductForDate,
  restockProduct,
  removeProductFromBundleOption,
  index,
  bundleOption,
  setDialogContext,
  setOpenDialog,
  setSelectedChoiceIndex,
  menuVersion,
  disableLocalProductForDate,
  disableLocalProductUntilDate,
}) => {
  const classes = useStyles();

  const [isDisabled, setIsDisabled] = useState(
    moment(product.disableDate, 'yyyy-MM-DD').isSame(moment(), 'day') ||
      moment(product.disableUntilDate, 'yyyy-MM-DD').isAfter(moment(), 'day'),
  );

  useEffect(() => {
    setIsDisabled(
      moment(product.disableDate, 'yyyy-MM-DD').isSame(moment(), 'day') ||
        moment(product.disableUntilDate, 'yyyy-MM-DD').isAfter(moment(), 'day'),
    );
  }, [product, product.disableDate, product.disableUntilDate]);

  const toggleIsDisabled = () => {
    if (isDisabled) {
      if (startsWith(product.objectId, 'product_')) {
        disableLocalProductForDate(product.objectId, null);
        disableLocalProductUntilDate(product.objectId, null);
      } else {
        restockProduct(product.objectId);
      }
      return;
    }

    const newDisableDate = moment().format('yyyy-MM-DD');
    if (startsWith(product.objectId, 'product_')) {
      disableLocalProductForDate(product.objectId, newDisableDate);
    } else {
      disableProductForDate(product.objectId, newDisableDate);
    }
  };

  const handleDelete = () => {
    removeProductFromBundleOption(product.objectId);
  };

  if (!product) {
    return <></>;
  }

  return (
    <Draggable draggableId={`${removeSpaces(product.productTitle)}`} index={index}>
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={`${classes.draggableChoice} ${snapshot.isDragging ? 'dragging' : ''} ${
            snapshot.isDropAnimating ? 'animating' : ''
          }`}
          boxShadow={snapshot.isDragging ? 3 : 0}
        >
          <ListItem
            button
            onClick={() => {
              setOpenDialog(true);
              setDialogContext('Edit');
              setSelectedChoiceIndex(index);
            }}
          >
            <ListItemIcon title='Drag to re-order' {...provided.dragHandleProps}>
              <Icon style={{ fill: 'rgba(0, 0, 0, 0.54)' }} />
            </ListItemIcon>
            {menuVersion.posEnabled && bundleOption?.importStatus !== null && (
              <Box
                className={classes.reviewIcon}
                style={{
                  backgroundColor: product?.importStatus === 'needsReview' ? '#F39A47' : '#77C76A',
                }}
              />
            )}
            <ListItemText primary={product.productTitle} style={{ verticalAlign: 'baseline' }} />
            <ListItemSecondaryAction style={{ verticalAlign: 'baseline' }}>
              <Box display='flex'>
                {product.price ? (
                  <Box
                    style={{ paddingTop: '14px', cursor: 'pointer' }}
                    onClick={() => {
                      setOpenDialog(true);
                      setDialogContext('Edit');
                      setSelectedChoiceIndex(index);
                    }}
                  >
                    {formatCurrency(product.price)}
                  </Box>
                ) : (
                  ''
                )}
                <Button
                  variant='text'
                  size='medium'
                  className={`${classes.listButton} ${isDisabled ? 'listButtonDisabled' : ''}`}
                  startIcon={isDisabled ? <RemoveShoppingCart /> : <ShoppingCart />}
                  onClick={toggleIsDisabled}
                  title='Toggle stock level'
                  style={{ fontSize: isDisabled ? '12px' : '14px' }}
                >
                  {isDisabled ? 'Out of Stock' : 'In Stock'}
                </Button>
                <IconButton
                  edge='end'
                  aria-label='delete'
                  onClick={handleDelete}
                  title='Remove product from bundle'
                >
                  <Delete />
                </IconButton>
              </Box>
            </ListItemSecondaryAction>
          </ListItem>
        </Box>
      )}
    </Draggable>
  );
};

const mapStateToProps = (state) => ({
  menuVersion: state.menuVersion,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      disableProductForDate: disableProductForDateAction,
      restockProduct: restockProductAction,
      removeProductFromBundleOption: removeProductFromBundleOptionAction,
      disableLocalProductForDate: disableLocalProductForDateAction,
      disableLocalProductUntilDate: disableLocalProductUntilDateAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(BundleOptionProduct);
