import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  tab: {
    '& > span': {
      flexDirection: 'column-reverse',
    },
  },
  menuTab: {
    border: '1px solid #D8D8D8',
    borderRadius: '8px',
    padding: '20px',
    margin: '20px 0',
    height: '100%',
    textTransform: 'none',
    color: '2E2E2E',
    minWidth: '200px',
    maxWidth: '200px',
    width: '200px',
    textAlign: 'center',
    minHeight: '220px',
  },
  platform: {
    textTransform: 'uppercase',
    fontSize: '12px',
    minHeight: '42px',
  },
  menuTitle: {
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '1.2',
    marginTop: '10px',
    marginBottom: '5px',
  },
  menuTabContent: {
    fontSize: '16px',
    lineHeight: '20px',
  },
  menuTabActionMenuIcon: {
    height: '20px',
    marginBottom: '5px',
    color: '#9E9E9E',
  },
  globalMenuContent: {
    fontSize: '16px',
    fontWeight: 'bold',
    textTransform: 'none',
    lineHeight: '1.5',
  },
}));

export default useStyles;
