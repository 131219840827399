import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  boxLabel: {
    transform: 'translate(14px, -6px) scale(0.75)',
    padding: '0px 0.5rem',
    backgroundColor: '#fff',
    zIndex: 1,
    pointerEvents: 'none',
    transformOrigin: 'top left',
    top: 0,
    left: 0,
    position: 'absolute',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '1rem',
    lineHeight: 1,
    letterSpacing: '0.00938em',
  },
  boxOutlined: {
    borderColor: 'rgba(0, 0, 0, 0.23)',
    border: '1px solid rgba(0,0,0,.23)',
    borderRadius: '4px',
    marginTop: '1rem',
    padding: theme.spacing(3),
    position: 'relative',
    '&:hover': {
      borderColor: 'rgba(0, 0, 0, 0.87)',
    },
    '&.boxError': {
      borderColor: theme.palette.primary.main,
    },
    '&.boxError > label': {
      color: theme.palette.primary.main,
    },
  },
  dropzone: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    minHeight: '200px',
    color: theme.palette.secondary.light,
    '& svg': {
      color: theme.palette.secondary.light,
    },
    padding: theme.spacing(3),
  },
  thumbnail: {
    maxHeight: '100px',
    maxWidth: '100%',
  },
}));

export default useStyles;
