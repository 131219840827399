import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: '56px',
    padding: `${theme.spacing(3)}px ${theme.spacing(0)}px`,
    [theme.breakpoints.up('sm')]: {
      marginTop: '105px',
    },
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(4)}px ${theme.spacing(3)}px`,
      flexGrow: 1,
      justifyContent: 'center',
      display: 'flex',
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px',
      marginRight: '20px',
    },
  },
  contentInner: {
    maxWidth: '1200px',
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      overflow: 'hidden', // fix: grids at full width cause unwanted "x" scrolling
      paddingBottom: '3px', // fix: make room for shadows/elevation
    },
  },
}));

export default useStyles;
