import * as type from './types';
import { devLog } from '../helpers/devLog';
import { createCategories, removeCategories, updateCategories } from '../graphql/mutations';
import { removeEmpty } from '../helpers/Helpers';
import { makeApiCall } from './actions';

/*
 * v2 menu category actions
 */

export const createCategoryAction = (categoryId, categoryTitle, menuId) => (dispatch) => {
  dispatch({
    type: type.CREATE_CATEGORY,
    payload: {
      categoryId,
      categoryTitle,
      menuId,
    },
  });
};

export const updateCategoryAction =
  (objectId, categoryTitle, categoryIndex, menuId, productIds, bundleIds) => (dispatch) => {
    dispatch({
      type: type.UPDATE_CATEGORY,
      payload: {
        objectId,
        categoryTitle,
        categoryIndex,
        menuId,
        productIds,
        bundleIds,
      },
    });
  };

export const deleteCategoryAction = (categoryId) => (dispatch) => {
  dispatch({
    type: type.DELETE_CATEGORY,
    payload: { categoryId },
  });
};

export const clearCategoryAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_CATEGORIES,
    payload: {},
  });
};

export const addProductToCategoryAction = (productId, categoryId) => (dispatch) => {
  dispatch({
    type: type.ADD_PRODUCT_TO_CATEGORY,
    payload: { productId, categoryId },
  });
};

export const addProductsToCategoryAction = (productIds, categoryId) => (dispatch) => {
  dispatch({
    type: type.ADD_PRODUCTS_TO_CATEGORY,
    payload: { productIds, categoryId },
  });
};

export const removeProductFromCategoryAction = (productId, categoryId) => (dispatch) => {
  dispatch({
    type: type.REMOVE_PRODUCT_FROM_CATEGORY,
    payload: { productId, categoryId },
  });
};

export const addBundleToCategoryAction = (bundleId, categoryId) => (dispatch) => {
  dispatch({
    type: type.ADD_BUNDLE_TO_CATEGORY,
    payload: { bundleId, categoryId },
  });
};

export const addBundlesToCategoryAction = (bundleIds, categoryId) => (dispatch) => {
  dispatch({
    type: type.ADD_BUNDLES_TO_CATEGORY,
    payload: { bundleIds, categoryId },
  });
};

export const removeBundleFromCategoryAction = (bundleId, categoryId) => (dispatch) => {
  dispatch({
    type: type.REMOVE_BUNDLE_FROM_CATEGORY,
    payload: { bundleId, categoryId },
  });
};

export const dbRemoveCategories = async (categoryIds, restaurantId, dispatch) => {
  try {
    const response = await makeApiCall(removeCategories, {
      restId: restaurantId,
      categoryIds,
    });

    devLog('success', 'remove categories', response.data.removeCategories);

    return null;
  } catch (error) {
    devLog('error', 'unable to remove categories', error);

    dispatch({
      type: type.SET_TOAST,
      payload: {
        id: `CATEGORIES_REMOVE_${new Date().getTime()}`,
        message: `Unable to remove categories: ${error}`,
        type: 'error',
      },
    });

    return error;
  }
};

export const dbCreateCategories = async (categories, restaurantId, dispatch) => {
  try {
    const response = await makeApiCall(createCategories, {
      categories: categories.map((category) =>
        removeEmpty({
          categoryTitle: category.categoryTitle,
          categoryIndex: category.categoryIndex,
          menuId: category.menuId,
          restId: restaurantId,
          pos: category.pos,
          productIds: category.productIds,
          bundleIds: category.bundleIds,
        }),
      ),
    });

    if (
      response?.data?.createCategories === undefined ||
      response?.data?.createCategories === null
    ) {
      throw new Error('Response is undefined');
    }

    devLog('success', 'create categories', response.data.createCategories);

    return response?.data?.createCategories?.map((createdCategory, index) => {
      dispatch({
        type: type.BACKEND_SAVE_CATEGORY_SUCCESS,
        payload: {
          savedItem: createdCategory,
          itemObjectId: categories[index]._objectId,
          categoryIndex: categories[index].categoryIndex,
        },
      });

      // Also return the original objectId
      return { ...createdCategory, _objectId: categories[index]._objectId };
    });
  } catch (error) {
    devLog('error', 'unable to create categories', error);

    dispatch({
      type: type.SET_TOAST,
      payload: {
        id: `CATEGORIES_CREATE_${new Date().getTime()}`,
        message: `Unable to create categories: ${error}`,
        type: 'error',
      },
    });

    return error;
  }
};

export const dbUpdateCategories = async (categories, restaurantId, dispatch) => {
  try {
    const response = await makeApiCall(updateCategories, {
      restId: restaurantId,
      categories: categories.map((category) =>
        removeEmpty({
          objectId: category.objectId,
          menuId: category.menuId,
          categoryTitle: category.categoryTitle,
          categoryIndex: category.categoryIndex,
          productIds: category.productIds,
          bundleIds: category.bundleIds,
        }),
      ),
    });

    if (
      response?.data?.updateCategories === undefined ||
      response?.data?.updateCategories === null
    ) {
      throw new Error('Response is undefined');
    }

    devLog('success', 'update categories', response.data.updateCategories);

    response?.data?.updateCategories?.forEach((updatedCategory) => {
      dispatch({
        type: type.BACKEND_UPDATE_CATEGORY_SUCCESS,
        payload: { savedItem: updatedCategory },
      });
    });

    return null;
  } catch (error) {
    devLog('error', 'unable to update categories', error);

    dispatch({
      type: type.SET_TOAST,
      payload: {
        id: `CATEGORIES_UPDATE_${new Date().getTime()}`,
        message: `Unable to update categories: ${error}`,
        type: 'error',
      },
    });

    return error;
  }
};
