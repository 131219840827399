/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  DialogContentText,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { useRollbar } from '@rollbar/react';
import { copyMenuAction } from '../../../../actions/menusAction';
import { dbSaveAllAction } from '../../../../actions/saveAction';
import TextFieldTimePicker from '../textFieldTimePicker/TextFieldTimePicker';
import { getAsString, startsWith } from '../../../../helpers/Helpers';

const MenuCopyDialog = ({
  activeRestaurantId,
  activeMenu,
  openDialog,
  copyMenuAction,
  setOpenDialog,
  dbSaveAllAction,
  menus,
  save,
}) => {
  const rollbar = useRollbar();

  const formatTime = (minutes) => {
    if (minutes === 'Invalid Time') {
      return minutes;
    }

    if (minutes === '' || minutes === -1) {
      return '';
    }

    return moment.utc().startOf('day').add(minutes, 'minutes').format('hh:mm a');
  };

  const formatMinutes = (time) => {
    const timeAtMidnight = time.clone().startOf('day');
    const timeInMinutes = time.diff(timeAtMidnight, 'minutes');
    return timeInMinutes;
  };

  const getInitialCheck = (string, check) => (string ? string === check || string === 'all' : true);
  const initialTimeLimited = !(activeMenu.startTime === -1 || activeMenu.endTime === -1);
  const initialTitle = `${activeMenu.menuTitle} (copy)`;
  const initialStartTime = activeMenu.startTime === -1 ? '' : activeMenu.startTime;
  const initialEndTime = activeMenu.endTime === -1 ? '' : activeMenu.endTime;

  const [menuTitle, setMenuTitle] = useState(initialTitle);
  const [menuTitleError, setMenuTitleError] = useState('');
  const [menuDescription, setMenuDescription] = useState(activeMenu.menuDescription);
  const [menuTakeaway, setMenuTakeaway] = useState(getInitialCheck(activeMenu.type, 'takeaway'));
  const [menuDinein, setMenuDinein] = useState(getInitialCheck(activeMenu.type, 'dinein'));
  const [menuTypeError, setMenuTypeError] = useState('');
  const [menuEatclub, setMenuEatclub] = useState(getInitialCheck(activeMenu.platform, 'eatclub'));
  const [menuWhitelabel, setMenuWhitelabel] = useState(
    getInitialCheck(activeMenu.platform, 'whitelabel'),
  );
  const [menuPlatformError, setMenuPlatformError] = useState('');
  const [timeLimited, setTimeLimited] = useState(initialTimeLimited);
  const [startTime, setStartTime] = useState(initialStartTime);
  const [startTimeError, setStartTimeError] = useState('');
  const [endTime, setEndTime] = useState(initialEndTime);
  const [endTimeError, setEndTimeError] = useState('');

  // When save is complete, copy the menu
  const [copyOnSaveCompletion, setCopyOnSaveCompletion] = useState(false);

  useEffect(() => {
    // If waiting for save to finish, and it has
    if (!save.saving && !save.error && copyOnSaveCompletion) {
      const menuType = getAsString(menuTakeaway, 'takeaway', menuDinein, 'dinein');

      copyMenuAction(
        activeRestaurantId,
        activeMenu.objectId,
        menus.activeMenuTab + 1,
        menuTitle,
        menuDescription,
        timeLimited ? startTime : -1,
        timeLimited ? endTime : -1,
        menuType,
      );

      setCopyOnSaveCompletion(false);
    }
  }, [save.saving]);

  const validateForm = () => {
    let formErrors = false;

    // Don't allow copying a menu that hasn't been saved
    if (startsWith(activeMenu.objectId, 'menu_')) {
      setMenuTitleError(
        "You can't copy a menu that has not yet been saved. Please save the menu first.",
      );
      formErrors = true;
    }

    if (!menuTitle || menuTitle === '') {
      setMenuTitleError('Please enter a menu title');
      formErrors = true;
    } else if (menuTitleError) {
      setMenuTitleError('');
    }

    if (!menuEatclub && !menuWhitelabel) {
      setMenuPlatformError('Please select at least one platform.');
      formErrors = true;
    } else if (menuPlatformError) {
      setMenuPlatformError('');
    }

    if (!menuTakeaway && !menuDinein) {
      setMenuTypeError('Please select at least one menu type.');
      formErrors = true;
    } else if (menuTypeError) {
      setMenuTypeError('');
    }

    if (
      (timeLimited && startTime === 'Invalid Time') ||
      (timeLimited && (!startTime || startTime === '')) ||
      (timeLimited && startTime && endTime && startTime > endTime)
    ) {
      if (timeLimited && startTime === 'Invalid Time') {
        setStartTimeError('Please enter a valid time');
      }

      if (timeLimited && (!startTime || startTime === '')) {
        setStartTimeError('Please enter a start time');
      }

      if (timeLimited && startTime && endTime && startTime > endTime) {
        setStartTimeError('Please enter a start time before the end time');
      }

      formErrors = true;
    } else if (startTimeError) {
      setStartTimeError('');
    }

    if (
      (timeLimited && endTime === 'Invalid Time') ||
      (timeLimited && (!endTime || endTime === ''))
    ) {
      if (timeLimited && endTime === 'Invalid Time') {
        setEndTimeError('Please enter a valid time');
      }

      if (timeLimited && (!endTime || endTime === '')) {
        setEndTimeError('Please enter an end time');
      }
      formErrors = true;
    } else if (endTimeError) {
      setEndTimeError('');
    }

    return formErrors;
  };

  const closeDialog = () => {
    setOpenDialog(false);
  };

  const submit = (event) => {
    event.preventDefault();

    const hasErrors = validateForm();

    if (hasErrors) {
      return;
    }

    setCopyOnSaveCompletion(true);

    // Save current menu
    dbSaveAllAction(rollbar);

    closeDialog();
  };

  const handleStartTimeChange = (time) => {
    if (!time) {
      setStartTimeError('Please select a time.');
      setStartTime('');
      return;
    }

    if (!time.isValid()) {
      setStartTimeError('Please select a valid time.');
      setStartTime('Invalid Time');
      return;
    }

    if (startTimeError) {
      setStartTimeError('');
    }

    const minutes = formatMinutes(time);
    setStartTime(minutes);
  };

  const handleEndTimeChange = (time) => {
    if (!time) {
      setEndTimeError('Please select a time.');
      setEndTime('');
      return;
    }

    if (!time.isValid()) {
      setEndTimeError('Please select a valid time.');
      setEndTime('Invalid Time');
      return;
    }

    if (startTimeError) {
      setEndTimeError('');
    }

    const minutes = formatMinutes(time);
    setEndTime(minutes);
  };

  return (
    <Dialog
      open={openDialog}
      onClose={closeDialog}
      fullWidth
      maxWidth='sm'
      onEnter={() => {
        // Reset state when entering
        setMenuTitle(initialTitle);
        setMenuDescription(activeMenu.menuDescription);
        setTimeLimited(initialTimeLimited);
        setStartTime(initialStartTime);
        setEndTime(initialEndTime);

        setMenuTakeaway(getInitialCheck(activeMenu.type, 'takeaway'));
        setMenuDinein(getInitialCheck(activeMenu.type, 'dinein'));
        setMenuTypeError('');

        setMenuEatclub(getInitialCheck(activeMenu.platform, 'eatclub'));
        setMenuWhitelabel(getInitialCheck(activeMenu.platform, 'whitelabel'));
        setMenuPlatformError('');

        setMenuTitleError('');
        setStartTimeError('');
        setEndTimeError('');
      }}
    >
      <DialogTitle>Copy {activeMenu.menuTitle}</DialogTitle>
      <DialogContent>
        <form id='edit-menu-form' onSubmit={submit}>
          <TextField
            autoFocus
            fullWidth
            margin='normal'
            variant='outlined'
            label='Menu Title'
            value={menuTitle}
            onBlur={(e) => setMenuTitle(e.target.value.trim())}
            onChange={(e) => setMenuTitle(e.target.value)}
            error={Boolean(menuTitleError)}
            helperText={menuTitleError}
          />

          <TextField
            multiline
            rows={4}
            rowsMax={8}
            fullWidth
            margin='normal'
            variant='outlined'
            label='Menu Description'
            value={menuDescription}
            onBlur={(e) => setMenuDescription(e.target.value.trim())}
            onChange={(e) => setMenuDescription(e.target.value)}
          />

          {/* <FormControl fullWidth component='fieldset' margin='normal'>
            <FormLabel component='legend'>Menu Platform</FormLabel>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={menuEatclub}
                    onChange={(event) => setMenuEatclub(event.target.checked)}
                    name='eatclub'
                    color='primary'
                  />
                }
                label='Eatclub'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={menuWhitelabel}
                    onChange={(event) => setMenuWhitelabel(event.target.checked)}
                    name='whitelabel'
                    color='primary'
                  />
                }
                label='White Label'
              />
            </FormGroup>
            {Boolean(menuPlatformError) && (
              <FormHelperText error>{menuPlatformError}</FormHelperText>
            )}
          </FormControl> */}

          <FormControl fullWidth component='fieldset' margin='normal'>
            <FormLabel component='legend'>Menu Type</FormLabel>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={menuTakeaway}
                    onChange={(event) => setMenuTakeaway(event.target.checked)}
                    name='takeaway'
                    color='primary'
                  />
                }
                label='Takeaway'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={menuDinein}
                    onChange={(event) => setMenuDinein(event.target.checked)}
                    name='dinein'
                    color='primary'
                  />
                }
                label='Dine In'
              />
            </FormGroup>
            {Boolean(menuTypeError) && <FormHelperText error>{menuTypeError}</FormHelperText>}
          </FormControl>

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color='primary'
                  checked={timeLimited}
                  onChange={(event) => setTimeLimited(event.target.checked)}
                />
              }
              label='Time Limited'
            />
          </FormGroup>

          {timeLimited && (
            <>
              <TextFieldTimePicker
                // className={classes.formControl}
                label='Start time'
                value={startTime ? formatTime(startTime) : ''}
                onChange={handleStartTimeChange}
                error={Boolean(startTimeError)}
                helperText={startTimeError}
              />

              <TextFieldTimePicker
                // className={classes.formControl}
                label='End time'
                value={endTime ? formatTime(endTime) : ''}
                onChange={handleEndTimeChange}
                error={Boolean(endTimeError)}
                helperText={endTimeError}
              />
            </>
          )}
        </form>
        <DialogContentText id='alert-dialog-description'>
          Please note copying a menu will save all your current changes.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color='default'>
          Cancel
        </Button>
        <Button onClick={submit} color='primary' type='submit' form='copy-menu-form'>
          Copy Menu
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  menus: state.menus,
  activeRestaurantId: state.activeRestaurant.data.objectId,
  activeRestaurant: state.activeRestaurant,
  products: state.products,
  categories: state.categories,
  options: state.options,
  choices: state.choices,
  bundles: state.bundles,
  bundleOptions: state.bundleOptions,
  save: state.save,
  menuVersion: state.menuVersion,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      copyMenuAction,
      dbSaveAllAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MenuCopyDialog);
