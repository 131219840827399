import * as type from './types';
import { menuItemsForMenu } from '../graphql/queries';
import { devLog } from '../helpers/devLog';
import { makeApiCall } from './actions';

export const fetchMenuItemsAction = (menuId) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: `MENU_ITEMS_${menuId}`,
  });

  dispatch({
    type: type.FETCH_MENU_ITEMS_PENDING,
  });

  (async () => {
    try {
      const response = await makeApiCall(menuItemsForMenu, {
        menuId,
        pageSize: 500,
        offset: 0,
      });

      devLog('success', 'menu items for menu', response.data.menuItemsForMenu);

      dispatch({
        type: type.FETCH_MENU_ITEMS_SUCCESS,
        payload: response.data.menuItemsForMenu,
      });
    } catch (error) {
      devLog('error', 'menu items for menu', error);

      dispatch({
        type: type.SET_TOAST,
        payload: {
          id: `FETCH_MENU_ITEMS_${new Date().getTime()}`,
          message: `Unable to fetch menu items: ${error}`,
          type: 'error',
        },
      });

      dispatch({
        type: type.FETCH_MENU_ITEMS_FAILURE,
        payload: `Unable to retrieve MENU ITEMS: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: `MENU_ITEMS_${menuId}`,
      });
    }
  })();
};

/*
 * MENU ITEMS
 */

export const createMenuItemAction =
  (
    name,
    price,
    desc,
    index,
    category,
    menuId,
    menuItemId,
    imageLink,
    image,
    noDiscount,
    disableDate,
    disableUntilDate,
  ) =>
  (dispatch) => {
    dispatch({
      type: type.CREATE_MENU_ITEM,
      payload: {
        name,
        price,
        desc,
        index,
        category,
        menuId,
        menuItemId,
        imageLink,
        image,
        noDiscount,
        disableDate,
        disableUntilDate,
      },
    });
  };

export const updateMenuItemAction =
  (objectId, name, price, desc, imageLink, image, noDiscount, disableDate, disableUntilDate) =>
  (dispatch) => {
    dispatch({
      type: type.UPDATE_MENU_ITEM,
      payload: {
        objectId,
        name,
        price,
        desc,
        imageLink,
        image,
        noDiscount,
        disableDate,
        disableUntilDate,
      },
    });
  };

export const deleteMenuItemAction = (menuItemId, category) => (dispatch) => {
  dispatch({
    type: type.DELETE_MENU_ITEM,
    payload: { menuItemId, category },
  });
};

export const clearMenuItemsAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_MENU_ITEMS,
  });
};

export const resetMenuItemsAction = () => (dispatch) => {
  dispatch({
    type: type.RESET_MENU_ITEMS,
  });
};

export const disableMenuItemAction = (menuItemId, disableDate) => (dispatch) => {
  dispatch({
    type: type.DISABLE_MENU_ITEM,
    payload: { menuItemId, disableDate },
  });
};

export const disableMenuItemUntilDateAction = (menuItemId, disableUntilDate) => (dispatch) => {
  dispatch({
    type: type.DISABLE_MENU_ITEM_UNTIL_DATE,
    payload: { menuItemId, disableUntilDate },
  });
};

export const saveMenuItemsLocalAction = (state, menuId) => (dispatch) => {
  dispatch({
    type: type.SAVE_MENU_ITEMS_LOCAL,
    payload: state,
  });
};

/*
 * CATEGORIES
 */

export const createCategoryAction = (newCategory, menuId) => (dispatch) => {
  dispatch({
    type: type.CREATE_MENU_ITEM_CATEGORY,
    payload: { newCategory, menuId },
  });
};

export const updateCategoryAction = (category, newCategory) => (dispatch) => {
  dispatch({
    type: type.UPDATE_MENU_ITEM_CATEGORY,
    payload: { category, newCategory },
  });
};

export const deleteCategoryAction = (category) => (dispatch) => {
  dispatch({
    type: type.DELETE_MENU_ITEM_CATEGORY,
    payload: { category },
  });
};
