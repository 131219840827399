import React, { useState } from 'react';
import { Box, Button, CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Publish } from '@material-ui/icons';
import ImportOptionsDialog from '../importOptionsDialog/ImportOptionsDialog';

const ImportOptions = ({ importOptions, menuItemId }) => {
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <Box display='flex' justifyContent='flex-end'>
      <Button
        variant='outlined'
        color='secondary'
        style={{ marginTop: '1rem' }}
        onClick={() => setOpenDialog(true)}
        disabled={importOptions.fetching}
        startIcon={importOptions.fetching ? null : <Publish />}
      >
        {importOptions.fetching ? <CircularProgress size={22} color='inherit' /> : 'Import Options'}
      </Button>
      <ImportOptionsDialog
        menuItemId={menuItemId}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
      />
    </Box>
  );
};

const mapStateToProps = (state) => ({ importOptions: state.importOptions });
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ImportOptions);
