import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ToastHandler from './components/v2/components/ToastHandler/ToastHandler';
import SwitchRoutes from './routes/SwitchRoutes';
import useStyles from './AppStyles';
import Header from './components/header/Header';
import Body from './components/body/Body';
import LoadingBackdrop from './components/loadingBackdrop/LoadingBackdrop';
import { setUserAction } from './actions/authAction';
import './App.css';
import { devLog } from './helpers/devLog';

const App = ({ setUserAction }) => {
  const classes = useStyles();

  useEffect(() => {
    // Extract objectId from the User in the User Pool
    Auth.currentAuthenticatedUser({ bypassCache: true })
      .then((response) => {
        if (!response) {
          return null;
        }

        const authUserId = response.attributes['custom:objectId'];
        const authEmail = response.attributes.email;
        if (authUserId) {
          setUserAction(authUserId, authEmail);
          return null;
        }

        devLog('error', '', "User 'objectId' is not set!");
        return null;
      })
      .catch((error) => {
        devLog('error', 'User is not signed in', error);
      });
  }, [setUserAction]);

  return (
    <Router>
      <div className={classes.root}>
        <Header />
        <Body>
          <SwitchRoutes />
          <ToastHandler />
        </Body>
        <LoadingBackdrop />
      </div>
    </Router>
  );
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ setUserAction }, dispatch);

export default connect(null, mapDispatchToProps)(App);
