import React from 'react';
import { Button } from '@eatclub-apps/ec-component-library';

export const TextButton = ({ children, style, ...props }) => (
  <Button
    {...props}
    type='text'
    style={{
      button: {
        fontWeight: 'normal',
        padding: '10px 20px',
        fontSize: '14px',
        ...style?.button,
      },
      ...style,
    }}
  >
    {children}
  </Button>
);
