/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Box, Paper, Button, IconButton } from '@material-ui/core';
import ProductEditDialog from '../menuProduct/ProductEditDialog';
import MenuCategory from './MenuCategory';
import {
  removeBundleFromCategoryAction,
  removeProductFromCategoryAction,
  updateCategoryAction,
} from '../../../../actions/categoriesAction';
import useStyles from './MenuCategoriesStyles';
import MultiProductEditDialog from '../menuProduct/MultiProductEditDialog';
import MultiBundleEditDialog from '../menuBundle/MultiBundleEditDialog';
import { dialogContexts, itemTypes } from '../../../../constants/constants';
import { insertItemInArrayAtIndex } from '../../../../helpers/ArrayUtils';
import { ReactComponent as IconCancel } from '../../../../assets/icon_cancel.svg';
import { arraysMatch, parseJson } from '../../../../helpers/Helpers';
import { reviewProductAction } from '../../../../actions/productsAction';
import { reviewBundleAction } from '../../../../actions/bundlesAction';

const MenuCategories = ({
  removeProductFromCategoryAction,
  removeBundleFromCategoryAction,
  updateCategoryAction,
  categories,
  menuVersion,
  reviewProduct,
  reviewBundle,
}) => {
  const classes = useStyles();

  // Menu items with checkboxes selected
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [showAddOptionsDialog, setShowAddOptionsDialog] = useState(false);
  const [sortedCategories, setSortedCategories] = useState(categories);

  const [showProductEditDialog, setShowProductEditDialog] = useState(null);
  const [editingProduct, setEditingProduct] = useState(null);

  const decimalCount = (num) => {
    const numStr = String(num);
    if (numStr.includes('.')) {
      return numStr.split('.')[1].length;
    }
    return 0;
  };

  // Resort the categories when they change. Note: Categories change if products change or are added
  useEffect(() => {
    const updatedCategories = [...categories].sort((a, b) => a.categoryIndex - b.categoryIndex);
    setSortedCategories(updatedCategories);
  }, [categories]);

  const getNewIndex = (categories, destinationIndex) => {
    let prevIndex = 0;
    let nextIndex = categories.length;

    const prevItem = categories[destinationIndex - 1];
    if (prevItem) {
      prevIndex = prevItem.categoryIndex;
    } else {
      return 0;
    }

    const nextItem = categories[destinationIndex + 1];
    if (nextItem) {
      nextIndex = nextItem.categoryIndex;
    }

    return (prevIndex + nextIndex) / 2;
  };

  const onDragEnd = (result) => {
    const { destination, source, type } = result;

    if (!destination) {
      return;
    }

    // dropped in same position
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    if (type === 'category') {
      const newCategories = Array.from(sortedCategories);

      // Remove category being dragged, and store it
      const dragCategory = {
        ...newCategories.splice(source.index, 1)[0],
        _altered: true,
      };

      // Add dragged category back at the new index
      newCategories.splice(destination.index, 0, dragCategory);

      let indexRefresh = false;
      const newIndex = getNewIndex(newCategories, destination.index);

      if (decimalCount(newIndex) > 2) {
        indexRefresh = true;
      }

      if (newIndex === 0) {
        const nextIndex = newCategories[2] ? newCategories[2].categoryIndex / 2 : 1;

        if (decimalCount(nextIndex) > 2) {
          indexRefresh = true;
        }

        if (!indexRefresh) {
          updateCategoryAction(
            newCategories[1].objectId,
            newCategories[1].categoryTitle,
            nextIndex,
            newCategories[1].menuId,
            newCategories[1].productIds,
            newCategories[1].bundleIds,
          );
        }
      }

      if (indexRefresh) {
        newCategories?.forEach((category, index) =>
          updateCategoryAction(
            category.objectId,
            category.categoryTitle,
            index,
            category.menuId,
            category.productIds,
            category.bundleIds,
          ),
        );
      } else {
        updateCategoryAction(
          dragCategory.objectId,
          dragCategory.categoryTitle,
          newIndex,
          dragCategory.menuId,
          dragCategory.productIds,
          dragCategory.bundleIds,
        );
      }

      setSortedCategories(newCategories);
      return;
    }

    const startCategory = sortedCategories.find((cat) => cat.objectId === source.droppableId);

    const finishCategory = sortedCategories.find((cat) => cat.objectId === destination.droppableId);

    /*
     * Re-order Menu items and bundles - within same category
     */

    if (startCategory.objectId === finishCategory.objectId) {
      const newCategoryProducts = finishCategory?.productIds?.map((productId) => ({
        objectId: productId,
        type: itemTypes.PRODUCT,
      }));

      const newCategoryBundles = finishCategory?.bundleIds?.map((bundle) => {
        const parsed = parseJson(bundle);

        return {
          objectId: parsed?.bundleId,
          bundleIndex: parsed?.index,
          type: itemTypes.BUNDLE,
        };
      });

      let newCategoryItems = newCategoryProducts;
      newCategoryBundles?.forEach((bundle) => {
        newCategoryItems = insertItemInArrayAtIndex(newCategoryItems, bundle.bundleIndex, bundle);
      });

      const dragMenuItem = newCategoryItems.splice(source.index, 1)[0];
      newCategoryItems.splice(destination.index, 0, dragMenuItem);

      const productIds = [];
      const bundleIds = [];

      newCategoryItems.forEach((item, index) => {
        if (item.type === itemTypes.PRODUCT) {
          productIds.push(item.objectId);
        }

        if (item.type === itemTypes.BUNDLE) {
          bundleIds.push(JSON.stringify({ bundleId: item.objectId, index }));
        }
      });

      updateCategoryAction(
        startCategory.objectId,
        startCategory.categoryTitle,
        startCategory.categoryIndex,
        startCategory.menuId,
        productIds,
        bundleIds,
      );

      return;
    }

    /*
     * Re-order Menu items and bundles - within different categories
     */

    const newStartCategoryProducts = startCategory?.productIds?.map((productId) => ({
      objectId: productId,
      type: itemTypes.PRODUCT,
    }));

    const newStartCategoryBundles = startCategory?.bundleIds?.map((bundle) => {
      const parsed = parseJson(bundle);

      return {
        objectId: parsed?.bundleId,
        bundleIndex: parsed?.index,
        type: itemTypes.BUNDLE,
      };
    });

    let newStartCategoryItems = newStartCategoryProducts || [];
    newStartCategoryBundles?.forEach((bundle) => {
      newStartCategoryItems = insertItemInArrayAtIndex(
        newStartCategoryItems,
        bundle.bundleIndex,
        bundle,
      );
    });

    const newFinishCategoryProducts = finishCategory?.productIds?.map((productId) => ({
      objectId: productId,
      type: itemTypes.PRODUCT,
    }));

    const newFinishCategoryBundles = finishCategory?.bundleIds
      ?.map((bundle) => parseJson(bundle))
      ?.map((bundleIdsWithIndexes) => ({
        objectId: bundleIdsWithIndexes?.bundleId,
        bundleIndex: bundleIdsWithIndexes?.index,
        type: itemTypes.BUNDLE,
      }));

    let newFinishCategoryItems = newFinishCategoryProducts || [];
    newFinishCategoryBundles?.forEach((bundle) => {
      newFinishCategoryItems = insertItemInArrayAtIndex(
        newFinishCategoryItems,
        bundle.bundleIndex,
        bundle,
      );
    });

    // Remove menu item being dragged, and store it
    const dragMenuItem = newStartCategoryItems.splice(source.index, 1)[0];

    // Dropped in a category that already contains this product
    if (
      destination.droppableId !== source.droppableId &&
      (finishCategory.productIds?.includes(dragMenuItem.objectId) ||
        finishCategory.bundleIds
          ?.map((bundleId) => parseJson(bundleId)?.bundleId)
          ?.includes(dragMenuItem.objectId))
    ) {
      return;
    }

    // Add dragged menu item back at the new index
    newFinishCategoryItems.splice(destination.index, 0, dragMenuItem);

    let startProductIds = dragMenuItem.type === itemTypes.PRODUCT ? [] : null;
    let startBundleIds = dragMenuItem.type === itemTypes.BUNDLE ? [] : null;

    newStartCategoryItems.forEach((item, index) => {
      if (item.type === itemTypes.PRODUCT) {
        if (startProductIds == null) {
          startProductIds = [];
        }
        startProductIds.push(item.objectId);
      }

      if (item.type === itemTypes.BUNDLE) {
        if (startBundleIds == null) {
          startBundleIds = [];
        }
        startBundleIds.push(JSON.stringify({ bundleId: item.objectId, index }));
      }
    });

    let finishProductIds = null;
    let finishBundleIds = null;

    newFinishCategoryItems.forEach((item, index) => {
      if (item.type === itemTypes.PRODUCT) {
        if (finishProductIds == null) {
          finishProductIds = [];
        }
        finishProductIds.push(item.objectId);
      }

      if (item.type === itemTypes.BUNDLE) {
        if (finishBundleIds == null) {
          finishBundleIds = [];
        }
        finishBundleIds.push(JSON.stringify({ bundleId: item.objectId, index }));
      }
    });

    const updatedCategories = [
      {
        ...startCategory,
        productIds: startProductIds,
        bundleIds: startBundleIds,
      },
      {
        ...finishCategory,
        productIds: finishProductIds,
        bundleIds: finishBundleIds,
      },
    ];

    updatedCategories?.forEach((category) => {
      updateCategoryAction(
        category.objectId,
        category.categoryTitle,
        category.categoryIndex,
        category.menuId,
        category.productIds,
        category.bundleIds,
      );
    });
  };

  useEffect(() => {
    if (selectedItems?.length === 0) {
      setSelectedType(null);
    }
  }, [selectedItems]);

  const selectItem = useCallback(
    (itemId, categoryId, selected, type) => {
      setSelectedType(type);

      const isSelected =
        selectedItems?.filter((item) => item.itemId === itemId && item.category === categoryId)
          ?.length > 0;

      if (selected && !isSelected) {
        setSelectedItems([...selectedItems, { itemId, categoryId }]);
      }

      // if deselected
      if (!selected) {
        setSelectedItems(
          selectedItems.filter(
            (item) => !(item.itemId === itemId && categoryId === item.categoryId),
          ),
        );
      }
    },
    [selectedItems],
  );

  /**
   * Select every item for this category
   */
  const selectAllItems = useCallback(
    (category) => {
      let newSelectedItems = [];
      category?.productIds?.forEach((product) => {
        const isSelected =
          selectedItems?.filter((selectedItem) => selectedItem.itemId === product)?.length > 0;
        if (!isSelected) {
          newSelectedItems = [
            ...newSelectedItems,
            { itemId: product, categoryId: category.objectId },
          ];
        }
      });

      setSelectedType(itemTypes.PRODUCT);
      setSelectedItems(newSelectedItems);
    },
    [selectedItems],
  );

  const selectAllBundles = useCallback(
    (category) => {
      let newSelectedItems = [];
      category?.bundleIds?.forEach((bundle) => {
        const parsed = parseJson(bundle);

        const isSelected =
          selectedItems?.filter((selectedItem) => selectedItem.itemId === parsed?.bundleId)
            ?.length > 0;

        if (!isSelected) {
          newSelectedItems = [
            ...newSelectedItems,
            { itemId: parsed?.bundleId, categoryId: category.objectId },
          ];
        }
      });

      setSelectedType(itemTypes.BUNDLE);
      setSelectedItems(newSelectedItems);
    },
    [selectedItems],
  );

  const setOpenDialog = useCallback((product) => {
    setEditingProduct(product);
    setShowProductEditDialog(true);
  }, []);

  const onClose = () => {
    setSelectedType(null);
    setSelectedItems([]);
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='categories' type='category'>
          {(provided, snapshot) => (
            <Box
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={`${classes.droppableCategory} ${
                snapshot.isDraggingOver ? 'dragging-over' : ''
              } `}
            >
              {sortedCategories.map((category, index) => (
                <MenuCategory
                  key={category.objectId}
                  index={index}
                  category={category}
                  selectedItems={selectedItems}
                  selectItem={selectItem}
                  selectAllItems={selectAllItems}
                  selectAllBundles={selectAllBundles}
                  selectedType={selectedType}
                  setOpenDialog={setOpenDialog}
                />
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      {selectedItems.length > 0 && (
        <Box className={classes.selectionBoxContainer}>
          <Paper className={classes.selectionBox}>
            <Box
              className={classes.selectionBoxContent}
              display='flex'
              justifyContent='space-between'
            >
              <Box display='inline-flex' gridGap='12px' alignItems='center'>
                <Box style={{ fontSize: '20px' }}>
                  <b>
                    {selectedItems.length} {selectedType === itemTypes.PRODUCT && 'menu item'}
                    {selectedType === itemTypes.BUNDLE && 'bundle'}
                    {selectedItems.length === 1 ? '' : 's'}
                  </b>{' '}
                  selected
                </Box>
                <Box>
                  <IconButton onClick={onClose} style={{ padding: '8px' }}>
                    <IconCancel />
                  </IconButton>
                </Box>
              </Box>
              <Box display='inline-flex' gridGap='8px'>
                {menuVersion.posEnabled && (
                  <Button
                    onClick={() => {
                      if (selectedType === itemTypes.PRODUCT) {
                        selectedItems.forEach((item) => {
                          reviewProduct(item.itemId, item.categoryId);
                        });
                      }

                      if (selectedType === itemTypes.BUNDLE) {
                        selectedItems.forEach((item) => {
                          reviewBundle(item.itemId, item.categoryId);
                        });
                      }

                      onClose();
                    }}
                    color='primary'
                  >
                    Mark reviewed
                  </Button>
                )}
                <Button
                  onClick={() => {
                    selectedItems.forEach((item) => {
                      if (selectedType === itemTypes.PRODUCT) {
                        removeProductFromCategoryAction(item.itemId, item.categoryId);
                      }
                      if (selectedType === itemTypes.BUNDLE) {
                        removeBundleFromCategoryAction(item.itemId, item.categoryId);
                      }
                    });

                    onClose();
                  }}
                  color='primary'
                >
                  Remove from menu
                </Button>

                {!menuVersion.posEnabled && (
                  <>
                    <Button onClick={() => setShowAddOptionsDialog(true)} color='primary'>
                      Edit options
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          </Paper>
        </Box>
      )}

      <ProductEditDialog
        openDialog={showProductEditDialog}
        dialogContext={dialogContexts.EDIT}
        setOpenDialog={setShowProductEditDialog}
        product={editingProduct}
      />

      <MultiProductEditDialog
        openDialog={selectedType === itemTypes.PRODUCT && showAddOptionsDialog}
        setOpenDialog={setShowAddOptionsDialog}
        selectedProducts={
          selectedType === itemTypes.PRODUCT ? selectedItems?.map((item) => item?.itemId) : []
        }
      />

      <MultiBundleEditDialog
        openDialog={selectedType === itemTypes.BUNDLE && showAddOptionsDialog}
        setOpenDialog={setShowAddOptionsDialog}
        selectedBundles={
          selectedType === itemTypes.BUNDLE ? selectedItems?.map((item) => item?.itemId) : []
        }
      />
    </>
  );
};

MenuCategories.defaultProps = {
  categories: [],
};

MenuCategories.propTypes = {
  categories: PropTypes.shape({}),
};

const mapStateToProps = (state) => ({
  menuVersion: state.menuVersion,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      removeProductFromCategoryAction,
      removeBundleFromCategoryAction,
      updateCategoryAction,
      reviewProduct: reviewProductAction,
      reviewBundle: reviewBundleAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MenuCategories);
