import * as type from './types';
import { devLog } from '../helpers/devLog';
import {
  createBundleOptions,
  removeBundleOptions,
  updateBundleOptions,
} from '../graphql/mutations';
import { removeEmpty } from '../helpers/Helpers';
import { makeApiCall } from './actions';

export const createBundleOptionAction =
  (
    objectId,
    bundleOptionTitle,
    bundleOptionDescription,
    minSelections,
    maxSelections,
    mandatory,
    restaurantId,
    posId,
    productIds,
  ) =>
  (dispatch) => {
    dispatch({
      type: type.CREATE_BUNDLE_OPTION,
      payload: {
        objectId,
        bundleOptionTitle,
        bundleOptionDescription,
        minSelections,
        maxSelections,
        mandatory,
        restaurantId,
        posId,
        productIds,
      },
    });
  };

export const updateBundleOptionAction =
  (
    objectId,
    title,
    description,
    minSelections,
    maxSelections,
    mandatory,
    restaurantId,
    posId,
    productIds,
    importStatus,
  ) =>
  (dispatch) => {
    dispatch({
      type: type.UPDATE_BUNDLE_OPTION,
      payload: {
        objectId,
        title,
        description,
        minSelections,
        maxSelections,
        mandatory,
        restaurantId,
        posId,
        productIds,
        importStatus,
      },
    });
  };

export const deleteBundleOptionAction = (bundleOptionId) => (dispatch) => {
  dispatch({
    type: type.DELETE_BUNDLE_OPTION,
    payload: { bundleOptionId },
  });
};

export const addProductToBundleOptionAction = (productId, bundleOptionId) => (dispatch) => {
  dispatch({
    type: type.ADD_PRODUCT_TO_BUNDLE_OPTION,
    payload: { productId, bundleOptionId },
  });
};

export const addProductsToBundleOptionAction = (productIds) => (dispatch) => {
  dispatch({
    type: type.ADD_PRODUCTS_TO_BUNDLE_OPTION,
    payload: { productIds },
  });
};

export const removeProductFromBundleOptionAction = (productId) => (dispatch) => {
  dispatch({
    type: type.REMOVE_PRODUCT_FROM_BUNDLE_OPTION,
    payload: { productId },
  });
};

export const clearProductsFromBundleOptionAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_PRODUCTS_FROM_BUNDLE_OPTION,
  });
};

export const dbRemoveBundleOptions = async (bundleOptionIds, restaurantId, dispatch) => {
  try {
    const response = await makeApiCall(removeBundleOptions, {
      restId: restaurantId,
      bundleOptionIds,
    });

    devLog('success', 'remove bundle options', response.data.removeBundleOptions);

    return null;
  } catch (error) {
    devLog('error', 'unable to remove bundle options', error);

    dispatch({
      type: type.SET_TOAST,
      payload: {
        id: `BUNDLE_OPTIONS_REMOVE_${new Date().getTime()}`,
        message: `Unable to remove bundle options: ${error}`,
        type: 'error',
      },
    });
    return error;
  }
};

export const dbCreateBundleOptions = async (bundleOptions, restaurantId, dispatch) => {
  try {
    const response = await makeApiCall(createBundleOptions, {
      options: bundleOptions.map((bundleOption) =>
        removeEmpty({
          bundleOptionTitle: bundleOption?.bundleOptionTitle,
          bundleOptionDescription: bundleOption?.bundleOptionDescription,
          mandatory: bundleOption?.mandatory,
          minSelections: bundleOption?.minSelections,
          maxSelections: bundleOption?.maxSelections,
          restId: restaurantId,
          importStatus: bundleOption?.importStatus,
          productIds: bundleOption?.productIds,
        }),
      ),
    });

    if (
      response?.data?.createBundleOptions === undefined ||
      response?.data?.createBundleOptions === null
    ) {
      throw new Error('Response is undefined');
    }

    devLog('success', 'create bundle options', response.data.createBundleOptions);

    return response?.data?.createBundleOptions?.map((createdBundleOption, index) => {
      dispatch({
        type: type.BACKEND_SAVE_BUNDLE_OPTION_SUCCESS,
        payload: {
          savedItem: createdBundleOption,
          itemObjectId: bundleOptions[index]._objectId,
        },
      });

      // Also return the original objectId
      return { ...createdBundleOption, _objectId: bundleOptions[index]._objectId };
    });
  } catch (error) {
    devLog('error', 'unable to create bundle options', error);

    dispatch({
      type: type.SET_TOAST,
      payload: {
        id: `BUNDLE_OPTIONS_CREATE_${new Date().getTime()}`,
        message: `Unable to create bundle options: ${error}`,
        type: 'error',
      },
    });
    return error;
  }
};

export const dbUpdateBundleOptions = async (bundleOptions, restaurantId, dispatch) => {
  try {
    const response = await makeApiCall(updateBundleOptions, {
      restId: restaurantId,
      options: bundleOptions.map((bundleOption) =>
        removeEmpty({
          objectId: bundleOption?.objectId,
          bundleOptionTitle: bundleOption?.bundleOptionTitle,
          bundleOptionDescription: bundleOption?.bundleOptionDescription,
          mandatory: bundleOption?.mandatory,
          minSelections: bundleOption?.minSelections,
          maxSelections: bundleOption?.maxSelections,
          importStatus: bundleOption?.importStatus,
          productIds: bundleOption?.productIds,
        }),
      ),
    });

    if (
      response?.data?.updateBundleOptions === undefined ||
      response?.data?.updateBundleOptions === null
    ) {
      throw new Error('Response is undefined');
    }

    devLog('success', 'update bundle options', response.data.updateBundleOptions);

    return response.data.updateBundleOptions?.map((updatedBundleOption, index) => {
      dispatch({
        type: type.BACKEND_SAVE_BUNDLE_OPTION_SUCCESS,
        payload: {
          savedItem: updatedBundleOption,
          itemObjectId: bundleOptions[index]._objectId,
        },
      });

      // Also return the original objectId
      return { ...updatedBundleOption, _objectId: bundleOptions[index]._objectId };
    });
  } catch (error) {
    devLog('error', 'unable to update bundle options', error);

    dispatch({
      type: type.SET_TOAST,
      payload: {
        id: `BUNDLE_OPTIONS_UPDATE_${new Date().getTime()}`,
        message: `Unable to update bundle options: ${error}`,
        type: 'error',
      },
    });
    return error;
  }
};
