import React, { useState } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Skeleton } from '@material-ui/lab';
import { PlaylistAdd } from '@material-ui/icons';
import { dialogContexts } from '../../../../constants/constants';
import { TextButton } from '../Button/TextButton';
import useStyles from './BundleOptionsStyles';
import BundleOption from './BundleOption';
import BundleOptionEditDialog from './BundleOptionEditDialog';

const OptionsLoading = () => (
  <List>
    <ListItem>
      <ListItemAvatar>
        <Skeleton variant='circle' width={40} height={40} />
      </ListItemAvatar>
      <ListItemText>
        <Skeleton height={10} width='100%' style={{ marginBottom: 6 }} />
        <Skeleton height={10} width='80%' />
      </ListItemText>
    </ListItem>
  </List>
);

const BundleOptions = ({ bundleOptions, menuVersion }) => {
  const classes = useStyles();

  const [dialogContext, setDialogContext] = useState('');
  const [selectedOptionId, setSelectedOptionId] = useState(false);

  const lastOption = bundleOptions.localData?.slice(-1)[0];
  const [openDialog, setOpenDialog] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);

  const selectItem = (itemId, selected) => {
    const isSelected = selectedItems?.filter((item) => item === itemId).length > 0;
    if (selected && !isSelected) {
      setSelectedItems([...selectedItems, itemId]);
    }

    // if deselected
    if (!selected) {
      setSelectedItems(selectedItems.filter((item) => item.itemId !== itemId));
    }
  };

  /**
   * Select every item
   */
  const selectAllItems = () => {
    let newSelectedItems = selectedItems;
    bundleOptions.localData.forEach((option) => {
      const isSelected =
        selectedItems?.filter((selectedItem) => selectedItem === option.objectId)?.length > 0;
      if (!isSelected) {
        newSelectedItems = [...newSelectedItems, option.objectId];
      }
    });

    setSelectedItems(newSelectedItems);
  };

  const onSelect = (optionId, selected) => {
    selectItem(optionId, selected);
  };

  const onSelectAll = () => {
    selectAllItems();
  };

  return (
    <>
      <Paper style={{ marginTop: '1rem' }}>
        <List
          dense={false}
          subheader={
            <ListSubheader
              component='div'
              id='category-list-subheader'
              color='primary'
              className={classes.listSubheader}
            >
              <Box display='flex' alignItems='center' justifyContent='space-between'>
                <span>
                  {bundleOptions.localData?.length === 0
                    ? 'No bundle options yet'
                    : 'All bundle options'}
                </span>
                {/* <ProductGroupActionButton selectAllItems={onSelectAll} /> */}
              </Box>
            </ListSubheader>
          }
        >
          {bundleOptions.localData?.map((bundleOption, index) => (
            <BundleOption
              key={bundleOption?.objectId}
              bundleOption={bundleOption}
              index={index}
              selected={selectedItems?.filter((item) => item === bundleOption.objectId)?.length > 0}
              onSelect={(value) => onSelect(bundleOption.objectId, value)}
            />
          ))}
        </List>
      </Paper>

      {!menuVersion.posEnabled && (
        <Paper style={{ marginTop: '1rem' }}>
          <>
            <ListItem button onClick={() => setOpenDialog(true)}>
              <ListItemIcon>
                <PlaylistAdd />
              </ListItemIcon>
              <ListItemText
                primary='Create new bundle option'
                primaryTypographyProps={{ color: 'textSecondary', variant: 'button' }}
              />
            </ListItem>

            <BundleOptionEditDialog
              openDialog={openDialog}
              setOpenDialog={setOpenDialog}
              dialogContext={dialogContexts.CREATE}
              bundleOption={{}}
            />
          </>
        </Paper>
      )}
    </>
  );
};

BundleOptions.propTypes = {};

const mapStateToProps = (state) => ({
  bundleOptions: state.bundleOptions,
  menuVersion: state.menuVersion,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BundleOptions);
