import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  columnTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  tableCell: {
    color: '#2E2E2E',
    fontSize: '18px',
  },
}));

export default useStyles;
