import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Box, Grid, Typography } from '@material-ui/core';
import { TabbedContent } from '@eatclub-apps/ec-component-library';
import {
  fetchActiveChangeRequestsAction,
  fetchCompletedChangeRequestsAction,
} from '../../actions/changeRequestsAction';
import ChangeRequests from '../changeRequests/ChangeRequests';
import RestaurantFinder from './RestaurantFinder';
import RestaurantsUpdated from './RestaurantsUpdated';

const SelectRestaurant = ({
  activeChangeRequests,
  completedChangeRequests,
  fetchActiveChangeRequests,
  fetchCompletedChangeRequests,
}) => {
  useEffect(() => {
    fetchActiveChangeRequests();
    fetchCompletedChangeRequests();
  }, []);

  return (
    <div>
      <Grid style={{ marginTop: '0' }} container spacing={9}>
        <Grid item xs={12} sm={8}>
          <Box maxWidth='480px'>
            <Typography variant='h2'>Find Your Restaurant</Typography>
            <RestaurantFinder />
          </Box>
          <Box mt='50px'>
            <Typography variant='h2'>Integrated menu change requests</Typography>
            <TabbedContent
              tabs={[
                {
                  label: 'To action',
                  component: <ChangeRequests changeRequests={activeChangeRequests} canBeActioned />,
                },
                {
                  label: 'Completed',
                  component: (
                    <ChangeRequests
                      changeRequests={completedChangeRequests}
                      canBeActioned={false}
                    />
                  ),
                },
              ]}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant='h2'>Last Updated</Typography>
          <RestaurantsUpdated />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeChangeRequests: state.activeChangeRequests,
  completedChangeRequests: state.completedChangeRequests,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchActiveChangeRequests: fetchActiveChangeRequestsAction,
      fetchCompletedChangeRequests: fetchCompletedChangeRequestsAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SelectRestaurant);
