import React from 'react';
import {
  ListItem,
  ListItemIcon,
  Box,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core';
import { Draggable } from 'react-beautiful-dnd';
import { Delete } from '@material-ui/icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ReactComponent as Icon } from '../../../../assets/two-bars-icon.svg';
import { removeOptionFromProductAction } from '../../../../actions/productsAction';
import { removeSpaces } from '../../../../helpers/Helpers';
import useStyles from './ProductOptionStyles';

const ProductOption = ({
  removeOptionFromProductAction,
  index,
  option,
  setDialogContext,
  setOpenDialog,
  setSelectedOptionId,
  labelledChoices,
  menuVersion,
  product,
}) => {
  const classes = useStyles();

  const handleRemove = () => {
    removeOptionFromProductAction(option.objectId);
  };

  return (
    <Draggable draggableId={`${removeSpaces(option.objectId)}`} index={index}>
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={`${classes.draggableMenuItemOption} ${snapshot.isDragging ? 'dragging' : ''} ${
            snapshot.isDropAnimating ? 'animating' : ''
          }`}
          boxShadow={snapshot.isDragging ? 3 : 0}
        >
          <ListItem
            button
            onClick={() => {
              setSelectedOptionId(option.objectId);
              setOpenDialog(true);
              setDialogContext('Edit');
            }}
          >
            <ListItemIcon title='Drag to re-order' {...provided.dragHandleProps}>
              <Icon style={{ fill: 'rgba(0, 0, 0, 0.54)' }} />
            </ListItemIcon>
            {menuVersion.posEnabled && product?.importStatus !== null && (
              <Box
                className={classes.reviewIcon}
                style={{
                  backgroundColor: option?.importStatus === 'needsReview' ? '#F39A47' : '#77C76A',
                }}
              />
            )}
            <ListItemText primary={option?.optionTitle} secondary={option?.optionDescription} />
            <ListItemText
              secondary={labelledChoices}
              style={{ maxWidth: '150px', textAlign: 'left' }}
            />
            <ListItemSecondaryAction>
              <IconButton
                edge='end'
                aria-label='delete'
                onClick={(e) => {
                  e.preventDefault();
                  handleRemove();
                }}
                title='Remove option from item'
              >
                <Delete />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </Box>
      )}
    </Draggable>
  );
};

const mapStateToProps = (state) => ({
  menuVersion: state.menuVersion,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ removeOptionFromProductAction }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ProductOption);
