import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Box,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { updateMenuItemAction } from '../../actions/menuItemsAction';
import MenuItemOptions from '../menuItemOptions/MenuItemOptions';
import {
  cancelMenuItemOptionsAction,
  clearInitialMenuItemOptionsAction,
} from '../../actions/menuItemOptionsAction';
import ImportOptions from '../importOptions/ImportOptions';
import MenuItemImageUpload from '../menuItemImageUpload/MenuItemImageUpload';
import InfoPopover from '../InfoPopover/InfoPopover';

const MenuItemEditDialog = ({
  cancelMenuItemOptionsAction,
  clearInitialMenuItemOptionsAction,
  menuItem,
  menuItems,
  openDialog,
  setOpenDialog,
  updateMenuItemAction,
}) => {
  const [save, setSave] = useState(false);
  const [name, setName] = useState(menuItem.itemTitle || null);
  const [nameError, setNameError] = useState('');

  const [price, setPrice] = useState(menuItem.price || 0);
  const [priceError, setPriceError] = useState('');

  const [desc, setDesc] = useState(menuItem.itemDescription || null);
  const [descError, setDescError] = useState('');

  const [imageLink, setImageLink] = useState(menuItem.imageLink || null);
  const [image, setImage] = useState(menuItem._image || {});

  const [noDiscount, setNoDiscount] = useState(menuItem.noDiscount || false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [disableDate, setDisableDate] = useState(null);

  const [isDisabled, setIsDisabled] = useState(
    moment(menuItem.disableDate, 'yyyy-MM-DD').isSame(moment(), 'day') ||
      moment(menuItem.disableUntilDate, 'yyyy-MM-DD').isAfter(moment(), 'day'),
  );

  const [disableUntilDate, setDisableUntilDate] = useState(
    isDisabled ? menuItem.disableUntilDate : null,
  );

  // Reset disable fields when reopened
  useEffect(() => {
    if (openDialog) {
      setDisableDate(menuItem?.disableDate || null);

      const newIsDisabled =
        moment(menuItem.disableDate, 'yyyy-MM-DD').isSame(moment(), 'day') ||
        moment(menuItem.disableUntilDate, 'yyyy-MM-DD').isAfter(moment(), 'day');

      setIsDisabled(newIsDisabled || false);
      setDisableUntilDate(menuItem?.disableUntilDate || null);
    }
  }, [openDialog]);

  // // Set or unset disable date if the box is checked
  useEffect(() => {
    if (!isDisabled || disableUntilDate) {
      setDisableDate(null);
    } else {
      // if disable option is enabled and no disable date given, set it for today only
      setDisableDate(moment().format('yyyy-MM-DD'));
    }
  }, [isDisabled, disableUntilDate, openDialog]);

  const formatNumber = (num) => (Math.round(num * 100) / 100).toFixed(2);

  const closeDialog = () => {
    setOpenDialog(false);
  };

  const submit = (event) => {
    event.preventDefault();

    let formError = false;

    if (!name) {
      setNameError('Please enter a name.');
      formError = true;
    } else if (nameError) {
      setNameError('');
    }

    if (!price || price === '0.00') {
      setPriceError('Please enter a price.');
      formError = true;
    } else if (priceError) {
      setPriceError('');
    }

    if (formError) {
      return;
    }

    updateMenuItemAction(
      menuItem.objectId,
      name,
      Number(price),
      desc,
      imageLink,
      image,
      noDiscount,
      disableDate,
      disableUntilDate,
    );

    setSave(true);
    closeDialog();
  };

  const objectId = menuItems?.data?.find(
    (apiMenuItems) => apiMenuItems?.objectId === menuItem?.objectId,
  )?.objectId;

  return (
    <Dialog
      open={openDialog}
      onClose={closeDialog}
      fullWidth
      maxWidth='lg'
      onEnter={() => {
        // Reset inputs when entering
        setName(menuItem.itemTitle || null);
        setPrice(menuItem.price || 0);
        setDesc(menuItem.itemDescription || null);
        setImageLink(menuItem.imageLink || null);
        setImage(menuItem._image || {});
        setNoDiscount(menuItem.noDiscount || false);
        setDisableDate(menuItem.disableDate || null);
        setDisableUntilDate(menuItem.disableUntilDate || null);

        setNameError('');
        setPriceError('');
        setDescError('');
        setSave(false);
      }}
      onExited={() => {
        if (!save) {
          // Revert menuItemOptions back to initial state
          cancelMenuItemOptionsAction();
        }
        clearInitialMenuItemOptionsAction();
      }}
    >
      <DialogTitle>Edit Menu Item</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <form onSubmit={submit} id='edit-menu-item-form'>
              <TextField
                id='menu-item-name'
                label='Name'
                placeholder='eg. Mains, Entrees'
                variant='outlined'
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
                onBlur={(e) => setName(e.target.value.trim())}
                InputLabelProps={{ shrink: true }}
                margin='normal'
                required
                error={Boolean(nameError)}
                helperText={nameError}
              />

              <TextField
                id='menu-item-price'
                label='Price'
                placeholder='10.00'
                variant='outlined'
                fullWidth
                value={price}
                type='number'
                onChange={(e) => setPrice(e.target.value)}
                onBlur={(e) => setPrice(formatNumber(e.target.value))}
                InputLabelProps={{ shrink: true }}
                min={0}
                inputProps={{ min: 0, step: 1 }}
                InputProps={{
                  startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                }}
                margin='normal'
                required
                error={Boolean(priceError)}
                helperText={priceError}
              />

              <TextField
                multiline
                id='menu-item-desc'
                label='Description'
                placeholder="eg. Grandma's flaky pie crust filled with roast chicken, baby carrots, and spring peas."
                variant='outlined'
                fullWidth
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
                onBlur={(e) => setDesc(e.target.value.trim())}
                InputLabelProps={{ shrink: true }}
                margin='normal'
                error={Boolean(descError)}
                helperText={descError}
              />

              <MenuItemImageUpload
                itemId={menuItem.objectId}
                imageLink={imageLink}
                setImageLink={setImageLink}
                setImage={setImage}
              />

              <Dialog open={showConfirmation}>
                <Box style={{ padding: '20px' }}>
                  <Box mb='20px'>
                    {' '}
                    Are you sure you want to make this item exempt from discounts? (typically only
                    used for special promotions)
                  </Box>
                  <Button
                    onClick={() => {
                      setShowConfirmation(false);
                      setNoDiscount(true);
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    onClick={() => {
                      setShowConfirmation(false);
                    }}
                  >
                    No
                  </Button>
                </Box>
              </Dialog>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={noDiscount}
                    onChange={(event) =>
                      event.target.checked === true
                        ? setShowConfirmation(true)
                        : setNoDiscount(false)
                    }
                    name='noDiscount'
                    color='primary'
                  />
                }
                label='Exempt from discounts'
              />

              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isDisabled}
                      onChange={(event) => {
                        if (event.target.checked) {
                          setIsDisabled(true);
                        } else {
                          setIsDisabled(false);
                          setDisableUntilDate(null);
                          setDisableDate(null);
                        }
                      }}
                      name='showDisableOption'
                      color='primary'
                    />
                  }
                  label='Disable item'
                />
              </Box>

              {isDisabled && (
                <TextField
                  id='menu-item-disable-until-date'
                  label='Disable until'
                  type='date'
                  value={disableUntilDate}
                  onChange={(e) => setDisableUntilDate(e.target.value)}
                  onBlur={(e) => setDisableUntilDate(e.target.value.trim())}
                  InputLabelProps={{ shrink: true }}
                  variant='outlined'
                  margin='normal'
                />
              )}
            </form>
          </Grid>
          <Grid item md={6} xs={12}>
            <MenuItemOptions menuItemId={menuItem.objectId} />
            <ImportOptions menuItemId={menuItem.objectId} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <InfoPopover
          info={objectId}
          style={{ position: 'absolute', left: 0, right: 0, margin: '0 auto' }}
        />
        <Button onClick={closeDialog} color='default'>
          Cancel
        </Button>
        <Button onClick={submit} color='primary' type='submit' form='edit-menu-item-form' autoFocus>
          Save Item
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  menuItems: state.menuItems,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      cancelMenuItemOptionsAction,
      clearInitialMenuItemOptionsAction,
      updateMenuItemAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemEditDialog);
