import React, { useEffect, useState } from 'react';
import { SecondaryButton } from '../Button/SecondaryButton';
import OptionAddDialog from './OptionAddDialog';

const OptionAdd = ({
  setSelectedOptionId,
  setDialogContext,
  lastOption,
  productId,
  selectedOptions,
  setProductOptions,
}) => {
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <>
      <SecondaryButton
        onClick={() => {
          setOpenDialog(true);
          setSelectedOptionId('Add');
          setDialogContext('Add');
        }}
        style={{ text: { fontWeight: 'bold' } }}
      >
        ADD FROM EXISTING
      </SecondaryButton>
      <OptionAddDialog
        lastOptionIndex={lastOption?.itemIndex || 0}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        productId={productId}
        defaultSelectedItems={selectedOptions}
        setProductOptions={setProductOptions}
      />
    </>
  );
};

export default OptionAdd;
