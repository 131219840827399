import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, List, ListItem, ListItemAvatar, ListItemText, Paper } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Skeleton } from '@material-ui/lab';
import MenuCategories from '../menuCategory/MenuCategories';
import MenuCategoryCreate from '../menuCategory/MenuCategoryCreate';
import ReviewNotificationBanner from '../NotificationBanner/ReviewNotificationBanner';

const CategoriesLoading = () => (
  <List>
    <ListItem>
      <ListItemAvatar>
        <Skeleton variant='circle' width={40} height={40} />
      </ListItemAvatar>
      <ListItemText>
        <Skeleton height={10} width='100%' style={{ marginBottom: 6 }} />
        <Skeleton height={10} width='80%' />
      </ListItemText>
    </ListItem>
  </List>
);

const MenuTabPanel = ({ menuId, categories, menuVersion, products }) => {
  const categoriesForMenu = useMemo(
    () => categories?.localData?.filter((category) => category?.menuId === menuId),
    [categories?.localData],
  );

  return (
    <>
      {categories.fetching ? (
        <Paper style={{ marginTop: '1rem' }}>
          <CategoriesLoading />
        </Paper>
      ) : (
        <>
          {menuVersion.posEnabled && (
            <Box mt='40px'>
              <ReviewNotificationBanner menuId={menuId} />
            </Box>
          )}

          {products?.data.length > 200 && (
            <Box
              mt='40px'
              style={{ padding: '20px', backgroundColor: '#fff8e5', color: '#8d5806' }}
            >
              Note: This is a very large menu. Images and item descriptions are currently hidden to
              improve performance. Clicking a menu item will show the image and description.
            </Box>
          )}

          <Paper style={{ marginTop: '1rem' }}>
            <MenuCategories categories={categoriesForMenu} />
          </Paper>
          <Paper style={{ marginTop: '1rem' }}>
            <MenuCategoryCreate
              menuId={menuId}
              categories={categories.localData.filter((category) => category?.menuId === menuId)}
            />
          </Paper>
        </>
      )}
    </>
  );
};

MenuTabPanel.defaultProps = {
  categories: {},
};

MenuTabPanel.propTypes = {
  categories: PropTypes.shape({}),
};

const mapStateToProps = (state) => ({
  categories: state.categories,
  menuVersion: state.menuVersion,
  products: state.products,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MenuTabPanel);
