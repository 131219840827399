import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Paper, Box, Chip } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { menuTypeMap, menuPlatformMap } from '../../../../constants/constants';
import { minutesToTime } from '../../../../helpers/Helpers';
import useStyles from './MenuTabsStyles';
import { ReactComponent as ActionMenuIcon } from '../../../../assets/action_menu.svg';

const MenuTab = ({ menus, menu, active, showActionMenu }) => {
  const classes = useStyles();

  if (menus.fetching) {
    return <></>;
  }

  const platformText =
    !menu.platform || menu.platform === 'all'
      ? `${menuPlatformMap('eatclub').long} + ${menuPlatformMap('whitelabel').long}`
      : menuPlatformMap(menu.platform).long;

  const typeText =
    !menu.type || menu.type === 'all'
      ? `${menuTypeMap('takeaway').long} + ${menuTypeMap('dinein').long}`
      : menuTypeMap(menu.type).long;

  const isTimeLimited = menu.startTime > -1 && menu.endTime > -1;

  const startTime = minutesToTime(menu.startTime);
  const endTime = minutesToTime(menu.endTime);

  return (
    <Box
      class={classes.menuTab}
      style={{
        alignItems: 'center',
        borderColor: active ? '#E54439' : '#D8D8D8',
        backgroundColor: active ? '#ffffff' : 'inherit',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      {/* {showActionMenu && (
        <Box
          display='flex'
          justifyContent='flex-end'
          onClick={(e) => {
            e.stopPropagation();
            // TODO open action menu
          }}
        >
          <ActionMenuIcon
            className={classes.menuTabActionMenuIcon}
            style={{ color: active ? '#FF1745' : '#9E9E9E' }}
          />
        </Box>
      )} */}
      {/* <Box className={classes.platform}>{platformText}</Box> */}
      <Box className={classes.menuTitle}>{menu.menuTitle}</Box>
      <Chip
        style={{
          backgroundColor: menu.enabled ? '#E54439' : '#FBD283',
          color: menu.enabled ? '#FFFFFF' : '#2E2E2E',
          height: '18px',
          fontSize: '0.625rem',
          textTransform: 'uppercase',
          marginTop: '10px',
          marginBottom: '15px',
        }}
        variant='default'
        size='small'
        label={menu.enabled ? 'Active' : 'Disabled'}
      />
      <Box>
        <Box className={classes.menuTabContent}>
          {isTimeLimited ? `${startTime} - ${endTime}` : 'All day menu'}
        </Box>
        <Box className={classes.menuTabContent}>{typeText}</Box>
      </Box>
    </Box>
  );
};

MenuTab.propTypes = {
  menus: PropTypes.object.isRequired,
  menu: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired,
  showActionMenu: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  menus: state.menus,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MenuTab);
