import React from 'react';
import MenuTabPanel from './MenuTabPanel';

const MenuTabPanelContainer = ({ index, value, menuId, menuVersion }) => (
  <div
    role='tabpanel'
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
  >
    {value === index && <MenuTabPanel menuId={menuId} menuVersion={menuVersion} />}
  </div>
);

MenuTabPanelContainer.propTypes = {};

export default MenuTabPanelContainer;
