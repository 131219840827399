import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List, ListItem, ListItemAvatar, ListItemText, Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import {
  saveInitialMenuItemOptionsAction,
  fetchMenuItemOptionsAction,
  reorderMenuItemOptionsAction,
} from '../../actions/menuItemOptionsAction';
import useStyles from './MenuItemOptionsStyles';
import MenuItemOption from '../menuItemOption/MenuItemOption';
import MenuItemOptionCreate from '../menuItemOptionCreate/MenuItemOptionCreate';
import MenuItemOptionEditDialog from '../menuItemOptionEdit/MenuItemOptionEditDialog';

const MenuItemOptions = ({
  create,
  fetchMenuItemOptionsAction,
  menuItemId,
  menuItemOptions,
  reorderMenuItemOptionsAction,
  saveInitialMenuItemOptionsAction,
}) => {
  const classes = useStyles();
  const options = menuItemOptions.stored[menuItemId] || [];
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContext, setDialogContext] = useState('');
  const [selectedOptionId, setSelectedOptionId] = useState(false);

  const lastOption = options ? options.slice(-1)[0] : null;
  const lastOptionIndex = lastOption ? lastOption.optionIndex : 0;

  useEffect(() => {
    // Fetching Options if they haven't been stored yet
    if (!menuItemOptions.stored[menuItemId] && !create) {
      fetchMenuItemOptionsAction(menuItemId);
      return;
    }

    if (!menuItemOptions.initialStored[menuItemId] && !create) {
      saveInitialMenuItemOptionsAction();
    }
  }, [
    create,
    fetchMenuItemOptionsAction,
    menuItemId,
    menuItemOptions.initialStored,
    menuItemOptions.stored,
    saveInitialMenuItemOptionsAction,
  ]);

  const onDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    // dropped in same position
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    /*
     * Re-order Menu item options
     */

    reorderMenuItemOptionsAction(destination.droppableId, source.index, destination.index);
  };

  if (menuItemOptions.fetching && !create && menuItemOptions.stored[menuItemId] === undefined) {
    return (
      <Box
        style={{
          borderColor: 'rgba(0, 0, 0, 0.23)',
          padding: '1rem',
          border: '1px solid rgba(0,0,0,.23)',
          borderRadius: '4px',
          marginTop: '1rem',
        }}
      >
        <List>
          <ListItem>
            <ListItemAvatar>
              <Skeleton variant='circle' width={40} height={40} />
            </ListItemAvatar>
            <ListItemText>
              <Skeleton height={10} width='100%' style={{ marginBottom: 6 }} />
              <Skeleton height={10} width='80%' />
            </ListItemText>
          </ListItem>
        </List>
      </Box>
    );
  }

  if (menuItemOptions.error) {
    return (
      <Box
        style={{
          borderColor: 'rgba(0, 0, 0, 0.23)',
          padding: '1rem',
          border: '1px solid rgba(0,0,0,.23)',
          borderRadius: '4px',
          marginTop: '1rem',
        }}
      >
        {menuItemOptions.errorMessage}
      </Box>
    );
  }

  return (
    <>
      <Box className={classes.boxOutlined}>
        <Box
          className={`MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-shrink MuiInputLabel-outlined ${classes.boxLabel}`}
        >
          Options
        </Box>
        <List dense={false}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={menuItemId} type='menu-item-option'>
              {(provided, snapshot) => (
                <Box
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={`${classes.droppableMenuItemOption} ${
                    snapshot.isDraggingOver ? 'dragging-over' : ''
                  } `}
                >
                  {options.map((menuItemOption, index) => (
                    <MenuItemOption
                      dialogContext={dialogContext}
                      index={index}
                      key={menuItemOption.objectId}
                      menuItemId={menuItemId}
                      menuItemOption={menuItemOption}
                      setDialogContext={setDialogContext}
                      setOpenDialog={setOpenDialog}
                      setSelectedOptionId={setSelectedOptionId}
                    />
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
            <MenuItemOptionCreate
              menuItemId={menuItemId}
              menuItemOptions={options}
              setOpenDialog={setOpenDialog}
              setDialogContext={setDialogContext}
              setSelectedOptionId={setSelectedOptionId}
            />
            {selectedOptionId && (
              <MenuItemOptionEditDialog
                lastOptionIndex={lastOptionIndex}
                menuItemId={menuItemId}
                option={options.find((opt) => opt.objectId === selectedOptionId) || {}}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                dialogContext={dialogContext}
                setDialogContext={setDialogContext}
              />
            )}
          </DragDropContext>
        </List>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  menuItemOptions: state.menuItemOptions,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchMenuItemOptionsAction,
      reorderMenuItemOptionsAction,
      saveInitialMenuItemOptionsAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemOptions);
