import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  draggableCategory: {
    backgroundColor: '#fff',

    '&.dragging': {
      backgroundColor: '#f1f2f3',
    },
  },
  listSubheader: {
    paddingTop: theme.spacing(1),
  },
  droppableMenuItem: {
    flexGrow: 1,
    minHeight: '65px',
    transition: 'background-color 200ms ease',

    '&.dragging-over': {
      backgroundColor: '#eff2f3',
    },
  },
  selectionBoxContainer: {
    position: 'fixed',
    left: '50%',
    bottom: '40px',
    width: 'calc(100% - 40px)',
    maxWidth: '900px',
    zIndex: 1,
  },
  selectionBox: {
    position: 'relative',
    left: '-50%',
    width: '100%',
    maxWidth: '900px',
  },
  selectionBoxContent: {
    padding: '30px',
    lineHeight: '36px',
  },
}));

export default useStyles;
