import React, { useEffect, useRef, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  Table,
  TableHead,
  Grid,
  Box,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addBreadcrumbAction, popBreadcrumbAction } from '../../../../actions/breadcrumbsAction';
import {
  updateMenuItemOptionAction,
  createMenuItemOptionAction,
} from '../../../../actions/menuItemOptionsAction';
import {
  cancelChoicesAction,
  saveInitialChoicesAction,
} from '../../../../actions/menuItemOptionChoicesAction';
import { TextButton } from '../Button/TextButton';
import useStyles from './BundleOptionAddStyles';
import { dialogContexts } from '../../../../constants/constants';
import BundleOptionEditDialog from './BundleOptionEditDialog';
import BundleOptionCreate from './BundleOptionCreate';
import { addBundleOptionsToBundleAction } from '../../../../actions/bundlesAction';

/**
 * The dialog for dragging options onto a single menu item
 */
const OptionAddDialog = ({
  addBundleOptionsToBundleAction,
  openDialog,
  setOpenDialog,
  bundleOptions,
  products,
  defaultSelectedItems,
  addBreadcrumbAction,
  popBreadcrumbAction,
  bundleId,
}) => {
  const classes = useStyles();
  const [save, setSave] = useState(false);

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(false);

  const [processedBundleOptions, setProcessedBundleOptions] = useState([]);
  useEffect(() => {
    setProcessedBundleOptions(
      bundleOptions?.localData.map((bundleOption) => {
        let productsForBundleOption = [];
        products?.localData?.forEach((product) => {
          if (bundleOption?.productIds?.includes(product.objectId)) {
            productsForBundleOption = [...productsForBundleOption, product?.productTitle];
          }
        });

        return {
          ...bundleOption,
          labelledChoices: productsForBundleOption,
        };
      }),
    );
  }, [bundleOptions?.localData]);

  // breadcrumbs
  const [hasBreadcrumb, setHasBreadcrumb] = useState(false); // To ensure we don't pop some other breadcrumb if re-rendered while closed
  useEffect(() => {
    if (openDialog && !hasBreadcrumb) {
      addBreadcrumbAction(`Add bundle option`);
      setHasBreadcrumb(true);
    } else if (!openDialog && hasBreadcrumb) {
      popBreadcrumbAction();
      setHasBreadcrumb(false);
    }
  }, [openDialog]);

  const closeDialog = () => {
    setOpenDialog(false);
  };

  const validateForm = () => {
    const formError = false;

    return formError;
  };

  const submit = (event) => {
    event.preventDefault();

    const formError = validateForm();

    if (formError) {
      return;
    }

    addBundleOptionsToBundleAction(selectedItems);

    setSave(true);
    closeDialog();
  };

  const tableRef = useRef();
  const [selectedItems, setSelectedItems] = useState([]);
  const selectItem = (itemId, value) => {
    if (value === true && !selectedItems.includes(itemId)) {
      setSelectedItems([...selectedItems, itemId]);
    }

    // Remove item if deselected and in list
    if (value === false) {
      const updatedSelectedItems = selectedItems.filter((item) => item !== itemId);
      setSelectedItems(updatedSelectedItems);
    }
  };

  useEffect(() => {
    setSelectedItems(defaultSelectedItems);
  }, [defaultSelectedItems]);

  const selectAllItems = () => {
    setSelectedItems(bundleOptions.localData.map((item) => item.id));
  };

  return (
    <Dialog
      open={openDialog}
      onClose={closeDialog}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth
      maxWidth='lg'
      className={classes.dialog}
      onEnter={() => {
        setSave(false);
      }}
      onExited={() => {
        if (!save) {
          // Revert Options back to as they were on enter
          // cancelChoicesAction(menuItemId, optionId);
        }
      }}
    >
      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={2}>
          <Grid item md={8} xs={12} className={classes.leftColumn}>
            <Box className={classes.modalTitle}>Bundle options</Box>
            <Box className={classes.modalDescription}>Select from existing bundle options</Box>
            {processedBundleOptions.length === 0 ? (
              'No bundle options to display. Please create some first.'
            ) : (
              <Table tableref={tableRef}>
                <TableHead>
                  <TableRow>
                    <TableCell> </TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Menu items</TableCell>
                    <TableCell> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {processedBundleOptions.map((bundleOption, index) => (
                    <TableRow key={bundleOption.objectId} className={classes.row}>
                      <TableCell style={{ width: '20px' }}>
                        <Checkbox
                          style={{
                            color: selectedItems?.includes(bundleOption.objectId)
                              ? '#E54439'
                              : '#BDBDBD',
                          }}
                          checked={selectedItems.includes(bundleOption.objectId)}
                          onClick={(event) => event.stopPropagation()}
                          onChange={(event) =>
                            selectItem(bundleOption.objectId, event.target.checked)
                          }
                        />
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          selectItem(
                            bundleOption.objectId,
                            !selectedItems.includes(bundleOption.objectId),
                          )
                        }
                        style={{ fontSize: '18px' }}
                      >
                        {bundleOption.bundleOptionTitle}
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          selectItem(
                            bundleOption.objectId,
                            !selectedItems.includes(bundleOption.objectId),
                          )
                        }
                        style={{ fontSize: '14px' }}
                      >
                        {bundleOption.bundleOptionDescription}
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          selectItem(
                            bundleOption.objectId,
                            !selectedItems.includes(bundleOption.objectId),
                          )
                        }
                      >
                        <Box
                          style={{
                            fontSize: '14px',
                            color: '#2E2E2E',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            maxWidth: '300px',
                          }}
                        >
                          {bundleOption?.labelledChoices?.join('; ')}
                        </Box>
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: '#FFF',
                          color: '#E54439',
                          width: '65px',
                          padding: 0,
                        }}
                        onClick={(event) => {
                          event.stopPropagation();
                          setOpenEditDialog(true);
                          setSelectedOptionIndex(index);
                        }}
                      >
                        <TextButton>Edit</TextButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Grid>
          <Grid item md={4} xs={12} className={classes.rightColumn}>
            <Box className={classes.modalDescription}>Bundle option doesn&apos;t exist?</Box>
            <BundleOptionCreate
              buttonLabel='CREATE NEW BUNDLE OPTION'
              buttonType='secondary'
              bundleId={bundleId}
            />
          </Grid>
        </Grid>

        {selectedOptionIndex !== false && (
          <BundleOptionEditDialog
            openDialog={openEditDialog}
            setOpenDialog={setOpenEditDialog}
            option={
              bundleOptions.localData.find((option, index) => index === selectedOptionIndex) || {}
            }
            dialogContext={dialogContexts.EDIT}
          />
        )}
      </DialogContent>
      <DialogActions style={{ borderTop: '1px solid #DCDCDB', minHeight: '50px' }}>
        <Box
          style={{ minHeight: '60px', margin: '0 20px', width: '100%' }}
          display='flex'
          justifyContent='space-between'
        >
          <Button onClick={closeDialog} color='default'>
            Cancel
          </Button>
          <Button onClick={submit} color='primary' type='submit' form='add-menu-item-option-form'>
            Add bundle options
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  bundleOptions: state.bundleOptions,
  products: state.products,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      cancelChoicesAction,
      createMenuItemOptionAction,
      saveInitialChoicesAction,
      updateMenuItemOptionAction,
      addBundleOptionsToBundleAction,
      addBreadcrumbAction,
      popBreadcrumbAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(OptionAddDialog);
