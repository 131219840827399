import * as type from './types';

export const setMenuVersionAction = (menuVersion, posEnabled) => (dispatch) => {
  dispatch({
    type: type.SET_MENU_VERSION,
    payload: {
      version: menuVersion,
      posEnabled,
    },
  });
};
