import * as type from './types';

export const createChoiceAction = (title, price) => (dispatch) => {
  dispatch({
    type: type.CREATE_MENU_ITEM_OPTION_CHOICE,
    payload: { title, price },
  });
};

export const updateChoiceAction = (index, title, price) => (dispatch) => {
  dispatch({
    type: type.UPDATE_MENU_ITEM_OPTION_CHOICE,
    payload: { index, title, price },
  });
};

export const deleteChoiceAction = (index) => (dispatch) => {
  dispatch({
    type: type.DELETE_MENU_ITEM_OPTION_CHOICE,
    payload: { index },
  });
};

export const disableMenuItemOptionChoiceAction = (index, disableDate) => (dispatch) => {
  dispatch({
    type: type.DISABLE_MENU_ITEM_OPTION_CHOICE,
    payload: { index, disableDate },
  });
};

export const reorderMenuItemOptionChoicesAction =
  (menuItemId, source, destination) => (dispatch) => {
    dispatch({
      type: type.REORDER_MENU_ITEM_OPTION_CHOICES,
      payload: { menuItemId, source, destination },
    });
  };

export const saveInitialChoicesAction = (menuItemId, optionId) => (dispatch) => {
  dispatch({
    type: type.SAVE_INITIAL_MENU_ITEM_OPTION_CHOICES,
    payload: { menuItemId, optionId },
  });
};

export const clearInitialChoicesAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_INITIAL_MENU_ITEM_OPTION_CHOICES,
    payload: {},
  });
};

export const cancelChoicesAction = (menuItemId, optionId) => (dispatch) => {
  dispatch({
    type: type.CANCEL_MENU_ITEM_OPTION_CHOICES,
    payload: { menuItemId, optionId },
  });
};
