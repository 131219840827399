import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, Switch, Route } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Box } from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons/';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import useStyles from './HeaderStyles';
import Loading from '../loading/Loading';
import { signOutAction } from '../../actions/authAction';
import routes from '../../routes/Routes';
import SaveButton from './SaveButton';
import BackButton from './BackButton';
import Logo from './Logo';
import VersionDropdown from './VersionDropdown';
import DiscardDialog from '../DiscardDialog/DiscardDialog';
import VersionBanner from './VersionBanner';

const Header = ({ activeRestaurant, signOutAction }) => {
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const breakpointXsDown = useMediaQuery(theme.breakpoints.down('xs'));

  const [pageName, setPageName] = useState('');
  const [showVersionToggles, setShowVersionToggles] = useState(false);
  const [discardCallback, setDiscardCallback] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    let titleFront;

    // If route is a restaurant then use restaurant name as title
    const regexRestaurantRouteWithID = /^\/(restaurants|duplicate)\/[a-zA-Z0-9-]{36,36}/;
    if (regexRestaurantRouteWithID.test(location.pathname)) {
      if (activeRestaurant.data.name) {
        titleFront = activeRestaurant.data.name;
      } else {
        titleFront = 'Restaurant';
      }

      setShowVersionToggles(location.pathname.includes('/restaurants'));
    } else {
      titleFront = routes.find((route) => route.path === location.pathname);
      titleFront = titleFront ? titleFront.pageTitle : '';

      setShowVersionToggles(false);
    }
    setPageName(titleFront);
    const delimiter = titleFront !== '' ? ' | ' : '';
    const titleEnd = `${process.env.REACT_APP_WEBSITE_NAME} Menus`;
    const title = `${titleFront}${delimiter}${titleEnd}`;
    window.document.title = title;
  }, [activeRestaurant.data.name, location.pathname]);

  const handleLogout = () => {
    signOutAction();
  };

  const handleOnClick = () => {
    if (!hasChanges) {
      handleLogout();

      return;
    }

    setDiscardCallback(() => () => handleLogout());
  };

  return (
    <>
      <AppBar position='fixed' color='inherit' elevation={0}>
        <Toolbar>
          <Box display='flex' flexWrap='nowrap' alignItems='center' style={{ flexBasis: '50%' }}>
            <Switch>
              {routes
                .filter((route) => route.pageTitle === 'Restaurant')
                .map((route) => (
                  <Route key={route.path} path={route.path} exact={route.exact}>
                    <BackButton route='/' />
                  </Route>
                ))}
            </Switch>
            <Switch>
              {routes
                .filter((route) => route.pageTitle === 'Duplicate Restaurant Menus')
                .map((route) => (
                  <Route key={route.path} path={route.path} exact={route.exact}>
                    <BackButton
                      route={`/restaurants/${activeRestaurant.data.objectId}`}
                      skipChangesCheck
                    />
                  </Route>
                ))}
            </Switch>
            <Logo />
            <Typography
              className={classes.title}
              variant='h6'
              color='primary'
              noWrap
              title={pageName}
            >
              {pageName.length > 30 ? <>{pageName.substr(0, 30)}&hellip;</> : pageName}
            </Typography>
          </Box>

          {showVersionToggles && !activeRestaurant.fetching && <VersionDropdown />}

          <Box
            display='flex'
            flexWrap='nowrap'
            alignItems='center'
            justifyContent='flex-end'
            style={{ flexBasis: '50%' }}
          >
            <Button
              color='primary'
              variant='text'
              onClick={handleOnClick}
              style={{ marginRight: '1rem' }}
            >
              {breakpointXsDown ? (
                <ExitToApp />
              ) : (
                <span style={{ whiteSpace: 'nowrap' }}>Log Out</span>
              )}
            </Button>

            <Switch>
              {routes
                .filter((route) => route.pageTitle === 'Restaurant')
                .map((route, index) => (
                  <Route key={index} path={route.path} exact={route.exact}>
                    <SaveButton />
                  </Route>
                ))}
            </Switch>
          </Box>
        </Toolbar>

        {showVersionToggles && !activeRestaurant.fetching && <VersionBanner />}

        <Loading />
      </AppBar>

      <DiscardDialog
        callback={discardCallback}
        handleClose={() => setDiscardCallback(null)}
        setHasChanges={setHasChanges}
      />
    </>
  );
};

Header.propTypes = {
  signOutAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  activeRestaurant: state.activeRestaurant,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ signOutAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
