import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  Button,
  Box,
  Checkbox,
} from '@material-ui/core';
import {
  addOptionsToProductsAction,
  removeOptionsFromProductsAction,
} from '../../../../actions/productsAction';
import { sortAlphabetical } from '../../../../helpers/ArrayUtils';
import useStyles from './MultiBundleEditStyles';
import BundleOptionCreate from '../menuBundleOption/BundleOptionCreate';
import {
  addBundleOptionsToBundlesAction,
  removeBundleOptionsFromBundlesAction,
} from '../../../../actions/bundlesAction';

const MultiBundleEditDialog = ({
  bundleOptions,
  bundles,
  products,
  openDialog,
  setOpenDialog,
  selectedBundles,
  addBundleOptionsToBundlesAction,
  removeBundleOptionsFromBundlesAction,
}) => {
  const classes = useStyles();
  const [dialogContext, setDialogContext] = useState('');
  const [bundleOptionsWithSelection, setBundleOptionsWithSelection] = useState([]);
  const [selectedBundleList, setSelectedBundleList] = useState([]);

  // Get choices for each option
  const [processedBundleOptions, setProcessedBundleOptions] = useState([]);
  useEffect(() => {
    setProcessedBundleOptions(
      bundleOptions?.localData.map((bundleOption) => {
        let productsForBundleOption = [];
        products?.localData?.forEach((product) => {
          if (bundleOption?.productIds?.includes(product.objectId)) {
            productsForBundleOption = [...productsForBundleOption, product?.productTitle];
          }
        });

        return {
          ...bundleOption,
          labelledProducts: productsForBundleOption?.join(', '),
        };
      }),
    );
  }, [bundleOptions?.localData]);

  // Populate the list of products with data instead of just being IDs
  useEffect(() => {
    setSelectedBundleList(
      selectedBundles.map((bundleId) =>
        bundles.localData.find((bundle) => bundle.objectId === bundleId),
      ),
    );
  }, [bundles.localData, selectedBundles]);

  // Set the options, and whether they are checked or not (or partially)
  useEffect(() => {
    const newOptionsWithSelection = processedBundleOptions?.map((bundleOption) => {
      const bundlesWithBundleOption = selectedBundleList.filter((bundle) =>
        bundle.bundleOptionIds?.includes(bundleOption.objectId),
      );
      if (bundlesWithBundleOption.length === 0) {
        return { bundleOption, value: 'unchecked', modified: false };
      }
      if (bundlesWithBundleOption.length === selectedBundles.length) {
        return { bundleOption, value: 'checked', modified: false };
      }
      return { bundleOption, value: 'partial', modified: false };
    });

    setBundleOptionsWithSelection(newOptionsWithSelection || []);
  }, [processedBundleOptions, selectedBundleList, openDialog]);

  const selectItem = (bundleOptionId, value) => {
    const bundleOptionToUpdate = bundleOptionsWithSelection?.find(
      (bundleOption) => bundleOption.bundleOption.objectId === bundleOptionId,
    );
    const bundleOptionsWithoutUpdated = bundleOptionsWithSelection?.filter(
      (bundleOption) => bundleOption.bundleOption.objectId !== bundleOptionId,
    );
    bundleOptionToUpdate.value = value ? 'checked' : 'unchecked';
    bundleOptionToUpdate.modified = true;

    setBundleOptionsWithSelection([...bundleOptionsWithoutUpdated, bundleOptionToUpdate]);
  };

  const closeDialog = () => {
    setOpenDialog(false);
  };

  const submit = (event) => {
    event.preventDefault();

    const checkedBoxes = bundleOptionsWithSelection.filter(
      (bundleOption) => bundleOption.modified && bundleOption.value === 'checked',
    );
    const uncheckedBoxes = bundleOptionsWithSelection.filter(
      (bundleOption) => bundleOption.modified && bundleOption.value === 'unchecked',
    );

    // Update all newly added options
    if (checkedBoxes.length > 0) {
      addBundleOptionsToBundlesAction(
        selectedBundles,
        checkedBoxes.map((bundleOption) => bundleOption.bundleOption.objectId),
      );
    }

    // Update all newly removed options
    if (uncheckedBoxes.length > 0) {
      removeBundleOptionsFromBundlesAction(
        selectedBundles,
        uncheckedBoxes.map((bundleOption) => bundleOption.bundleOption.objectId),
      );
    }

    closeDialog();
  };

  return (
    <Dialog
      open={openDialog}
      onClose={closeDialog}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth
      maxWidth='lg'
      style={{ minHeight: '600px' }}
      minHeight='600px'
      className={classes.modal}
    >
      <DialogTitle id='multi-product-edit-dialog'>Add bundle options to menu bundles</DialogTitle>
      <DialogContent className={classes.modal}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Box className={classes.columnTitle}>
              {selectedBundles.length} bundle
              {selectedBundles.length !== 1 ? 's' : ''} selected
            </Box>
            <Box className={`${classes.boxOutlined}`}>
              {selectedBundleList.map((selectedBundle) => (
                <Box className={classes.productListItem}>{selectedBundle?.bundleTitle}</Box>
              ))}
            </Box>
          </Grid>
          <Grid item md={6} xs={12}>
            <Box className={classes.columnTitle}>Linked bundle options</Box>
            <Box className={classes.boxOutlined}>
              {bundleOptionsWithSelection
                ?.sort((a, b) =>
                  sortAlphabetical(
                    a.bundleOption.bundleOptionTitle,
                    b.bundleOption.bundleOptionTitle,
                  ),
                )
                ?.map((bundleOption) => (
                  <Box key={bundleOption.bundleOption.objectId} display='flex'>
                    <Checkbox
                      color='primary'
                      style={{
                        color: bundleOption.value !== 'unchecked' ? '#E54439' : '#BDBDBD',
                      }}
                      checked={bundleOption.value === 'checked'}
                      indeterminate={bundleOption.value === 'partial'}
                      onChange={(event) =>
                        selectItem(bundleOption.bundleOption.objectId, event.target.checked)
                      }
                      className={classes.checkbox}
                    />
                    <Box className={classes.optionTitle}>
                      {bundleOption.bundleOption.bundleOptionTitle}
                    </Box>
                    <Box className={classes.optionChoices}>
                      {bundleOption.bundleOption.labelledProducts}
                    </Box>
                  </Box>
                ))}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <BundleOptionCreate
          buttonLabel='CREATE NEW BUNDLE OPTION'
          type='submit'
          buttonType='secondary'
          productIds={selectedBundles}
        />
        <Button onClick={closeDialog} color='default'>
          Cancel
        </Button>
        <Button onClick={submit} color='primary' type='submit' form='edit-multi-product-form'>
          Save bundles
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  products: state.products,
  bundles: state.bundles,
  bundleOptions: state.bundleOptions,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addOptionsToProductsAction,
      removeOptionsFromProductsAction,
      addBundleOptionsToBundlesAction,
      removeBundleOptionsFromBundlesAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MultiBundleEditDialog);
