import React, { useState } from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { PlaylistAdd } from '@material-ui/icons';
import ProductEditDialog from './ProductEditDialog';
import { dialogContexts } from '../../../../constants/constants';

// TODO replace product create dialog with product edit dialog
const ProductCreate = ({ products }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const sortedProducts = products?.localData?.sort((a, b) => a.productIndex - b.productIndex);
  const lastProduct = sortedProducts?.slice(-1)[0];

  return (
    <>
      <ListItem button onClick={() => setOpenDialog(true)}>
        <ListItemIcon>
          <PlaylistAdd />
        </ListItemIcon>
        <ListItemText
          primary='Create new menu item'
          primaryTypographyProps={{ color: 'textSecondary', variant: 'button' }}
        />
      </ListItem>
      <ProductEditDialog
        newProductIndex={lastProduct ? lastProduct?.productIndex + 1 : 0}
        dialogContext={dialogContexts.CREATE}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
      />
    </>
  );
};

export default ProductCreate;
