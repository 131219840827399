import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchRestaurantByIdAction } from '../../actions/restaurantsAction';
import MenuTabsNew from '../v2/components/menuTabs/MenuTabs';
import MenuTabsOld from '../menuTabs/MenuTabs';
import { clearBreadcrumbsAction } from '../../actions/breadcrumbsAction';
import { setMenuVersionAction } from '../../actions/menuVersionAction';

const Restaurant = ({
  activeRestaurant,
  fetchRestaurantByIdAction,
  setMenuVersionAction,
  menuVersion,
  clearBreadcrumbsAction,
}) => {
  const { restaurantId } = useParams();

  const params = new URLSearchParams(window.location.search);
  const typeToReview = params.get('type');
  const objectIdToReview = params.get('objectId');

  useEffect(() => {
    clearBreadcrumbsAction();
  }, []);

  useEffect(() => {
    // no restaurant Id
    if (!restaurantId) {
      return;
    }

    // restaurant already fetch
    if (Object.keys(activeRestaurant.data).length > 0) {
      return;
    }

    fetchRestaurantByIdAction(restaurantId);
  }, [activeRestaurant.data, fetchRestaurantByIdAction, restaurantId]);

  if (typeToReview && objectIdToReview) {
    setMenuVersionAction(1, true);
  }

  if (activeRestaurant.fetching) {
    return <></>;
  }

  if (activeRestaurant.error) {
    return <div>Error loading Restaurant: {activeRestaurant.errorMessage}</div>;
  }

  const useNewDesign = menuVersion === 1;

  return (
    <div>
      {Object.keys(activeRestaurant.data).length > 0 ? (
        useNewDesign ? (
          <MenuTabsNew restaurantId={restaurantId} />
        ) : (
          <MenuTabsOld restaurantId={restaurantId} />
        )
      ) : (
        <div>No Restaurant Found</div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeRestaurant: state.activeRestaurant,
  menuVersion: state.menuVersion?.version,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { fetchRestaurantByIdAction, clearBreadcrumbsAction, setMenuVersionAction },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Restaurant);
