import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { useState } from 'react';
import { Box, Paper, ListSubheader, List } from '@material-ui/core';
import { deleteBundleAction, reviewBundleAction } from '../../../../actions/bundlesAction';
import ReviewNotificationBanner from '../NotificationBanner/ReviewNotificationBanner';
import BundleGroupActionButton from './BundleGroupActionButton';
import useStyles from './BundlesStyles';
import SelectionControls from '../SelectionControls/SelectionControls';
import MultiBundleEditDialog from './MultiBundleEditDialog';
import BundleCreate from './BundleCreate';
import Bundle from './Bundle';

const Bundles = ({ bundles, menuVersion, deleteBundle, reviewBundle }) => {
  const classes = useStyles();
  const [showAddOptionsDialog, setShowAddOptionsDialog] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const selectItem = (itemId, selected) => {
    const isSelected = selectedItems?.filter((item) => item === itemId)?.length > 0;
    if (selected && !isSelected) {
      setSelectedItems([...selectedItems, itemId]);
    }

    if (!selected) {
      setSelectedItems(selectedItems.filter((item) => item !== itemId));
    }
  };

  const selectAllItems = () => {
    let newSelectedItems = selectedItems;
    bundles.localData.forEach((bundle) => {
      const isSelected =
        selectedItems?.filter((selectedItem) => selectedItem.itemId === bundle.objectId)?.length >
        0;
      if (!isSelected) {
        newSelectedItems = [...newSelectedItems, bundle.objectId];
      }
    });

    setSelectedItems(newSelectedItems);
  };

  const onSelect = (bundleId, selected) => {
    selectItem(bundleId, selected);
  };

  const onSelectAll = () => {
    selectAllItems();
  };

  return (
    <>
      {menuVersion.posEnabled && <ReviewNotificationBanner />}
      <Paper style={{ marginTop: '1rem' }}>
        <List
          dense={false}
          subheader={
            <ListSubheader
              component='div'
              id='category-list-subheader'
              color='primary'
              className={classes.listSubheader}
            >
              <Box display='flex' alignItems='center' justifyContent='space-between'>
                <span>{bundles.localData?.length === 0 ? 'No bundles yet' : 'All bundles'}</span>
                <BundleGroupActionButton selectAllItems={onSelectAll} />
              </Box>
              {bundles.localData?.length !== 0 && (
                <Box
                  style={{
                    height: '20px',
                    fontSize: '13px',
                    lineHeight: '20px',
                    color: '#9F9F9F',
                    columnGap: '15px',
                  }}
                  display='flex'
                  justifyContent='flex-end'
                  alignItems='baseline'
                >
                  <span style={{ width: '100px' }}>Options</span>
                  <span style={{ width: '100px' }}>Price</span>
                  <span style={{ width: '140px' }} />
                  <span style={{ width: '36px' }} />
                </Box>
              )}
            </ListSubheader>
          }
        >
          {bundles.localData?.map((bundle) => (
            <Bundle
              key={bundle.objectId}
              bundle={bundle}
              selected={selectedItems?.filter((item) => item === bundle.objectId)?.length > 0}
              onSelect={(value) => onSelect(bundle.objectId, value)}
            />
          ))}
        </List>
        {selectedItems.length > 0 && (
          <SelectionControls
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            removeItem={deleteBundle}
            reviewItem={reviewBundle}
            showAddOptionsDialog={() => setShowAddOptionsDialog(true)}
          />
        )}
        <MultiBundleEditDialog
          openDialog={showAddOptionsDialog}
          setOpenDialog={setShowAddOptionsDialog}
          selectedBundles={selectedItems}
        />
      </Paper>

      {!menuVersion.posEnabled && (
        <Paper style={{ marginTop: '1rem' }}>
          <BundleCreate products={bundles} />
        </Paper>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  bundles: state.bundles,
  menuVersion: state.menuVersion,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteBundle: deleteBundleAction,
      reviewBundle: reviewBundleAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Bundles);
