import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { useState } from 'react';
import { dialogContexts } from '../../../../constants/constants';
import { SecondaryButton } from '../Button/SecondaryButton';
import { TextButton } from '../Button/TextButton';
import OptionEditDialog from './OptionEditDialog';

const OptionCreate = ({
  options,
  buttonLabel,
  buttonType,
  buttonBold = true,
  productId,
  productIds,
}) => {
  const lastOption = options.localData?.slice(-1)[0];
  const [openDialog, setOpenDialog] = useState(false);

  const createButton = () => {
    switch (buttonType) {
      case 'text':
        return (
          <TextButton
            onClick={() => {
              setOpenDialog(true);
            }}
            style={{ text: { fontWeight: buttonBold ? 'bold' : 'inherit' } }}
          >
            {buttonLabel}
          </TextButton>
        );
      case 'secondary':
      default:
        return (
          <SecondaryButton
            onClick={() => {
              setOpenDialog(true);
            }}
            style={{ text: { fontWeight: buttonBold ? 'bold' : 'inherit' } }}
          >
            {buttonLabel}
          </SecondaryButton>
        );
    }
  };

  return (
    <>
      {createButton()}
      <OptionEditDialog
        newOptionIndex={lastOption ? lastOption?.optionIndex + 1 : 0}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        dialogContext={dialogContexts.CREATE}
        option={{}}
        productId={productId}
        productIds={productIds}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  options: state.options,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(OptionCreate);
