import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const BundleGroupActionButton = ({ selectAllItems, menuVersion }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectAll = () => {
    handleClose();
    selectAllItems();
  };

  return (
    <>
      <IconButton style={{ marginRight: '-12px' }} onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu id='long-menu' anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        <MenuItem onClick={handleSelectAll}>Select all items</MenuItem>
      </Menu>
    </>
  );
};

BundleGroupActionButton.propTypes = {
  selectAllItems: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  menuVersion: state.menuVersion,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(BundleGroupActionButton);
