import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Box } from '@material-ui/core';
import { Button } from '@eatclub-apps/ec-component-library';
import { fetchRestaurantByIdAction } from '../../actions/restaurantsAction';
import { ReactComponent as SuccessImage } from '../../assets/confirmation_check_medium.svg';
import { pluralise } from '../../helpers/Helpers';
import { TextButton } from '../v2/components/Button/TextButton';
import useStyles from './DuplicateMenusStyles';

const DuplicateSuccess = ({ activeRestaurant, fetchRestaurantById, restaurants }) => {
  const classes = useStyles();
  const history = useHistory();

  const switchToRestaurantMenu = (restaurant) => {
    fetchRestaurantById(restaurant?.objectId);

    history.push(`/restaurants/${restaurant.objectId}`);
  };

  return (
    <Box className={classes.successStateContainer}>
      <SuccessImage style={{ height: '76px', width: '76px' }} />
      <Box style={{ fontSize: '60px', fontWeight: 'bold', marginBottom: '30px' }}>Success</Box>
      <Box style={{ fontSize: '20px', marginBottom: '30px' }}>
        Menus have been duplicated across to the following {pluralise('venue', restaurants?.length)}
      </Box>
      <Box mb='50px'>
        {restaurants?.map((restaurant) => (
          <TextButton
            style={{
              color: '#E54439',
              button: { fontSize: '20px', padding: '5px 20px' },
            }}
            onClick={() => switchToRestaurantMenu(restaurant)}
          >
            {restaurant?.name}
          </TextButton>
        ))}
      </Box>
      <Button
        type='tertiary'
        onClick={() => history.push(`/restaurants/${activeRestaurant?.data?.objectId}`)}
      >
        RETURN TO MENU
      </Button>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  activeRestaurant: state.activeRestaurant,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchRestaurantById: fetchRestaurantByIdAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateSuccess);
