import React, { useEffect, useState } from 'react';
import { SecondaryButton } from '../Button/SecondaryButton';
import OptionChoiceAddDialog from './OptionChoiceAddDialog';

const OptionChoiceAdd = ({ lastOption, optionId, selectedChoices, setChoices }) => {
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <>
      <SecondaryButton
        onClick={() => {
          setOpenDialog(true);
        }}
        style={{ text: { fontWeight: 'bold' } }}
      >
        ADD NEW
      </SecondaryButton>
      <OptionChoiceAddDialog
        lastOptionIndex={lastOption?.itemIndex || 0}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        optionId={optionId}
        defaultSelectedItems={selectedChoices}
        setChoices={setChoices}
      />
    </>
  );
};

export default OptionChoiceAdd;
