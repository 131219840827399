import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import MenuCategoryRenameDialog from './MenuCategoryRenameDialog';
import MenuCategoryDeleteDialog from './MenuCategoryDeleteDialog';

const MenuCategoryOptionButton = ({ category, selectAllItems, menuVersion, selectAllBundles }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openRenameDialog, setOpenRenameDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenRenameDialog = () => {
    handleClose();
    setOpenRenameDialog(true);
  };

  const handleOpenDeleteDialog = () => {
    handleClose();
    setOpenDeleteDialog(true);
  };

  const handleSelectAll = () => {
    handleClose();
    selectAllItems();
  };

  const handleSelectAllBundles = () => {
    handleClose();
    selectAllBundles();
  };

  return (
    <>
      <IconButton style={{ marginRight: '-12px' }} onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu id='long-menu' anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        <MenuItem onClick={handleOpenRenameDialog}>Rename category</MenuItem>
        {category.productIds?.length > 0 && (
          <MenuItem onClick={handleSelectAll}>Select all menu items</MenuItem>
        )}
        {category.bundleIds?.length > 0 && (
          <MenuItem onClick={handleSelectAllBundles}>Select all bundles</MenuItem>
        )}
        <MenuItem onClick={handleOpenDeleteDialog}>Delete category</MenuItem>
      </Menu>
      <MenuCategoryRenameDialog
        category={category}
        openDialog={openRenameDialog}
        setOpenDialog={setOpenRenameDialog}
      />
      <MenuCategoryDeleteDialog
        category={category}
        openDialog={openDeleteDialog}
        setOpenDialog={setOpenDeleteDialog}
      />
    </>
  );
};

MenuCategoryOptionButton.propTypes = {
  category: PropTypes.object.isRequired,
  selectAllItems: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  menuVersion: state.menuVersion,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(MenuCategoryOptionButton);
