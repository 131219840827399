import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core';
import { React, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DiscardDialog from '../DiscardDialog/DiscardDialog';
import { setMenuVersionAction } from '../../actions/menuVersionAction';
import ChangeVersionModal from '../changeVersionModal/ChangeVersionModal';
import { COLORS } from '../../EatClubTheme';
import { ReactComponent as CheckMedium } from '../../assets/confirmation_check_medium.svg';
import useStyles from './VersionDropdownStyles';

const VersionBanner = ({ activeRestaurant }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [menuVersionForModal, setVersionForModal] = useState(0);
  const [posEnabledForModal, setPosEnabledForModal] = useState(false);
  const anchorRef = useRef(null);

  const [discardCallback, setDiscardCallback] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);

  const handleToggle = () => setOpen((prevOpen) => !prevOpen);
  const handleClose = () => setOpen(false);

  const view = (version, pos) => {
    setVersionForModal(version);
    setPosEnabledForModal(pos);

    setModalOpen(true);
  };

  const handleOnClick = (version, pos) => {
    if (!hasChanges) {
      view(version, pos);
    } else {
      setDiscardCallback(() => () => view(version, pos));
    }
  };

  const getLiveVersionLabel = () => {
    if (activeRestaurant.data?.menuVersion === 0) {
      return 'Version 1.0 menus';
    }
    if (activeRestaurant.data?.menuVersion === 1 && !activeRestaurant.data?.posEnabled) {
      return 'Version 2.0 menus';
    }
    if (activeRestaurant.data?.menuVersion === 1 && activeRestaurant.data?.posEnabled) {
      return 'Integrated POS menus';
    }
  };

  const items = [
    {
      label: 'Version 1.0 menus',
      onClick: () => handleOnClick(0, false),
      active: activeRestaurant.data?.menuVersion === 0,
    },
    {
      label: 'Version 2.0 menus',
      onClick: () => handleOnClick(1, false),
      active: activeRestaurant.data?.menuVersion === 1 && !activeRestaurant.data?.posEnabled,
    },
    {
      label: 'Integrated POS menus',
      onClick: () => handleOnClick(1, true),
      active: activeRestaurant.data?.menuVersion === 1 && activeRestaurant.data?.posEnabled,
    },
  ];

  return (
    <Box
      style={{
        background: COLORS.VIOLENT_VIOLET,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '12px',
        height: '40px',
      }}
    >
      <Typography variant='p'>Live in customer app: {getLiveVersionLabel()}</Typography>
      <Button
        ref={anchorRef}
        id='composition-button'
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleToggle}
        variant='outlined'
        size='small'
      >
        <Typography variant='p'>Edit</Typography>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement='bottom-center'
        transition
        disablePortal
        style={{ zIndex: 1, marginTop: '10px' }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='composition-menu' aria-labelledby='composition-button'>
                  {items.map((item) => (
                    <MenuItem onClick={item.onClick} disabled={item.active}>
                      <Box className={classes.item}>
                        {item.label}
                        {item.active && <CheckMedium />}
                      </Box>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <ChangeVersionModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        menuVersion={menuVersionForModal}
        posEnabled={posEnabledForModal}
      />

      <DiscardDialog
        callback={discardCallback}
        handleClose={() => setDiscardCallback(null)}
        setHasChanges={setHasChanges}
      />
    </Box>
  );
};

const mapStateToProps = (state) => ({
  activeRestaurant: state.activeRestaurant,
  menuVersion: state.menuVersion?.version,
  posEnabled: state.menuVersion?.posEnabled,
  activeRestaurantVersion: state.activeRestaurantVersion,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setMenuVersion: setMenuVersionAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(VersionBanner);
