/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  ListItem,
  ListItemIcon,
  Box,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Button,
} from '@material-ui/core';
import { Draggable } from 'react-beautiful-dnd';
import { Delete, RemoveShoppingCart, ShoppingCart } from '@material-ui/icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { ReactComponent as Icon } from '../../../../assets/two-bars-icon.svg';
import {
  disableChoiceForDateAction,
  disableLocalChoiceForDateAction,
  disableLocalChoiceUntilDateAction,
  restockChoiceAction,
} from '../../../../actions/choicesAction';
import { removeChoiceFromOptionAction } from '../../../../actions/optionsAction';
import { formatCurrency, removeSpaces, startsWith } from '../../../../helpers/Helpers';
import useStyles from './OptionChoiceStyles';

const OptionChoice = ({
  choice,
  removeChoiceFromOption,
  index,
  setDialogContext,
  setOpenDialog,
  setSelectedChoiceIndex,
  menuVersion,
  restockChoice,
  disableChoiceForDate,
  disableLocalChoiceForDate,
  disableLocalChoiceUntilDate,
}) => {
  const classes = useStyles();

  const [isDisabled, setIsDisabled] = useState(
    moment(choice.disableDate, 'yyyy-MM-DD').isSame(moment(), 'day') ||
      moment(choice.disableUntilDate, 'yyyy-MM-DD').isAfter(moment(), 'day'),
  );

  useEffect(() => {
    setIsDisabled(
      moment(choice.disableDate, 'yyyy-MM-DD').isSame(moment(), 'day') ||
        moment(choice.disableUntilDate, 'yyyy-MM-DD').isAfter(moment(), 'day'),
    );
  }, [choice, choice.disableDate, choice.disableUntilDate]);

  const toggleIsDisabled = () => {
    if (isDisabled) {
      if (startsWith(choice.objectId, 'choice_')) {
        disableLocalChoiceForDate(choice.objectId, null);
        disableLocalChoiceUntilDate(choice.objectId, null);
      } else {
        restockChoice(choice.objectId);
      }
      return;
    }

    const newDisableDate = moment().format('yyyy-MM-DD');
    if (startsWith(choice.objectId, 'choice_')) {
      disableLocalChoiceForDate(choice.objectId, newDisableDate);
    } else {
      disableChoiceForDate(choice.objectId, newDisableDate);
    }
  };

  const handleDelete = () => {
    removeChoiceFromOption(choice.objectId);
  };

  if (!choice) {
    return <></>;
  }

  return (
    <Draggable draggableId={`${removeSpaces(choice.choiceTitle)}`} index={index}>
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={`${classes.draggableChoice} ${snapshot.isDragging ? 'dragging' : ''} ${
            snapshot.isDropAnimating ? 'animating' : ''
          }`}
          boxShadow={snapshot.isDragging ? 3 : 0}
        >
          <ListItem
            button
            onClick={() => {
              setOpenDialog(true);
              setDialogContext('Edit');
              setSelectedChoiceIndex(index);
            }}
          >
            <ListItemIcon title='Drag to re-order' {...provided.dragHandleProps}>
              <Icon style={{ fill: 'rgba(0, 0, 0, 0.54)' }} />
            </ListItemIcon>
            <ListItemText primary={choice.choiceTitle} style={{ verticalAlign: 'baseline' }} />
            <ListItemSecondaryAction style={{ verticalAlign: 'baseline' }}>
              <Box display='flex'>
                {choice.price ? (
                  <Box
                    style={{ paddingTop: '14px', cursor: 'pointer' }}
                    onClick={() => {
                      setOpenDialog(true);
                      setDialogContext('Edit');
                      setSelectedChoiceIndex(index);
                    }}
                  >
                    {formatCurrency(choice.price)}
                  </Box>
                ) : (
                  ''
                )}
                <Button
                  variant='text'
                  size='medium'
                  className={`${classes.listButton} ${isDisabled ? 'listButtonDisabled' : ''}`}
                  startIcon={isDisabled ? <RemoveShoppingCart /> : <ShoppingCart />}
                  onClick={toggleIsDisabled}
                  title='Toggle stock level'
                  style={{ fontSize: isDisabled ? '12px' : '14px' }}
                >
                  {isDisabled ? 'Out of Stock' : 'In Stock'}
                </Button>
                <IconButton
                  edge='end'
                  aria-label='delete'
                  onClick={handleDelete}
                  title='Remove choice from option'
                >
                  <Delete />
                </IconButton>
              </Box>
            </ListItemSecondaryAction>
          </ListItem>
        </Box>
      )}
    </Draggable>
  );
};

const mapStateToProps = (state) => ({
  menuVersion: state.menuVersion,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      removeChoiceFromOption: removeChoiceFromOptionAction,
      restockChoice: restockChoiceAction,
      disableChoiceForDate: disableChoiceForDateAction,
      disableLocalChoiceForDate: disableLocalChoiceForDateAction,
      disableLocalChoiceUntilDate: disableLocalChoiceUntilDateAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(OptionChoice);
