import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  boxLabel: {
    backgroundColor: '#fff',
    padding: '0px 0.5rem',
  },
  boxOutlined: {
    borderColor: 'rgba(0, 0, 0, 0.23)',
    border: '1px solid rgba(0,0,0,.23)',
    borderRadius: '4px',
    marginTop: '1rem',
    paddingLeft: '1px',
    paddingRight: '1px',
    position: 'relative',
    '&:hover': {
      borderColor: 'rgba(0, 0, 0, 0.87)',
    },
    '&.boxError': {
      borderColor: theme.palette.primary.main,
    },
    '&.boxError > label': {
      color: theme.palette.primary.main,
    },
  },
}));

export default useStyles;
