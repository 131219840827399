import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Box,
  FormHelperText,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useRollbar } from '@rollbar/react';
import { addBundleOptionsToBundleAction } from '../../../../actions/bundlesAction';
import { trimString } from '../../../../helpers/Helpers';
import useStyles from './BundleOptionsStyles';
import BundleOption from './BundleOption';
import BundleOptionCreate from '../menuBundleOption/BundleOptionCreate';
import BundleOptionEditDialog from '../menuBundleOption/BundleOptionEditDialog';
import { dialogContexts } from '../../../../constants/constants';
import BundleOptionAdd from '../menuBundleOption/BundleOptionAdd';

const BundleOptions = ({
  create,
  addBundleOptionsToBundleAction,
  bundleId,
  bundleOptions,
  bundles,
  menuVersion,
  products,
  bundleOptionError,
}) => {
  const rollbar = useRollbar();
  const classes = useStyles();
  const [storedOptions, setStoredOptions] = useState(bundles?.storedOptions || []);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContext, setDialogContext] = useState('');
  const [selectedOptionId, setSelectedOptionId] = useState(false);

  // Get products for each bundle option
  const [processedOptions, setProcessedOptions] = useState([]);
  useEffect(() => {
    setProcessedOptions(
      bundleOptions?.localData.map((bundleOption) => {
        let productsForBundleOption = [];

        products?.localData?.forEach((product) => {
          if (bundleOption?.productIds?.includes(product.objectId)) {
            productsForBundleOption = [...productsForBundleOption, product?.productTitle];
          }
        });

        return {
          ...bundleOption,
          labelledChoices: productsForBundleOption,
        };
      }),
    );
  }, [bundleOptions?.localData]);

  // Update stored options to reflect state
  useEffect(() => {
    setStoredOptions(bundles?.storedOptions);
  }, [bundles?.storedOptions, bundleId]);

  const bundle = bundles?.localData?.find((bundleToCheck) => bundleId === bundleToCheck.objectId);

  const onDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    // dropped in same position
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    /*
     * Re-order options
     */

    const newStoredOptions = Array.from(storedOptions);

    // Remove choice being dragged, and store it
    const dragOption = newStoredOptions.splice(source.index, 1)[0];

    // Add dragged choice back at the new index
    newStoredOptions.splice(destination.index, 0, dragOption);

    setStoredOptions(newStoredOptions);
    addBundleOptionsToBundleAction(newStoredOptions);
  };

  if (bundles?.fetching && !create) {
    return (
      <Box
        style={{
          borderTop: '1px solid rgba(0, 0, 0, 0.23) !important',
          marginTop: '1rem',
        }}
      >
        <List>
          <ListItem>
            <ListItemAvatar>
              <Skeleton variant='circle' width={40} height={40} />
            </ListItemAvatar>
            <ListItemText>
              <Skeleton height={10} width='100%' style={{ marginBottom: 6 }} />
              <Skeleton height={10} width='80%' />
            </ListItemText>
          </ListItem>
        </List>
      </Box>
    );
  }

  if (bundles?.error) {
    return (
      <Box
        style={{
          borderTop: '1px solid rgba(0, 0, 0, 0.23)',
          marginTop: '1rem',
        }}
      >
        {bundles?.errorMessage}
      </Box>
    );
  }

  return (
    <>
      <Box className={`${classes.boxOutlined}`}>
        <List dense={false}>
          <DragDropContext onDragEnd={onDragEnd}>
            {storedOptions?.length === 0 && (
              <Box className={classes.emptyText}>
                There are currently no bundle options attached to this bundle
              </Box>
            )}
            {storedOptions?.length > 0 && (
              <Droppable droppableId={bundleId} type='menu-item-option-choice'>
                {(provided, snapshot) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={`${classes.droppableMenuItemOption} ${
                      snapshot.isDraggingOver ? 'dragging-over' : ''
                    } `}
                  >
                    {storedOptions?.map((bundleOptionId, index) => {
                      const bundleOption = processedOptions.find(
                        (bundleOption) => bundleOptionId === bundleOption.objectId,
                      );

                      if (!bundleOption) {
                        rollbar.error(`Error: Missing bundle option ${bundleOptionId}`);
                        return <></>;
                      }

                      return (
                        <BundleOption
                          dialogContext={dialogContext}
                          index={index}
                          key={bundleOption.objectId}
                          bundleOption={bundleOption}
                          setDialogContext={setDialogContext}
                          setOpenDialog={setOpenDialog}
                          setSelectedOptionId={setSelectedOptionId}
                          labelledChoices={trimString(
                            bundleOption?.labelledChoices?.join(', '),
                            15,
                          )}
                          bundle={bundle}
                        />
                      );
                    })}
                  </Box>
                )}
              </Droppable>
            )}
            {!menuVersion.posEnabled && (
              <Box display='flex' style={{ marginTop: '20px' }}>
                <BundleOptionAdd
                  bundleId={bundleId}
                  setOpenDialog={setOpenDialog}
                  setDialogContext={setDialogContext}
                  setSelectedOptionId={setSelectedOptionId}
                  selectedOptions={bundles?.storedOptions}
                />
                <BundleOptionCreate
                  buttonLabel='+ CREATE NEW'
                  buttonType='text'
                  bundleId={bundleId}
                />
              </Box>
            )}
            {selectedOptionId && (
              <BundleOptionEditDialog
                bundleOption={
                  processedOptions.find(
                    (bundleOption) => bundleOption.objectId === selectedOptionId,
                  ) || {}
                }
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                dialogContext={dialogContexts.EDIT}
                setDialogContext={setDialogContext}
              />
            )}
          </DragDropContext>
        </List>
        {bundleOptionError && (
          <FormHelperText variant='outlined' error style={{ fontSize: '14px' }}>
            {bundleOptionError}
          </FormHelperText>
        )}
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  bundles: state.bundles,
  bundleOptions: state.bundleOptions,
  menuVersion: state.menuVersion,
  products: state.products,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addBundleOptionsToBundleAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(BundleOptions);
