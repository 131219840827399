import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List, Box, FormHelperText } from '@material-ui/core';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { removeSpaces } from '../../helpers/Helpers';
import useStyles from './MenuItemOptionChoicesStyles';
import MenuItemOptionChoice from '../menuItemOptionChoice/MenuItemOptionChoice';
import { reorderMenuItemOptionChoicesAction } from '../../actions/menuItemOptionChoicesAction';
import MenuItemOptionChoiceCreate from '../menuItemOptionChoiceCreate/MenuItemOptionChoiceCreate';
import MenuItemOptionChoiceEditDialog from '../menuItemOptionChoiceEdit/MenuItemOptionChoiceEditDialog';

const MenuItemOptionChoices = ({
  choiceError,
  choices,
  menuItemId,
  menuItemOptionId,
  reorderMenuItemOptionChoicesAction,
}) => {
  const classes = useStyles();

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContext, setDialogContext] = useState('');
  const [selectedChoiceIndex, setSelectedChoiceIndex] = useState(false);

  const onDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    // dropped in same position
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    /*
     * Re-order Menu item options
     */

    reorderMenuItemOptionChoicesAction(menuItemId, source.index, destination.index);
  };

  return (
    <>
      <Box className={`${classes.boxOutlined}  ${choiceError && 'boxError'}`}>
        <Box
          className={`MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-shrink MuiInputLabel-outlined ${classes.boxLabel}`}
        >
          Choices
        </Box>
        <List dense={false}>
          <DragDropContext onDragEnd={onDragEnd}>
            {choices.length > 0 && (
              <Droppable droppableId={menuItemOptionId} type='menu-item-option-choice'>
                {(provided, snapshot) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={`${classes.droppableMenuItemOptionChoice} ${
                      snapshot.isDraggingOver ? 'dragging-over' : ''
                    } `}
                  >
                    {choices.map((choice, index) => (
                      <MenuItemOptionChoice
                        choice={choice}
                        index={index}
                        key={`${index}-${removeSpaces(choice.title)}`}
                        menuItemId={menuItemId}
                        menuItemOptionId={menuItemOptionId}
                        setDialogContext={setDialogContext}
                        setOpenDialog={setOpenDialog}
                        setSelectedChoiceIndex={setSelectedChoiceIndex}
                      />
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            )}
            <MenuItemOptionChoiceCreate
              setDialogContext={setDialogContext}
              setOpenDialog={setOpenDialog}
              setSelectedChoiceIndex={setSelectedChoiceIndex}
            />
            {selectedChoiceIndex !== false && (
              <MenuItemOptionChoiceEditDialog
                choice={choices[selectedChoiceIndex] || {}}
                dialogContext={dialogContext}
                menuItemId={menuItemId}
                optionId={menuItemOptionId}
                openDialog={openDialog}
                selectedChoiceIndex={selectedChoiceIndex}
                setDialogContext={setDialogContext}
                setOpenDialog={setOpenDialog}
              />
            )}
          </DragDropContext>
        </List>
      </Box>
      {choiceError && (
        <FormHelperText variant='outlined' error>
          {choiceError}
        </FormHelperText>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ reorderMenuItemOptionChoicesAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemOptionChoices);
