import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  boxLabel: {
    backgroundColor: '#fff',
    padding: '0px 0.5rem',
  },
  boxOutlined: {
    borderColor: 'rgba(0, 0, 0, 0.23)',
    border: '1px solid rgba(0,0,0,.23)',
    borderRadius: '4px',
    marginTop: '1rem',
    padding: '20px',
    position: 'relative',
    height: '100%',
    maxHeight: '443px',
    // "&:active": {
    //   borderColor: theme.palette.primary.main,
    // },
  },
  columnTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  productListItem: {
    paddingTop: '10px',
    paddingLeft: '10px',
    fontSize: '16px',
  },
  modal: {
    minHeight: '400px',
  },
  optionTitle: {
    fontSize: '16px',
    paddingTop: '8px',
    lineHeight: '24px',
  },
  optionChoices: {
    fontSize: '14px',
    paddingTop: '8px',
    paddingLeft: '10px',
    lineHeight: '24px',
    color: '#6E6E6D',
  },
  checkbox: {},
}));

export default useStyles;
