import * as type from '../actions/types';
import {
  deleteItemsInArray,
  filterUnique,
  getItemInArray,
  insertItemInArrayAtIndex,
  updateItemInArray,
} from '../helpers/ArrayUtils';

const initialState = {
  data: [],
  localData: [],
  storedChoices: [],
  deleted: [],
  fetching: false,
  error: false,
  errorMessage: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_OPTIONS_PENDING: {
      return {
        ...initialState,
        fetching: true,
      };
    }

    case type.FETCH_OPTIONS_SUCCESS: {
      return {
        ...state,
        data: action.payload || [],
        localData: action.payload || [],
        fetching: false,
        error: false,
        errorMessage: '',
      };
    }

    case type.FETCH_OPTIONS_FAILURE: {
      return {
        ...state,
        data: [],
        localData: [],
        deleted: [],
        fetching: false,
        error: true,
        errorMessage: action.payload,
      };
    }

    case type.CREATE_OPTION: {
      const {
        objectId,
        optionTitle,
        optionDescription,
        mandatory,
        minSelections,
        maxSelections,
        restaurantId,
        posId,
        choiceIds,
      } = action.payload;

      const choiceIdsProvided = choiceIds && choiceIds?.length > 0;

      const newOption = {
        objectId,
        optionTitle,
        optionDescription,
        mandatory,
        minSelections,
        maxSelections,
        restId: restaurantId,
        posId,
        importStatus: null,
        choiceIds: choiceIdsProvided ? choiceIds : state.storedChoices,
        _created: true,
      };

      const newOptions = [...state.localData, newOption];

      return { ...state, localData: newOptions, storedChoices: [] };
    }

    case type.UPDATE_OPTION: {
      const {
        objectId,
        title,
        description,
        minSelections,
        maxSelections,
        mandatory,
        restaurantId,
        posId,
        importStatus,
      } = action.payload;

      const newOptions = updateItemInArray(state.localData, 'objectId', objectId, (option) => ({
        ...option,
        optionDescription: description,
        optionTitle: title,
        restId: restaurantId,
        minSelections,
        maxSelections,
        mandatory,
        posId,
        choiceIds: state.storedChoices,
        importStatus,
        _altered: true,
      }));

      // Update state, but also remove the temporary choices
      return { ...state, localData: newOptions, storedChoices: [] };
    }

    case type.DELETE_OPTION: {
      const { optionId } = action.payload;

      const deleted = getItemInArray(state.localData, 'objectId', optionId);

      const newDeleted = { ...deleted, _deleted: true };

      const newOptions = deleteItemsInArray(state.localData, 'objectId', optionId);

      const newDeletedItems = insertItemInArrayAtIndex(state.deleted, 0, newDeleted);

      return {
        ...state,
        localData: newOptions,
        deleted: newDeletedItems,
      };
    }

    case type.CLEAR_OPTIONS: {
      return initialState;
    }

    case type.ADD_CHOICE_TO_OPTION: {
      const { choiceId } = action.payload;

      let choiceIds = Array.from(state.storedChoices);
      choiceIds = [...state.storedChoices, choiceId].filter(filterUnique);

      // Can't update the option itself until it's saved, so store the choices
      // somewhere else temporarily.

      return { ...state, storedChoices: choiceIds };
    }

    case type.ADD_CHOICES_TO_OPTION: {
      const { choiceIds } = action.payload;

      return { ...state, storedChoices: choiceIds || [] };
    }

    case type.REMOVE_CHOICE_FROM_OPTION: {
      const { choiceId } = action.payload;

      return {
        ...state,
        storedChoices: state.storedChoices.filter((storedChoice) => storedChoice !== choiceId),
      };
    }

    case type.CLEAR_CHOICES: {
      return {
        ...state,
        storedChoices: [],
      };
    }

    // Remove the choice from all options that contain it
    case type.DELETE_CHOICE: {
      const { choiceId } = action.payload;

      const updatedOptions = state.localData.map((option) => {
        if (!option?.choiceIds?.includes(choiceId)) {
          return option;
        }

        const newChoices = option?.choiceIds?.filter(
          (optionChoiceId) => optionChoiceId !== choiceId,
        );
        const updatedOption = {
          ...option,
          choiceIds: newChoices,
          _altered: true,
        };
        return updatedOption;
      });

      // Also remove from stored choices
      const newStoredChoices = state.storedChoices.filter(
        (storedChoiceId) => storedChoiceId !== choiceId,
      );

      return {
        ...state,
        localData: updatedOptions,
        storedChoices: newStoredChoices,
      };
    }

    case type.BULK_REVIEW_OPTIONS: {
      const { optionIds } = action.payload;

      const newOptions = state.localData.map((option) => {
        if (optionIds?.includes(option.objectId)) {
          return { ...option, importStatus: 'reviewed', _altered: true };
        }
        return option;
      });

      return {
        ...state,
        localData: newOptions,
      };
    }

    /*
     * BACKEND SAVES
     */

    case type.BACKEND_SAVE_OPTION_SUCCESS: {
      const { savedItem, itemObjectId } = action.payload;

      const newOptions = state.localData.map((item) => {
        if (item.objectId === itemObjectId) {
          return savedItem;
        }

        return item;
      });

      return {
        ...state,
        data: newOptions,
        localData: newOptions,
      };
    }

    case type.BACKEND_SAVE_OPTIONS_SUCCESS: {
      const newOptions = state.localData.map(({ _altered, _created, _objectId, ...rest }) => rest);

      return {
        ...state,
        data: newOptions,
        localData: newOptions,
        storedChoices: [],
        deleted: [],
      };
    }

    default: {
      return state;
    }
  }
};
