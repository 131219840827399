// AUTH
export const SIGNOUT = 'SIGNOUT';
export const SIGNIN_PENDING = 'SIGNIN_PENDING';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_FAILURE = 'SIGNIN_FAILURE';

// LOADING
export const SET_ID_APP_LOADING = 'SET_ID_APP_LOADING';
export const REMOVE_ID_APP_LOADING = 'REMOVE_ID_APP_LOADING';

// TOATS
export const SET_TOAST = 'SET_TOAST';
export const UNSET_TOAST = 'UNSET_TOAST';

// RESTAURANTS
export const FETCH_RESTAURANT_PENDING = 'FETCH_RESTAURANT_PENDING';
export const FETCH_RESTAURANT_SUCCESS = 'FETCH_RESTAURANT_SUCCESS';
export const FETCH_RESTAURANT_FAILURE = 'FETCH_RESTAURANT_FAILURE';
export const FETCH_RESTAURANTS_PENDING = 'FETCH_RESTAURANTS_PENDING';
export const FETCH_RESTAURANTS_SUCCESS = 'FETCH_RESTAURANTS_SUCCESS';
export const FETCH_RESTAURANTS_FAILURE = 'FETCH_RESTAURANTS_FAILURE';
export const FETCH_LAST_UPDATED_RESTAURANTS_PENDING = 'FETCH_LAST_UPDATED_RESTAURANTS_PENDING';
export const FETCH_LAST_UPDATED_RESTAURANTS_SUCCESS = 'FETCH_LAST_UPDATED_RESTAURANTS_SUCCESS';
export const FETCH_LAST_UPDATED_RESTAURANTS_FAILURE = 'FETCH_LAST_UPDATED_RESTAURANTS_FAILURE';
export const UPDATE_RESTAURANT_VERSION_PENDING = 'UPDATE_RESTAURANT_VERSION_PENDING';
export const UPDATE_RESTAURANT_VERSION_SUCCESS = 'UPDATE_RESTAURANT_VERSION_SUCCESS';
export const UPDATE_RESTAURANT_VERSION_FAILURE = 'UPDATE_RESTAURANT_VERSION_FAILURE';

// MENU VERSION
export const SET_MENU_VERSION = 'SET_MENU_VERSION';

// MENUS
export const FETCH_MENUS_PENDING = 'FETCH_MENUS_PENDING';
export const FETCH_MENUS_SUCCESS = 'FETCH_MENUS_SUCCESS';
export const FETCH_MENUS_FAILURE = 'FETCH_MENUS_FAILURE';
export const DELETE_MENU = 'DELETE_MENU';
export const CREATE_MENU = 'CREATE_MENU';
export const UPDATE_MENU = 'UPDATE_MENU';
export const CLEAR_MENUS = 'CLEAR_MENUS';
export const COPY_MENU_PENDING = 'COPY_MENU_PENDING';
export const COPY_MENU_SUCCESS = 'COPY_MENU_SUCCESS';
export const COPY_MENU_FAILURE = 'COPY_MENU_FAILURE';
export const COPY_LEGACY_MENU_PENDING = 'COPY_LEGACY_MENU_PENDING';
export const COPY_LEGACY_MENU_SUCCESS = 'COPY_LEGACY_MENU_SUCCESS';
export const COPY_LEGACY_MENU_FAILURE = 'COPY_LEGACY_MENU_FAILURE';
export const UPDATE_MENU_TAB = 'UPDATE_MENU_TAB';
export const SET_SHOULD_FETCH_MENUS = 'SET_SHOULD_FETCH_MENUS';

// MENU ITEMS
export const FETCH_MENU_ITEMS_PENDING = 'FETCH_MENU_ITEMS_PENDING';
export const FETCH_MENU_ITEMS_SUCCESS = 'FETCH_MENU_ITEMS_SUCCESS';
export const FETCH_MENU_ITEMS_FAILURE = 'FETCH_MENU_ITEMS_FAILURE';
export const CREATE_MENU_ITEM = 'CREATE_MENU_ITEM';
export const UPDATE_MENU_ITEM = 'UPDATE_MENU_ITEM';
export const DELETE_MENU_ITEM = 'DELETE_MENU_ITEM';
export const CLEAR_MENU_ITEMS = 'CLEAR_MENU_ITEMS';
export const RESET_MENU_ITEMS = 'RESET_MENU_ITEMS';
export const DISABLE_MENU_ITEM = 'DISABLE_MENU_ITEM';
export const DISABLE_MENU_ITEM_UNTIL_DATE = 'DISABLE_MENU_ITEM_UNTIL_DATE';
export const SAVE_MENU_ITEMS_LOCAL = 'SAVE_MENU_ITEMS_LOCAL';

// MENU ITEM CATEGORIES
export const CREATE_MENU_ITEM_CATEGORY = 'CREATE_MENU_ITEM_CATEGORY';
export const UPDATE_MENU_ITEM_CATEGORY = 'UPDATE_MENU_ITEM_CATEGORY';
export const DELETE_MENU_ITEM_CATEGORY = 'DELETE_MENU_ITEM_CATEGORY';

// MENU ITEM OPTIONS
export const FETCH_MENU_ITEM_OPTIONS_PENDING = 'FETCH_MENU_ITEM_OPTIONS_PENDING';
export const FETCH_MENU_ITEM_OPTIONS_SUCCESS = 'FETCH_MENU_ITEM_OPTIONS_SUCCESS';
export const FETCH_MENU_ITEM_OPTIONS_FAILURE = 'FETCH_MENU_ITEM_OPTIONS_FAILURE';
export const CREATE_MENU_ITEM_OPTION = 'CREATE_MENU_ITEM_OPTION';
export const UPDATE_MENU_ITEM_OPTION = 'UPDATE_MENU_ITEM_OPTION';
export const DELETE_MENU_ITEM_OPTION = 'DELETE_MENU_ITEM_OPTION';
export const REORDER_MENU_ITEM_OPTIONS = 'REORDER_MENU_ITEM_OPTIONS';
export const SAVE_INITIAL_MENU_ITEM_OPTIONS = 'SAVE_INITIAL_MENU_ITEM_OPTIONS';
export const CLEAR_INITIAL_MENU_ITEM_OPTIONS = 'CLEAR_INITIAL_MENU_ITEM_OPTIONS';
export const CANCEL_MENU_ITEM_OPTIONS = 'CANCEL_MENU_ITEM_OPTIONS';

// MENU ITEM OPTION CHOICES
export const CREATE_MENU_ITEM_OPTION_CHOICE = 'CREATE_MENU_ITEM_OPTION_CHOICE';
export const UPDATE_MENU_ITEM_OPTION_CHOICE = 'UPDATE_MENU_ITEM_OPTION_CHOICE';
export const DELETE_MENU_ITEM_OPTION_CHOICE = 'DELETE_MENU_ITEM_OPTION_CHOICE';
export const DISABLE_MENU_ITEM_OPTION_CHOICE = 'DISABLE_MENU_ITEM_OPTION_CHOICE';
export const REORDER_MENU_ITEM_OPTION_CHOICES = 'REORDER_MENU_ITEM_OPTION_CHOICES';
export const SAVE_INITIAL_MENU_ITEM_OPTION_CHOICES = 'SAVE_INITIAL_MENU_ITEM_OPTION_CHOICES';
export const CLEAR_INITIAL_MENU_ITEM_OPTION_CHOICES = 'CLEAR_INITIAL_MENU_ITEM_OPTION_CHOICES';
export const CANCEL_MENU_ITEM_OPTION_CHOICES = 'CANCEL_MENU_ITEM_OPTION_CHOICES';

// IMPORT OPTIONS
export const FETCH_IMPORT_OPTIONS_PENDING = 'FETCH_IMPORT_OPTIONS_PENDING';
export const FETCH_IMPORT_OPTIONS_SUCCESS = 'FETCH_IMPORT_OPTIONS_SUCCESS';
export const FETCH_IMPORT_OPTIONS_FAILURE = 'FETCH_IMPORT_OPTIONS_FAILURE';
export const FETCH_IMPORT_OPTIONS_BEFORE_CREATED = 'FETCH_IMPORT_OPTIONS_BEFORE_CREATED';

// BACKEND SAVES
export const BACKEND_SAVE_PENDING = 'BACKEND_SAVE_PENDING';
export const BACKEND_SAVE_FINISH = 'BACKEND_SAVE_FINISH';
export const BACKEND_SAVE_FAILURE = 'BACKEND_SAVE_FAILURE';
export const BACKEND_SAVE_MENU_SUCCESS = 'BACKEND_SAVE_MENU_SUCCESS';
export const BACKEND_SAVE_MENUS_SUCCESS = 'BACKEND_SAVE_MENUS_SUCCESS';
export const BACKEND_SAVE_MENU_ITEM_SUCCESS = 'BACKEND_SAVE_MENU_ITEM_SUCCESS';
export const BACKEND_SAVE_MENU_ITEMS_SUCCESS = 'BACKEND_SAVE_MENU_ITEMS_SUCCESS';
export const BACKEND_SAVE_MENU_ITEM_OPTION_SUCCESS = 'BACKEND_SAVE_MENU_ITEM_OPTION_SUCCESS';
export const BACKEND_SAVE_MENU_ITEM_OPTIONS_SUCCESS = 'BACKEND_SAVE_MENU_ITEM_OPTIONS_SUCCESS';
export const BACKEND_SAVE_PRODUCT_SUCCESS = 'BACKEND_SAVE_PRODUCT_SUCCESS';
export const BACKEND_SAVE_PRODUCTS_SUCCESS = 'BACKEND_SAVE_PRODUCTS_SUCCESS';
export const BACKEND_SAVE_CATEGORY_SUCCESS = 'BACKEND_SAVE_CATEGORY_SUCCESS';
export const BACKEND_UPDATE_CATEGORY_SUCCESS = 'BACKEND_UPDATE_CATEGORY_SUCCESS';
export const BACKEND_SAVE_CATEGORIES_SUCCESS = 'BACKEND_SAVE_CATEGORIES_SUCCESS';
export const BACKEND_SAVE_OPTION_SUCCESS = 'BACKEND_SAVE_OPTION_SUCCESS';
export const BACKEND_SAVE_OPTIONS_SUCCESS = 'BACKEND_SAVE_OPTIONS_SUCCESS';
export const BACKEND_SAVE_CHOICE_SUCCESS = 'BACKEND_SAVE_CHOICE_SUCCESS';
export const BACKEND_SAVE_CHOICES_SUCCESS = 'BACKEND_SAVE_CHOICES_SUCCESS';
export const BACKEND_SAVE_BUNDLE_OPTION_SUCCESS = 'BACKEND_SAVE_BUNDLE_OPTION_SUCCESS';
export const BACKEND_SAVE_BUNDLE_OPTIONS_SUCCESS = 'BACKEND_SAVE_BUNDLE_OPTIONS_SUCCESS';
export const BACKEND_SAVE_BUNDLE_SUCCESS = 'BACKEND_SAVE_BUNDLE_SUCCESS';
export const BACKEND_SAVE_BUNDLES_SUCCESS = 'BACKEND_SAVE_BUNDLES_SUCCESS';

// PRODUCTS
export const FETCH_PRODUCTS_PENDING = 'FETCH_PRODUCTS_PENDING';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const REVIEW_PRODUCT = 'REVIEW_PRODUCT';
export const BULK_REVIEW_PRODUCTS = 'BULK_REVIEW_PRODUCTS';
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS';
export const ADD_OPTION_TO_PRODUCT = 'ADD_OPTION_TO_PRODUCT';
export const ADD_OPTIONS_TO_PRODUCT = 'ADD_OPTIONS_TO_PRODUCT';
export const ADD_OPTIONS_TO_PRODUCTS = 'ADD_OPTIONS_TO_PRODUCTS';
export const REMOVE_OPTION_FROM_PRODUCT = 'REMOVE_OPTION_FROM_PRODUCT';
export const REMOVE_OPTIONS_FROM_PRODUCTS = 'REMOVE_OPTIONS_FROM_PRODUCTS';
export const CLEAR_PRODUCT_OPTIONS = 'CLEAR_PRODUCT_OPTIONS';
export const DISABLE_LOCAL_PRODUCT_FOR_DATE = 'DISABLE_LOCAL_PRODUCT_FOR_DATE';
export const DISABLE_LOCAL_PRODUCT_UNTIL_DATE = 'DISABLE_LOCAL_PRODUCT_UNTIL_DATE';
export const RESTOCK_PRODUCT_PENDING = 'RESTOCK_PRODUCT_PENDING';
export const RESTOCK_PRODUCT_SUCCESS = 'RESTOCK_PRODUCT_SUCCESS';
export const RESTOCK_PRODUCT_FAILURE = 'RESTOCK_PRODUCT_FAILURE';
export const DISABLE_PRODUCT_FOR_DATE_PENDING = 'DISABLE_PRODUCT_FOR_DATE_PENDING';
export const DISABLE_PRODUCT_FOR_DATE_SUCCESS = 'DISABLE_PRODUCT_FOR_DATE_SUCCESS';
export const DISABLE_PRODUCT_FOR_DATE_FAILURE = 'DISABLE_PRODUCT_FOR_DATE_FAILURE';
export const DISABLE_PRODUCT_UNTIL_DATE_PENDING = 'DISABLE_PRODUCT_UNTIL_DATE_PENDING';
export const DISABLE_PRODUCT_UNTIL_DATE_SUCCESS = 'DISABLE_PRODUCT_UNTIL_DATE_SUCCESS';
export const DISABLE_PRODUCT_UNTIL_DATE_FAILURE = 'DISABLE_PRODUCT_UNTIL_DATE_FAILURE';

// OPTIONS
export const FETCH_OPTIONS_PENDING = 'FETCH_OPTIONS_PENDING';
export const FETCH_OPTIONS_SUCCESS = 'FETCH_OPTIONS_SUCCESS';
export const FETCH_OPTIONS_FAILURE = 'FETCH_OPTIONS_FAILURE';
export const CREATE_OPTION = 'CREATE_OPTION';
export const UPDATE_OPTION = 'UPDATE_OPTION';
export const DELETE_OPTION = 'DELETE_OPTION';
export const BULK_REVIEW_OPTIONS = 'BULK_REVIEW_OPTIONS';
export const CLEAR_OPTIONS = 'CLEAR_OPTIONS';
export const ADD_CHOICE_TO_OPTION = 'ADD_CHOICE_TO_OPTION';
export const ADD_CHOICES_TO_OPTION = 'ADD_CHOICES_TO_OPTION';
export const REMOVE_CHOICE_FROM_OPTION = 'REMOVE_CHOICE_FROM_OPTION';

// CATEGORIES
export const FETCH_CATEGORIES_PENDING = 'FETCH_CATEGORIES_PENDING';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE';
export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const CLEAR_CATEGORIES = 'CLEAR_CATEGORIES';
export const ADD_PRODUCT_TO_CATEGORY = 'ADD_PRODUCT_TO_CATEGORY';
export const ADD_PRODUCTS_TO_CATEGORY = 'ADD_PRODUCTS_TO_CATEGORY';
export const REMOVE_PRODUCT_FROM_CATEGORY = 'REMOVE_PRODUCT_FROM_CATEGORY';
export const ADD_BUNDLE_TO_CATEGORY = 'ADD_BUNDLE_TO_CATEGORY';
export const ADD_BUNDLES_TO_CATEGORY = 'ADD_BUNDLES_TO_CATEGORY';
export const REMOVE_BUNDLE_FROM_CATEGORY = 'REMOVE_BUNDLE_FROM_CATEGORY';

// CHOICES
export const FETCH_CHOICES_PENDING = 'FETCH_CHOICES_PENDING';
export const FETCH_CHOICES_SUCCESS = 'FETCH_CHOICES_SUCCESS';
export const FETCH_CHOICES_FAILURE = 'FETCH_CHOICES_FAILURE';
export const CREATE_CHOICE = 'CREATE_CHOICE';
export const UPDATE_CHOICE = 'UPDATE_CHOICE';
export const DELETE_CHOICE = 'DELETE_CHOICE';
export const CLEAR_CHOICES = 'CLEAR_CHOICES';
export const DISABLE_LOCAL_CHOICE_FOR_DATE = 'DISABLE_LOCAL_CHOICE_FOR_DATE';
export const DISABLE_LOCAL_CHOICE_UNTIL_DATE = 'DISABLE_LOCAL_CHOICE_UNTIL_DATE';
export const RESTOCK_CHOICE_PENDING = 'RESTOCK_CHOICE_PENDING';
export const RESTOCK_CHOICE_SUCCESS = 'RESTOCK_CHOICE_SUCCESS';
export const RESTOCK_CHOICE_FAILURE = 'RESTOCK_CHOICE_FAILURE';
export const DISABLE_CHOICE_FOR_DATE_PENDING = 'DISABLE_CHOICE_FOR_DATE_PENDING';
export const DISABLE_CHOICE_FOR_DATE_SUCCESS = 'DISABLE_CHOICE_FOR_DATE_SUCCESS';
export const DISABLE_CHOICE_FOR_DATE_FAILURE = 'DISABLE_CHOICE_FOR_DATE_FAILURE';
export const DISABLE_CHOICE_UNTIL_DATE_PENDING = 'DISABLE_CHOICE_UNTIL_DATE_PENDING';
export const DISABLE_CHOICE_UNTIL_DATE_SUCCESS = 'DISABLE_CHOICE_UNTIL_DATE_SUCCESS';
export const DISABLE_CHOICE_UNTIL_DATE_FAILURE = 'DISABLE_CHOICE_UNTIL_DATE_FAILURE';

// BREADCRUMBS
export const CLEAR_BREADCRUMBS = 'CLEAR_BREADCRUMBS';
export const ADD_BREADCRUMB = 'ADD_BREADCRUMB';
export const POP_BREADCRUMB = 'POP_BREADCRUMB';

// POS IMPORT
export const IMPORT_DOSHII_PENDING = 'IMPORT_DOSHII_PENDING';
export const IMPORT_DOSHII_SUCCESS = 'IMPORT_DOSHII_SUCCESS';
export const IMPORT_DOSHII_FAILURE = 'IMPORT_DOSHII_FAILURE';

export const SET_POS_ID_PENDING = 'SET_POS_ID_PENDING';
export const SET_POS_ID_SUCCESS = 'SET_POS_ID_SUCCESS';
export const SET_POS_ID_FAILURE = 'SET_POS_ID_FAILURE';

// CHANGE REQUESTS
export const FETCH_ACTIVE_CHANGE_REQUESTS_PENDING = 'FETCH_ACTIVE_CHANGE_REQUESTS_PENDING';
export const FETCH_ACTIVE_CHANGE_REQUESTS_SUCCESS = 'FETCH_ACTIVE_CHANGE_REQUESTS_SUCCESS';
export const FETCH_ACTIVE_CHANGE_REQUESTS_FAILURE = 'FETCH_ACTIVE_CHANGE_REQUESTS_FAILURE';
export const FETCH_ACTIVE_CHANGE_REQUESTS_FOR_RESTAURANT_PENDING =
  'FETCH_ACTIVE_CHANGE_REQUESTS_FOR_RESTAURANT_PENDING';
export const FETCH_ACTIVE_CHANGE_REQUESTS_FOR_RESTAURANT_SUCCESS =
  'FETCH_ACTIVE_CHANGE_REQUESTS_FOR_RESTAURANT_SUCCESS';
export const FETCH_ACTIVE_CHANGE_REQUESTS_FOR_RESTAURANT_FAILURE =
  'FETCH_ACTIVE_CHANGE_REQUESTS_FOR_RESTAURANT_FAILURE';
export const COMPLETE_CHANGE_REQUEST_PENDING = 'COMPLETE_CHANGE_REQUEST_PENDING';
export const COMPLETE_CHANGE_REQUEST_SUCCESS = 'COMPLETE_CHANGE_REQUEST_SUCCESS';
export const COMPLETE_CHANGE_REQUEST_FAILURE = 'COMPLETE_CHANGE_REQUEST_FAILURE';
export const FETCH_COMPLETED_CHANGE_REQUESTS_PENDING = 'FETCH_COMPLETED_CHANGE_REQUESTS_PENDING';
export const FETCH_COMPLETED_CHANGE_REQUESTS_SUCCESS = 'FETCH_COMPLETED_CHANGE_REQUESTS_SUCCESS';
export const FETCH_COMPLETED_CHANGE_REQUESTS_FAILURE = 'FETCH_COMPLETED_CHANGE_REQUESTS_FAILURE';

// BUNDLE OPTIONS
export const FETCH_BUNDLE_OPTIONS_PENDING = 'FETCH_BUNDLE_OPTIONS_PENDING';
export const FETCH_BUNDLE_OPTIONS_SUCCESS = 'FETCH_BUNDLE_OPTIONS_SUCCESS';
export const FETCH_BUNDLE_OPTIONS_FAILURE = 'FETCH_BUNDLE_OPTIONS_FAILURE';
export const CREATE_BUNDLE_OPTION = 'CREATE_BUNDLE_OPTION';
export const UPDATE_BUNDLE_OPTION = 'UPDATE_BUNDLE_OPTION';
export const DELETE_BUNDLE_OPTION = 'DELETE_BUNDLE_OPTION';
export const BULK_REVIEW_BUNDLE_OPTIONS = 'BULK_REVIEW_BUNDLE_OPTIONS';
export const ADD_PRODUCT_TO_BUNDLE_OPTION = 'ADD_PRODUCT_TO_BUNDLE_OPTION';
export const ADD_PRODUCTS_TO_BUNDLE_OPTION = 'ADD_PRODUCTS_TO_BUNDLE_OPTION';
export const REMOVE_PRODUCT_FROM_BUNDLE_OPTION = 'REMOVE_PRODUCT_FROM_BUNDLE_OPTION';
export const CLEAR_PRODUCTS_FROM_BUNDLE_OPTION = 'CLEAR_PRODUCTS_FROM_BUNDLE_OPTION';

// BUNDLES
export const FETCH_BUNDLES_PENDING = 'FETCH_BUNDLES_PENDING';
export const FETCH_BUNDLES_SUCCESS = 'FETCH_BUNDLES_SUCCESS';
export const FETCH_BUNDLES_FAILURE = 'FETCH_BUNDLES_FAILURE';
export const CREATE_BUNDLE = 'CREATE_BUNDLE';
export const UPDATE_BUNDLE = 'UPDATE_BUNDLE';
export const DELETE_BUNDLE = 'DELETE_BUNDLE';
export const REVIEW_BUNDLE = 'REVIEW_BUNDLE';
export const CLEAR_BUNDLES = 'CLEAR_BUNDLES';
export const ADD_BUNDLE_OPTION_TO_BUNDLE = 'ADD_BUNDLE_OPTION_TO_BUNDLE';
export const ADD_BUNDLE_OPTIONS_TO_BUNDLE = 'ADD_BUNDLE_OPTIONS_TO_BUNDLE';
export const ADD_BUNDLE_OPTIONS_TO_BUNDLES = 'ADD_BUNDLE_OPTIONS_TO_BUNDLES';
export const REMOVE_BUNDLE_OPTION_FROM_BUNDLE = 'REMOVE_BUNDLE_OPTION_FROM_BUNDLE';
export const REMOVE_BUNDLE_OPTIONS_FROM_BUNDLES = 'REMOVE_BUNDLE_OPTIONS_FROM_BUNDLES';
export const CLEAR_BUNDLE_OPTIONS_FROM_BUNDLE = 'CLEAR_BUNDLE_OPTIONS_FROM_BUNDLE';
export const DISABLE_LOCAL_BUNDLE_FOR_DATE = 'DISABLE_LOCAL_BUNDLE_FOR_DATE';
export const DISABLE_LOCAL_BUNDLE_UNTIL_DATE = 'DISABLE_LOCAL_BUNDLE_UNTIL_DATE';
export const RESTOCK_BUNDLE_PENDING = 'RESTOCK_BUNDLE_PENDING';
export const RESTOCK_BUNDLE_SUCCESS = 'RESTOCK_BUNDLE_SUCCESS';
export const RESTOCK_BUNDLE_FAILURE = 'RESTOCK_BUNDLE_FAILURE';
export const DISABLE_BUNDLE_FOR_DATE_PENDING = 'DISABLE_BUNDLE_FOR_DATE_PENDING';
export const DISABLE_BUNDLE_FOR_DATE_SUCCESS = 'DISABLE_BUNDLE_FOR_DATE_SUCCESS';
export const DISABLE_BUNDLE_FOR_DATE_FAILURE = 'DISABLE_BUNDLE_FOR_DATE_FAILURE';
export const DISABLE_BUNDLE_UNTIL_DATE_PENDING = 'DISABLE_BUNDLE_UNTIL_DATE_PENDING';
export const DISABLE_BUNDLE_UNTIL_DATE_SUCCESS = 'DISABLE_BUNDLE_UNTIL_DATE_SUCCESS';
export const DISABLE_BUNDLE_UNTIL_DATE_FAILURE = 'DISABLE_BUNDLE_UNTIL_DATE_FAILURE';
