import * as type from '../actions/types';

const initialState = {
  saving: false,
  error: false,
  errorMessage: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case type.BACKEND_SAVE_PENDING: {
      return {
        ...state,
        saving: true,
      };
    }

    case type.BACKEND_SAVE_FAILURE: {
      const { errorMessage } = action.payload;
      return {
        ...state,
        saving: false,
        error: true,
        errorMessage,
      };
    }

    case type.BACKEND_SAVE_FINISH: {
      return {
        ...state,
        saving: false,
      };
    }

    default: {
      return state;
    }
  }
}
