import React, { useState } from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import MenuCategoryCreateDialog from './MenuCategoryCreateDialog';

const MenuCategoryCreate = ({ menuId, categories }) => {
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <>
      <List disablePadding aria-label='menu category'>
        <ListItem button onClick={() => setOpenDialog(true)}>
          <ListItemIcon>
            <Add />
          </ListItemIcon>
          <ListItemText
            primary='Add New Category'
            primaryTypographyProps={{
              variant: 'button',
              color: 'textSecondary',
            }}
          />
        </ListItem>
      </List>
      <MenuCategoryCreateDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        menuId={menuId}
        categories={categories}
      />
    </>
  );
};

export default MenuCategoryCreate;
