import * as type from '../actions/types';

const initialState = {
  data: [],
  loading: false,
  error: false,
  errorMessage: '',
  success: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case type.SIGNIN_PENDING: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        errorMessage: false,
      };
    }

    case type.SIGNIN_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        errorMessage: '',
        success: true,
      };
    }

    case type.SIGNIN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
