// NOTE: Checks if name exists else returns empty,
// or if number turn into string to easily compare
export const sortByName = (a, b) => (a || '').toString().localeCompare((b || '').toString());

// NOTE: Checks if name exists else returns empty,
// or if number turn into string to easily compare
export const sortByTwoNames = (a1, b1, a2, b2) => {
  const sort1 = (a1 || '').toString().localeCompare((b1 || '').toString());
  const sort2 = (a2 || '').toString().localeCompare((b2 || '').toString());
  return sort1 || sort2;
};
