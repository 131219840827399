import React, { useEffect, useState } from 'react';
import { SecondaryButton } from '../Button/SecondaryButton';
import BundleOptionProductAddDialog from './BundleOptionProductAddDialog';

const BundleOptionProductAdd = ({ lastOption, bundleOptionId, selectedProducts, setProducts }) => {
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <>
      <SecondaryButton
        onClick={() => {
          setOpenDialog(true);
        }}
        style={{ text: { fontWeight: 'bold' } }}
      >
        ADD NEW
      </SecondaryButton>
      <BundleOptionProductAddDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        bundleOptionId={bundleOptionId}
        defaultSelectedItems={selectedProducts}
        setProducts={setProducts}
      />
    </>
  );
};

export default BundleOptionProductAdd;
