import * as type from '../actions/types';
import {
  deleteItemsInArray,
  filterUnique,
  getItemInArray,
  insertItemInArrayAtIndex,
  updateItemInArray,
} from '../helpers/ArrayUtils';

const initialState = {
  data: [],
  localData: [],
  storedOptions: [], // TODO this should not be in redux
  deleted: [],
  fetching: false,
  error: false,
  errorMessage: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case type.FETCH_PRODUCTS_PENDING: {
      return {
        ...initialState,
        fetching: true,
      };
    }

    case type.FETCH_PRODUCTS_SUCCESS: {
      return {
        ...state,
        data: action.payload || [],
        localData: action.payload || [],
        fetching: false,
        error: false,
        errorMessage: '',
      };
    }

    case type.FETCH_PRODUCTS_FAILURE: {
      return {
        ...state,
        data: [],
        localData: [],
        deleted: [],
        fetching: false,
        error: true,
        errorMessage: action.payload,
      };
    }

    case type.CREATE_PRODUCT: {
      const {
        productId,
        productTitle,
        productDescription,
        imageLink,
        image,
        price,
        noDiscount,
        disableDate,
        disableUntilDate,
        restId,
        posId,
      } = action.payload;

      const newProduct = {
        objectId: productId,
        productTitle,
        productDescription,
        imageLink,
        ...(Object?.keys(image).length > 0 && { _image: image }),
        price,
        noDiscount,
        disableDate,
        disableUntilDate,
        restId,
        posId,
        importState: null,
        optionIds: state.storedOptions,
        _created: true,
      };

      const newProducts = [...state.localData, newProduct];

      return { ...state, localData: newProducts, storedOptions: [] };
    }

    case type.UPDATE_PRODUCT: {
      const {
        objectId,
        productTitle,
        productDescription,
        price,
        imageLink,
        image,
        noDiscount,
        disableDate,
        disableUntilDate,
        restId,
        posId,
        importStatus,
      } = action.payload;

      const newProducts = updateItemInArray(state.localData, 'objectId', objectId, (product) => ({
        ...product,
        productTitle,
        productDescription,
        price,
        imageLink,
        noDiscount,
        disableDate,
        disableUntilDate,
        restId,
        posId,
        optionIds: state.storedOptions,
        importStatus,
        _image: image,
        _altered: true,
      }));

      return { ...state, localData: newProducts, storedOptions: [] };
    }

    case type.DELETE_PRODUCT: {
      const { productId } = action.payload;

      const deleted = getItemInArray(state.localData, 'objectId', productId);

      const newDeleted = { ...deleted, _deleted: true };

      const newProducts = deleteItemsInArray(state.localData, 'objectId', productId);

      const newDeletedItems = insertItemInArrayAtIndex(state.deleted, 0, newDeleted);

      return {
        ...state,
        localData: newProducts,
        deleted: newDeletedItems,
      };
    }

    case type.REVIEW_PRODUCT: {
      const { productId } = action.payload;

      const newProducts = updateItemInArray(state.localData, 'objectId', productId, (product) => ({
        ...product,
        importStatus: 'reviewed',
        _altered: true,
      }));

      return {
        ...state,
        localData: newProducts,
      };
    }

    case type.BULK_REVIEW_PRODUCTS: {
      const { productIds } = action.payload;

      const newProducts = state.localData.map((product) => {
        if (productIds?.includes(product.objectId)) {
          return { ...product, importStatus: 'reviewed', _altered: true };
        }
        return product;
      });

      return {
        ...state,
        localData: newProducts,
      };
    }

    case type.CLEAR_PRODUCTS: {
      return initialState;
    }

    case type.ADD_OPTION_TO_PRODUCT: {
      const { optionId } = action.payload;

      const newStoredOptions = [...state.storedOptions, optionId].filter(filterUnique);

      return { ...state, storedOptions: newStoredOptions };
    }

    case type.ADD_OPTIONS_TO_PRODUCT: {
      const { options } = action.payload;

      const newStoredOptions = [...options].filter(filterUnique);

      return { ...state, storedOptions: newStoredOptions };
    }

    case type.ADD_OPTIONS_TO_PRODUCTS: {
      const { productIds, optionIds } = action.payload;

      // Update every single product to add the new options
      const newProducts = state.localData.map((product) => {
        if (productIds?.includes(product.objectId)) {
          const newOptions = [...(product.optionIds || []), ...optionIds].filter(filterUnique);
          return {
            ...product,
            optionIds: newOptions,
            _altered: true,
          };
        }

        return product;
      });

      return { ...state, localData: newProducts, storedOptions: [] };
    }

    case type.REMOVE_OPTION_FROM_PRODUCT: {
      const { optionId } = action.payload;

      return {
        ...state,
        storedOptions: state.storedOptions.filter((storedOption) => storedOption !== optionId),
      };
    }

    case type.REMOVE_OPTIONS_FROM_PRODUCTS: {
      const { productIds, optionIds } = action.payload;

      // Update every single product to add the new options
      const newProducts = state.localData.map((product) => {
        if (productIds.includes(product.objectId)) {
          const newOptions = product.optionIds.filter((optionId) => !optionIds.includes(optionId));
          return {
            ...product,
            optionIds: newOptions,
            _altered: true,
          };
        }

        return product;
      });

      return { ...state, localData: newProducts, storedOptions: [] };
    }

    case type.CLEAR_PRODUCT_OPTIONS: {
      return { ...state, storedOptions: [] };
    }

    case type.DISABLE_LOCAL_PRODUCT_FOR_DATE: {
      const { productId, disableDate } = action.payload;
      const newProducts = updateItemInArray(state.localData, 'objectId', productId, (product) => ({
        ...product,
        disableDate,
      }));

      return {
        ...state,
        localData: newProducts,
      };
    }

    case type.DISABLE_LOCAL_PRODUCT_UNTIL_DATE: {
      const { productId, disableUntilDate } = action.payload;
      const newProducts = updateItemInArray(state.localData, 'objectId', productId, (product) => ({
        ...product,
        disableUntilDate,
      }));

      return {
        ...state,
        localData: newProducts,
      };
    }

    case type.RESTOCK_PRODUCT_SUCCESS: {
      const { objectId, disableDate, disableUntilDate } = action.payload;

      const newProducts = updateItemInArray(state.localData, 'objectId', objectId, (product) => ({
        ...product,
        disableDate,
        disableUntilDate,
      }));

      return {
        ...state,
        localData: newProducts,
      };
    }

    case type.DISABLE_PRODUCT_FOR_DATE_SUCCESS: {
      const { objectId, disableDate } = action.payload;
      const newProducts = updateItemInArray(state.localData, 'objectId', objectId, (product) => ({
        ...product,
        disableDate,
      }));

      return {
        ...state,
        localData: newProducts,
      };
    }

    case type.DISABLE_PRODUCT_UNTIL_DATE_SUCCESS: {
      const { objectId, disableUntilDate } = action.payload;
      const newProducts = updateItemInArray(state.localData, 'objectId', objectId, (product) => ({
        ...product,
        disableUntilDate,
      }));

      return {
        ...state,
        localData: newProducts,
      };
    }

    // Remove the option from all products that contain it
    case type.DELETE_OPTION: {
      const { optionId } = action.payload;

      const updatedProducts = state.localData.map((product) => {
        if (!product?.optionIds?.includes(optionId)) {
          return product;
        }

        const newOptions = product?.optionIds?.filter(
          (productOptionId) => productOptionId !== optionId,
        );
        const updatedProduct = {
          ...product,
          optionIds: newOptions,
          _altered: true,
        };
        return updatedProduct;
      });

      // Also remove from stored options
      const newStoredOptions = state.storedOptions.filter(
        (storedOption) => storedOption !== optionId,
      );

      return {
        ...state,
        localData: updatedProducts,
        storedOptions: newStoredOptions,
      };
    }

    /*
     * BACKEND SAVES
     */

    case type.BACKEND_SAVE_PRODUCT_SUCCESS: {
      const { savedItem, itemObjectId } = action.payload;

      const newProducts = state.localData.map((item) => {
        if (item.objectId === itemObjectId) {
          return savedItem;
        }

        return item;
      });

      return {
        ...state,
        data: newProducts,
        localData: newProducts,
      };
    }

    case type.BACKEND_SAVE_PRODUCTS_SUCCESS: {
      const newProducts = state.localData.map(({ _altered, _created, _objectId, ...rest }) => rest);

      return {
        ...state,
        data: newProducts,
        localData: newProducts,
        storedOptions: [],
        deleted: [],
      };
    }

    default: {
      return state;
    }
  }
}
