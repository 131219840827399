import React from 'react';
import { Box, IconButton, FormHelperText, Tooltip } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import Resizer from 'react-image-file-resizer';
import useStyles from './MenuItemImageUploadStyles';

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      750,
      563,
      'JPEG',
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      'blob', // "base64"
      750,
      563,
    );
  });

const MenuItemImageUpload = ({ itemId, imageLink, setImageLink, setImage }) => {
  const classes = useStyles();

  const deletePhoto = () => {
    setImageLink('');
    setImage({});
  };

  const handleChange = async (event) => {
    const fileForUpload = event.target.files[0];

    const newFile = await resizeFile(fileForUpload);

    // NOTE: turn local image into blob
    const src = URL.createObjectURL(newFile);

    const imageType = newFile.type.split('/')[1];
    const name = `${itemId}-${new Date().getTime()}.${imageType}`;

    setImage({ file: newFile, name });
    setImageLink(src);
  };

  return (
    <>
      <Box className={classes.boxOutlined}>
        <Box className={classes.boxLabel}>Photo</Box>

        {imageLink ? (
          <Box display='flex' alignItems='center' justifyContent='space-between'>
            <div>
              <img className={classes.thumbnail} src={imageLink} alt='menu item' />
            </div>
            <Tooltip title='Remove Photo'>
              <IconButton edge='end' onClick={deletePhoto}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        ) : (
          <input type='file' onChange={handleChange} />
        )}
      </Box>
      <FormHelperText variant='outlined'>(Image size: 750 x 563 pixels)</FormHelperText>
    </>
  );
};

export default MenuItemImageUpload;
