const getColor = (context) => {
  switch (context) {
    case 'success':
      return 'lawngreen';
    case 'error':
      return 'hotpink';
    case 'info':
      return 'DodgerBlue';
    default:
      return 'white';
  }
};

/**
 * Log a `msg` to console only in dev/demo mode.
 *
 * @param context Success, Tracking or Danger
 * @param name Name of console
 * @param msg Message to log
 */
export const devLog = (context = 'success', name, msg) => {
  if (!process.env.REACT_APP_MODE || process.env.REACT_APP_MODE === 'development') {
    console.log(`%c${context.toUpperCase()} ${name}: `, `color: ${getColor(context)}`, msg);
  }
};
