import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List, Box, FormHelperText } from '@material-ui/core';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useRollbar } from '@rollbar/react';
import { removeSpaces } from '../../../../helpers/Helpers';
import OptionChoice from './OptionChoice';
import OptionChoiceAdd from './OptionChoiceAdd';
import OptionChoiceEditDialog from './OptionChoiceEditDialog';
import useStyles from './OptionChoicesStyles';
import { addChoicesToOptionAction } from '../../../../actions/optionsAction';

const OptionChoices = ({
  choiceError,
  choices,
  optionId,
  option,
  options,
  setOptionChoices,
  addChoicesToOptionAction,
  menuVersion,
}) => {
  const rollbar = useRollbar();
  const classes = useStyles();

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContext, setDialogContext] = useState('');
  const [selectedChoiceIndex, setSelectedChoiceIndex] = useState(false);

  const [storedChoices, setStoredChoices] = useState(options.storedChoices || []);

  const hydratedChoices = storedChoices.map((optionChoiceId) =>
    choices.localData.find((choice) => optionChoiceId === choice.objectId),
  );

  // Update stored choices to reflect state
  useEffect(() => {
    setStoredChoices(options.storedChoices);
  }, [options.storedChoices, optionId]);

  const onDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    // dropped in same position
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    /*
     * Re-order choices
     */

    const newStoredChoices = Array.from(storedChoices);

    // Remove choice being dragged, and store it
    const dragChoice = newStoredChoices.splice(source.index, 1)[0];

    // Add dragged choice back at the new index
    newStoredChoices.splice(destination.index, 0, dragChoice);
    addChoicesToOptionAction(newStoredChoices);
  };

  // Find choices with IDs that this object has
  useEffect(() => {
    const optionChoiceIds = option.choiceIds ? option.choiceIds : options.storedChoices;
    setOptionChoices(
      choices.localData.filter((choice) => optionChoiceIds.includes(choice.objectId)),
    );
  }, [options.storedChoices, choices.localData]);

  return (
    <>
      <Box className={`${classes.boxOutlined}`}>
        <List dense={false}>
          <DragDropContext onDragEnd={onDragEnd}>
            {storedChoices.length === 0 && (
              <Box className={classes.emptyText}>
                There are currently no choices attached to this option
              </Box>
            )}
            {storedChoices.length > 0 && (
              <Droppable droppableId={optionId} type='menu-item-option-choice'>
                {(provided, snapshot) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={`${classes.droppableMenuItemOptionChoice} ${
                      snapshot.isDraggingOver ? 'dragging-over' : ''
                    } `}
                  >
                    {storedChoices.map((optionChoiceId, index) => {
                      const optionChoice = choices.localData.find(
                        (choice) => optionChoiceId === choice.objectId,
                      );

                      // Don't show if no choice. e.g. race condition when a choice is
                      // removed from all choices before removed from option
                      if (!optionChoice) {
                        rollbar.error(`Error: Missing option choice ${optionChoiceId}`);
                        return <></>;
                      }

                      return (
                        <OptionChoice
                          choice={optionChoice}
                          index={index}
                          key={`${index}-${removeSpaces(optionChoice?.choiceTitle)}`}
                          optionId={optionId}
                          setDialogContext={setDialogContext}
                          setOpenDialog={setOpenDialog}
                          setSelectedChoiceIndex={setSelectedChoiceIndex}
                        />
                      );
                    })}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            )}
            {!menuVersion.posEnabled && (
              <Box style={{ marginTop: '20px' }}>
                <OptionChoiceAdd selectedChoices={options.storedChoices} optionId={optionId} />
              </Box>
            )}
            {selectedChoiceIndex !== false && (
              <OptionChoiceEditDialog
                choice={hydratedChoices[selectedChoiceIndex] || {}}
                dialogContext={dialogContext}
                openDialog={openDialog}
                selectedChoiceIndex={selectedChoiceIndex}
                setDialogContext={setDialogContext}
                setOpenDialog={setOpenDialog}
              />
            )}
          </DragDropContext>
        </List>
        {choiceError && (
          <FormHelperText variant='outlined' error style={{ fontSize: '14px' }}>
            {choiceError}
          </FormHelperText>
        )}
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  options: state.options,
  choices: state.choices,
  menuVersion: state.menuVersion,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ addChoicesToOptionAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OptionChoices);
