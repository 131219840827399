import React, { useState, useEffect } from 'react';
import { Hub } from 'aws-amplify';
import {
  Authenticator,
  Greetings,
  SignUp,
  ForgotPassword,
  SignIn,
  VerifyContact,
  Loading,
  TOTPSetup,
  ConfirmSignUp,
  RequireNewPassword,
  ConfirmSignIn,
} from 'aws-amplify-react';
import config from '../aws-exports';
import CustomSignIn from './CustomSignIn';
import App from '../App';

const AppWithAuth = () => {
  const [authSignedIn, setAuthSignedIn] = useState(false);
  const [authLoading, setAuthLoading] = useState(false);

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event } }) => {
      switch (event) {
        case 'signIn':
          setAuthSignedIn(true);
          break;
        case 'signOut':
          setAuthSignedIn(false);
          setAuthLoading(false);
          break;
        case 'customOAuthState':
          break;
        case 'signIn_failure':
          // setAuthLoading(false);
          break;
        default:
          break;
      }
    });
  }, []);

  const mapErrorMessages = (message) => {
    if (message === 'Custom auth lambda trigger is not configured for the user pool.') {
      return 'Please enter your password';
    }
    return message;
  };

  const signUpConfig = {
    header: 'Sign Up to EatClub',
    hiddenDefaults: ['phone_number'],
  };

  const customTheme = {
    button: {
      backgroundColor: '#E54439',
      fontFamily: "Gordita, 'Open Sans', 'Helvetica', 'sans-serif' !important",
      width: '100%',
      borderRadius: '4px',
    },
    a: {
      color: '#E54439',
    },
    navButton: {
      backgroundColor: '#E54439',
      fontFamily: "Gordita, 'Open Sans', 'Helvetica', 'sans-serif' !important",
    },
    sectionFooter: {
      display: 'block',
    },
    sectionFooterPrimaryContent: {
      display: 'block',
      marginBottom: '12px',
    },
    toast: {
      backgroundColor: '#E54439',
      color: '#fff',
      padding: '1rem',
    },
  };

  return (
    <Authenticator
      onStateChange={(authState) => {
        if (authState === 'signedIn') {
          // NOTE: this will trigger when refreshing and logged in
          // show app here to prevent showing both login screen, and app
          setAuthSignedIn(true);
          setAuthLoading(false);
        }
        if (authState === 'signIn') {
          setAuthLoading(false);
        }
      }}
      hide={[
        Greetings,
        SignIn,
        ConfirmSignIn,
        RequireNewPassword,
        SignUp,
        ConfirmSignUp,
        VerifyContact,
        ForgotPassword,
        TOTPSetup,
        Loading,
      ]}
      includeGreetings={false}
      amplifyConfig={config}
      theme={customTheme}
      signUpConfig={signUpConfig}
      usernameAttributes='email'
      errorMessage={mapErrorMessages}
    >
      <>
        {authSignedIn && <App />}

        {!authLoading && !authSignedIn && <CustomSignIn authSignedIn={authSignedIn} />}
      </>
    </Authenticator>
  );
};

export default AppWithAuth;
