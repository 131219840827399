import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core';
import { React, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DiscardDialog from '../DiscardDialog/DiscardDialog';
import { setMenuVersionAction } from '../../actions/menuVersionAction';
import { ReactComponent as CheckMedium } from '../../assets/confirmation_check_medium.svg';
import useStyles from './VersionDropdownStyles';

const VersionDropdown = ({ setMenuVersion, menuVersion, posEnabled }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const [discardCallback, setDiscardCallback] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);

  const handleToggle = () => setOpen((prevOpen) => !prevOpen);
  const handleClose = () => setOpen(false);

  const view = (version, pos) => {
    setMenuVersion(version, pos);
    handleClose();
  };

  const handleOnClick = (version, pos) => {
    if (!hasChanges) {
      view(version, pos);
    } else {
      setDiscardCallback(() => () => view(version, pos));
    }
  };

  const getMenuVersionView = () => {
    if (menuVersion === 0) {
      return 'Version 1.0 menus';
    }
    if (menuVersion === 1 && !posEnabled) {
      return 'Version 2.0 menus';
    }
    if (menuVersion === 1 && posEnabled) {
      return 'Integrated POS menus';
    }
  };

  const items = [
    {
      label: 'Version 1.0 menus',
      onClick: () => handleOnClick(0, false),
      active: menuVersion === 0,
    },
    {
      label: 'Version 2.0 menus',
      onClick: () => handleOnClick(1, false),
      active: menuVersion === 1 && !posEnabled,
    },
    {
      label: 'Integrated POS menus',
      onClick: () => handleOnClick(1, true),
      active: menuVersion === 1 && posEnabled,
    },
  ];

  return (
    <Box style={{ width: 'max-content', position: 'absolute', left: 0, right: 0, margin: 'auto' }}>
      <Button
        ref={anchorRef}
        id='composition-button'
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleToggle}
        variant='outlined'
      >
        <Typography variant='body1' style={{ textTransform: 'none', fontWeight: 500 }}>
          Viewing: {getMenuVersionView()}
        </Typography>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement='bottom-center'
        transition
        disablePortal
        style={{ zIndex: 1, marginTop: '10px' }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='composition-menu' aria-labelledby='composition-button'>
                  {items.map((item) => (
                    <MenuItem onClick={item.onClick} disabled={item.active}>
                      <Box className={classes.item}>
                        {item.label}
                        {item.active && <CheckMedium />}
                      </Box>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <DiscardDialog
        callback={discardCallback}
        handleClose={() => setDiscardCallback(null)}
        setHasChanges={setHasChanges}
      />
    </Box>
  );
};

const mapStateToProps = (state) => ({
  activeRestaurant: state.activeRestaurant,
  menuVersion: state.menuVersion?.version,
  posEnabled: state.menuVersion?.posEnabled,
  activeRestaurantVersion: state.activeRestaurantVersion,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setMenuVersion: setMenuVersionAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(VersionDropdown);
