import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';
import { RestaurantSelector } from '@eatclub-apps/ec-component-library';
import {
  fetchRestaurantByIdAction,
  fetchRestaurantsByNameAction,
} from '../../actions/restaurantsAction';

const RestaurantFinder = ({ fetchRestaurantsByName, restaurants, fetchRestaurantById }) => {
  const history = useHistory();
  const [typingTimeout, setTypingTimeout] = useState(0);

  const handleChange = (value) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(
      setTimeout(() => {
        fetchRestaurantsByName(value);
      }, 300),
    );
  };

  const restaurantSelected = (selectedRestaurant) => {
    fetchRestaurantById(selectedRestaurant?.objectId);

    history.push(`/restaurants/${selectedRestaurant.objectId}`);
  };

  return (
    <>
      <RestaurantSelector
        placeholder='Restaurant name'
        items={restaurants.data}
        loading={restaurants.fetching}
        onSelect={(value) => restaurantSelected(value)}
        searchRestaurants={handleChange}
        // defaultValue
        style={{ marginTop: '20px' }}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  restaurants: state.restaurants,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchRestaurantsByName: fetchRestaurantsByNameAction,
      fetchRestaurantById: fetchRestaurantByIdAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantFinder);
