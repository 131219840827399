import React from 'react';
import {
  ListItem,
  ListItemIcon,
  Box,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core';
import { Draggable } from 'react-beautiful-dnd';
import { Delete } from '@material-ui/icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import useStyles from './MenuItemOptionStyles';
import { deleteMenuItemOptionAction } from '../../actions/menuItemOptionsAction';
import { ReactComponent as Icon } from '../../assets/two-bars-icon.svg';

const MenuItemOption = ({
  deleteMenuItemOptionAction,
  index,
  menuItemId,
  menuItemOption,
  setDialogContext,
  setOpenDialog,
  setSelectedOptionId,
}) => {
  const classes = useStyles();

  const handleDelete = () => {
    deleteMenuItemOptionAction(menuItemId, menuItemOption.objectId);
  };

  return (
    <Draggable draggableId={menuItemOption.objectId} index={index}>
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={`${classes.draggableMenuItemOption} ${snapshot.isDragging ? 'dragging' : ''} ${
            snapshot.isDropAnimating ? 'animating' : ''
          }`}
          boxShadow={snapshot.isDragging ? 3 : 0}
        >
          <ListItem
            button
            onClick={() => {
              setSelectedOptionId(menuItemOption.objectId);
              setOpenDialog(true);
              setDialogContext('Edit');
            }}
          >
            <ListItemIcon title='Drag to re-order' {...provided.dragHandleProps}>
              <Icon style={{ fill: 'rgba(0, 0, 0, 0.54)' }} />
            </ListItemIcon>
            <ListItemText
              primary={menuItemOption.optionTitle}
              secondary={menuItemOption.optionDescription}
            />
            <ListItemSecondaryAction>
              <IconButton
                edge='end'
                aria-label='delete'
                onClick={handleDelete}
                title='Delete menu item'
              >
                <Delete />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </Box>
      )}
    </Draggable>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ deleteMenuItemOptionAction }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(MenuItemOption);
