import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Box,
  ListItemAvatar,
  Avatar,
  Tooltip,
} from '@material-ui/core';
import { ShoppingCart, RemoveShoppingCart, Delete, Image } from '@material-ui/icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { Draggable } from 'react-beautiful-dnd';
import { ReactComponent as Icon } from '../../assets/two-bars-icon.svg';
import {
  deleteMenuItemAction,
  disableMenuItemAction,
  disableMenuItemUntilDateAction,
} from '../../actions/menuItemsAction';
import useStyles from './MenuItemStyles';
import MenuItemEditDialog from '../menuItemEdit/MenuItemEditDialog';

const MenuItem = ({
  menuItem,
  deleteMenuItem,
  disableMenuItem,
  disableMenuItemUntilDate,
  index,
  hideImages,
}) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [isDisabled, setIsDisabled] = useState(
    moment(menuItem.disableDate, 'yyyy-MM-DD').isSame(moment(), 'day') ||
      moment(menuItem.disableUntilDate, 'yyyy-MM-DD').isAfter(moment(), 'day'),
  );

  useEffect(() => {
    setIsDisabled(
      moment(menuItem.disableDate, 'yyyy-MM-DD').isSame(moment(), 'day') ||
        moment(menuItem.disableUntilDate, 'yyyy-MM-DD').isAfter(moment(), 'day'),
    );
  }, [menuItem, menuItem.disableDate, menuItem.disableUntilDate]);

  const formatCurrency = (num) => `$${num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;

  const handleDelete = () => {
    deleteMenuItem(menuItem.objectId, menuItem.category);
  };

  const toggleIsDisabled = () => {
    if (isDisabled) {
      setIsDisabled(false);
      disableMenuItem(menuItem.objectId, null);
      disableMenuItemUntilDate(menuItem.objectId, null);
      return;
    }

    const newDisableDate = moment().format('yyyy-MM-DD');
    setIsDisabled(newDisableDate);
    disableMenuItem(menuItem.objectId, newDisableDate);
  };

  return (
    <Draggable draggableId={menuItem.objectId} index={index}>
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={`${classes.draggableMenuItem} ${snapshot.isDragging ? 'dragging' : ''} ${
            snapshot.isDropAnimating ? 'animating' : ''
          }`}
          boxShadow={snapshot.isDragging ? 3 : 0}
        >
          <ListItem button onClick={() => setOpenDialog(true)}>
            <Tooltip title='Drag to re-order'>
              <ListItemIcon {...provided.dragHandleProps}>
                <Icon style={{ fill: 'rgba(0, 0, 0, 0.54)' }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemAvatar>
              {!hideImages && menuItem.imageLink ? (
                <Avatar alt='Image' src={menuItem.imageLink} />
              ) : (
                <Avatar>
                  <Image />
                </Avatar>
              )}
            </ListItemAvatar>
            <ListItemText
              primary={menuItem.itemTitle}
              secondary={hideImages ? '' : menuItem.itemDescription}
              style={{ paddingRight: '220px' }}
            />
            <ListItemSecondaryAction>
              <span style={{ padding: '0 1rem' }}>{formatCurrency(menuItem.price)}</span>
              <Button
                variant='text'
                size='medium'
                color={isDisabled ? 'primary' : 'default'}
                startIcon={isDisabled ? <RemoveShoppingCart /> : <ShoppingCart />}
                className={`${classes.listButton} ${isDisabled ? 'listButtonDisabled' : ''}`}
                onClick={toggleIsDisabled}
                title='Toggle stock level'
                style={{ fontSize: isDisabled ? '12px' : '14px' }}
              >
                {isDisabled ? 'Out of Stock' : 'In Stock'}
              </Button>
              <Tooltip title='Delete menu item'>
                <IconButton edge='end' aria-label='delete' onClick={handleDelete}>
                  <Delete />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
          <MenuItemEditDialog
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            menuItem={menuItem}
          />
        </Box>
      )}
    </Draggable>
  );
};

MenuItem.defaultProps = {
  hideImages: false,
};

MenuItem.propTypes = {
  menuItem: PropTypes.object.isRequired,
  deleteMenuItem: PropTypes.func.isRequired,
  disableMenuItem: PropTypes.func.isRequired,
  disableMenuItemUntilDate: PropTypes.func.isRequired,
  hideImages: PropTypes.bool,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteMenuItem: deleteMenuItemAction,
      disableMenuItem: disableMenuItemAction,
      disableMenuItemUntilDate: disableMenuItemUntilDateAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MenuItem);
