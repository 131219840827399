import * as type from './types';
import { latestRestaurants, restaurantForId, restaurantSearchByName } from '../graphql/queries';
import { devLog } from '../helpers/devLog';
import { setRestaurantMenuVersion } from '../graphql/mutations';
import { makeApiCall } from './actions';

export const fetchRestaurantByIdAction = (restaurantId) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'RESTAURANT_FETCH',
  });

  dispatch({
    type: type.FETCH_RESTAURANT_PENDING,
  });

  (async () => {
    try {
      const response = await makeApiCall(restaurantForId, { restId: restaurantId });

      devLog('success', 'restaurant', response.data.restaurantForId);

      dispatch({
        type: type.FETCH_RESTAURANT_SUCCESS,
        payload: response.data.restaurantForId,
      });
    } catch (error) {
      devLog('error', 'restaurant', error);

      dispatch({
        type: type.SET_TOAST,
        payload: {
          id: `FETCH_RESTAURANT_${new Date().getTime()}`,
          message: `Unable to fetch restaurant: ${error}`,
          type: 'error',
        },
      });

      dispatch({
        type: type.FETCH_RESTAURANT_FAILURE,
        payload: `Unable to retrieve Restaurant: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'RESTAURANT_FETCH',
      });
    }
  })();
};

export const fetchRestaurantsByNameAction = (name) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'RESTAURANT_FETCH_BY_NAME',
  });

  dispatch({
    type: type.FETCH_RESTAURANTS_PENDING,
  });

  (async () => {
    try {
      const response = await makeApiCall(restaurantSearchByName, {
        name,
      });

      devLog('success', 'restaurants search by name', response.data.restaurantSearchByName);

      dispatch({
        type: type.FETCH_RESTAURANTS_SUCCESS,
        payload: response.data.restaurantSearchByName,
      });
    } catch (error) {
      devLog('error', 'restaurants search by name', error);

      dispatch({
        type: type.SET_TOAST,
        payload: {
          id: `FETCH_RESTAURANTS_${new Date().getTime()}`,
          message: `Unable to fetch restaurants: ${error}`,
          type: 'error',
        },
      });

      dispatch({
        type: type.FETCH_RESTAURANTS_FAILURE,
        payload: `Unable to retrieve Restaurants: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'RESTAURANT_FETCH_BY_NAME',
      });
    }
  })();
};

export const fetchRestaurantsLastUpdatedAction = () => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'RESTAURANTS_LAST_UPDATED',
  });

  dispatch({
    type: type.FETCH_LAST_UPDATED_RESTAURANTS_PENDING,
  });

  (async () => {
    try {
      const response = await makeApiCall(latestRestaurants, {});

      if (!response) {
        throw new Error('Response is undefined');
      }

      devLog('success', 'latest restaurants', response.data.latestRestaurants);

      dispatch({
        type: type.FETCH_LAST_UPDATED_RESTAURANTS_SUCCESS,
        payload: response.data.latestRestaurants,
      });
    } catch (error) {
      devLog('error', 'latest restaurants', error);

      dispatch({
        type: type.SET_TOAST,
        payload: {
          id: `LAST_UPDATED_RESTAURANTS_${new Date().getTime()}`,
          message: `Unable to fetch last updated restaurants: ${error}`,
          type: 'error',
        },
      });

      dispatch({
        type: type.FETCH_LAST_UPDATED_RESTAURANTS_FAILURE,
        payload: `Unable to retrieve Last Updated Restaurants: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'RESTAURANTS_LAST_UPDATED',
      });
    }
  })();
};

export const setRestaurantMenuVersionAction = (restId, version, pos) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'SET_RESTAURANT_MENU_VERSION',
  });

  dispatch({
    type: type.UPDATE_RESTAURANT_VERSION_PENDING,
  });

  (async () => {
    try {
      const response = await makeApiCall(setRestaurantMenuVersion, { restId, version, pos });

      devLog('success', 'set restaurant menu version', response.data.setRestaurantMenuVersion);

      dispatch({
        type: type.UPDATE_RESTAURANT_VERSION_SUCCESS,
        payload: response.data.setRestaurantMenuVersion,
      });

      dispatch({
        type: type.SET_TOAST,
        payload: {
          id: `COMPLETE_CHANGE_REQUEST_${new Date().getTime()}`,
          message: 'Customer app menu version has been changed.',
          type: 'success',
        },
      });
    } catch (error) {
      devLog('error', 'set restaurant menu version', error);

      dispatch({
        type: type.UPDATE_RESTAURANT_VERSION_FAILURE,
        payload: `Unable to set Restaurant version: ${error}`,
      });

      dispatch({
        type: type.SET_TOAST,
        payload: {
          id: `COMPLETE_CHANGE_REQUEST_${new Date().getTime()}`,
          message: `Unable to change menu version in customer app: ${error}`,
          type: 'error',
        },
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'SET_RESTAURANT_MENU_VERSION',
      });
    }
  })();
};
