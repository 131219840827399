import React from 'react';
import useStyles from './BodyStyles';

const Body = (props) => {
  const classes = useStyles();
  const { children } = props;

  return (
    <main className={classes.content}>
      <div className={classes.contentInner}>{children}</div>
    </main>
  );
};

export default Body;
