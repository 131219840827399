import React from 'react';
import { Box } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import useStyles from './ReviewButtonStyles';
import { ReactComponent as CheckIcon } from '../../../../assets/small_check.svg';

const ReviewLabel = ({ importStatus, menuVersion }) => {
  if (!menuVersion.posEnabled || importStatus === null) {
    return <></>;
  }

  const classes = useStyles();

  return (
    <Box className={classes.reviewLabelContainer}>
      {importStatus === 'needsReview' ? (
        <Box display='flex' gridColumnGap='8px'>
          <Box className={classes.reviewLabelIcon} />
          <Box>To review</Box>
        </Box>
      ) : (
        <Box display='flex' gridColumnGap='8px'>
          <CheckIcon className={classes.reviewLabelIcon} />
          <Box>Reviewed</Box>
        </Box>
      )}
    </Box>
  );
};

ReviewLabel.propTypes = {};

const mapStateToProps = (state) => ({
  menuVersion: state.menuVersion,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ReviewLabel);
