export const latestRestaurants = /* GraphQL */ `
  query LatestRestaurants {
    latestRestaurants {
      objectId
      region
      name
      imageLink
      menuVersion
      posId
      posEnabled
      posMenuVersion
      posFilteredMenu
    }
  }
`;

export const restaurantSearchByName = /* GraphQL */ `
  query RestaurantSearchByName($name: String!) {
    restaurantSearchByName(name: $name) {
      objectId
      region
      name
      imageLink
      menuVersion
      posId
      posEnabled
      posMenuVersion
      posFilteredMenu
    }
  }
`;

export const restaurantForId = /* GraphQL */ `
  query RestaurantForId($restId: String!) {
    restaurantForId(restId: $restId) {
      objectId
      region
      name
      imageLink
      menuVersion
      posId
      posEnabled
      posMenuVersion
      posFilteredMenu
    }
  }
`;

export const menusForRestaurant = /* GraphQL */ `
  query MenusForRestaurant($restId: String!, $version: Int, $pos: Boolean) {
    menusForRestaurant(restId: $restId, version: $version, pos: $pos) {
      objectId
      type
      enabled
      restaurantIds
      startTime
      endTime
      menuIndex
      menuTitle
      menuDescription
      platform
    }
  }
`;

export const menuItemsForMenu = /* GraphQL */ `
  query MenuItemsForMenu($menuId: String!, $pageSize: Int, $offset: Int) {
    menuItemsForMenu(menuId: $menuId, pageSize: $pageSize, offset: $offset) {
      objectId
      price
      imageLink
      itemIndex
      itemDescription
      itemTitle
      category
      enabled
      menuId
      disableDate
      disableUntilDate
      noDiscount
    }
  }
`;

export const menuItemOptionsForMenuItem = /* GraphQL */ `
  query MenuItemOptionsForMenuItem($menuItemId: String!, $pageSize: Int, $offset: Int) {
    menuItemOptionsForMenuItem(menuItemId: $menuItemId, pageSize: $pageSize, offset: $offset) {
      objectId
      menuItemId
      optionIndex
      optionDescription
      optionTitle
      minSelections
      maxSelections
      mandatory
      enabled
      choices
    }
  }
`;

export const getMenuItemImageUploadUrl = /* GraphQL */ `
  query GetMenuItemImageUploadUrl($restId: String!, $fileName: String!) {
    getMenuItemImageUploadUrl(restId: $restId, fileName: $fileName)
  }
`;

export const menuDataForRestaurant = /* GraphQL */ `
  query MenuDataForRestaurant($restId: String!, $pos: Boolean) {
    menuDataForRestaurant(restId: $restId, pos: $pos) {
      bundles {
        objectId
        bundleTitle
        bundleDescription
        imageLink
        price
        noDiscount
        disableDate
        disableUntilDate
        posId
        importStatus
        bundleOptionIds
      }
      bundleOptions {
        objectId
        bundleOptionTitle
        bundleOptionDescription
        mandatory
        minSelections
        maxSelections
        importStatus
        productIds
      }
      products {
        objectId
        productTitle
        productDescription
        imageLink
        price
        noDiscount
        disableDate
        disableUntilDate
        bundleOnly
        posId
        importStatus
        optionIds
      }
      options {
        objectId
        optionTitle
        optionDescription
        mandatory
        minSelections
        maxSelections
        bundleOnly
        posId
        importStatus
        choiceIds
      }
      choices {
        objectId
        choiceTitle
        price
        disableDate
        disableUntilDate
        bundleOnly
        posId
        importStatus
      }
      menus {
        objectId
        type
        enabled
        startTime
        endTime
        menuTitle
        menuDescription
        platform
        version
        categories {
          objectId
          categoryTitle
          categoryIndex
          productIds
          bundleIds
          maxQty
        }
      }
    }
  }
`;

export const activeChangeRequests = /* GraphQL */ `
  query ActiveChangeRequests($restId: String) {
    activeChangeRequests(restId: $restId) {
      objectId
      created
      restId
      businessName
      posId
      type
      action
      status
      posData
      ourData
    }
  }
`;

export const completedChangeRequests = /* GraphQL */ `
  query CompletedChangeRequests($pageSize: Int!, $offset: Int!) {
    completedChangeRequests(pageSize: $pageSize, offset: $offset) {
      objectId
      created
      restId
      businessName
      posId
      type
      action
      status
      posData
      ourData
    }
  }
`;
