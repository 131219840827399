import {
  deleteItemsInArray,
  getItemInArray,
  insertItemInArrayAtIndex,
  updateItemInArray,
} from '../helpers/ArrayUtils';
import * as type from '../actions/types';
import { parseJson } from '../helpers/Helpers';

const initialState = {
  data: [],
  localData: [],
  deleted: [],
  fetching: false,
  error: false,
  errorMessage: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_CATEGORIES_PENDING: {
      return {
        ...initialState,
        fetching: true,
      };
    }

    case type.FETCH_CATEGORIES_SUCCESS: {
      return {
        ...state,
        data: action.payload || [],
        localData: action.payload || [],
        fetching: false,
        error: false,
        errorMessage: '',
      };
    }

    case type.FETCH_CATEGORIES_FAILURE: {
      return {
        ...state,
        data: [],
        localData: [],
        deleted: [],
        fetching: false,
        error: true,
        errorMessage: action.payload,
      };
    }

    case type.CREATE_CATEGORY: {
      const { categoryId, categoryTitle, menuId } = action.payload;

      const categoryIndex =
        state.localData.filter((category) => category.menuId === menuId).length + 1;

      const newCategory = {
        objectId: categoryId,
        categoryTitle,
        categoryIndex,
        menuId,
        _created: true,
      };

      const newCategories = [...state.localData, newCategory];

      return { ...state, localData: newCategories };
    }

    case type.UPDATE_CATEGORY: {
      const { objectId, categoryTitle, categoryIndex, menuId, productIds, bundleIds } =
        action.payload;

      const newCategories = updateItemInArray(
        state.localData,
        'objectId',
        objectId,
        (category) => ({
          ...category,
          categoryTitle,
          categoryIndex,
          menuId,
          productIds,
          bundleIds,
          _altered: true,
        }),
      );

      return { ...state, localData: newCategories };
    }

    case type.DELETE_CATEGORY: {
      const deleted = getItemInArray(state.localData, 'objectId', action.payload.categoryId);

      const newDeleted = { ...deleted, _deleted: true };

      const newCategories = deleteItemsInArray(
        state.localData,
        'objectId',
        action.payload.categoryId,
      );

      // If item hadn't been saved yet, no need to schedule it for deletion
      let newDeletedItems = [...state.deleted];

      if (newDeleted?._created !== true) {
        newDeletedItems = insertItemInArrayAtIndex(state.deleted, 0, newDeleted);
      }

      return {
        ...state,
        localData: newCategories,
        deleted: newDeletedItems,
      };
    }

    case type.CLEAR_CATEGORIES: {
      return initialState;
    }

    case type.ADD_PRODUCT_TO_CATEGORY: {
      const { productId, categoryId } = action.payload;

      const newCategories = updateItemInArray(
        state.localData,
        'objectId',
        categoryId,
        (category) => ({
          ...category,
          productIds: [...(category.productIds || []), productId],
          _altered: true,
        }),
      );

      return { ...state, localData: newCategories };
    }

    case type.ADD_PRODUCTS_TO_CATEGORY: {
      const { productIds, categoryId } = action.payload;

      const newCategories = updateItemInArray(
        state.localData,
        'objectId',
        categoryId,
        (category) => ({
          ...category,
          productIds: productIds || [],
          _altered: true,
        }),
      );

      return { ...state, localData: newCategories };
    }

    case type.REMOVE_PRODUCT_FROM_CATEGORY: {
      const { productId, categoryId } = action.payload;

      const newCategories = updateItemInArray(
        state.localData,
        'objectId',
        categoryId,
        (category) => {
          const newProductIds = category?.productIds.filter((product) => product !== productId);
          return {
            ...category,
            productIds: newProductIds,
            _altered: true,
          };
        },
      );

      return { ...state, localData: newCategories };
    }

    case type.ADD_BUNDLE_TO_CATEGORY: {
      const { bundleId, categoryId } = action.payload;

      const newCategories = updateItemInArray(
        state.localData,
        'objectId',
        categoryId,
        (category) => ({
          ...category,
          bundleIds: [
            ...(category.bundleIds || []),
            JSON.stringify({
              bundleId,
              index: (category.productIds?.length || 0) + (category.bundleIds?.length || 0),
            }),
          ],
          _altered: true,
        }),
      );

      return { ...state, localData: newCategories };
    }

    case type.ADD_BUNDLES_TO_CATEGORY: {
      const { bundleIds, categoryId } = action.payload;

      const newCategories = updateItemInArray(
        state.localData,
        'objectId',
        categoryId,
        (category) => ({
          ...category,
          bundleIds,
          _altered: true,
        }),
      );

      return { ...state, localData: newCategories };
    }

    case type.REMOVE_BUNDLE_FROM_CATEGORY: {
      const { bundleId, categoryId } = action.payload;

      const newCategories = updateItemInArray(
        state.localData,
        'objectId',
        categoryId,
        (category) => {
          const newBundleIds = category?.bundleIds.filter(
            (bundle) => parseJson(bundle)?.bundleId !== bundleId,
          );
          return {
            ...category,
            bundleIds: newBundleIds,
            _altered: true,
          };
        },
      );

      return { ...state, localData: newCategories };
    }

    // Remove the product from all categories that contain it
    case type.DELETE_PRODUCT: {
      const { productId } = action.payload;

      const updatedCategories = state.localData.map((category) => {
        if (!category?.productIds?.includes(productId)) {
          return category;
        }

        const newProductIds = category?.productIds?.filter(
          (categoryProductId) => categoryProductId !== productId,
        );
        const updatedCategory = {
          ...category,
          productIds: newProductIds,
          _altered: true,
        };
        return updatedCategory;
      });

      return {
        ...state,
        localData: updatedCategories,
      };
    }

    // Remove the bundle from all categories that contain it
    case type.DELETE_BUNDLE: {
      const { bundleId } = action.payload;

      const updatedCategories = state.localData.map((category) => {
        const includesBundleId = category?.bundleIds?.some(
          (categoryBundleId) => parseJson(categoryBundleId)?.bundleId === bundleId,
        );

        if (!includesBundleId) {
          return category;
        }

        const newBundleIds = category?.bundleIds?.filter(
          (categoryBundleId) => parseJson(categoryBundleId)?.bundleId !== bundleId,
        );

        const updatedCategory = {
          ...category,
          bundleIds: newBundleIds,
          _altered: true,
        };
        return updatedCategory;
      });

      return {
        ...state,
        localData: updatedCategories,
      };
    }

    case type.COPY_MENU_SUCCESS: {
      const { copiedMenu } = action.payload;

      const copiedCategories = copiedMenu?.categories?.map((cateogry, index) => ({
        ...cateogry,
        categoryIndex: index,
        menuId: copiedMenu.objectId,
      }));

      const newCategories = [...state.localData, ...copiedCategories];

      return {
        ...state,
        data: newCategories,
        localData: newCategories,
      };
    }

    /*
     * BACKEND SAVES
     */

    case type.BACKEND_SAVE_CATEGORY_SUCCESS: {
      const { savedItem, itemObjectId, categoryIndex } = action.payload;

      const newCategories = state.localData.map((item) => {
        if (item.objectId === itemObjectId) {
          return {
            ...savedItem,
            categoryIndex: categoryIndex || item.categoryIndex,
            menuId: item.menuId,
          };
        }

        return item;
      });

      return {
        ...state,
        data: newCategories,
        localData: newCategories,
      };
    }

    case type.BACKEND_UPDATE_CATEGORY_SUCCESS: {
      const { savedItem } = action.payload;

      const newCategories = state.localData.map((item) => {
        if (item.objectId === savedItem.objectId) {
          return {
            ...item,
            ...savedItem,
            menuId: item.menuId,
          };
        }

        return item;
      });

      return {
        ...state,
        data: newCategories,
        localData: newCategories,
      };
    }

    case type.BACKEND_SAVE_CATEGORIES_SUCCESS: {
      const newCategories = state.localData.map(
        ({ _altered, _created, _objectId, ...rest }) => rest,
      );

      return {
        ...state,
        data: newCategories,
        localData: newCategories,
        deleted: [],
      };
    }

    default: {
      return state;
    }
  }
};
