import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  Button,
  Box,
  Checkbox,
} from '@material-ui/core';
import {
  addOptionsToProductsAction,
  removeOptionsFromProductsAction,
} from '../../../../actions/productsAction';
import { sortAlphabetical } from '../../../../helpers/ArrayUtils';
import OptionCreate from '../menuOption/OptionCreate';
import useStyles from './MultiProductEditStyles';

const MultiProductEditDialog = ({
  options,
  products,
  choices,
  openDialog,
  setOpenDialog,
  selectedProducts,
  addOptionsToProductsAction,
  removeOptionsFromProductsAction,
}) => {
  const classes = useStyles();
  const [dialogContext, setDialogContext] = useState('');
  const [optionsWithSelection, setOptionsWithSelection] = useState([]);
  const [selectedProductList, setSelectedProductList] = useState([]);

  // Get choices for each option
  const [processedOptions, setProcessedOptions] = useState([]);
  useEffect(() => {
    setProcessedOptions(
      options?.localData?.map((option) => {
        let choicesForOption = [];
        choices?.localData?.forEach((choice) => {
          if (option?.choiceIds?.includes(choice?.objectId)) {
            choicesForOption = [...choicesForOption, choice?.choiceTitle];
          }
        });

        return {
          ...option,
          labelledChoices: choicesForOption?.join(', '),
        };
      }),
    );
  }, [options?.localData]);

  // Populate the list of products with data instead of just being IDs
  useEffect(() => {
    setSelectedProductList(
      selectedProducts.map((productId) =>
        products.localData.find((product) => product.objectId === productId),
      ),
    );
  }, [products.localData, selectedProducts]);

  // Set the options, and whether they are checked or not (or partially)
  useEffect(() => {
    const newOptionsWithSelection = processedOptions?.map((option) => {
      const productsWithOption = selectedProductList?.filter((product) =>
        product?.optionIds?.includes(option?.objectId),
      );
      if (productsWithOption.length === 0) {
        return { option, value: 'unchecked', modified: false };
      }
      if (productsWithOption.length === selectedProducts.length) {
        return { option, value: 'checked', modified: false };
      }
      return { option, value: 'partial', modified: false };
    });

    setOptionsWithSelection(newOptionsWithSelection || []);
  }, [processedOptions, selectedProductList, openDialog]);

  const selectItem = (optionId, value) => {
    const optionToUpdate = optionsWithSelection?.find(
      (option) => option.option.objectId === optionId,
    );
    const optionsWithoutUpdated = optionsWithSelection?.filter(
      (option) => option.option.objectId !== optionId,
    );
    optionToUpdate.value = value ? 'checked' : 'unchecked';
    optionToUpdate.modified = true;

    setOptionsWithSelection([...optionsWithoutUpdated, optionToUpdate]);
  };

  const closeDialog = () => {
    setOpenDialog(false);
  };

  const submit = (event) => {
    event.preventDefault();

    const checkedBoxes = optionsWithSelection.filter(
      (option) => option.modified && option.value === 'checked',
    );
    const uncheckedBoxes = optionsWithSelection.filter(
      (option) => option.modified && option.value === 'unchecked',
    );

    // Update all newly added options
    if (checkedBoxes.length > 0) {
      addOptionsToProductsAction(
        selectedProducts,
        checkedBoxes.map((option) => option.option.objectId),
      );
    }

    // Update all newly removed options
    if (uncheckedBoxes.length > 0) {
      removeOptionsFromProductsAction(
        selectedProducts,
        uncheckedBoxes.map((option) => option.option.objectId),
      );
    }

    closeDialog();
  };

  return (
    <Dialog
      open={openDialog}
      onClose={closeDialog}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth
      maxWidth='lg'
      style={{ minHeight: '600px' }}
      minHeight='600px'
      className={classes.modal}
    >
      <DialogTitle id='multi-product-edit-dialog'>Add options to menu items</DialogTitle>
      <DialogContent className={classes.modal}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Box className={classes.columnTitle}>
              {selectedProducts.length} menu item
              {selectedProducts.length !== 1 ? 's' : ''} selected
            </Box>
            <Box className={`${classes.boxOutlined}`}>
              {selectedProductList.map((selectedProduct) => (
                <Box className={classes.productListItem}>{selectedProduct?.productTitle}</Box>
              ))}
            </Box>
          </Grid>
          <Grid item md={6} xs={12}>
            <Box className={classes.columnTitle}>Linked options</Box>
            <Box className={classes.boxOutlined}>
              {optionsWithSelection
                ?.sort((a, b) => sortAlphabetical(a.option.optionTitle, b.option.optionTitle))
                ?.map((productOption, index) => (
                  <Box key={productOption.option.objectId} display='flex'>
                    <Checkbox
                      color='primary'
                      style={{
                        color: productOption.value !== 'unchecked' ? '#E54439' : '#BDBDBD',
                      }}
                      checked={productOption.value === 'checked'}
                      indeterminate={productOption.value === 'partial'}
                      onChange={(event) =>
                        selectItem(productOption.option.objectId, event.target.checked)
                      }
                      className={classes.checkbox}
                    />
                    <Box className={classes.optionTitle}>{productOption.option.optionTitle}</Box>
                    <Box className={classes.optionChoices}>
                      {productOption.option.labelledChoices}
                    </Box>
                  </Box>
                ))}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <OptionCreate
          buttonLabel='CREATE NEW OPTION'
          type='submit'
          buttonType='secondary'
          productIds={selectedProducts}
        />
        <Button onClick={closeDialog} color='default'>
          Cancel
        </Button>
        <Button onClick={submit} color='primary' type='submit' form='edit-multi-product-form'>
          Save menu items
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  options: state.options,
  products: state.products,
  choices: state.choices,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ addOptionsToProductsAction, removeOptionsFromProductsAction }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(MultiProductEditDialog);
