/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Box,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addBundlesToCategoryAction } from '../../../../actions/categoriesAction';
import { sortAlphabetical } from '../../../../helpers/ArrayUtils';
import { formatCurrency, parseJson } from '../../../../helpers/Helpers';
import useStyles from './MenuCategoryAddStyles';

const MenuItemAddDialog = ({
  addBundlesToCategoryAction,
  openDialog,
  setOpenDialog,
  category,
  bundles,
}) => {
  const classes = useStyles();

  const closeDialog = () => {
    setOpenDialog(false);
  };

  const [save, setSave] = useState(false);

  const submit = (event) => {
    event.preventDefault();

    const numberOfCurrentItems =
      (category.productIds?.length || 0) + (category.bundleIds?.length || 0);

    const newBundleItems = selectedItems?.map((item, index) => {
      const bundleId = category.bundleIds?.find(
        (bundleId) => parseJson(bundleId)?.bundleId === item,
      );

      return (
        bundleId ||
        JSON.stringify({
          bundleId: item,
          index: numberOfCurrentItems + index,
        })
      );
    });

    addBundlesToCategoryAction(newBundleItems, category?.objectId);

    setSave(true);
    closeDialog();
  };

  const items = bundles?.localData.sort((a, b) => sortAlphabetical(a.bundleTitle, b.bundleTitle));

  const tableRef = useRef();
  const [selectedItems, setSelectedItems] = useState([]);
  const selectItem = (itemId, value) => {
    if (value === true && !selectedItems.includes(itemId)) {
      setSelectedItems([...selectedItems, itemId]);
    }

    // Remove item if deselected and in list
    if (value === false) {
      const updatedSelectedItems = selectedItems.filter((item) => item !== itemId);
      setSelectedItems(updatedSelectedItems);
    }
  };

  useEffect(() => {
    setSelectedItems(
      category.bundleIds ? category.bundleIds.map((bundle) => parseJson(bundle)?.bundleId) : [],
    );
  }, [category.bundleIds]);

  const itemIsDisabled = (bundle) =>
    moment(bundle.disableDate, 'yyyy-MM-DD').isSame(moment(), 'day') ||
    moment(bundle.disableUntilDate, 'yyyy-MM-DD').isAfter(moment(), 'day');

  const selectAllItems = () => {
    setSelectedItems(items.map((item) => item.objectId));
  };

  return (
    <Dialog
      open={openDialog}
      onClose={closeDialog}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth
      maxWidth='lg'
      onEnter={() => {
        setSave(false);
      }}
      onExited={() => {
        if (!save) {
          // TODO this should be changed? atm saving does nothing.
        }
      }}
    >
      <DialogTitle id='alert-dialog-title'>Add bundles to menu</DialogTitle>
      <DialogContent>
        {items?.length === 0 ? (
          <Box>No bundles to add. Please create them on the global menu.</Box>
        ) : (
          <Table tableref={tableRef} className={classes.table}>
            <TableBody>
              {items.map((item) => (
                <TableRow
                  key={item.objectId}
                  onClick={() => selectItem(item.objectId, !selectedItems.includes(item.objectId))}
                >
                  <TableCell style={{ width: '50px' }}>
                    <Checkbox
                      style={{
                        color: selectedItems?.includes(item.objectId) ? '#E54439' : '#BDBDBD',
                      }}
                      checked={selectedItems.includes(item.objectId)}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Box>
                      <Box>{item?.bundleTitle}</Box>
                      <Box style={{ fontSize: '14px' }}>{item.bundleDescription}</Box>
                    </Box>
                  </TableCell>
                  <TableCell className={classes.tableCell} style={{ width: '130px' }}>
                    {item?.bundleOptionIds?.length > 0 &&
                      `${item?.bundleOptionIds?.length} option${
                        item?.bundleOptionIds?.length === 1 ? '' : 's'
                      }`}
                  </TableCell>
                  <TableCell className={classes.tableCell} style={{ width: '100px' }}>
                    {formatCurrency(item?.price)}
                  </TableCell>
                  <TableCell className={classes.tableCell} style={{ width: '150px' }}>
                    {itemIsDisabled(item) ? 'Out of stock' : 'In stock'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={selectAllItems} color='default'>
          Select all
        </Button>
        <Button onClick={closeDialog} color='default'>
          Cancel
        </Button>
        <Button onClick={submit} color='primary' type='submit' form='create-menu-item-form'>
          Add items
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({ bundles: state.bundles });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addBundlesToCategoryAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemAddDialog);
