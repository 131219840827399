import * as type from '../actions/types';

const initialState = {
  messages: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.SET_TOAST: {
      const { id, message, type: toastType = 'success' } = action.payload;

      const checkId = state.messages.findIndex((message) => message.id === id);

      if (checkId === -1) {
        const addMessages = [...state.messages, { id, message, type: toastType }];

        return {
          ...state,
          messages: addMessages,
        };
      }
      return state;
    }

    case type.UNSET_TOAST: {
      const { id } = action.payload;
      const removeMessages = [...state.messages.filter((messages) => messages.id !== id)];

      return {
        ...state,
        messages: removeMessages,
      };
    }

    default: {
      return state;
    }
  }
};
