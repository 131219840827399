import * as type from '../actions/types';

const initialState = {
  pending: false,
  success: false,
  error: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case type.UPDATE_RESTAURANT_VERSION_PENDING: {
      return {
        ...state,
        pending: true,
        success: false,
        error: '',
      };
    }

    case type.UPDATE_RESTAURANT_VERSION_SUCCESS: {
      return {
        ...state,
        pending: false,
        error: '',
      };
    }

    case type.UPDATE_RESTAURANT_VERSION_FAILURE: {
      return {
        ...state,
        pending: false,
        success: true,
        error: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
