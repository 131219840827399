import * as type from './types';
import { devLog } from '../helpers/devLog';
import { parseJson, removeEmpty } from '../helpers/Helpers';
import {
  createBundles,
  disableBundleForDate,
  disableBundleUntilDate,
  removeBundles,
  restockBundle,
  updateBundles,
} from '../graphql/mutations';
import { getMenuItemImageUploadUrlAction } from './menuItemImageAction';
import { isEmpty } from '../constants/constants';
import { makeApiCall } from './actions';
import store from '../store';
import { syncMenu, syncMenuCacheAction } from './menusAction';
import { getItemInArray } from '../helpers/ArrayUtils';

export const createBundleAction =
  (
    bundleId,
    bundleTitle,
    bundleDescription,
    price,
    imageLink,
    image,
    noDiscount,
    disableDate,
    disableUntilDate,
    restId,
    posId,
  ) =>
  (dispatch) => {
    dispatch({
      type: type.CREATE_BUNDLE,
      payload: {
        bundleId,
        bundleTitle,
        bundleDescription,
        price,
        imageLink,
        image,
        noDiscount,
        disableDate,
        disableUntilDate,
        restId,
        posId,
      },
    });
  };

export const updateBundleAction =
  (
    objectId,
    bundleTitle,
    bundleDescription,
    price,
    imageLink,
    image,
    noDiscount,
    disableDate,
    disableUntilDate,
    restId,
    posId,
    importStatus,
    bundleOptions,
  ) =>
  (dispatch) => {
    dispatch({
      type: type.UPDATE_BUNDLE,
      payload: {
        objectId,
        bundleTitle,
        bundleDescription,
        price,
        imageLink,
        image,
        noDiscount,
        disableDate,
        disableUntilDate,
        restId,
        posId,
        bundleOptions,
        importStatus,
      },
    });
  };

export const deleteBundleAction = (bundleId) => (dispatch) => {
  dispatch({
    type: type.DELETE_BUNDLE,
    payload: { bundleId },
  });
};

export const reviewBundleAction = (bundleId) => (dispatch) => {
  const state = store.getState();
  const { bundles, bundleOptions, products } = state;

  const bundle = getItemInArray(bundles.localData, 'objectId', bundleId);

  dispatch({
    type: type.REVIEW_BUNDLE,
    payload: { bundleId, bundleOptionIds: bundle.bundleOptionIds },
  });

  dispatch({
    type: type.BULK_REVIEW_BUNDLE_OPTIONS,
    payload: { bundleOptionIds: bundle.bundleOptionIds },
  });

  // Mark all related products and options to the bundle to reviewed as well
  const productIds = bundleOptions.localData
    .filter((bundleOption) => bundle.bundleOptionIds?.includes(bundleOption.objectId))
    .flatMap((bundleOption) => bundleOption.productIds);

  const bundleOptionProducts = products.localData.filter((product) =>
    productIds.includes(product.objectId),
  );

  dispatch({
    type: type.BULK_REVIEW_PRODUCTS,
    payload: {
      productIds: bundleOptionProducts.map((product) => product.objectId),
    },
  });

  dispatch({
    type: type.BULK_REVIEW_OPTIONS,
    payload: {
      optionIds: bundleOptionProducts.flatMap((product) => product.optionIds),
    },
  });
};

export const clearBundlesAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_BUNDLES,
  });
};

export const addBundleOptionToBundleAction = (bundleOptionId) => (dispatch) => {
  dispatch({
    type: type.ADD_BUNDLE_OPTION_TO_BUNDLE,
    payload: { bundleOptionId },
  });
};

export const addBundleOptionsToBundleAction = (bundleOptions) => (dispatch) => {
  dispatch({
    type: type.ADD_BUNDLE_OPTIONS_TO_BUNDLE,
    payload: { bundleOptions },
  });
};

export const addBundleOptionsToBundlesAction = (bundleIds, bundleOptionIds) => (dispatch) => {
  dispatch({
    type: type.ADD_BUNDLE_OPTIONS_TO_BUNDLES,
    payload: { bundleIds, bundleOptionIds },
  });
};

export const removeBundleOptionFromBundleAction = (bundleOptionId) => (dispatch) => {
  dispatch({
    type: type.REMOVE_BUNDLE_OPTION_FROM_BUNDLE,
    payload: { bundleOptionId },
  });
};

export const removeBundleOptionsFromBundlesAction = (bundleIds, bundleOptionIds) => (dispatch) => {
  dispatch({
    type: type.REMOVE_BUNDLE_OPTIONS_FROM_BUNDLES,
    payload: { bundleIds, bundleOptionIds },
  });
};

export const clearBundleOptionsAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_BUNDLE_OPTIONS_FROM_BUNDLE,
  });
};

export const disableLocalBundleForDateAction = (bundleId, disableDate) => (dispatch) => {
  dispatch({
    type: type.DISABLE_LOCAL_BUNDLE_FOR_DATE,
    payload: { bundleId, disableDate },
  });
};

export const disableLocalBundleUntilDateAction = (bundleId, disableUntilDate) => (dispatch) => {
  dispatch({
    type: type.DISABLE_LOCAL_BUNDLE_UNTIL_DATE,
    payload: { bundleId, disableUntilDate },
  });
};

export const restockBundleAction = (bundleId) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: `RESTOCK_BUNDLE_${bundleId}`,
  });

  dispatch({
    type: type.RESTOCK_BUNDLE_PENDING,
  });

  (async () => {
    try {
      const response = await makeApiCall(restockBundle, { bundleId });

      devLog('success', 'restock bundle', response.data.restockBundle);

      dispatch({
        type: type.RESTOCK_BUNDLE_SUCCESS,
        payload: response.data.restockBundle,
      });

      syncMenu(dispatch);
    } catch (error) {
      devLog('error', 'restock bundle', error);

      dispatch({
        type: type.SET_TOAST,
        payload: {
          id: `RESTOCK_BUNDLE_${new Date().getTime()}`,
          message: `Unable to restock bundle: ${error}`,
          type: 'error',
        },
      });

      dispatch({
        type: type.RESTOCK_BUNDLE_FAILURE,
        payload: `Unable to restock bundle: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: `RESTOCK_BUNDLE_${bundleId}`,
      });
    }
  })();
};

export const disableBundleForDateAction = (bundleId, disableDate) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: `DISABLE_BUNDLE_FOR_DATE_${bundleId}`,
  });

  dispatch({
    type: type.DISABLE_BUNDLE_FOR_DATE_PENDING,
  });

  (async () => {
    try {
      const response = await makeApiCall(disableBundleForDate, { bundleId, disableDate });

      devLog('success', 'disable bundle for date', response.data.disableBundleForDate);

      dispatch({
        type: type.DISABLE_BUNDLE_FOR_DATE_SUCCESS,
        payload: response.data.disableBundleForDate,
      });

      syncMenu(dispatch);
    } catch (error) {
      devLog('error', 'disable bundle for date', error);

      dispatch({
        type: type.SET_TOAST,
        payload: {
          id: `DISABLE_BUNDLE_FOR_DATE_${new Date().getTime()}`,
          message: `Unable to disable bundle for date: ${error}`,
          type: 'error',
        },
      });

      dispatch({
        type: type.DISABLE_BUNDLE_FOR_DATE_FAILURE,
        payload: `Unable to disable bundle for date: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: `DISABLE_BUNDLE_FOR_DATE_${bundleId}`,
      });
    }
  })();
};

export const disableBundleUntilDateAction = (bundleId, disableUntilDate) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: `DISABLE_BUNDLE_UNTIL_DATE_${bundleId}`,
  });

  dispatch({
    type: type.DISABLE_BUNDLE_UNTIL_DATE_PENDING,
  });

  (async () => {
    try {
      const response = await makeApiCall(disableBundleUntilDate, { bundleId, disableUntilDate });

      devLog('success', 'disable bundle until date', response.data.disableBundleUntilDate);

      dispatch({
        type: type.DISABLE_BUNDLE_UNTIL_DATE_SUCCESS,
        payload: response.data.disableBundleUntilDate,
      });

      syncMenu(dispatch);
    } catch (error) {
      devLog('error', 'disable bundle until date', error);

      dispatch({
        type: type.SET_TOAST,
        payload: {
          id: `DISABLE_BUNDLE_UNTIL_DATE_${new Date().getTime()}`,
          message: `Unable to disable bundle until date: ${error}`,
          type: 'error',
        },
      });

      dispatch({
        type: type.DISABLE_BUNDLE_UNTIL_DATE_FAILURE,
        payload: `Unable to disable bundle until date: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: `DISABLE_BUNDLE_UNTIL_DATE_${bundleId}`,
      });
    }
  })();
};

export const dbRemoveBundles = async (bundleIds, restaurantId, dispatch) => {
  try {
    const response = await makeApiCall(removeBundles, {
      restId: restaurantId,
      bundleIds,
    });

    devLog('success', 'remove bundle', response.data.removeBundles);

    return null;
  } catch (error) {
    devLog('error', 'unable to remove bundle', error);

    dispatch({
      type: type.SET_TOAST,
      payload: {
        id: `BUNDLE_SAVE_${new Date().getTime()}`,
        message: `Unable to remove bundle: ${error}`,
        type: 'error',
      },
    });
    return error;
  }
};

export const dbCreateBundles = async (bundles, restaurantId, dispatch) => {
  try {
    const response = await makeApiCall(createBundles, {
      bundles: await Promise.all(
        bundles.map(async (bundle) => {
          let imageLink = null;
          if (!isEmpty(bundle._image) && !bundle._deleted) {
            imageLink = await getMenuItemImageUploadUrlAction(bundle, restaurantId);
          }

          return removeEmpty({
            bundleTitle: bundle?.bundleTitle,
            bundleDescription: bundle?.bundleDescription,
            imageLink,
            price: bundle?.price,
            disableDate: bundle?.disableDate,
            disableUntilDate: bundle?.disableUntilDate,
            noDiscount: bundle?.noDiscount,
            restId: bundle?.restId,
            importStatus: bundle?.importStatus,
            bundleOptionIds: bundle?.bundleOptionIds,
          });
        }),
      ),
    });

    if (response?.data?.createBundles === undefined || response?.data?.createBundles === null) {
      throw new Error('Response is undefined');
    }

    devLog('success', 'create bundles', response.data.createBundles);

    return response?.data?.createBundles?.map((createdBundle, index) => {
      dispatch({
        type: type.BACKEND_SAVE_BUNDLE_SUCCESS,
        payload: {
          savedItem: createdBundle,
          itemObjectId: bundles[index]._objectId,
        },
      });

      // Also return the original objectId
      return { ...createdBundle, _objectId: bundles[index]._objectId };
    });
  } catch (error) {
    devLog('error', 'unable to create bundles', error);

    dispatch({
      type: type.SET_TOAST,
      payload: {
        id: `BUNDLES_CREATE_${new Date().getTime()}`,
        message: `Unable to create bundles: ${error}`,
        type: 'error',
      },
    });
    return error;
  }
};

export const dbUpdateBundles = async (bundles, restaurantId, dispatch) => {
  try {
    const response = await makeApiCall(updateBundles, {
      restId: restaurantId,
      bundles: await Promise.all(
        bundles.map(async (bundle) => {
          let { imageLink } = bundle;
          if (!isEmpty(bundle._image) && !bundle._deleted) {
            imageLink = await getMenuItemImageUploadUrlAction(bundle, restaurantId);
          }

          return removeEmpty({
            objectId: bundle?.objectId,
            bundleTitle: bundle?.bundleTitle,
            bundleDescription: bundle?.bundleDescription,
            imageLink,
            price: bundle?.price,
            noDiscount: bundle?.noDiscount,
            importStatus: bundle?.importStatus,
            bundleOptionIds: bundle?.bundleOptionIds,
          });
        }),
      ),
    });

    if (response?.data?.updateBundles === undefined || response?.data?.updateBundles === null) {
      throw new Error('Response is undefined');
    }

    devLog('success', 'update bundles', response.data.updateBundles);

    return response?.data?.updateBundles?.map((createdBundle, index) => {
      dispatch({
        type: type.BACKEND_SAVE_BUNDLE_SUCCESS,
        payload: {
          savedItem: createdBundle,
          itemObjectId: bundles[index]._objectId,
        },
      });

      // Also return the original objectId
      return { ...createdBundle, _objectId: bundles[index]._objectId };
    });
  } catch (error) {
    devLog('error', 'unable to update bundles', error);

    dispatch({
      type: type.SET_TOAST,
      payload: {
        id: `BUNDLES_UPDATE_${new Date().getTime()}`,
        message: `Unable to update bundles: ${error}`,
        type: 'error',
      },
    });
    return error;
  }
};
