import * as type from '../actions/types';

const initialState = {
  data: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case type.CLEAR_BREADCRUMBS: {
      return initialState;
    }

    case type.ADD_BREADCRUMB: {
      return {
        data: [...state.data, action.payload],
      };
    }

    case type.POP_BREADCRUMB: {
      return {
        data: state.data.slice(0, -1),
      };
    }

    default: {
      return state;
    }
  }
};
