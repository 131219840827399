import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createCategoryAction } from '../../actions/menuItemsAction';

const MenuCategoryCreateDialog = ({
  createCategoryAction,
  menuId,
  menuItems,
  openDialog,
  setOpenDialog,
}) => {
  const closeDialog = () => {
    setCategoryName('');
    setOpenDialog(false);
  };

  const [categoryName, setCategoryName] = useState('');
  const [inputError, setInputError] = useState('');

  const submit = (event) => {
    event.preventDefault();

    if (!categoryName) {
      setInputError('Please enter a category name.');
      return;
    }
    if (inputError) {
      setInputError(null);
    }

    const categoryIndex = menuItems.localData.findIndex(
      (menuItem) => menuItem.category === categoryName,
    );

    if (categoryIndex > -1) {
      setInputError(`'${categoryName}' already exists. Please enter unique category name.`);
      return;
    }
    if (inputError) {
      setInputError(null);
    }

    createCategoryAction(categoryName, menuId);

    closeDialog();
  };

  return (
    <Dialog
      open={openDialog}
      onClose={closeDialog}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>Category Name</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description' style={{ marginBottom: '1rem' }}>
          Please note empty category list will not be saved unless there is at least one menu item
          added.
        </DialogContentText>

        <form id='create-category-form' noValidate autoComplete='off' onSubmit={submit}>
          <TextField
            autoFocus
            id='category-name'
            label='Category Name'
            placeholder='eg. Mains, Entrees'
            variant='outlined'
            fullWidth
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
            onBlur={(e) => setCategoryName(e.target.value.trim())}
            InputLabelProps={{ shrink: true }}
            error={Boolean(inputError)}
            helperText={inputError}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color='default'>
          Cancel
        </Button>
        <Button onClick={submit} color='primary'>
          Save Category
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({ menuItems: state.menuItems });
const mapDispatchToProps = (dispatch) => bindActionCreators({ createCategoryAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MenuCategoryCreateDialog);
