import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tabs, Tab, Box } from '@material-ui/core';
import { clearOptionsAction } from '../../../../actions/optionsAction';
import { clearProductsAction } from '../../../../actions/productsAction';
import { clearChoicesAction } from '../../../../actions/choicesAction';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import GlobalMenuPanel from '../menuTabPanel/GlobalMenuPanel';
import {
  setMenuTabAction,
  fetchMenuDataAction,
  clearMenusAction,
} from '../../../../actions/menusAction';
import MenuTabPanel from '../menuTabPanel/MenuTabPanel';
import MenuTabPanelContainer from '../menuTabPanel/MenuTabPanelContainer';
import MenuOptionsButton from '../menu/MenuOptionsButton';
import MenuTab from './MenuTab';
import useStyles from './MenuTabsStyles';
import MenusValidation from './MenusValidation';
import { clearBundleOptionsAction, clearBundlesAction } from '../../../../actions/bundlesAction';
import OptionEditDialog from '../menuOption/OptionEditDialog';
import { dialogContexts } from '../../../../constants/constants';
import ProductEditDialog from '../menuProduct/ProductEditDialog';
import BundleOptionEditDialog from '../menuBundleOption/BundleOptionEditDialog';
import BundleEditDialog from '../menuBundle/BundleEditDialog';
import { setToastAction } from '../../../../actions/toastAction';
import { clearCategoryAction } from '../../../../actions/categoriesAction';

const MenuTabs = ({
  clearProductsAction,
  clearOptionsAction,
  clearBundlesAction,
  clearBundleOptionsAction,
  setToastAction,
  clearMenusAction,
  clearCategoryAction,
  clearChoicesAction,
  menus,
  products,
  bundles,
  options,
  bundleOptions,
  restaurantId,
  setMenuTabAction,
  menuVersion,
  fetchMenuDataAction,
  activeRestaurant,
}) => {
  const classes = useStyles();
  const [itemToReview, setItemToReview] = useState(null);

  const params = new URLSearchParams(window.location.search);
  const typeToReivew = params.get('type');
  const objectIdToReview = params.get('objectId');

  const { activeMenuTab } = menus;
  const posFilteredMenuView = menuVersion.posEnabled && activeRestaurant.posFilteredMenu;

  if (activeMenuTab === -1 && posFilteredMenuView) {
    // hide global modifiers for pos filter menu view
    setMenuTabAction(0);
  }

  // Get the active menu. Because index -1 is "global modifiers", offset menu choice by one
  const activeMenu = activeMenuTab === -1 ? null : menus.localData[activeMenuTab];

  // Menu data
  useEffect(() => {
    fetchMenuDataAction(restaurantId, menuVersion?.posEnabled);

    return () => {
      clearBundlesAction();
      clearBundleOptionsAction();
      clearMenusAction();
      clearCategoryAction();
      clearProductsAction();
      clearOptionsAction();
      clearChoicesAction();
    };
  }, [
    fetchMenuDataAction,
    clearBundlesAction,
    clearBundleOptionsAction,
    clearMenusAction,
    clearCategoryAction,
    clearProductsAction,
    clearOptionsAction,
    clearChoicesAction,
    restaurantId,
    menuVersion?.posEnabled,
  ]);

  // Refresh
  // TODO this is a side effect of the import. Should be there
  useEffect(() => {
    if (menus?.shouldFetch) {
      fetchMenuDataAction(restaurantId, menuVersion?.posEnabled);
    }
  }, [menus.shouldFetch]);

  useEffect(() => {
    if (!options.fetching && !products.fetching && !bundles.fetching && !bundleOptions.fetching) {
      if (typeToReivew && objectIdToReview) {
        let reviewItem = null;

        switch (typeToReivew) {
          case 'option': {
            reviewItem = options.localData?.find((option) => option.objectId === objectIdToReview);
            break;
          }
          case 'product': {
            reviewItem = products.localData?.find(
              (product) => product.objectId === objectIdToReview,
            );
            break;
          }
          case 'bundleOption': {
            reviewItem = bundleOptions.localData?.find(
              (bundleOption) => bundleOption.objectId === objectIdToReview,
            );
            break;
          }
          case 'bundle': {
            reviewItem = bundles.localData?.find((bundle) => bundle.objectId === objectIdToReview);
            break;
          }
          default: {
            break;
          }
        }

        if (reviewItem) {
          setItemToReview(reviewItem);
        } else {
          setToastAction(
            `FIND_ITEM_TO_REVIEW_${new Date().getTime()}`,
            'Error: Unable to find item to review',
            'error',
          );
        }
      }
    }
  }, [options.fetching, products.fetching, bundles.fetching, bundleOptions.fetching]);

  const handleChange = (event, newValue) =>
    setMenuTabAction(posFilteredMenuView ? newValue : newValue - 1);

  const closeItemToReview = () => {
    window.history.pushState({}, document.title, window.location.pathname);
    setItemToReview(null);
  };

  if (menus.fetching) {
    return <></>;
  }

  return (
    <div className='v2-container' style={{ flexGrow: 1 }}>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        style={{ borderBottom: '2px solid #E4E4E4', minHeight: '272px' }}
      >
        <Tabs
          variant='scrollable'
          scrollButtons={menus.localData?.length === 0 ? 'off' : 'auto'}
          value={!posFilteredMenuView ? activeMenuTab + 1 : activeMenuTab}
          indicatorColor='primary'
          onChange={handleChange}
        >
          {!posFilteredMenuView && (
            <Tab
              key={0}
              index={-1}
              className={classes.tab}
              style={{
                backgroundColor: '#F3F3F3',
                maxWidth: '200px',
                minHeight: '272px',
              }}
              label={
                <Box>
                  <Box style={{ fontSize: '12px', color: '#2E2E2E' }}>Global</Box>
                  <Box
                    class={classes.globalMenuContent}
                    style={{
                      color: activeMenuTab === -1 ? '#E54439' : 'inherit',
                    }}
                  >
                    All menu items & options
                  </Box>
                </Box>
              }
            />
          )}

          {menus.localData?.map((menu, index) => {
            const active = index === activeMenuTab;

            return (
              <Tab
                key={menu.objectId}
                className={classes.tab}
                label={<MenuTab menu={menu} active={active} showActionMenu />}
              />
            );
          })}
        </Tabs>

        <MenuOptionsButton activeMenu={activeMenu} />
      </Box>

      <MenusValidation menus={menus} />

      <Breadcrumbs />

      {!posFilteredMenuView && activeMenuTab === -1 && (
        <MenuTabPanelContainer value={activeMenuTab} index={-1}>
          <GlobalMenuPanel />
        </MenuTabPanelContainer>
      )}

      {menus.localData?.map((menu, index) => (
        <MenuTabPanelContainer
          key={menu.objectId}
          value={activeMenuTab}
          menuId={menu.objectId}
          index={index}
        >
          {index === activeMenuTab && <MenuTabPanel menuId={menu.objectId} />}
        </MenuTabPanelContainer>
      ))}

      {typeToReivew === 'option' && (
        <OptionEditDialog
          openDialog={itemToReview}
          setOpenDialog={closeItemToReview}
          option={itemToReview}
          dialogContext={dialogContexts.EDIT}
        />
      )}

      {typeToReivew === 'product' && (
        <ProductEditDialog
          openDialog={itemToReview}
          setOpenDialog={closeItemToReview}
          product={itemToReview}
          dialogContext={dialogContexts.EDIT}
        />
      )}

      {typeToReivew === 'bundleOption' && (
        <BundleOptionEditDialog
          openDialog={itemToReview}
          setOpenDialog={closeItemToReview}
          bundleOption={itemToReview}
          dialogContext={dialogContexts.EDIT}
        />
      )}

      {typeToReivew === 'bundle' && (
        <BundleEditDialog
          openDialog={itemToReview}
          setOpenDialog={closeItemToReview}
          bundle={itemToReview}
          dialogContext={dialogContexts.EDIT}
        />
      )}
    </div>
  );
};

MenuTabs.propTypes = {
  clearProductsAction: PropTypes.func.isRequired,
  // TODO change objects
  // eslint-disable-next-line react/forbid-prop-types
  menus: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  menus: state.menus,
  options: state.options,
  products: state.products,
  bundles: state.bundles,
  bundleOptions: state.bundleOptions,
  optionsFetching: state.options.fetching,
  menuVersion: state.menuVersion,
  activeRestaurant: state.activeRestaurant.data,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setMenuTabAction,
      clearProductsAction,
      clearOptionsAction,
      clearBundlesAction,
      clearBundleOptionsAction,
      clearMenusAction,
      clearCategoryAction,
      clearChoicesAction,
      setToastAction,
      fetchMenuDataAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MenuTabs);
