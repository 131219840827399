import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DiscardDialog from '../DiscardDialog/DiscardDialog';

const BackButton = ({ route, skipChangesCheck }) => {
  const history = useHistory();

  const [discardCallback, setDiscardCallback] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);

  const handleOnClick = () => {
    if (!hasChanges || skipChangesCheck) {
      history.push(route);
      return;
    }

    setDiscardCallback(() => () => history.push(route));
  };

  return (
    <>
      <IconButton onClick={handleOnClick} color='primary'>
        <ChevronLeft />
      </IconButton>

      <DiscardDialog
        callback={discardCallback}
        handleClose={() => setDiscardCallback(null)}
        setHasChanges={setHasChanges}
      />
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BackButton);
