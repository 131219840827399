import React, { useEffect, useRef, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  Table,
  TableHead,
  Grid,
  Box,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useRollbar } from '@rollbar/react';
import { addBreadcrumbAction, popBreadcrumbAction } from '../../../../actions/breadcrumbsAction';
import { addChoicesToOptionAction } from '../../../../actions/optionsAction';
import { formatCurrency } from '../../../../helpers/Helpers';
import { TextButton } from '../Button/TextButton';
import OptionChoiceCreate from './OptionChoiceCreate';
import OptionChoiceEditDialog from './OptionChoiceEditDialog';
import useStyles from './OptionChoiceAddStyles';
import { dialogContexts } from '../../../../constants/constants';

/**
 * The dialog for dragging options onto a single menu item
 */
const OptionChoiceAddDialog = ({
  addChoicesToOptionAction,
  openDialog,
  setOpenDialog,
  choices,
  optionId,
  defaultSelectedItems,
  addBreadcrumbAction,
  popBreadcrumbAction,
  options,
}) => {
  const rollbar = useRollbar();
  const classes = useStyles();
  const [save, setSave] = useState(false);

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedChoiceIndex, setSelectedChoiceIndex] = useState(false);

  const [optionName, setOptionName] = useState('');
  useEffect(() => {
    setOptionName(options?.localData?.find((option) => option?.objectId === optionId)?.optionTitle);
  }, [optionId]);

  // Get options for each choice
  const processedChoices = choices?.localData.map((choice) => {
    let optionsForChoice = [];
    options?.localData?.forEach((option) => {
      if (option?.choiceIds?.includes(choice.objectId)) {
        optionsForChoice = [...optionsForChoice, option?.optionTitle];
      }
    });

    return {
      ...choice,
      options: optionsForChoice,
    };
  });

  // Find all choices with options and group them at the top
  let sortedChoices = [];
  options?.localData?.forEach((option) => {
    option?.choiceIds?.forEach((choiceId) => {
      if (
        sortedChoices.filter((sortedChoice) => sortedChoice?.objectId === choiceId).length === 0
      ) {
        const processedChoice = processedChoices.find((choice) => choice?.objectId === choiceId);

        // Error checking
        if (processedChoice === null) {
          rollbar.error(`Error: Choice is null ${choiceId}`);
        }

        sortedChoices = [...sortedChoices, processedChoice];
      }
    });
  });

  // Add choices that don't have an option
  processedChoices.forEach((choice) => {
    if (
      sortedChoices.filter((sortedChoice) => sortedChoice?.objectId === choice?.objectId).length ===
      0
    ) {
      sortedChoices = [...sortedChoices, choice];
    }
  });

  // breadcrumbs
  const [hasBreadcrumb, setHasBreadcrumb] = useState(false); // To ensure we don't pop some other breadcrumb if re-rendered while closed
  useEffect(() => {
    if (openDialog && !hasBreadcrumb) {
      addBreadcrumbAction(`Add choice`);
      setHasBreadcrumb(true);
    } else if (!openDialog && hasBreadcrumb) {
      popBreadcrumbAction();
      setHasBreadcrumb(false);
    }
  }, [openDialog]);

  const closeDialog = () => {
    setOpenDialog(false);
  };

  const validateForm = () => {
    const formError = false;

    return formError;
  };

  const submit = (event) => {
    event.preventDefault();

    const formError = validateForm();

    if (formError) {
      return;
    }
    addChoicesToOptionAction(selectedItems);

    setSave(true);
    closeDialog();
  };

  const tableRef = useRef();
  const [selectedItems, setSelectedItems] = useState([]);

  const selectItem = (itemId, value) => {
    if (value === true && !selectedItems.includes(itemId)) {
      setSelectedItems([...selectedItems, itemId]);
    }

    // Remove item if deselected and in list
    if (value === false) {
      const updatedSelectedItems = selectedItems.filter((item) => item !== itemId);
      setSelectedItems(updatedSelectedItems);
    }
  };

  useEffect(() => {
    setSelectedItems(defaultSelectedItems);
  }, [defaultSelectedItems, openDialog]);

  // Deselect all when closed
  useEffect(() => {
    if (!openDialog) {
      setSelectedItems([]);
    }
  }, [openDialog]);

  return (
    <Dialog
      open={openDialog}
      onClose={closeDialog}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth
      maxWidth='lg'
      className={classes.dialog}
      onEnter={() => {
        setSave(false);
      }}
      onExited={() => {
        if (!save) {
          // Revert Options back to as they were on enter
        }
      }}
    >
      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={2}>
          <Grid item md={8} xs={12} className={classes.leftColumn}>
            <Box className={classes.modalTitle}>Choices</Box>
            <Box className={classes.modalDescription}>Select from existing choices</Box>
            {sortedChoices.length === 0 ? (
              'No choices to display. Please create some first.'
            ) : (
              <Table tableref={tableRef}>
                <TableHead>
                  <TableRow>
                    <TableCell> </TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Cost</TableCell>
                    <TableCell>Options</TableCell>
                    <TableCell> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedChoices.map((choice, index) => (
                    <TableRow className={classes.row} key={choice?.objectId}>
                      <TableCell style={{ width: '20px' }}>
                        <Checkbox
                          style={{
                            color: selectedItems?.includes(choice?.objectId)
                              ? '#E54439'
                              : '#BDBDBD',
                          }}
                          checked={selectedItems?.includes(choice?.objectId)}
                          onClick={(event) => event.stopPropagation()}
                          onChange={(event) => selectItem(choice?.objectId, event.target.checked)}
                        />
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          selectItem(choice.objectId, !selectedItems.includes(choice.objectId))
                        }
                        style={{ fontSize: '18px', whiteSpace: 'nowrap' }}
                      >
                        {choice?.choiceTitle}
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          selectItem(choice.objectId, !selectedItems.includes(choice.objectId))
                        }
                        style={{ fontSize: '16px', whiteSpace: 'nowrap' }}
                      >
                        {choice?.price ? `+ ${formatCurrency(choice?.price)}` : ''}
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          selectItem(choice.objectId, !selectedItems.includes(choice.objectId))
                        }
                      >
                        <Box
                          style={{
                            fontSize: '16px',
                            color: '#2E2E2E',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            maxWidth: '300px',
                          }}
                        >
                          {choice?.options?.join('; ')}
                        </Box>
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: '#FFF',
                          color: '#E54439',
                          width: '65px',
                          padding: 0,
                        }}
                        onClick={(event) => {
                          event.stopPropagation();
                          setOpenEditDialog(true);
                          setSelectedChoiceIndex(index);
                        }}
                      >
                        <TextButton style={{ button: { padding: 0 } }}>Edit</TextButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Grid>
          <Grid item md={4} xs={12} className={classes.rightColumn}>
            <Box className={classes.modalDescription}>Create new</Box>
            <OptionChoiceCreate />
          </Grid>
        </Grid>

        {selectedChoiceIndex !== false && (
          <OptionChoiceEditDialog
            choice={sortedChoices[selectedChoiceIndex] || {}}
            dialogContext={dialogContexts.EDIT}
            openDialog={openEditDialog}
            selectedChoiceIndex={selectedChoiceIndex}
            setOpenDialog={setOpenEditDialog}
          />
        )}
      </DialogContent>
      <DialogActions style={{ borderTop: '1px solid #DCDCDB', minHeight: '50px' }}>
        <Box
          style={{ minHeight: '60px', margin: '0 20px', width: '100%' }}
          display='flex'
          justifyContent='space-between'
        >
          <Box>
            <Box
              style={{
                fontSize: '18px',
                fontWeight: 'bold',
                margin: '20px 0',
              }}
            >
              Add {selectedItems?.length} choice
              {selectedItems?.length === 1 ? '' : 's'} {optionName ? `to ${optionName}` : ''}
            </Box>
          </Box>
          <Box display='flex'>
            <Button onClick={closeDialog} color='default'>
              Cancel
            </Button>
            <Button onClick={submit} color='primary' type='submit' form='add-menu-item-option-form'>
              Add choices
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  choices: state.choices,
  options: state.options,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addChoicesToOptionAction,
      addBreadcrumbAction,
      popBreadcrumbAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(OptionChoiceAddDialog);
