import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SecondaryButton } from '../Button/SecondaryButton';
import ProductEditDialog from '../menuProduct/ProductEditDialog';
import { dialogContexts } from '../../../../constants/constants';

const BundleOptionProductCreate = ({ bundleOptionId }) => {
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <>
      <SecondaryButton
        onClick={() => setOpenDialog(true)}
        style={{
          text: { fontWeight: 'bold', color: '#E54439' },
        }}
      >
        CREATE MENU ITEM
      </SecondaryButton>
      <ProductEditDialog
        dialogContext={dialogContexts.CREATE}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        bundleOptionId={bundleOptionId}
      />
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BundleOptionProductCreate);
