import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { useState } from 'react';
import { dialogContexts } from '../../../../constants/constants';
import { SecondaryButton } from '../Button/SecondaryButton';
import { TextButton } from '../Button/TextButton';
import BundleOptionEditDialog from './BundleOptionEditDialog';

const BundleOptionCreate = ({
  bundleOptions,
  buttonLabel,
  buttonType,
  buttonBold = true,
  bundleId,
  bundleIds,
}) => {
  const [openDialog, setOpenDialog] = useState(false);

  const createButton = () => {
    switch (buttonType) {
      case 'text':
        return (
          <TextButton
            onClick={() => {
              setOpenDialog(true);
            }}
            style={{ text: { fontWeight: buttonBold ? 'bold' : 'inherit' } }}
          >
            {buttonLabel}
          </TextButton>
        );
      case 'secondary':
      default:
        return (
          <SecondaryButton
            onClick={() => {
              setOpenDialog(true);
            }}
            style={{ text: { fontWeight: buttonBold ? 'bold' : 'inherit' } }}
          >
            {buttonLabel}
          </SecondaryButton>
        );
    }
  };

  return (
    <>
      {createButton()}
      <BundleOptionEditDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        dialogContext={dialogContexts.CREATE}
        bundleOption={{}}
        bundleId={bundleId}
        bundleIds={bundleIds}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  bundleOptions: state.bundleOptions,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BundleOptionCreate);
