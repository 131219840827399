import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tabs, Tab, Paper, Box, Chip, Typography } from '@material-ui/core';
import moment from 'moment';
import { clearMenusAction, fetchMenusAction, setMenuTabAction } from '../../actions/menusAction';
import MenuTabPanelContainer from '../menuTabPanel/MenuTabPanelContainer';
import MenuOptionsButton from '../menuOptions/MenuOptionsButton';
import { menuTypeMap, menuPlatformMap } from '../../constants/constants';
import useStyles from './MenuTabsStyles';
import MenusValidation from './MenusValidation';
import { clearMenuItemsAction } from '../../actions/menuItemsAction';

const Menu = ({
  clearMenusAction,
  clearMenuItemsAction,
  fetchMenusAction,
  menuItemsFetching,
  menus,
  restaurantId,
  menuVersion,
  setMenuTabAction,
}) => {
  const classes = useStyles();

  const { activeMenuTab } = menus;
  const activeMenu = menus.localData[menus.activeMenuTab];

  useEffect(() => {
    fetchMenusAction(restaurantId);

    return () => {
      clearMenusAction();
      clearMenuItemsAction();
    };
  }, [clearMenusAction, fetchMenusAction, restaurantId, menuVersion]);

  useEffect(() => {
    if (menus.shouldFetch) {
      fetchMenusAction(restaurantId);
    }
  }, [menus.shouldFetch]);

  const handleChange = (event, newValue) => setMenuTabAction(newValue);

  if (menus.fetching) {
    return <></>;
  }

  const minutesToTime = (minutes, format = 'h:mm a') =>
    moment().startOf('day').add(minutes, 'minutes').format(format);

  return (
    <div style={{ flexGrow: 1, marginTop: '32px' }}>
      <Paper>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          {menus.localData.length > 0 ? (
            <Tabs
              variant='scrollable'
              scrollButtons={menus.localData.length === 0 || menuItemsFetching ? 'off' : 'auto'}
              value={activeMenuTab}
              indicatorColor='primary'
              textColor='primary'
              onChange={handleChange}
            >
              {menus.localData.map((menu, index) => {
                const active = index === activeMenuTab;

                const platformText =
                  !menu.platform || menu.platform === 'all'
                    ? `${menuPlatformMap('eatclub').long} + ${menuPlatformMap('whitelabel').long}`
                    : menuPlatformMap(menu.platform).long;

                const typeText =
                  !menu.type || menu.type === 'all'
                    ? `${menuTypeMap('takeaway').long} + ${menuTypeMap('dinein').long}`
                    : menuTypeMap(menu.type).long;

                const isTimeLimited = menu.startTime > -1 && menu.endTime > -1;

                const startTime = minutesToTime(menu.startTime);
                const endTime = minutesToTime(menu.endTime);

                return (
                  <Tab
                    key={menu.objectId}
                    className={classes.tab}
                    style={{ opacity: !menu.enabled ? 0.6 : 1 }}
                    label={
                      <div>
                        <div>
                          {menu.menuTitle} {!menu.enabled && <small>(Disabled)</small>}
                        </div>
                        <div>
                          <small>
                            {isTimeLimited ? (
                              <>
                                {startTime} - {endTime}
                              </>
                            ) : (
                              'All Day'
                            )}
                          </small>
                        </div>
                      </div>
                    }
                    icon={
                      <Box display='flex' flexDirection='column' style={{ marginTop: 6 }}>
                        <Chip
                          style={{
                            height: '18px',
                            fontSize: '0.625rem',
                            marginBottom: '6px',
                          }}
                          variant='default'
                          color={active ? 'primary' : 'secondary'}
                          size='small'
                          label={platformText}
                        />
                        <Chip
                          style={{
                            height: '18px',
                            fontSize: '0.625rem',
                          }}
                          variant='default'
                          color={active ? 'primary' : 'secondary'}
                          size='small'
                          label={typeText}
                        />
                      </Box>
                    }
                  />
                );
              })}
            </Tabs>
          ) : (
            <Typography style={{ padding: '1rem' }} variant='subtitle1' color='textSecondary'>
              No Menus
            </Typography>
          )}
          <MenuOptionsButton activeMenu={activeMenu} />
        </Box>
      </Paper>

      <MenusValidation menus={menus} />

      {menus.localData.map((menu, index) => (
        <MenuTabPanelContainer
          key={menu.objectId}
          value={activeMenuTab}
          menuId={menu.objectId}
          index={index}
          menuVersion={menu.version}
        />
      ))}
    </div>
  );
};

Menu.propTypes = {
  clearMenusAction: PropTypes.func.isRequired,
  fetchMenusAction: PropTypes.func.isRequired,
  menus: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  menus: state.menus,
  menuItemsFetching: state.menuItems.fetching,
  menuVersion: state.menuVersion,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearMenusAction,
      fetchMenusAction,
      clearMenuItemsAction,
      setMenuTabAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
