import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List, Box, FormHelperText } from '@material-ui/core';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useRollbar } from '@rollbar/react';
import { removeSpaces } from '../../../../helpers/Helpers';
import BundleOptionProduct from './BundleOptionProduct';
import BundleOptionProductAdd from './BundleOptionProductAdd';
import useStyles from './BundleOptionProductsStyles';
import { addProductsToBundleOptionAction } from '../../../../actions/bundleOptionsAction';
import ProductEditDialog from '../menuProduct/ProductEditDialog';

const BundleOptionProducts = ({
  productError,
  products,
  bundleOptionId,
  bundleOption,
  bundleOptions,
  setBundleOptionProducts,
  addProductsToBundleOptionAction,
  menuVersion,
}) => {
  const rollbar = useRollbar();
  const classes = useStyles();

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContext, setDialogContext] = useState('');
  const [selectedProductIndex, setSelectedChoiceIndex] = useState(false);

  const [storedProducts, setStoredProducts] = useState(bundleOptions?.storedProducts || []);

  const hydratedProducts = storedProducts?.map((bundleOptionProductId) =>
    products.localData.find((product) => bundleOptionProductId === product.objectId),
  );

  // Update stored choices to reflect state
  useEffect(() => {
    setStoredProducts(bundleOptions.storedProducts);
  }, [bundleOptions.storedProducts, bundleOptionId]);

  const onDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    // dropped in same position
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const newStoredProducts = Array.from(storedProducts);

    const dragProduct = newStoredProducts?.splice(source.index, 1)[0];

    newStoredProducts?.splice(destination.index, 0, dragProduct);
    addProductsToBundleOptionAction(newStoredProducts);
  };

  useEffect(() => {
    const bundleOptionProductIds = bundleOption?.productIds
      ? bundleOption?.productIds
      : bundleOptions?.storedProducts;

    setBundleOptionProducts(
      products.localData.filter((choice) => bundleOptionProductIds?.includes(choice.objectId)),
    );
  }, [bundleOptions.storedProducts, products.localData]);

  return (
    <>
      <Box className={`${classes.boxOutlined}`}>
        <List dense={false}>
          <DragDropContext onDragEnd={onDragEnd}>
            {storedProducts?.length === 0 && (
              <Box className={classes.emptyText}>
                There are currently no menu items attached to this bundle option
              </Box>
            )}
            {storedProducts?.length > 0 && (
              <Droppable droppableId={bundleOptionId} type='menu-item-option-choice'>
                {(provided, snapshot) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={`${classes.droppableMenuItemOptionChoice} ${
                      snapshot.isDraggingOver ? 'dragging-over' : ''
                    } `}
                  >
                    {storedProducts?.map((productId, index) => {
                      const bundleOptionProduct = products.localData.find(
                        (product) => productId === product.objectId,
                      );

                      if (!bundleOptionProduct) {
                        rollbar.error(`Error: Missing bundle option menu item ${productId}`);
                        return <></>;
                      }

                      return (
                        <BundleOptionProduct
                          product={bundleOptionProduct}
                          index={index}
                          key={`${index}-${removeSpaces(bundleOptionProduct?.productTitle)}`}
                          bundleOption={bundleOption}
                          setDialogContext={setDialogContext}
                          setOpenDialog={setOpenDialog}
                          setSelectedChoiceIndex={setSelectedChoiceIndex}
                        />
                      );
                    })}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            )}
            {!menuVersion.posEnabled && (
              <Box style={{ marginTop: '20px' }}>
                <BundleOptionProductAdd
                  selectedProducts={bundleOptions.storedProducts}
                  bundleOptionId={bundleOptionId}
                />
              </Box>
            )}
            {selectedProductIndex !== false && (
              <ProductEditDialog
                bundleOptionId={bundleOptionId}
                openDialog={openDialog}
                dialogContext={dialogContext}
                setOpenDialog={setOpenDialog}
                product={hydratedProducts[selectedProductIndex] || {}}
              />
            )}
          </DragDropContext>
        </List>
        {productError && (
          <FormHelperText variant='outlined' error style={{ fontSize: '14px' }}>
            {productError}
          </FormHelperText>
        )}
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  bundleOptions: state.bundleOptions,
  products: state.products,
  menuVersion: state.menuVersion,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ addProductsToBundleOptionAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BundleOptionProducts);
