import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './reducers/rootReducer';

let middlewares = [thunk];

if (!process.env.REACT_APP_MODE || process.env.REACT_APP_MODE === 'development') {
  const logger = createLogger({
    collapsed: true,
    diff: true,
  });

  middlewares = [...middlewares, logger];
}

const store = createStore(rootReducer, applyMiddleware(...middlewares));
export default store;
