import React, { useMemo, useRef, useState } from 'react';
import { Box, Button, Divider, Menu, MenuItem, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { SecondaryButton } from '../Button/SecondaryButton';
import MenuCreateIntegratedDialog from './MenuCreateIntegratedDialog';
import MenuEditDialog from './MenuEditDialog';
import MenuCopyDialog from './MenuCopyDialog';
import MenuDeleteDialog from './MenuDeleteDialog';
import MenuCreateDialog from './MenuCreateDialog';
import { ReactComponent as DownCaretIcon } from '../../../../assets/down_caret.svg';
import { ReactComponent as CheckIcon } from '../../../../assets/small_check.svg';
import useStyles from './MenuOptionsButtonStyles';
import DiscardDialog from '../../../DiscardDialog/DiscardDialog';
import { COLORS } from '../../../../EatClubTheme';
import { isEmpty } from '../../../../constants/constants';

const MenuOptionsButton = ({
  activeMenu,
  activeRestaurant,
  menuVersion,
  options,
  choices,
  bundles,
  bundleOptions,
  products,
}) => {
  const history = useHistory();
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openCopyDialog, setOpenCopyDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openCreateIntegratedDialog, setOpenCreateIntegratedDialog] = useState(false);

  const [discardCallback, setDiscardCallback] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);

  const handleOnClick = () => {
    if (!hasChanges) {
      showDuplicationOptions();
      return;
    }

    setDiscardCallback(() => () => showDuplicationOptions());
  };

  const classes = useStyles();

  const ref = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenDropdown = (event) => {
    setAnchorEl(ref.current);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  const handleClickOption = (action) => {
    action(true);
    handleCloseMenu();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const showDuplicationOptions = () => {
    // history.push(`/duplicate/${activeRestaurant.data.objectId}?version=1`);
  };

  let items = [{ name: 'Create new menu', action: setOpenCreateDialog }];

  if (activeMenu) {
    items = [
      ...items,
      ...[
        { name: `Edit ${activeMenu?.menuTitle}`, action: setOpenEditDialog },
        { name: `Copy ${activeMenu?.menuTitle}`, action: setOpenCopyDialog },
        {
          name: `Delete ${activeMenu?.menuTitle}`,
          action: setOpenDeleteDialog,
        },
      ],
    ];
  }

  // Determine if POS items have been imported
  const hasImported = useMemo(() =>
    [options, choices, bundles, bundleOptions, products].some(
      (item) => !isEmpty(item.localData),
      [
        options.localData,
        choices.localData,
        bundles.localData,
        bundleOptions.localData,
        products.localData,
      ],
    ),
  );

  // if (!menuVersion.posEnabled) {
  //   items = [
  //     ...items,
  //     {
  //       name: 'Duplicate to other venue',
  //       action: handleOnClick,
  //     },
  //   ];
  // }

  // TODO replace menu with ec-component

  return (
    <Box ref={ref}>
      <SecondaryButton onClick={handleOpenDropdown}>
        <Box style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
          OPTIONS
          <DownCaretIcon />
        </Box>
      </SecondaryButton>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseDropdown}
        classes={classes}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {/* If POS and hasn't imported */}
        {menuVersion.posEnabled && !hasImported ? (
          <Box>
            <Typography variant='body1' style={{ fontWeight: 500, textAlign: 'center' }}>
              Get started
            </Typography>
            <Button
              onClick={setOpenCreateIntegratedDialog}
              color='primary'
              variant='contained'
              style={{ margin: '6px 12px 0 12px' }}
            >
              Import from POS
            </Button>
          </Box>
        ) : (
          <Box>
            {items.map((item, index) => (
              <MenuItem key={index} onClick={() => handleClickOption(item.action)}>
                {item.name}
              </MenuItem>
            ))}

            {/* If POS and has imported */}
            {menuVersion.posEnabled && hasImported && (
              <Box>
                <Divider style={{ margin: '6px 0' }} />
                <Box className={classes.importedContainer}>
                  <Box className={classes.itemsImported}>
                    <CheckIcon height={18} width={18} />
                    <Typography variant='body1'>POS items imported</Typography>
                  </Box>
                  <MenuItem
                    onClick={setOpenCreateIntegratedDialog}
                    style={{ color: COLORS.BRAND_PRIMARY }}
                  >
                    Import again
                  </MenuItem>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Menu>

      {activeMenu && (
        <>
          <MenuEditDialog
            activeMenu={activeMenu}
            openDialog={openEditDialog}
            setOpenDialog={setOpenEditDialog}
          />

          <MenuCopyDialog
            activeMenu={activeMenu}
            openDialog={openCopyDialog}
            setOpenDialog={setOpenCopyDialog}
          />

          <MenuDeleteDialog
            activeMenu={activeMenu}
            openDialog={openDeleteDialog}
            setOpenDialog={setOpenDeleteDialog}
          />
        </>
      )}

      <MenuCreateIntegratedDialog
        openDialog={openCreateIntegratedDialog}
        setOpenDialog={setOpenCreateIntegratedDialog}
      />

      <MenuCreateDialog openDialog={openCreateDialog} setOpenDialog={setOpenCreateDialog} />

      <DiscardDialog
        callback={discardCallback}
        handleClose={() => setDiscardCallback(null)}
        setHasChanges={setHasChanges}
      />
    </Box>
  );
};

const mapStateToProps = (state) => ({
  activeRestaurant: state.activeRestaurant,
  menuVersion: state.menuVersion,
  options: state.options,
  choices: state.choices,
  bundles: state.bundles,
  bundleOptions: state.bundleOptions,
  products: state.products,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MenuOptionsButton);
