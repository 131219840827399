import React, { useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createChoiceAction } from '../../../../actions/choicesAction';
import { addChoiceToOptionAction } from '../../../../actions/optionsAction';
import { TextButton } from '../Button/TextButton';

const OptionChoiceCreate = ({ createChoiceAction, addChoiceToOptionAction }) => {
  const newChoiceId = `choice_${new Date().getTime()}`;

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');

  const [price, setPrice] = useState('');
  const [includePrice, setIncludePrice] = useState(false);
  const [priceError, setPriceError] = useState('');

  const validateForm = () => {
    let formError = false;
    if (!name) {
      setNameError('Please enter a choice name.');
      formError = true;
    } else if (nameError) {
      setNameError('');
    }

    if (includePrice) {
      if (!price || price === '0.00') {
        setPriceError('Please enter a price.');
        formError = true;
      } else if (priceError) {
        setPriceError('');
      }
    }

    return formError;
  };

  const formatNumber = (num) => (Math.round(num * 100) / 100).toFixed(2);

  const submit = (event) => {
    event.preventDefault();

    const formError = validateForm();

    if (formError) {
      return;
    }

    createChoiceAction(newChoiceId, name, includePrice ? price : 0);
    addChoiceToOptionAction(newChoiceId);

    // TODO clear form
    setName('');
    setPrice('');
    setIncludePrice(false);
    setPriceError('');
  };

  return (
    <>
      <form onSubmit={submit} id='edit-choice-form'>
        <TextField
          id='option-choice-name'
          label='Choice Name'
          placeholder='eg. Rare, Medium Rare, Medium, Well Done'
          variant='outlined'
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          onBlur={(e) => {
            setName(e.target.value.trim());
            if (e.target.value) {
              validateForm();
            }
          }}
          InputLabelProps={{ shrink: true }}
          margin='normal'
          required
          error={Boolean(nameError)}
          helperText={nameError}
          onKeyUp={(e) => e.code === 'Enter' && submit(e)}
        />

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                style={{ color: includePrice ? '#E54439' : '#BDBDBD' }}
                checked={includePrice}
                onChange={(e) => setIncludePrice(e.target.checked)}
                name='include-price'
              />
            }
            label='Is this choice an extra cost?'
          />
        </FormGroup>

        {includePrice && (
          <TextField
            id='option-choice-price'
            label='Price'
            placeholder='e.g. 17.50'
            variant='outlined'
            fullWidth
            value={price}
            type='number'
            onChange={(e) => setPrice(e.target.value)}
            onBlur={(e) => setPrice(formatNumber(e.target.value))}
            InputLabelProps={{ shrink: true }}
            min={1}
            inputProps={{ min: 1, step: 1 }}
            InputProps={{
              startAdornment: <InputAdornment position='start'>$</InputAdornment>,
            }}
            margin='normal'
            error={Boolean(priceError)}
            helperText={priceError}
            onKeyUp={(e) => e.code === 'Enter' && submit(e)}
          />
        )}
      </form>
      <TextButton
        onClick={submit}
        style={{
          text: { fontWeight: 'bold', color: '#E54439', marginLeft: '-20px' },
        }}
      >
        CREATE CHOICE
      </TextButton>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createChoiceAction,
      addChoiceToOptionAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(OptionChoiceCreate);
