import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: '10px',
    padding: '5px 0',
    borderRadius: '8px',
    border: '1px solid #E3E3E3',
  },
  importedContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
  },
  itemsImported: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    padding: '6px 16px',
  },
}));

export default useStyles;
