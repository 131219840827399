import { devLog } from '../helpers/devLog';
import * as type from './types';
import {
  createChoices,
  disableChoiceForDate,
  disableChoiceUntilDate,
  removeChoices,
  restockChoice,
  updateChoices,
} from '../graphql/mutations';
import { parseJson, removeEmpty } from '../helpers/Helpers';
import { makeApiCall } from './actions';
import store from '../store';
import { syncMenu, syncMenuCacheAction } from './menusAction';

export const createChoiceAction = (choiceId, choiceTitle, price) => (dispatch) => {
  dispatch({
    type: type.CREATE_CHOICE,
    payload: { choiceId, choiceTitle, price },
  });
};

export const updateChoiceAction =
  (choiceId, choiceTitle, price, disableDate, disableUntilDate, importStatus) => (dispatch) => {
    dispatch({
      type: type.UPDATE_CHOICE,
      payload: {
        choiceId,
        choiceTitle,
        price,
        disableDate,
        disableUntilDate,
        importStatus,
      },
    });
  };

export const deleteChoiceAction = (choiceId) => (dispatch) => {
  dispatch({
    type: type.DELETE_CHOICE,
    payload: { choiceId },
  });
};

export const clearChoicesAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_CHOICES,
  });
};

export const disableLocalChoiceForDateAction = (choiceId, disableDate) => (dispatch) => {
  dispatch({
    type: type.DISABLE_LOCAL_CHOICE_FOR_DATE,
    payload: { choiceId, disableDate },
  });
};

export const disableLocalChoiceUntilDateAction = (choiceId, disableUntilDate) => (dispatch) => {
  dispatch({
    type: type.DISABLE_LOCAL_CHOICE_UNTIL_DATE,
    payload: { choiceId, disableUntilDate },
  });
};

export const restockChoiceAction = (choiceId) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: `RESTOCK_CHOICE_${choiceId}`,
  });

  dispatch({
    type: type.RESTOCK_CHOICE_PENDING,
  });

  (async () => {
    try {
      const response = await makeApiCall(restockChoice, { choiceId });

      devLog('success', 'restock choice', response.data.restockChoice);

      dispatch({
        type: type.RESTOCK_CHOICE_SUCCESS,
        payload: response.data.restockChoice,
      });

      syncMenu(dispatch);
    } catch (error) {
      devLog('error', 'restock choice', error);

      dispatch({
        type: type.SET_TOAST,
        payload: {
          id: `RESTOCK_CHOICE_${new Date().getTime()}`,
          message: `Unable to restock choice: ${error}`,
          type: 'error',
        },
      });

      dispatch({
        type: type.RESTOCK_CHOICE_FAILURE,
        payload: `Unable to restock choice: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: `RESTOCK_CHOICE_${choiceId}`,
      });
    }
  })();
};

export const disableChoiceForDateAction = (choiceId, disableDate) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: `DISABLE_CHOICE_FOR_DATE_${choiceId}`,
  });

  dispatch({
    type: type.DISABLE_CHOICE_FOR_DATE_PENDING,
  });

  (async () => {
    try {
      const response = await makeApiCall(disableChoiceForDate, { choiceId, disableDate });

      devLog('success', 'disable choice for date', response.data.disableChoiceForDate);

      dispatch({
        type: type.DISABLE_CHOICE_FOR_DATE_SUCCESS,
        payload: response.data.disableChoiceForDate,
      });

      syncMenu(dispatch);
    } catch (error) {
      devLog('error', 'disable choice for date', error);

      dispatch({
        type: type.SET_TOAST,
        payload: {
          id: `DISABLE_CHOICE_FOR_DATE_${new Date().getTime()}`,
          message: `Unable to disable choice for date: ${error}`,
          type: 'error',
        },
      });

      dispatch({
        type: type.DISABLE_CHOICE_FOR_DATE_FAILURE,
        payload: `Unable to disable choice for date: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: `DISABLE_CHOICE_FOR_DATE_${choiceId}`,
      });
    }
  })();
};

export const disableChoiceUntilDateAction = (choiceId, disableUntilDate) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: `DISABLE_CHOICE_UNTIL_DATE_${choiceId}`,
  });

  dispatch({
    type: type.DISABLE_CHOICE_UNTIL_DATE_PENDING,
  });

  (async () => {
    try {
      const response = await makeApiCall(disableChoiceUntilDate, { choiceId, disableUntilDate });

      devLog('success', 'disable choice until date', response.data.disableChoiceUntilDate);

      dispatch({
        type: type.DISABLE_CHOICE_UNTIL_DATE_SUCCESS,
        payload: response.data.disableChoiceUntilDate,
      });

      syncMenu(dispatch);
    } catch (error) {
      devLog('error', 'disable choice until date', error);

      dispatch({
        type: type.SET_TOAST,
        payload: {
          id: `DISABLE_CHOICE_UNTIL_DATE_${new Date().getTime()}`,
          message: `Unable to disable choice until date: ${error}`,
          type: 'error',
        },
      });

      dispatch({
        type: type.DISABLE_CHOICE_UNTIL_DATE_FAILURE,
        payload: `Unable to disable choice until date: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: `DISABLE_CHOICE_UNTIL_DATE_${choiceId}`,
      });
    }
  })();
};

export const dbRemoveChoices = async (choiceIds, restaurantId, dispatch) => {
  try {
    const response = await makeApiCall(removeChoices, {
      restId: restaurantId,
      choiceIds,
    });

    devLog('success', 'remove choices', response.data.removeChoices);

    return null;
  } catch (error) {
    devLog('error', 'unable to remove choices', error);

    dispatch({
      type: type.SET_TOAST,
      payload: {
        id: `CHOICE_REMOVE_${new Date().getTime()}`,
        message: `Unable to remove choices: ${error}`,
        type: 'error',
      },
    });

    return error;
  }
};

export const dbCreateChoices = async (choices, restaurantId, dispatch) => {
  try {
    const response = await makeApiCall(createChoices, {
      restId: restaurantId,
      choices: choices.map((choice) =>
        removeEmpty({
          restId: restaurantId,
          choiceTitle: choice.choiceTitle,
          price: choice.price,
          disableDate: choice.disableDate,
          disableUntilDate: choice.disableUntilDate,
          importStatus: choice.importStatus,
        }),
      ),
    });

    if (response?.data?.createChoices === undefined || response?.data?.createChoices === null) {
      throw new Error('Response is undefined');
    }

    devLog('success', 'create choices', response.data.createChoices);

    return response?.data?.createChoices?.map((createdChoice, index) => {
      dispatch({
        type: type.BACKEND_SAVE_CHOICE_SUCCESS,
        payload: {
          savedItem: createdChoice,
          itemObjectId: choices[index]._objectId,
        },
      });

      // Also return the original objectId
      return { ...createdChoice, _objectId: choices[index]._objectId };
    });
  } catch (error) {
    devLog('error', 'unable to create choices', error);

    dispatch({
      type: type.SET_TOAST,
      payload: {
        id: `CHOICES_CREATE_${new Date().getTime()}`,
        message: `Unable to create choices: ${error}`,
        type: 'error',
      },
    });

    return error;
  }
};

export const dbUpdateChoices = async (choices, restaurantId, dispatch) => {
  try {
    const response = await makeApiCall(updateChoices, {
      restId: restaurantId,
      choices: choices.map((choice) =>
        removeEmpty({
          objectId: choice.objectId,
          choiceTitle: choice.choiceTitle,
          price: choice.price,
          importStatus: choice.importStatus,
        }),
      ),
    });

    if (response?.data?.updateChoices === undefined || response?.data?.updateChoices === null) {
      throw new Error('Response is undefined');
    }

    devLog('success', 'update choices', response.data.updateChoices);

    return response?.data?.updateChoices?.map((updatedChoice, index) => {
      dispatch({
        type: type.BACKEND_SAVE_CHOICE_SUCCESS,
        payload: {
          savedItem: updatedChoice,
          itemObjectId: choices[index]._objectId,
        },
      });

      // Also return the original objectId
      return { ...updatedChoice, _objectId: choices[index]._objectId };
    });
  } catch (error) {
    devLog('error', 'unable to update choices', error);

    dispatch({
      type: type.SET_TOAST,
      payload: {
        id: `CHOICES_UPDATE_${new Date().getTime()}`,
        message: `Unable to update choices: ${error}`,
        type: 'error',
      },
    });

    return error;
  }
};
