import React from 'react';
import { Typography, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import image from './burger-404.png';

const NotFound = () => (
  <div style={{ textAlign: 'center' }}>
    <img src={image} alt='404 Burger' style={{ marginBottom: 10 }} />
    <Typography variant='subtitle1' style={{ marginBottom: 20 }}>
      We couldn&apos;t find the page you were looking for.
      <br />
      Please check the URL or <Link to='/'>select a restaurant</Link>.
    </Typography>
    <Button variant='outlined' color='primary' component={Link} to='/'>
      Select Restaurant
    </Button>
  </div>
);

export default NotFound;
