import React from 'react';
import { TabbedContent } from '@eatclub-apps/ec-component-library';
import Options from '../menuOption/Options';
import Products from '../menuProduct/Products';
import Bundles from '../menuBundle/Bundles';
import BundleOptions from '../menuBundleOption/BundleOptions';

const GlobalMenuPanel = () => (
  <TabbedContent
    tabs={[
      { label: 'All menu items', component: <Products /> },
      { label: 'All options', component: <Options /> },
      { label: 'All bundles', component: <Bundles /> },
      { label: 'All bundle options', component: <BundleOptions /> },
    ]}
  />
);

GlobalMenuPanel.propTypes = {};

export default GlobalMenuPanel;
