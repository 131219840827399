import * as type from '../actions/types';
import {
  deleteItemsInArray,
  filterUnique,
  getItemInArray,
  insertItemInArrayAtIndex,
  updateItemInArray,
} from '../helpers/ArrayUtils';

const initialState = {
  data: [],
  localData: [],
  storedOptions: [],
  deleted: [],
  fetching: false,
  error: false,
  errorMessage: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case type.FETCH_BUNDLES_PENDING: {
      return {
        ...initialState,
        fetching: true,
      };
    }

    case type.FETCH_BUNDLES_SUCCESS: {
      return {
        ...state,
        data: action.payload || [],
        localData: action.payload || [],
        fetching: false,
        error: false,
        errorMessage: '',
      };
    }

    case type.FETCH_BUNDLES_FAILURE: {
      return {
        ...state,
        data: [],
        localData: [],
        deleted: [],
        fetching: false,
        error: true,
        errorMessage: action.payload,
      };
    }

    case type.CREATE_BUNDLE: {
      const {
        bundleId,
        bundleTitle,
        bundleDescription,
        price,
        imageLink,
        image,
        noDiscount,
        disableDate,
        disableUntilDate,
        restId,
        posId,
      } = action.payload;

      const newBundle = {
        objectId: bundleId,
        bundleTitle,
        bundleDescription,
        imageLink,
        ...(Object?.keys(image).length > 0 && { _image: image }),
        price,
        noDiscount,
        disableDate,
        disableUntilDate,
        restId,
        posId,
        importState: null,
        bundleOptionIds: state.storedOptions,
        _created: true,
      };

      const newBundles = [...state.localData, newBundle];

      return { ...state, localData: newBundles, storedOptions: [] };
    }

    case type.UPDATE_BUNDLE: {
      const {
        objectId,
        bundleTitle,
        bundleDescription,
        price,
        imageLink,
        image,
        noDiscount,
        disableDate,
        disableUntilDate,
        restId,
        posId,
        importStatus,
      } = action.payload;

      const newBundles = updateItemInArray(state.localData, 'objectId', objectId, (bundle) => ({
        ...bundle,
        bundleTitle,
        bundleDescription,
        price,
        imageLink,
        noDiscount,
        disableDate,
        disableUntilDate,
        restId,
        posId,
        bundleOptionIds: state.storedOptions,
        importStatus,
        _image: image,
        _altered: true,
      }));

      return { ...state, localData: newBundles, storedOptions: [] };
    }

    case type.DELETE_BUNDLE: {
      const { bundleId } = action.payload;

      const deleted = getItemInArray(state.localData, 'objectId', bundleId);

      const newDeleted = { ...deleted, _deleted: true };

      const newBundles = deleteItemsInArray(state.localData, 'objectId', bundleId);

      const newDeletedItems = insertItemInArrayAtIndex(state.deleted, 0, newDeleted);

      return {
        ...state,
        localData: newBundles,
        deleted: newDeletedItems,
      };
    }

    case type.REVIEW_BUNDLE: {
      const { bundleId } = action.payload;

      const newBundles = updateItemInArray(state.localData, 'objectId', bundleId, (bundle) => ({
        ...bundle,
        importStatus: 'reviewed',
        _altered: true,
      }));

      return {
        ...state,
        localData: newBundles,
      };
    }

    case type.CLEAR_BUNDLES: {
      return initialState;
    }

    case type.ADD_BUNDLE_OPTION_TO_BUNDLE: {
      const { bundleOptionId } = action.payload;

      const newStoredOptions = [...state.storedOptions, bundleOptionId].filter(filterUnique);

      return { ...state, storedOptions: newStoredOptions };
    }

    case type.ADD_BUNDLE_OPTIONS_TO_BUNDLE: {
      const { bundleOptions } = action.payload;

      return { ...state, storedOptions: bundleOptions };
    }

    case type.ADD_BUNDLE_OPTIONS_TO_BUNDLES: {
      const { bundleIds, bundleOptionIds } = action.payload;

      const newBundles = state.localData.map((bundle) => {
        if (bundleIds?.includes(bundle.objectId)) {
          const newBundleOptions = [...(bundle.bundleOptionIds || []), ...bundleOptionIds].filter(
            filterUnique,
          );
          return {
            ...bundle,
            bundleOptionIds: newBundleOptions,
            _altered: true,
          };
        }

        return bundle;
      });

      return { ...state, localData: newBundles, storedOptions: [] };
    }

    case type.REMOVE_BUNDLE_OPTION_FROM_BUNDLE: {
      const { bundleOptionId } = action.payload;

      return {
        ...state,
        storedOptions: state.storedOptions.filter(
          (storedOption) => storedOption !== bundleOptionId,
        ),
      };
    }

    case type.REMOVE_BUNDLE_OPTIONS_FROM_BUNDLES: {
      const { bundleIds, bundleOptionIds } = action.payload;

      const newBundles = state.localData.map((bundle) => {
        if (bundleIds.includes(bundle.objectId)) {
          const newBundleOptions = bundle.bundleOptionIds.filter(
            (bundleOptionId) => !bundleOptionIds.includes(bundleOptionId),
          );

          return {
            ...bundle,
            bundleOptionIds: newBundleOptions,
            _altered: true,
          };
        }

        return bundle;
      });

      return { ...state, localData: newBundles, storedOptions: [] };
    }

    case type.CLEAR_BUNDLE_OPTIONS_FROM_BUNDLE: {
      return { ...state, storedOptions: [] };
    }

    case type.DISABLE_LOCAL_BUNDLE_FOR_DATE: {
      const { bundleId, disableDate } = action.payload;

      const newBundles = updateItemInArray(state.localData, 'objectId', bundleId, (bundle) => ({
        ...bundle,
        disableDate,
      }));

      return {
        ...state,
        localData: newBundles,
      };
    }

    case type.DISABLE_LOCAL_BUNDLE_UNTIL_DATE: {
      const { bundleId, disableUntilDate } = action.payload;
      const newBundles = updateItemInArray(state.localData, 'objectId', bundleId, (bundle) => ({
        ...bundle,
        disableUntilDate,
      }));

      return {
        ...state,
        localData: newBundles,
      };
    }

    case type.RESTOCK_BUNDLE_SUCCESS: {
      const { objectId, disableDate, disableUntilDate } = action.payload;

      const newBundles = updateItemInArray(state.localData, 'objectId', objectId, (bundle) => ({
        ...bundle,
        disableDate,
        disableUntilDate,
      }));

      return {
        ...state,
        localData: newBundles,
      };
    }

    case type.DISABLE_BUNDLE_FOR_DATE_SUCCESS: {
      const { objectId, disableDate } = action.payload;
      const newBundles = updateItemInArray(state.localData, 'objectId', objectId, (bundle) => ({
        ...bundle,
        disableDate,
      }));

      return {
        ...state,
        localData: newBundles,
      };
    }

    case type.DISABLE_BUNDLE_UNTIL_DATE_SUCCESS: {
      const { objectId, disableUntilDate } = action.payload;
      const newBundles = updateItemInArray(state.localData, 'objectId', objectId, (bundle) => ({
        ...bundle,
        disableUntilDate,
      }));

      return {
        ...state,
        localData: newBundles,
      };
    }

    // Remove the bundle option from all bundles that contain it
    case type.DELETE_BUNDLE_OPTION: {
      const { bundleOptionId } = action.payload;

      const updatedBundles = state.localData.map((bundle) => {
        if (!bundle?.bundleOptionIds?.includes(bundleOptionId)) {
          return bundle;
        }

        const newBundleOptions = bundle?.bundleOptionIds?.filter(
          (bundleBundleOptionId) => bundleBundleOptionId !== bundleOptionId,
        );
        const updatedBundle = {
          ...bundle,
          bundleOptionIds: newBundleOptions,
          _altered: true,
        };
        return updatedBundle;
      });

      const newStoredOptions = state.storedOptions.filter(
        (storedOption) => storedOption !== bundleOptionId,
      );

      return {
        ...state,
        localData: updatedBundles,
        storedOptions: newStoredOptions,
      };
    }

    /*
     * BACKEND SAVES
     */

    case type.BACKEND_SAVE_BUNDLE_SUCCESS: {
      const { savedItem, itemObjectId } = action.payload;

      const newBundles = state.localData.map((item) => {
        if (item.objectId === itemObjectId) {
          return savedItem;
        }

        return item;
      });

      return {
        ...state,
        data: newBundles,
        localData: newBundles,
      };
    }

    case type.BACKEND_SAVE_BUNDLES_SUCCESS: {
      const newBundles = state.localData.map(({ _altered, _created, _objectId, ...rest }) => rest);

      return {
        ...state,
        data: newBundles,
        localData: newBundles,
        storedOptions: [],
        deleted: [],
      };
    }

    default: {
      return state;
    }
  }
}
