import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const Breadcrumbs = ({ breadcrumbs, style, ...props }) => {
  if (!breadcrumbs?.data || breadcrumbs?.data?.length === 0) {
    return <></>;
  }

  return (
    <Box
      style={{
        position: 'fixed',
        height: '80px',
        top: 0,
        left: 0,
        backgroundColor: '#ffffff',
        width: '100%',
        padding: '20px 45px',
        zIndex: 20000,
      }}
    >
      <Box display='flex' gridColumnGap='10px'>
        {breadcrumbs?.data?.map((crumb, index) => {
          const isLast = index === breadcrumbs?.data.length - 1;
          return (
            <Box
              display='flex'
              gridColumnGap='10px'
              style={{
                lineHeight: '40px',
                fontSize: '18px',
                fontWeight: 'bold',
                color: isLast ? '#000000' : '#D0D0D0',
              }}
            >
              <Box>{index === 0 ? '' : ' / '}</Box>
              <Box>{crumb}</Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

Breadcrumbs.defaultProps = {
  breadcrumbs: {},
};

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.shape({}),
};

const mapStateToProps = (state) => ({ breadcrumbs: state.breadcrumbs });

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumbs);
