import * as type from './types';
import { menuItemOptionsForMenuItem } from '../graphql/queries';
import { devLog } from '../helpers/devLog';
import { makeApiCall } from './actions';

export const fetchImportOptions = (currentItemId, importItemId) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'IMPORT_OPTIONS',
  });
  dispatch({
    type: type.FETCH_IMPORT_OPTIONS_PENDING,
  });

  (async () => {
    try {
      const response = await makeApiCall(menuItemOptionsForMenuItem, {
        menuItemId: importItemId,
        pageSize: 500,
        offset: 0,
      });

      devLog(
        'success',
        'menu item options for menu item',
        response.data.menuItemOptionsForMenuItem,
      );

      dispatch({
        type: type.FETCH_IMPORT_OPTIONS_SUCCESS,
        payload: {
          importedOptions: response.data.menuItemOptionsForMenuItem,
          currentItemId,
        },
      });
    } catch (error) {
      devLog('error', 'menu item options for menu item', error);

      dispatch({
        type: type.SET_TOAST,
        payload: {
          id: `IMPORT_OPTIONS_${new Date().getTime()}`,
          message: `Unable to import options: ${error}`,
          type: 'error',
        },
      });

      dispatch({
        type: type.FETCH_IMPORT_OPTIONS_FAILURE,
        payload: `Unable to retrieve menu item options: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'IMPORT_OPTIONS',
      });
    }
  })();
};

export const fetchImportOptionsBeforeCreated = (currentItemId, importItemId) => (dispatch) => {
  dispatch({
    type: type.FETCH_IMPORT_OPTIONS_BEFORE_CREATED,
    payload: { currentItemId, importItemId },
  });
};
