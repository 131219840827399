import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  columnTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  dialogTitle: {
    padding: 0,
  },
  dialogContent: {
    // padding: "40px",
    padding: '0 !important',
    overflow: 'hidden',
    overflowY: 'scroll',
  },
  modalTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  dialog: {
    marginTop: '60px',
  },
  leftColumn: {
    padding: '40px !important',
  },
  rightColumn: {
    padding: '114px 40px 40px 40px !important',
    backgroundColor: '#F8F8F8',
  },
  modalDescription: {
    fontSize: '18px',
    fontWeight: 'bold',
    paddingBottom: '20px',
  },
  row: {
    '&:hover': {
      backgroundColor: '#f1f2f3',
    },
    fontSize: '18px',
    cursor: 'pointer',
  },
}));

export default useStyles;
