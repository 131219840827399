/* eslint-disable no-prototype-builtins */
import { updateItemInArray } from '../helpers/ArrayUtils';
import * as type from '../actions/types';
import { parseJson } from '../helpers/Helpers';

const initialState = {
  data: [],
  stored: {},
  initialStored: {},
  storedChoices: [],
  initialChoicesStored: '',
  deleted: {},
  initialDeleted: {},
  fetching: false,
  error: false,
  errorMessage: '',
};

/*
 * Re-ordering Helpers
 */

const prevItemIndex = (array, index, key) => array[index - 1][key];

const nextItemIndex = (array, index, key) => array[index + 1][key];

const getFirstItemIndex = (array, index, key) => nextItemIndex(array, index, key) / 2;

const getLastItemIndex = (array, index, key) => prevItemIndex(array, index, key) + 1;

const getMiddleItemIndex = (array, index, key) =>
  (prevItemIndex(array, index, key) + nextItemIndex(array, index, key)) / 2;

const getItemIndex = (array, index, key) => {
  // FIRST item
  if (index === 0) {
    const newIndex = getFirstItemIndex(array, index, key);
    return newIndex;

    // LAST item
  }
  if (index === array.length - 1) {
    const newIndex = getLastItemIndex(array, index, key);
    return newIndex;

    // MIDDLE item
  }
  const newIndex = getMiddleItemIndex(array, index, key);
  return newIndex;
};

const moveItemInArray = (array, source, destination, key, updateItemCallback) => {
  const newArray = Array.from(array);

  // remove item to move
  const moveItem = newArray.splice(source, 1)[0];

  // move to new index
  newArray.splice(destination, 0, moveItem);

  // update move item index with callback
  const newMoveItem = updateItemCallback(moveItem, newArray, key);

  // replace moved item with the new one
  newArray.splice(destination, 1);
  newArray.splice(destination, 0, newMoveItem);

  return newArray;
};

const parseChoicesWithIndexes = (stringifiedChoices) => {
  let choices = parseJson(parseJson(stringifiedChoices));

  // NOTE: choice index is a new field, some options may have it, some may not.
  // We define / rewrite the indexes incase they don't exist.
  const needsInitialIndex = choices.some((el) => !el.hasOwnProperty('index'));

  if (needsInitialIndex) {
    choices = choices.map((choice, index) => ({
      ...choice,
      index: index + 1,
    }));
  }

  const sortedChoices = choices.slice().sort((a, b) => a.index - b.index);

  return sortedChoices;
};

export default (state = initialState, action) => {
  switch (action.type) {
    /*
     * FETCH
     */

    case type.FETCH_MENU_ITEM_OPTIONS_PENDING: {
      return {
        ...state,
        data: [],
        fetching: true,
        error: false,
        errorMessage: false,
      };
    }

    case type.FETCH_MENU_ITEM_OPTIONS_SUCCESS: {
      const { menuItemId, data } = action.payload;

      const options = data.map((option) => ({
        ...option,
        choices: parseChoicesWithIndexes(option.choices),
      }));

      return {
        ...state,
        data,
        stored: {
          ...state.stored,
          [menuItemId]: options,
        },
        fetching: false,
        error: false,
        errorMessage: '',
      };
    }

    case type.FETCH_MENU_ITEM_OPTIONS_FAILURE: {
      return {
        ...state,
        fetching: false,
        error: true,
        errorMessage: action.payload,
      };
    }

    case type.CREATE_MENU_ITEM_OPTION: {
      const {
        choices,
        desc,
        lastOptionIndex,
        mandatory,
        maxSelect,
        menuItemId,
        optionId,
        minSelect,
        name,
      } = action.payload;

      const newItem = {
        choices,
        enabled: true,
        mandatory,
        maxSelections: maxSelect,
        menuItemId,
        minSelections: minSelect,
        objectId: optionId,
        optionDescription: desc,
        optionIndex: lastOptionIndex + 1,
        optionTitle: name,
        _created: true,
      };

      let newItems = [];

      // Menu Item was recently created and has no Options
      if (state.stored[menuItemId] === undefined) {
        newItems = [newItem];
      } else {
        newItems = [...state.stored[menuItemId], newItem];
      }

      return {
        ...state,
        stored: {
          ...state.stored,
          [menuItemId]: newItems,
        },
      };
    }

    case type.UPDATE_MENU_ITEM_OPTION: {
      const { choices, desc, mandatory, maxSelect, menuItemId, minSelect, name, optionId } =
        action.payload;

      const newItems = updateItemInArray(
        state.stored[menuItemId],
        'objectId',
        optionId,
        (menuItem) => ({
          ...menuItem,
          choices,
          mandatory,
          maxSelections: maxSelect,
          minSelections: minSelect,
          optionDescription: desc,
          optionTitle: name,
          _altered: true,
        }),
      );

      return {
        ...state,
        stored: {
          ...state.stored,
          [menuItemId]: newItems,
        },
      };
    }

    case type.DELETE_MENU_ITEM_OPTION: {
      const { menuItemId, optionId } = action.payload;

      const deletedItem = state.stored[menuItemId].find((item) => item.objectId === optionId);
      const newDeletedItem = {
        ...deletedItem,
        enabled: false,
        _deleted: true,
      };

      const newStored = state.stored[menuItemId].filter((item) => item.objectId !== optionId);

      return {
        ...state,
        stored: {
          ...state.stored,
          [menuItemId]: newStored,
        },
        deleted: {
          ...state.deleted,
          [optionId]: newDeletedItem,
        },
      };
    }

    case type.REORDER_MENU_ITEM_OPTIONS: {
      const { menuItemId, source, destination } = action.payload;

      const array = state.stored[menuItemId];
      if (!array) {
        return state;
      }

      const newArray = moveItemInArray(
        array,
        source,
        destination,
        'optionIndex',
        // eslint-disable-next-line no-shadow
        (item, newArray, key) => {
          // Calculate new index
          const newIndex = getItemIndex(newArray, destination, key);
          return {
            ...item,
            [key]: newIndex,
            _altered: true,
          };
        },
      );

      return {
        ...state,
        stored: {
          ...state.stored,
          [menuItemId]: newArray,
        },
      };
    }

    case type.SAVE_INITIAL_MENU_ITEM_OPTIONS: {
      return {
        ...state,
        initialStored: {
          ...state.stored,
        },
        initialDeleted: {
          ...state.deleted,
        },
      };
    }

    case type.CLEAR_INITIAL_MENU_ITEM_OPTIONS: {
      return {
        ...state,
        initialStored: {},
        initialDeleted: {},
      };
    }

    case type.CANCEL_MENU_ITEM_OPTIONS: {
      return {
        ...state,
        stored: {
          ...state.initialStored,
        },
        deleted: {
          ...state.initialDeleted,
        },
      };
    }

    // Delete new or altered menu options when parent menu item is deleted.
    case type.DELETE_MENU_ITEM: {
      const { menuItemId } = action.payload;

      let newStoredOptions = state.stored[menuItemId];

      if (!newStoredOptions) {
        return state;
      }

      if (newStoredOptions.length > 0) {
        newStoredOptions = newStoredOptions.filter(
          (option) => !option._created && !option._altered,
        );
      }

      return {
        ...state,
        stored: {
          ...state.stored,
          [menuItemId]: newStoredOptions,
        },
        // deleted: newDeletedItems,
      };
    }

    /*
     * MENU ITEM OPTION CHOCIES
     */

    case type.CREATE_MENU_ITEM_OPTION_CHOICE: {
      const { title, price } = action.payload;

      const choices = Array.from(state.storedChoices);

      // NOTE: index is last choice incremented by 1, otherwise 1 if none.
      const newIndex = choices.length > 0 ? choices[choices.length - 1].index + 1 : 1;

      // Create
      const newChoice = {
        title,
        ...(price && { price }),
        index: newIndex,
      };
      choices.splice(choices.length, 0, newChoice);

      // Return as string
      return {
        ...state,
        storedChoices: choices,
      };
    }

    case type.UPDATE_MENU_ITEM_OPTION_CHOICE: {
      const { index, title, price } = action.payload;

      const choices = Array.from(state.storedChoices);

      // Update
      let dragChoice = choices.splice(index, 1);

      if (!price && dragChoice[0].hasOwnProperty('price')) {
        // NOTE: Remove price property from object
        // eslint-disable-next-line no-shadow
        dragChoice = dragChoice.map(({ price, ...rest }) => rest);
      }

      const newChoice = {
        ...dragChoice[0],
        title,
        ...(price && { price }),
      };
      choices.splice(index, 0, newChoice);

      // Return as string
      return {
        ...state,
        storedChoices: choices,
      };
    }

    case type.DELETE_MENU_ITEM_OPTION_CHOICE: {
      const { index } = action.payload;

      const choices = Array.from(state.storedChoices);

      // Remove
      choices.splice(index, 1);

      // Return as string
      return {
        ...state,
        storedChoices: choices,
      };
    }

    case type.DISABLE_MENU_ITEM_OPTION_CHOICE: {
      const { index, disableDate } = action.payload;

      const choices = Array.from(state.storedChoices);

      // Update
      const dragChoice = choices.splice(index, 1);
      const newChoice = {
        ...dragChoice[0],
        disableDate,
      };
      choices.splice(index, 0, newChoice);

      // Return as string
      return {
        ...state,
        storedChoices: choices,
      };
    }

    case type.REORDER_MENU_ITEM_OPTION_CHOICES: {
      const { source, destination } = action.payload;

      // todo: do index check here
      // ...
      // so yeah, everytime we save an options, all indexes will be saved, so that's good! no issue!

      // Reorder
      const updatedChoices = moveItemInArray(
        state.storedChoices,
        source,
        destination,
        'index',
        (item, newArray, key) => {
          // Calculate new index
          const newIndex = getItemIndex(newArray, destination, key);
          return {
            ...item,
            [key]: newIndex,
          };
        },
      );

      // Return as string
      return {
        ...state,
        storedChoices: updatedChoices,
      };
    }

    case type.SAVE_INITIAL_MENU_ITEM_OPTION_CHOICES: {
      const { menuItemId, optionId } = action.payload;

      // Current Option
      const storedOptions = state.stored[menuItemId];

      let choices = state.storedChoices;

      if (storedOptions) {
        const optionIndex = storedOptions.findIndex((option) => option.objectId === optionId);
        if (optionIndex > -1) {
          choices = storedOptions[optionIndex].choices;
        }
      }

      return {
        ...state,
        initialChoicesStored: choices,
        storedChoices: choices,
      };
    }

    case type.CLEAR_INITIAL_MENU_ITEM_OPTION_CHOICES: {
      return {
        ...state,
        initialChoicesStored: '',
        storedChoices: [],
      };
    }

    case type.CANCEL_MENU_ITEM_OPTION_CHOICES: {
      const { menuItemId, optionId } = action.payload;

      // Current Option
      const storedOptions = state.stored[menuItemId];

      if (!storedOptions) {
        return state;
      }

      const newItems = state.stored[menuItemId].map((option) => {
        if (option.objectId !== optionId) {
          return option;
        }

        // Revert choices to initial
        return {
          ...option,
          choices: state.initialChoicesStored,
        };
      });

      return {
        ...state,
        stored: {
          ...state.stored,
          [menuItemId]: newItems,
        },
      };
    }

    /*
     * IMPORT OPTIONS
     */

    case type.FETCH_IMPORT_OPTIONS_SUCCESS: {
      const { importedOptions, currentItemId } = action.payload;

      let newOptions = importedOptions.map((option, index) => {
        const choices = parseChoicesWithIndexes(option.choices);
        const objectId = `${currentItemId.substring(0, 10)}-${index}-${new Date().getTime()}`;

        return {
          ...option,
          menuItemId: currentItemId,
          objectId,
          choices,
          _created: true,
        };
      });

      if (state.stored[currentItemId] !== undefined) {
        newOptions = [...state.stored[currentItemId], ...newOptions];
      }

      return {
        ...state,
        stored: {
          ...state.stored,
          [currentItemId]: newOptions,
        },
      };
    }

    case type.FETCH_IMPORT_OPTIONS_BEFORE_CREATED: {
      const { currentItemId, importItemId } = action.payload;

      // Fetch options from local state as menu item hasn't been created yet
      let newOptions = state.stored[importItemId];

      // Cancel if no new options exist
      if (!newOptions) {
        return state;
      }

      newOptions = newOptions.map((option, index) => {
        // NOTE: don't need to parse choices as they are already parsed

        const objectId = `${currentItemId.substring(0, 10)}-${index}-${new Date().getTime()}`;
        return {
          ...option,
          menuItemId: currentItemId,
          objectId,
          _created: true,
        };
      });

      if (state.stored[currentItemId] !== undefined) {
        newOptions = [...state.stored[currentItemId], ...newOptions];
      }

      return {
        ...state,
        stored: {
          ...state.stored,
          [currentItemId]: newOptions,
        },
      };
    }

    /*
     * BACKEND SAVES
     */

    case type.BACKEND_SAVE_MENU_ITEM_OPTION_SUCCESS: {
      // Update the local option, with saved option from backend
      // this also clears any meta props (_created/_deleted etc)

      const { savedOption, optionObjectId, itemId } = action.payload;

      // actually, if we parse it back, then when we "open" it again, it might try to parse it again?? check
      // NOTE: backend gives choices as string, so we parse it back into array
      const parsedChoices = parseJson(parseJson(savedOption.choices));
      const updatedOption = {
        ...savedOption,
        choices: parsedChoices,
      };

      // TODO: there seems to be a duplicate option with the same object id.... hmm

      const newItems = updateItemInArray(
        state.stored[itemId],
        'objectId',
        optionObjectId,
        () => updatedOption,
      );

      return {
        ...state,
        stored: {
          ...state.stored,
          [itemId]: newItems,
        },
      };
    }

    default: {
      return state;
    }
  }
};
