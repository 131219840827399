import { API } from 'aws-amplify';
import { getAppVersion } from '../helpers/Helpers';
import store from '../store';

export const makeApiCall = (apiQuery, apiVariables) => {
  const state = store.getState();

  const userId = state?.auth?.data?.id;
  const userEmail = state?.auth?.data?.email;
  const restaurantId = state?.activeRestaurant?.data?.objectId;

  return API.graphql(
    {
      query: apiQuery,
      variables: apiVariables,
      // authMode: 'AMAZON_COGNITO_USER_POOLS',
    },
    {
      'user-id': userId,
      'user-email': userEmail,
      'rest-id': restaurantId,
      'app-version': getAppVersion(),
    },
  ).catch((error) => {
    throw new Error(error.errors[0].message);
  });
};
