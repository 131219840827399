import {
  deleteItemsInArray,
  getItemInArray,
  getNewIndex,
  insertItemInArray,
  insertItemInArrayAtIndex,
  updateItemInArray,
} from '../helpers/ArrayUtils';
import * as type from '../actions/types';

const initialState = {
  data: [],
  localData: [],
  deleted: [],
  fetching: false,
  error: false,
  errorMessage: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case type.FETCH_MENU_ITEMS_PENDING: {
      return {
        ...state,
        data: [],
        fetching: true,
        error: false,
        errorMessage: false,
      };
    }

    case type.FETCH_MENU_ITEMS_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        localData: action.payload,
        fetching: false,
        error: false,
        errorMessage: '',
      };
    }

    case type.FETCH_MENU_ITEMS_FAILURE: {
      return {
        ...state,
        data: [],
        localData: [],
        deleted: [],
        fetching: false,
        error: true,
        errorMessage: action.payload,
      };
    }

    /*
     * CATEGORIES
     */

    case type.CREATE_MENU_ITEM_CATEGORY: {
      const { newCategory, menuId } = action.payload;

      const newCategoryIndex = state.localData.findIndex(
        (menuItem) => menuItem.category === newCategory,
      );

      if (newCategoryIndex > -1) {
        return state;
      }

      const lastItem = state.localData.slice(-1)[0];
      const newIndex = lastItem === undefined ? 1 : lastItem.itemIndex + 1;

      // create empty menu item with new category
      return {
        ...state,
        localData: [
          ...state.localData,
          {
            category: action.payload.newCategory,
            itemIndex: newIndex,
            menuId,
            enabled: true,
            _placeholder: true,
          },
        ],
      };
    }

    case type.UPDATE_MENU_ITEM_CATEGORY: {
      const newMenuItems = updateItemInArray(
        state.localData,
        'category',
        action.payload.category,
        (menuItem) => ({
          ...menuItem,
          category: action.payload.newCategory,
          _altered: true,
        }),
      );
      return { ...state, localData: newMenuItems };
    }

    case type.DELETE_MENU_ITEM_CATEGORY: {
      // exclude placeholder or newly created menu items
      const itemsInCategory = state.localData.filter(
        (item) => item.category === action.payload.category && !item._placeholder && !item._created,
      );
      const deleteItemsInCategory = itemsInCategory.map((item) => ({
        ...item,
        enabled: false,
        _deleted: true,
      }));

      const newMenuItems = deleteItemsInArray(state.localData, 'category', action.payload.category);

      const newDeletedItems = [...state.deleted, ...deleteItemsInCategory];

      return {
        ...state,
        localData: newMenuItems,
        deleted: newDeletedItems,
      };
    }

    /*
     * MENU ITEMS
     */

    case type.CREATE_MENU_ITEM: {
      const {
        category,
        desc,
        index, // lastItemInCategoryIndex
        menuId,
        name,
        price,
        menuItemId,
        imageLink,
        image,
        noDiscount,
      } = action.payload;

      const stateIndex = state.localData.findIndex((menuItem) => menuItem.itemIndex === index);
      const newIndex = getNewIndex(state.localData, stateIndex);

      const newMenuItem = {
        category,
        disableDate: null,
        enabled: true,
        imageLink,
        itemDescription: desc,
        itemIndex: newIndex,
        itemTitle: name,
        menuId,
        objectId: menuItemId,
        price,
        noDiscount,
        ...(Object?.keys(image).length > 0 && { _image: image }),
        _created: true,
      };

      let newMenuItems = insertItemInArray(state.localData, 'itemIndex', index, newMenuItem);

      // Remove category placeholder if exists
      const categoryPlaceholderIndex = newMenuItems.findIndex(
        (menuItem) => menuItem.category === newMenuItem.category && menuItem._placeholder,
      );
      if (categoryPlaceholderIndex > -1) {
        newMenuItems = newMenuItems.filter((menuItem, index) => index !== categoryPlaceholderIndex);
      }

      return { ...state, localData: newMenuItems };
    }

    case type.UPDATE_MENU_ITEM: {
      const { desc, name, price, imageLink, image, noDiscount, disableDate, disableUntilDate } =
        action.payload;

      const newMenuItems = updateItemInArray(
        state.localData,
        'objectId',
        action.payload.objectId,
        (menuItem) => ({
          ...menuItem,
          itemDescription: desc,
          itemTitle: name,
          price,
          imageLink,
          noDiscount,
          disableDate,
          disableUntilDate,
          _image: image,
          _altered: true,
        }),
      );

      return { ...state, localData: newMenuItems };
    }

    case type.DELETE_MENU_ITEM: {
      const deleted = getItemInArray(state.localData, 'objectId', action.payload.menuItemId);

      const newDeleted = {
        ...deleted,
        enabled: false,
        _deleted: true,
      };

      const deletedItemIndex = state.localData.findIndex(
        (item) => item.objectId === action.payload.menuItemId,
      );

      let newMenuItems = deleteItemsInArray(state.localData, 'objectId', action.payload.menuItemId);

      const newDeletedItems = insertItemInArrayAtIndex(state.deleted, 0, newDeleted);

      const categoryItems = newMenuItems.filter(
        (item) => item.category === action.payload.category && item.enabled === true,
      );

      // Create a placeholder if this is the last item
      if (categoryItems.length === 0) {
        const newMenuItem = {
          category: newDeleted.category,
          itemIndex: newDeleted.itemIndex,
          menuId: newDeleted.menuId,
          enabled: true,
          _placeholder: true,
        };

        newMenuItems = insertItemInArrayAtIndex(newMenuItems, deletedItemIndex, newMenuItem);
      }

      return {
        ...state,
        localData: newMenuItems,
        deleted: newDeletedItems,
      };
    }

    case type.CLEAR_MENU_ITEMS: {
      return initialState;
    }

    // NOTE: reset menu items when creating new menu
    case type.CREATE_MENU:
    case type.RESET_MENU_ITEMS: {
      return {
        ...state,
        data: [],
        localData: [],
        deleted: [],
        fetching: false,
        error: false,
        errorMessage: '',
      };
    }

    case type.DISABLE_MENU_ITEM: {
      const newMenuItems = updateItemInArray(
        state.localData,
        'objectId',
        action.payload.menuItemId,
        (menuItem) => ({
          ...menuItem,
          disableDate: action.payload.disableDate,
          _altered: true,
        }),
      );

      return {
        ...state,
        localData: newMenuItems,
      };
    }

    case type.DISABLE_MENU_ITEM_UNTIL_DATE: {
      const newMenuItems = updateItemInArray(
        state.localData,
        'objectId',
        action.payload.menuItemId,
        (menuItem) => ({
          ...menuItem,
          disableUntilDate: action.payload.disableUntilDate,
          _altered: true,
        }),
      );

      return {
        ...state,
        localData: newMenuItems,
      };
    }

    case type.SAVE_MENU_ITEMS_LOCAL: {
      return { ...state, localData: [...action.payload] };
    }

    /*
     * BACKEND SAVES
     */

    case type.BACKEND_SAVE_MENU_ITEM_SUCCESS: {
      const { savedItem, itemObjectId } = action.payload;

      const newMenuItems = state.localData.map((item) => {
        if (item.objectId === itemObjectId) {
          return savedItem;
        }

        return item;
      });

      return {
        ...state,
        data: newMenuItems,
        localData: newMenuItems,
      };
    }

    case type.BACKEND_SAVE_MENU_ITEMS_SUCCESS: {
      const newMenuItems = state.localData.map(
        ({ _altered, _created, _objectId, ...rest }) => rest,
      );

      return {
        ...state,
        data: newMenuItems,
        localData: newMenuItems,
        deleted: [],
      };
    }

    default: {
      return state;
    }
  }
}
