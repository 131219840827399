import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  InputAdornment,
  Box,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addBreadcrumbAction, clearBreadcrumbsAction } from '../../../../actions/breadcrumbsAction';
import {
  addBundleOptionsToBundleAction,
  createBundleAction,
  updateBundleAction,
  clearBundleOptionsAction,
  restockBundleAction,
  disableBundleForDateAction,
  disableBundleUntilDateAction,
} from '../../../../actions/bundlesAction';
import { addBundleToCategoryAction } from '../../../../actions/categoriesAction';
import { startsWith } from '../../../../helpers/Helpers';
import MenuItemImageUpload from '../menuItemImageUpload/MenuItemImageUpload';
import ReviewButton from '../ReviewButton/ReviewButton';
import useStyles from './BundleCreateStyles';
import BundleOptions from './BundleOptions';
import { dialogContexts } from '../../../../constants/constants';
import InfoPopover from '../../../InfoPopover/InfoPopover';

const BundleEditDialog = ({
  clearBundleOptionsAction,
  addBundleOptionsToBundleAction,
  addBundleToCategoryAction,
  createBundleAction,
  updateBundleAction,
  restaurantId,
  openDialog,
  setOpenDialog,
  dialogContext,
  addBreadcrumbAction,
  clearBreadcrumbsAction,
  bundle,
  bundles,
  category,
  bundleOptions,
  menuVersion,
  restockBundleAction,
  disableBundleForDateAction,
  disableBundleUntilDateAction,
}) => {
  const classes = useStyles();

  const closeDialog = () => {
    clearBreadcrumbsAction();

    setOpenDialog(null);
  };

  const [posId, setPosId] = useState(bundle?.posId || null);
  const [posIdError, setPosIdError] = useState('');

  const [bundleTitle, setBundleTitle] = useState(bundle?.bundleTitle || '');
  const [nameError, setNameError] = useState('');

  const [price, setPrice] = useState(bundle?.price || 0);
  const [priceError, setPriceError] = useState('');

  const [bundleDescription, setBundleDescription] = useState(bundle?.bundleDescription || '');
  const [descError, setDescError] = useState('');

  const [bundleOptionError, setBundleOptionError] = useState('');

  const [imageLink, setImageLink] = useState(bundle?.imageLink || null);
  const [image, setImage] = useState(bundle?._image || {});

  const [disableDate, setDisableDate] = useState(bundle?.disableDate || null);

  const [importStatus, setImportStatus] = useState(bundle?.importStatus || null);
  const [reviewError, setReviewError] = useState('');

  const [isDisabled, setIsDisabled] = useState(
    moment(bundle?.disableDate, 'yyyy-MM-DD').isSame(moment(), 'day') ||
      moment(bundle?.disableUntilDate, 'yyyy-MM-DD').isAfter(moment(), 'day'),
  );

  const [disableUntilDate, setDisableUntilDate] = useState(bundle?.disableUntilDate || null);

  const [newItemId, setNewItemId] = useState(`bundle_${new Date().getTime()}`);

  const [noDiscount, setNoDiscount] = useState(bundle?.noDiscount || false);
  const [showNoDiscountConfirmation, setShowNoDiscountConfirmation] = useState(false);

  const [free, setFree] = useState(bundle?.free || false);
  const [showFreeConfirmation, setShowFreeConfirmation] = useState(false);

  // Show breadcrumbs
  useEffect(() => {
    if (openDialog) {
      addBreadcrumbAction(`${dialogContext} bundle`);
    }
  }, [openDialog]);

  // Reset disable fields when reopened
  useEffect(() => {
    if (openDialog) {
      setDisableDate(bundle?.disableDate || null);
      setDisableUntilDate(bundle?.disableUntilDate || null);
      setIsDisabled(!!bundle?.disableDate || !!bundle?.disableUntilDate || false);
    }
  }, [openDialog]);

  // Set or unset disable date if the box is checked
  useEffect(() => {
    if (!isDisabled || disableUntilDate) {
      setDisableDate(null);
    } else {
      // if disable option is enabled and no disable date given, set it for today only
      setDisableDate(moment().format('yyyy-MM-DD'));
    }
  }, [isDisabled, disableUntilDate]);

  const getUnreviewedOptions = () =>
    bundle?.bundleOptionIds
      ?.map((bundleOptionId) =>
        bundleOptions?.localData?.find((bundleOption) => bundleOption?.objectId === bundleOptionId),
      )
      ?.filter((bundleOption) => bundleOption?.importStatus === 'needsReview');

  useEffect(() => {
    setReviewError('');
  }, [bundleOptions]);

  /**
   * Validate and set the bundle as reviewed
   */
  const setBundleReviewed = (newStatus) => {
    if (newStatus === 'reviewed') {
      // Check all options are valid
      const unreviewedOptions = getUnreviewedOptions();

      if (unreviewedOptions?.length > 0) {
        setReviewError(
          'You cannot set the bundle as reviewed until all of its bundle options have been reviewed.',
        );
        return;
      }
    }

    setImportStatus(newStatus);
    setReviewError('');
  };

  const formatNumber = (num) => (Math.round(num * 100) / 100).toFixed(2);

  const submit = (event) => {
    event.preventDefault();

    let formError = false;

    if (!bundleTitle) {
      setNameError('Please enter a name.');
      formError = true;
    } else if (nameError) {
      setNameError('');
    }

    if (!price || price === '0.00') {
      setPriceError('Please enter a price.');
      formError = true;
    } else if (priceError) {
      setPriceError('');
    }

    if (!bundles?.storedOptions.length) {
      setBundleOptionError('Please add at least one bundle option');
      formError = true;
    } else if (bundleOptionError) {
      setBundleOptionError('');
    }

    if (formError) {
      return;
    }
    if (dialogContext === dialogContexts.CREATE) {
      createBundleAction(
        newItemId,
        bundleTitle,
        bundleDescription,
        Number(price),
        imageLink,
        image,
        noDiscount,
        disableDate,
        disableUntilDate,
        restaurantId,
        posId,
      );
    } else {
      updateBundleAction(
        bundle.objectId,
        bundleTitle,
        bundleDescription,
        Number(price),
        imageLink,
        image,
        noDiscount,
        disableDate,
        disableUntilDate,
        restaurantId,
        posId,
        importStatus,
      );

      if (!startsWith(bundle.objectId, 'bundle_')) {
        if (!isDisabled) {
          if (bundle.disableDate !== null || bundle.disableUntilDate !== null) {
            restockBundleAction(bundle.objectId);
          }
        } else {
          if (disableDate !== bundle.disableDate && disableDate !== null) {
            disableBundleForDateAction(bundle.objectId, disableDate);
          }
          if (disableUntilDate !== bundle.disableUntilDate && disableUntilDate !== null) {
            disableBundleUntilDateAction(bundle.objectId, disableUntilDate);
          }
        }
      }
    }

    // If category was provided, ensure we add this product to the category too
    if (category) {
      addBundleToCategoryAction(newItemId, category.objectId);
    }

    closeDialog();
  };

  const objectId = bundles?.data?.find(
    (apiBundle) => apiBundle?.objectId === bundle?.objectId,
  )?.objectId;

  return (
    <Dialog
      open={openDialog}
      onClose={closeDialog}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth
      className={classes.dialog}
      maxWidth='lg'
      onEnter={() => {
        setNewItemId(`bundle_${new Date().getTime()}`);

        // Update local storage's options to match this item
        addBundleOptionsToBundleAction(bundle?.bundleOptionIds || []);

        // Reset inputs when entering
        setBundleTitle(bundle?.bundleTitle || '');
        setPrice(bundle?.price || 0);
        setBundleDescription(bundle?.bundleDescription || '');
        setImageLink(bundle?.imageLink || null);
        setImage(bundle?._image || {});
        setNoDiscount(bundle?.noDiscount || false);
        setFree(bundle?.free || false);
        setDisableDate(bundle?.disableDate || null);
        setDisableUntilDate(bundle?.disableUntilDate || null);
        setPosId(bundle?.posId || null);
        setImportStatus(bundle?.importStatus || null);

        setNameError('');
        setPosIdError('');
        setPriceError('');
        setDescError('');
        setReviewError('');
        setBundleOptionError('');
      }}
      onExited={() => {
        clearBundleOptionsAction();
      }}
    >
      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12} className={classes.leftColumn}>
            <Box display='flex' justifyContent='space-between'>
              <Box className={classes.modalTitle}>{dialogContext} Bundle</Box>
              {menuVersion.posEnabled && (
                <ReviewButton importStatus={importStatus} setStatus={setBundleReviewed} />
              )}
            </Box>
            {reviewError && <Box style={{ color: 'red', paddingTop: '20px' }}>{reviewError}</Box>}
            <form onSubmit={submit} id='create-menu-item-form' style={{ paddingTop: '40px' }}>
              <Box className={classes.columnTitle}>Item details</Box>
              <TextField
                autoFocus
                id='menu-item-name'
                label='Name'
                placeholder='eg. Value Bundle'
                variant='outlined'
                fullWidth
                value={bundleTitle}
                onChange={(e) => setBundleTitle(e.target.value)}
                onBlur={(e) => setBundleTitle(e.target.value.trim())}
                InputLabelProps={{ shrink: true }}
                margin='normal'
                required
                error={Boolean(nameError)}
                helperText={nameError}
              />

              <TextField
                id='menu-item-price'
                label='Price'
                placeholder='10.00'
                variant='outlined'
                fullWidth
                value={price}
                type='number'
                onChange={(e) => setPrice(e.target.value)}
                onBlur={(e) => setPrice(formatNumber(e.target.value))}
                InputLabelProps={{ shrink: true }}
                min={0}
                // inputProps={{ min: 0, step: 1 }}
                InputProps={{
                  startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                }}
                margin='normal'
                required
                error={Boolean(priceError)}
                helperText={priceError}
              />

              <TextField
                multiline
                id='menu-item-desc'
                label='Description'
                placeholder='eg. 1 Small size Pizza, Garlic Bread and Soft Drink.'
                variant='outlined'
                fullWidth
                value={bundleDescription}
                onChange={(e) => setBundleDescription(e.target.value)}
                onBlur={(e) => setBundleDescription(e.target.value.trim())}
                InputLabelProps={{ shrink: true }}
                margin='normal'
                error={Boolean(descError)}
                helperText={descError}
              />

              <MenuItemImageUpload
                itemId={newItemId}
                imageLink={imageLink}
                setImageLink={setImageLink}
                setImage={setImage}
              />

              {menuVersion.posEnabled && (
                <TextField
                  autoFocus
                  id='menu-item-pos-id'
                  label='POS ID'
                  variant='outlined'
                  fullWidth
                  disabled
                  value={posId}
                  onChange={(e) => setPosId(e.target.value)}
                  onBlur={(e) => setPosId(e.target.value.trim())}
                  InputLabelProps={{ shrink: true }}
                  margin='normal'
                  error={Boolean(posIdError)}
                  helperText={posIdError}
                  InputProps={{ style: { backgroundColor: '#EFEFEF' } }}
                />
              )}

              <Dialog open={showNoDiscountConfirmation}>
                <Box style={{ padding: '20px' }}>
                  <Box mb='20px' style={{ padding: '15px', fontSize: '16px' }}>
                    Are you sure you want to make this item exempt from discounts? (typically only
                    used for special promotions)
                  </Box>
                  <Button
                    color='primary'
                    onClick={() => {
                      setShowNoDiscountConfirmation(false);
                      setNoDiscount(true);
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    onClick={() => {
                      setShowNoDiscountConfirmation(false);
                    }}
                  >
                    No
                  </Button>
                </Box>
              </Dialog>

              <Dialog open={showFreeConfirmation}>
                <Box style={{ padding: '20px' }}>
                  <Box mb='20px' style={{ padding: '15px', fontSize: '16px' }}>
                    {' '}
                    Are you sure you want to make this item free?
                  </Box>
                  <Button
                    color='primary'
                    onClick={() => {
                      setShowFreeConfirmation(false);
                      setFree(true);
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    onClick={() => {
                      setShowFreeConfirmation(false);
                    }}
                  >
                    No
                  </Button>
                </Box>
              </Dialog>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={noDiscount}
                    onChange={(event) =>
                      event.target.checked === true
                        ? setShowNoDiscountConfirmation(true)
                        : setNoDiscount(false)
                    }
                    name='noDiscount'
                    color='primary'
                  />
                }
                label='Exempt from discounts'
              />

              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isDisabled}
                      onChange={(event) => {
                        if (event.target.checked) {
                          setIsDisabled(true);
                        } else {
                          setIsDisabled(false);
                          setDisableUntilDate(null);
                          setDisableDate(null);
                        }
                      }}
                      name='showDisableOption'
                      color='primary'
                    />
                  }
                  label='Disable item'
                />
              </Box>

              {isDisabled && (
                <TextField
                  id='menu-item-disable-until-date'
                  label='Disable until'
                  type='date'
                  value={disableUntilDate}
                  onChange={(e) => setDisableUntilDate(e.target.value)}
                  onBlur={(e) => setDisableUntilDate(e.target.value.trim())}
                  InputLabelProps={{ shrink: true }}
                  variant='outlined'
                  margin='normal'
                />
              )}
            </form>
          </Grid>
          <Grid item md={6} xs={12} className={classes.rightColumn}>
            <Box className={classes.columnTitle}>Bundle Options</Box>
            <BundleOptions
              bundleId={bundle?.objectId || newItemId}
              bundleOptionError={bundleOptionError}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ borderTop: '1px solid #DCDCDB', minHeight: '50px' }}>
        <Box
          style={{ minHeight: '60px', margin: '0 20px', width: '100%' }}
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <Button onClick={closeDialog} color='default'>
            Cancel
          </Button>

          <InfoPopover
            info={objectId}
            style={{ position: 'absolute', left: 0, right: 0, margin: '0 auto' }}
          />

          <Button onClick={submit} color='primary' type='submit' form='create-menu-item-form'>
            Save bundle
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  bundleOptions: state.bundleOptions,
  bundles: state.bundles,
  restaurantId: state.activeRestaurant.data.objectId,
  menuVersion: state.menuVersion,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearBundleOptionsAction,
      createBundleAction,
      updateBundleAction,
      addBundleOptionsToBundleAction,
      addBreadcrumbAction,
      clearBreadcrumbsAction,
      addBundleToCategoryAction,
      restockBundleAction,
      disableBundleForDateAction,
      disableBundleUntilDateAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(BundleEditDialog);
