import React from 'react';
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const LoadingBackdrop = ({ save }) => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={save.saving}>
      <CircularProgress color='inherit' />
    </Backdrop>
  );
};

const mapStateToProps = (state) => ({ save: state.save });
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(LoadingBackdrop);
