import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  selectionBoxContainer: {
    position: 'fixed',
    left: '50%',
    bottom: '40px',
    width: 'calc(100% - 40px)',
    maxWidth: '900px',
    zIndex: 1,
  },
  selectionBox: {
    position: 'relative',
    left: '-50%',
    width: '100%',
    maxWidth: '900px',
  },
  selectionBoxContent: {
    padding: '12px 30px',
    lineHeight: '36px',
    alignItems: 'center',
  },
}));

export default useStyles;
