import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateChoiceAction, createChoiceAction } from '../../actions/menuItemOptionChoicesAction';
import { dialogContexts, isEmpty } from '../../constants/constants';

const MenuItemOptionChoiceEditDialog = ({
  choice,
  createChoiceAction,
  dialogContext,
  menuItemId,
  openDialog,
  optionId,
  selectedChoiceIndex,
  setOpenDialog,
  updateChoiceAction,
}) => {
  const closeDialog = () => {
    setOpenDialog(false);
  };

  const [name, setName] = useState(choice.title || null);
  const [nameError, setNameError] = useState('');

  const [price, setPrice] = useState(choice.price || null);
  const [includePrice, setIncludePrice] = useState(Boolean(choice.price));
  const [priceError, setPriceError] = useState('');

  useEffect(() => {
    setPriceError(null);
    if (openDialog && isEmpty(choice)) {
      setName('');
      setPrice(null);
      setIncludePrice(false);
    }
  }, [openDialog, setOpenDialog]);

  const validateForm = () => {
    let formError = false;
    if (!name) {
      setNameError('Please enter a choice name.');
      formError = true;
    } else if (nameError) {
      setNameError('');
    }

    if (includePrice) {
      if (!price || price === '0.00') {
        setPriceError('Please enter a price.');
        formError = true;
      } else if (priceError) {
        setPriceError('');
      }
    }

    return formError;
  };

  const formatNumber = (num) => (Math.round(num * 100) / 100).toFixed(2);

  const submit = (event) => {
    event.preventDefault();

    const formError = validateForm();

    if (formError) {
      return;
    }

    if (dialogContext === dialogContexts.CREATE) {
      createChoiceAction(name, includePrice ? price : 0);
    } else {
      updateChoiceAction(selectedChoiceIndex, name, includePrice ? price : 0);
    }
    closeDialog();
  };

  return (
    <Dialog
      open={openDialog}
      onClose={closeDialog}
      fullWidth
      maxWidth='sm'
      onEnter={() => {
        // Reset inputs when entering
        setName(choice.title || null);
        setPrice(choice.price || null);
        setIncludePrice(Boolean(choice.price));
        setNameError('');
        setPriceError('');
      }}
    >
      <DialogTitle id='choice-dialog'>{dialogContext} Choice</DialogTitle>
      <DialogContent>
        <form onSubmit={submit} id='edit-choice-form'>
          <TextField
            autoFocus
            id='choice-name'
            label='Choice Name'
            placeholder='eg. Rare, Medium Rare, Medium, Well Done'
            variant='outlined'
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            onBlur={(e) => {
              setName(e.target.value.trim());
              validateForm();
            }}
            InputLabelProps={{ shrink: true }}
            margin='normal'
            required
            error={Boolean(nameError)}
            helperText={nameError}
            onKeyUp={(e) => e.code === 'Enter' && submit(e)}
          />

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color='primary'
                  checked={includePrice}
                  onChange={(e) => setIncludePrice(e.target.checked)}
                  name='include-price'
                />
              }
              label='Is this choice an extra cost?'
            />
          </FormGroup>

          {includePrice && (
            <TextField
              id='choice-price'
              label='Price'
              placeholder='17.50'
              variant='outlined'
              fullWidth
              value={price}
              type='number'
              onChange={(e) => setPrice(e.target.value)}
              onBlur={(e) => setPrice(formatNumber(e.target.value))}
              InputLabelProps={{ shrink: true }}
              min={1}
              inputProps={{ min: 1, step: 1 }}
              InputProps={{
                startAdornment: <InputAdornment position='start'>$</InputAdornment>,
              }}
              margin='normal'
              error={Boolean(priceError)}
              helperText={priceError}
              onKeyUp={(e) => e.code === 'Enter' && submit(e)}
            />
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color='default'>
          Cancel
        </Button>
        <Button onClick={submit} color='primary' type='submit' form='choice-form'>
          {dialogContext === dialogContexts.CREATE ? 'Create' : 'Save'} Choice
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createChoiceAction,
      updateChoiceAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemOptionChoiceEditDialog);
