import * as PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button as MaterialButton,
} from '@material-ui/core';
import { Checkbox, Button } from '@eatclub-apps/ec-component-library';
import { TextButton } from '../v2/components/Button/TextButton';
import { fetchRestaurantByIdAction } from '../../actions/restaurantsAction';
import { clearMenusAction, fetchMenuDataAction, fetchMenusAction } from '../../actions/menusAction';
import { filterUnique } from '../../helpers/ArrayUtils';
import { pluralise } from '../../helpers/Helpers';
import MenuTab from '../v2/components/menuTabs/MenuTab';
import DuplicateFail from './DuplicateFail';
import DuplicateSuccess from './DuplicateSuccess';
import RestaurantSearch from './RestaurantSearch';
import useStyles from './DuplicateMenusStyles';

function Fragment(props) {
  return null;
}

Fragment.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const DuplicateMenus = ({
  activeRestaurant,
  fetchRestaurantByIdAction,
  menus,
  fetchMenusAction,
  fetchMenuDataAction,
  clearMenusAction,
  menuVersion,
}) => {
  const classes = useStyles();
  const query = useQuery();
  const version = query.get('version');
  const { restaurantId } = useParams();

  // Fetch the restaurant if it's not the active one
  useEffect(() => {
    if (activeRestaurant?.data?.objectId !== restaurantId) {
      fetchRestaurantByIdAction(restaurantId);
    }
  }, [restaurantId, activeRestaurant?.data, fetchRestaurantByIdAction]);

  const [selectedRestaurants, setSelectedRestaurants] = useState([]);
  const [results, setResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [completionStatus, setCompletionStatus] = useState(null);

  // Fetch menus
  useEffect(() => {
    if (version === '0') {
      fetchMenusAction(restaurantId);
    }

    if (version === '1') {
      fetchMenuDataAction(restaurantId);
    }

    return () => {
      clearMenusAction();
    };
  }, [activeRestaurant.data, clearMenusAction, fetchMenusAction, restaurantId, menuVersion]);

  useEffect(() => {
    // no restaurant Id
    if (!restaurantId) {
      return;
    }

    // restaurant already fetch
    if (Object.keys(activeRestaurant.data).length > 0) {
      return;
    }

    fetchRestaurantByIdAction(restaurantId);
  }, [activeRestaurant.data, fetchRestaurantByIdAction, restaurantId]);

  if (activeRestaurant.fetching) {
    return <></>;
  }

  if (activeRestaurant.error) {
    return <div>Error loading Restaurant: {activeRestaurant.errorMessage}</div>;
  }

  const toggleRestaurant = (restaurant) => {
    // Remove if already there
    if (selectedRestaurants?.includes(restaurant)) {
      setSelectedRestaurants(
        selectedRestaurants.filter(
          (selectedRestaurant) => selectedRestaurant?.objectId !== restaurant?.objectId,
        ),
      );
      return;
    }

    // Add if not
    setSelectedRestaurants([...selectedRestaurants, restaurant].filter(filterUnique));
  };

  /**
   * Begin duplicating
   */
  const duplicateMenus = () => {
    // TODO
    setCompletionStatus('success');
  };

  if (completionStatus === 'success') {
    return (
      <DuplicateSuccess activeRestaurant={activeRestaurant} restaurants={selectedRestaurants} />
    );
  }

  if (completionStatus === 'fail') {
    return <DuplicateFail activeRestaurant={activeRestaurant} restaurants={selectedRestaurants} />;
  }

  return (
    <Box className={classes.duplicateMenuContainer}>
      <h1 style={{ marginLeft: '20px', marginTop: '24px' }}>
        Duplicate {version === '0' && 'Version 1'}
        {version === '1' && 'Version 2'} Menus
      </h1>
      <Box className={classes.duplicateMenu}>
        <Box className={classes.leftColumn}>
          <Box className='duplicate-info' mb='40px'>
            <Box className={classes.header} style={{ marginBottom: '20px' }}>
              From
            </Box>
            <Box className={classes.descriptionText}>
              This will duplicate and replace the menus that exist.
            </Box>
          </Box>
          <Box className='duplicate-menus'>
            <Box className={classes.subheader}>{menus?.data?.length} menus for this restaurant</Box>
            <Box className={classes.menuGrid}>
              {menus.data.map((menu) => (
                <MenuTab menu={menu} />
              ))}
            </Box>
          </Box>
        </Box>
        <Box className={classes.rightColumn}>
          <Box className={classes.header} mb='20px'>
            To
          </Box>
          <RestaurantSearch
            onSelectRestaurant={(restaurant) =>
              setSelectedRestaurants([...selectedRestaurants, restaurant])
            }
            onRestaurantsUpdated={(restaurants) => {
              setResults(restaurants);
              setIsSearching(false);
            }}
            onSearchInitiated={() => setIsSearching(true)}
          />
          <Box className='search-results-container'>
            <Box className='divider' />
            <Box className='search-results'>
              <Box className={classes.subheader} mb='10px'>
                All results
              </Box>

              {isSearching ? (
                <CircularProgress />
              ) : results?.length === 0 ? (
                <Box>No results found</Box>
              ) : (
                results.map((result) => (
                  <Box mb='10px'>
                    <Checkbox
                      label={result?.name}
                      disabled={result?.objectId === activeRestaurant?.data?.objectId}
                      selected={
                        selectedRestaurants?.filter(
                          (restaurant) => restaurant?.objectId === result?.objectId,
                        )?.length > 0
                      }
                      onChange={() => toggleRestaurant(result)}
                    />
                  </Box>
                ))
              )}
            </Box>
          </Box>
          <Box className={classes.selectionFooter}>
            <Box className='selected-restaurants' mb='30px'>
              <Box className={classes.subheader} mb='10px'>
                {selectedRestaurants.length} {pluralise('venue', selectedRestaurants.length)}{' '}
                selected
              </Box>
              {selectedRestaurants.map((restaurant) => (
                <Box>
                  <Box display='inline-flex'>
                    <Box mt='3px'>{restaurant?.name}</Box>
                    <TextButton
                      style={{
                        color: '#E54439',
                        button: { fontSize: '14px', padding: '5px 10px' },
                      }}
                      text='Remove'
                      onClick={() => toggleRestaurant(restaurant)}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
            <Box display='flex' alignItems='flex-end' mb='35px'>
              <Button
                text='Submit'
                disabled={selectedRestaurants?.length === 0}
                onClick={() => setShowConfirmationModal(true)}
              />
            </Box>
          </Box>
        </Box>
        <Dialog open={showConfirmationModal} onClose={() => setShowConfirmationModal(false)}>
          <DialogTitle id='alert-dialog-title'>Confirm duplication</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Are you sure you want to duplicate all of the menus for {activeRestaurant?.data?.name}{' '}
              to {selectedRestaurants?.length} other restaurants?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MaterialButton onClick={() => setShowConfirmationModal(false)} color='default'>
              No
            </MaterialButton>
            <MaterialButton
              onClick={() => {
                setShowConfirmationModal(false);
                duplicateMenus();
              }}
              color='primary'
              autoFocus
            >
              Yes
            </MaterialButton>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  activeRestaurant: state.activeRestaurant,
  menuVersion: state.menuVersion,
  menus: state.menus,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { fetchRestaurantByIdAction, clearMenusAction, fetchMenusAction, fetchMenuDataAction },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateMenus);
