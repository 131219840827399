import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  duplicateMenuContainer: {
    fontFamily: "Gordita, 'Open Sans', 'Helvetica', 'sans-serif'",
    fontSize: '16px',
  },
  duplicateMenu: {
    display: 'flex',
    gap: '40px',
  },
  leftColumn: {
    width: '60%',
    // maxWidth: "500px",
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    // minHeight: "calc(100vh - 240px)",
    padding: '40px 30px',
  },
  rightColumn: {
    width: 'calc(50% - 1px)',
    borderLeft: '1px solid #DCDCDC',
    minHeight: 'calc(100vh + 120px)',
    padding: '40px 40px',
  },
  header: {
    fontWeight: 'bold',
    fontSize: '24px',
  },
  subheader: {
    fontWeight: 'bold',
    fontSize: '18px',
  },
  descriptionText: {
    maxWidth: '300px',
  },
  menuGrid: {
    display: 'flex',
    columnGap: '40px',
    flexWrap: 'wrap',
    // justifyContent: "space-between",
  },
  selectionFooter: {
    minHeight: '100px',
    width: 'calc(100% - 60vw)',
    margin: '-40px',
    padding: '40px 40px',
    bottom: 0,
    zIndex: 5,
    position: 'fixed',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    borderRight: '1px solid #DCDCDC',
    borderTop: '1px solid #DCDCDC',
  },
  successStateContainer: {
    fontFamily: "Gordita, 'Open Sans', 'Helvetica', 'sans-serif'",
    textAlign: 'center',
    paddingTop: '100px',
  },
}));

export default useStyles;
