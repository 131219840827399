import * as type from '../actions/types';

const initialState = {
  data: {},
  fetching: false,
  error: false,
  success: false,
  errorMessage: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.COMPLETE_CHANGE_REQUEST_PENDING: {
      return {
        ...initialState,
        fetching: true,
      };
    }

    case type.COMPLETE_CHANGE_REQUEST_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        fetching: false,
        success: true,
      };
    }

    case type.COMPLETE_CHANGE_REQUEST_FAILURE: {
      return {
        ...state,
        fetching: false,
        error: true,
        errorMessage: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
