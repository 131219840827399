import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  TextField,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { importDoshiiAction, setPosIdAction } from '../../../../actions/importAction';
import { setMenuVersionAction } from '../../../../actions/menuVersionAction';
import { isEmpty } from '../../../../constants/constants';
import useStyles from './MenuCreateIntegratedDialogStyles';

const MenuCreateIntegratedDialog = ({
  openDialog,
  setOpenDialog,
  activeRestaurant,
  importDoshiiAction,
  setMenuVersionAction,
  setPosId,
}) => {
  const classes = useStyles();
  const [customDoshiiID, setCustomDoshiiID] = useState('');

  useEffect(() => {
    if (openDialog) {
      setCustomDoshiiID('');
    }
  }, [openDialog]);

  const closeDialog = () => {
    setOpenDialog(false);
  };

  const hasPosId = !isEmpty(activeRestaurant.data?.posId);

  const submit = (event) => {
    event.preventDefault();

    if (hasPosId) {
      importDoshiiAction(activeRestaurant?.data?.objectId);

      // TODO save current menu before switching?

      // close modal on success
      setOpenDialog(false);

      // switch to "v3" on success
      setMenuVersionAction(1, true);

      // TODO refresh products on success

      // TODO refresh the screen or something
    } else {
      setPosId(activeRestaurant?.data?.objectId, customDoshiiID);

      // keep open to be able to import after POS ID being set
    }
  };

  return (
    <Dialog
      open={openDialog}
      onClose={closeDialog}
      fullWidth
      maxWidth='sm'
      onEnter={() => {
        // Reset state when entering
        // TODO
      }}
    >
      <DialogContent className={classes.dialogContent}>
        <Box className={classes.modalContent}>
          <Box mb='20px'>
            <Typography variant='h2'>Import menu from Doshii</Typography>
          </Box>
          {!hasPosId ? (
            <Box className={classes.modalText}>
              <Typography>Input the Doshii ID to start importing the POS menu</Typography>
              <TextField
                label='Doshii ID'
                variant='outlined'
                fullWidth
                value={customDoshiiID}
                onChange={(e) => setCustomDoshiiID(e.target.value)}
              />
            </Box>
          ) : (
            <Box>
              <Typography>
                {activeRestaurant?.data?.name}&apos;s Doshii menu will be imported into EatClub.
                This will create a new set of menu items, options, bundles, bundle options, and
                choices.
              </Typography>
              <br />
              <Typography>A menu will need its items reviewed before it can go live.</Typography>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box className={classes.footer}>
          <Button onClick={closeDialog} color='default'>
            Cancel
          </Button>
          <Button
            onClick={submit}
            color='primary'
            type='submit'
            form='edit-menu-form'
            disabled={!hasPosId && isEmpty(customDoshiiID)}
            style={{ textTransform: 'uppercase' }}
          >
            {!hasPosId ? 'Update' : 'Import menu'}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  activeRestaurant: state.activeRestaurant,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      importDoshiiAction,
      setMenuVersionAction,
      setPosId: setPosIdAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MenuCreateIntegratedDialog);
