import { Box, Button, IconButton, Paper } from '@material-ui/core';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import useStyles from './SelectionControlsStyles';
import { ReactComponent as IconCancel } from '../../../../assets/icon_cancel.svg';

const SelectionControls = ({
  selectedItems,
  setSelectedItems,
  removeItem,
  showAddOptionsDialog,
  menuVersion,
  reviewItem,
}) => {
  const classes = useStyles();

  const onCancel = () => {
    setSelectedItems([]);
  };

  return (
    <Box className={classes.selectionBoxContainer}>
      <Paper className={classes.selectionBox}>
        <Box className={classes.selectionBoxContent} display='flex' justifyContent='space-between'>
          <Box display='inline-flex' gridGap='12px' alignItems='center'>
            <Box style={{ fontSize: '20px' }}>
              <b>
                {selectedItems.length} item{selectedItems.length === 1 ? '' : 's'}
              </b>{' '}
              selected
            </Box>
            <Box>
              <IconButton onClick={onCancel} style={{ padding: '8px' }}>
                <IconCancel />
              </IconButton>
            </Box>
          </Box>
          <Box display='inline-flex' gridGap='8px'>
            {menuVersion.posEnabled && (
              <Button
                onClick={() => {
                  console.log(selectedItems);
                  selectedItems.forEach((item) => reviewItem(item));
                  setSelectedItems([]);
                }}
                color='primary'
              >
                Mark reviewed
              </Button>
            )}
            {!menuVersion.posEnabled && (
              <>
                <Button onClick={showAddOptionsDialog} color='primary'>
                  Edit options
                </Button>
              </>
            )}
            <Button
              onClick={() => {
                selectedItems.forEach((item) => removeItem(item));
                setSelectedItems([]);
              }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  menuVersion: state.menuVersion,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SelectionControls);
