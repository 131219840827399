import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { deleteMenuAction } from '../../actions/menusAction';

const MenuDeleteDialog = ({ activeMenu, deleteMenuAction, openDialog, setOpenDialog }) => {
  const closeDialog = () => {
    setOpenDialog(false);
  };

  const submit = (event) => {
    event.preventDefault();

    deleteMenuAction(activeMenu.objectId);
    closeDialog();
  };

  return (
    <Dialog open={openDialog} onClose={closeDialog}>
      <DialogTitle>Delete {activeMenu.menuTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to delete this menu?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color='default'>
          Cancel
        </Button>
        <Button onClick={submit} color='primary'>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteMenuAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MenuDeleteDialog);
