import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addBreadcrumbAction, popBreadcrumbAction } from '../../../../actions/breadcrumbsAction';
import {
  updateChoiceAction,
  createChoiceAction,
  deleteChoiceAction,
  restockChoiceAction,
  disableChoiceForDateAction,
  disableChoiceUntilDateAction,
} from '../../../../actions/choicesAction';
import { addChoiceToOptionAction } from '../../../../actions/optionsAction';
import { dialogContexts } from '../../../../constants/constants';
import { startsWith } from '../../../../helpers/Helpers';
import InfoPopover from '../../../InfoPopover/InfoPopover';

const OptionChoiceEditDialog = ({
  choice,
  choices,
  createChoiceAction,
  dialogContext,
  openDialog,
  addBreadcrumbAction,
  popBreadcrumbAction,
  setOpenDialog,
  updateChoiceAction,
  deleteChoiceAction,
  menuVersion,
  addChoiceToOptionAction,
  restockChoiceAction,
  disableChoiceForDateAction,
  disableChoiceUntilDateAction,
}) => {
  const closeDialog = () => {
    setOpenDialog(false);
  };

  const newChoiceId = `choice_${new Date().getTime()}`;
  const [choiceId, setChoiceId] = useState(choice?.objectId || newChoiceId);

  const [name, setName] = useState(choice.choiceTitle || '');
  const [nameError, setNameError] = useState('');

  const [price, setPrice] = useState(choice.price || 0);
  const [includePrice, setIncludePrice] = useState(Boolean(choice.price));
  const [priceError, setPriceError] = useState('');

  const [disableDate, setDisableDate] = useState(choice?.disableDate || null);
  const [posId, setPosId] = useState(choice?.posId || null);

  const isDisabled =
    moment(choice.disableDate, 'yyyy-MM-DD').isSame(moment(), 'day') ||
    moment(choice.disableUntilDate, 'yyyy-MM-DD').isAfter(moment(), 'day');

  const [showDisableOption, setShowDisableOption] = useState(isDisabled || false);
  const [disableUntilDate, setDisableUntilDate] = useState(choice?.disableUntilDate || null);

  useEffect(() => {
    if (choice?.objectId) {
      setChoiceId(choice?.objectId);
      return;
    }

    setChoiceId(`choice_${new Date().getTime()}`);
  }, [choice?.objectId]);

  // breadcrumbs
  const [hasBreadcrumb, setHasBreadcrumb] = useState(false); // To ensure we don't pop some other breadcrumb if re-rendered while closed
  useEffect(() => {
    if (openDialog && !hasBreadcrumb) {
      addBreadcrumbAction(`${dialogContext} choice`);
      setHasBreadcrumb(true);
    } else if (!openDialog && hasBreadcrumb) {
      popBreadcrumbAction();
      setHasBreadcrumb(false);
    }
  }, [openDialog]);

  // Reset disable fields when reopened
  useEffect(() => {
    if (openDialog) {
      setDisableDate(choice?.disableDate || null);
      setDisableUntilDate(choice?.disableUntilDate || null);

      const isDisabled =
        moment(choice.disableDate, 'yyyy-MM-DD').isSame(moment(), 'day') ||
        moment(choice.disableUntilDate, 'yyyy-MM-DD').isAfter(moment(), 'day');

      setShowDisableOption(isDisabled || false);
    }
  }, [openDialog]);

  // // Set or unset disable date if the box is checked
  useEffect(() => {
    if (!showDisableOption || disableUntilDate) {
      setDisableDate(null);
    } else {
      // if disable option is enabled and no disable date given, set it for today only
      setDisableDate(moment().format('yyyy-MM-DD'));
    }
  }, [showDisableOption, disableUntilDate, openDialog]);

  const deleteChoice = () => {
    deleteChoiceAction(choice.objectId);
    setOpenDialog(false);
  };

  const validateForm = () => {
    let formError = false;
    if (!name) {
      setNameError('Please enter a choice name.');
      formError = true;
    } else if (nameError) {
      setNameError('');
    }

    if (includePrice) {
      if (!price || price === '0.00') {
        setPriceError('Please enter a price.');
        formError = true;
      } else if (priceError) {
        setPriceError('');
      }
    }

    return formError;
  };

  const formatNumber = (num) => (Math.round(num * 100) / 100).toFixed(2);

  const submit = (event) => {
    event.preventDefault();

    const formError = validateForm();

    if (formError) {
      return;
    }

    if (dialogContext === dialogContexts.CREATE) {
      createChoiceAction(choiceId, name, includePrice ? price : 0);
      addChoiceToOptionAction(choiceId);
    } else {
      updateChoiceAction(
        choiceId,
        name,
        includePrice ? price : 0,
        disableDate,
        disableUntilDate,
        choice?.importStatus,
      );

      if (!startsWith(choice.objectId, 'choice_')) {
        if (!isDisabled) {
          if (choice.disableDate !== null || choice.disableUntilDate !== null) {
            restockChoiceAction(choice.objectId);
          }
        } else {
          if (disableDate !== choice.disableDate && disableDate !== null) {
            disableChoiceForDateAction(choice.objectId, disableDate);
          }
          if (disableUntilDate !== choice.disableUntilDate && disableUntilDate !== null) {
            disableChoiceUntilDateAction(choice.objectId, disableUntilDate);
          }
        }
      }
    }
    closeDialog();
  };

  const objectId = choices?.data?.find(
    (apiChoice) => apiChoice?.objectId === choice?.objectId,
  )?.objectId;

  return (
    <Dialog
      open={openDialog}
      onClose={closeDialog}
      fullWidth
      maxWidth='sm'
      onEnter={() => {
        // Reset inputs when entering
        setName(choice.choiceTitle || null);
        setPrice(choice.price || null);
        setIncludePrice(Boolean(choice.price));
        setPosId(choice.posId || null);
        setNameError('');
        setPriceError('');
      }}
    >
      <DialogTitle id='choice-dialog'>{dialogContext} Choice</DialogTitle>
      <DialogContent>
        <form onSubmit={submit} id='edit-choice-form'>
          {menuVersion.posEnabled && (
            <TextField
              autoFocus
              id='menu-item-pos-id'
              label='POS ID'
              variant='outlined'
              fullWidth
              disabled
              value={posId}
              onChange={(e) => setPosId(e.target.value)}
              onBlur={(e) => setPosId(e.target.value.trim())}
              InputLabelProps={{ shrink: true }}
              margin='normal'
              InputProps={{ style: { backgroundColor: '#EFEFEF' } }}
            />
          )}
          <TextField
            autoFocus
            id='option-choice-name'
            label='Choice Name'
            placeholder='eg. Rare, Medium Rare, Medium, Well Done'
            variant='outlined'
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            onBlur={(e) => {
              setName(e.target.value.trim());
              validateForm();
            }}
            InputLabelProps={{ shrink: true }}
            margin='normal'
            required
            error={Boolean(nameError)}
            helperText={nameError}
            onKeyUp={(e) => e.code === 'Enter' && submit(e)}
          />

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color='primary'
                  checked={includePrice}
                  onChange={(e) => setIncludePrice(e.target.checked)}
                  name='include-price'
                />
              }
              label='Is this choice an extra cost?'
            />
          </FormGroup>

          {includePrice && (
            <TextField
              id='option-choice-price'
              label='Price'
              placeholder='e.g. 17.50'
              variant='outlined'
              fullWidth
              value={price}
              type='number'
              onChange={(e) => setPrice(e.target.value)}
              onBlur={(e) => setPrice(formatNumber(e.target.value))}
              InputLabelProps={{ shrink: true }}
              min={1}
              inputProps={{ min: 1, step: 1 }}
              InputProps={{
                startAdornment: <InputAdornment position='start'>$</InputAdornment>,
              }}
              margin='normal'
              error={Boolean(priceError)}
              helperText={priceError}
              onKeyUp={(e) => e.code === 'Enter' && submit(e)}
            />
          )}

          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showDisableOption}
                  onChange={(event) => {
                    if (event.target.checked) {
                      setShowDisableOption(true);
                    } else {
                      setShowDisableOption(false);
                      setDisableUntilDate(null);
                      setDisableDate(null);
                    }
                  }}
                  name='showDisableOption'
                  color='primary'
                />
              }
              label='Disable item'
            />
          </Box>

          {showDisableOption && (
            <TextField
              id='menu-item-disable-until-date'
              label='Disable until'
              type='date'
              value={disableUntilDate}
              onChange={(e) => setDisableUntilDate(e.target.value)}
              onBlur={(e) => setDisableUntilDate(e.target.value.trim())}
              InputLabelProps={{ shrink: true }}
              variant='outlined'
              margin='normal'
            />
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Box display='flex' justifyContent='space-between' width='100%'>
          <Box>
            <Button onClick={deleteChoice} style={{ color: '#FF0000' }}>
              Delete choice
            </Button>
          </Box>
          <Box>
            <InfoPopover
              info={objectId}
              style={{ position: 'absolute', left: 0, right: 0, margin: '0 auto' }}
            />
            <Button onClick={closeDialog} color='default'>
              Cancel
            </Button>
            <Button onClick={submit} color='primary' type='submit' form='choice-form'>
              {dialogContext === dialogContexts.CREATE ? 'Create' : 'Save'} Choice
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  menuVersion: state.menuVersion,
  choices: state.choices,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createChoiceAction,
      updateChoiceAction,
      deleteChoiceAction,
      addBreadcrumbAction,
      popBreadcrumbAction,
      addChoiceToOptionAction,
      restockChoiceAction,
      disableChoiceForDateAction,
      disableChoiceUntilDateAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(OptionChoiceEditDialog);
