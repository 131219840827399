import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { PlaylistAdd } from '@material-ui/icons';
import { dialogContexts } from '../../constants/constants';

const MenuItemOptionChoiceCreate = ({
  setDialogContext,
  setOpenDialog,
  setSelectedChoiceIndex,
}) => (
  <>
    <ListItem
      button
      onClick={() => {
        setOpenDialog(true);
        setSelectedChoiceIndex(dialogContexts.CREATE);
        setDialogContext(dialogContexts.CREATE);
      }}
    >
      <ListItemIcon>
        <PlaylistAdd />
      </ListItemIcon>
      <ListItemText
        primary='Add New Choice'
        primaryTypographyProps={{ color: 'textSecondary', variant: 'button' }}
      />
    </ListItem>
  </>
);

export default MenuItemOptionChoiceCreate;
