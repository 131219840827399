import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
} from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { devLog } from '../../helpers/devLog';

const DiscardDialog = ({
  callback,
  handleClose,
  setHasChanges,
  menus,
  items,
  categories,
  products,
  options,
  choices,
  bundles,
  bundleOptions,
}) => {
  useEffect(() => {
    // MENUS
    const alteredMenus = menus.localData.filter((menu) => menu._altered || menu._created);
    const deletedMenus = menus.deleted;

    // MENU ITEMS
    const alteredItems = items.localData.filter(
      (item) => (item._altered || item._created) && !item._placeholder,
    );
    const deletedItems = items.deleted;

    // CATEGORIES
    const alteredCategories = categories.localData.filter(
      (category) => category._altered || category._created,
    );
    const deletedCategories = categories.deleted;

    // PRODUCTS
    const alteredProducts = products.localData.filter(
      (product) => product._altered || product._created,
    );
    const deletedProducts = products.deleted;

    // OPTIONS
    const alteredOptions = options.localData.filter((option) => option._altered || option._created);
    const deletedOptions = options.deleted;

    // CHOICES
    const alteredChoices = choices.localData.filter((choice) => choice._altered || choice._created);
    const deletedChoices = choices.deleted;

    // BUNDLES
    const alteredBundles = bundles.localData.filter((bundle) => bundle._altered || bundle._created);
    const deletedBundles = bundles.deleted;

    // BUNDLE OPTIONS
    const alteredBundleOptions = bundleOptions.localData.filter(
      (bundleOption) => bundleOption._altered || bundleOption._created,
    );
    const deletedBundleOptions = bundleOptions.deleted;

    const changes = []
      .concat(
        alteredMenus,
        deletedMenus,
        alteredItems,
        deletedItems,
        alteredCategories,
        deletedCategories,
        alteredProducts,
        deletedProducts,
        alteredOptions,
        deletedOptions,
        alteredChoices,
        deletedChoices,
        alteredBundles,
        deletedBundles,
        alteredBundleOptions,
        deletedBundleOptions,
      )
      .filter((change) => change);

    const hasChanges = changes.length > 0;

    if (hasChanges) {
      if (callback) {
        devLog('info', 'unsaved changes for menu', changes);
      }
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }

    setHasChanges(hasChanges);
  }, [
    items.deleted,
    items.localData,
    menus.localData,
    menus.deleted,
    categories.localData,
    categories.deleted,
    products.localData,
    products.deleted,
    options.localData,
    options.deleted,
    choices.localData,
    choices.deleted,
    bundles.localData,
    bundles.deleted,
    bundleOptions.localData,
    bundleOptions.deleted,
    callback,
  ]);

  return (
    <Dialog open={!!callback} onClose={() => handleClose()}>
      <DialogTitle id='alert-dialog-title'>Discard changes?</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          Are you sure you want to discard all unsaved changes?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} color='default'>
          No
        </Button>
        <Button
          onClick={() => {
            handleClose();

            callback();
          }}
          color='primary'
          autoFocus
        >
          Discard
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  menus: state.menus,
  items: state.menuItems,
  categories: state.categories,
  products: state.products,
  options: state.options,
  choices: state.choices,
  bundles: state.bundles,
  bundleOptions: state.bundleOptions,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DiscardDialog);
