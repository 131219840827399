export const Restaurant = `
    objectId
	region
	name
	imageLink
	menuVersion
	posId
	posEnabled
	posMenuVersion
	posFilteredMenu
`;
