import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { deleteCategoryAction } from '../../actions/menuItemsAction';

const MenuCategoryDeleteDialog = ({
  category,
  openDialog,
  setOpenDialog,
  deleteCategoryAction,
}) => {
  const closeDialog = () => {
    setOpenDialog(false);
  };

  const submit = (event) => {
    event.preventDefault();
    deleteCategoryAction(category.category);
    closeDialog();
  };

  const menuItemsCount = category.menuItems.filter((menuItem) => !menuItem._placeholder).length;

  return (
    <Dialog
      open={openDialog}
      onClose={closeDialog}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>Delete Category</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description' style={{ marginBottom: '1rem' }}>
          Are you sure you want to delete the <strong>&apos;{category.category}&apos;</strong>{' '}
          category?{' '}
          {menuItemsCount > 0 && (
            <span>
              This will also delete the{' '}
              <strong>
                {menuItemsCount} Menu Item
                {menuItemsCount > 1 && 's'}
              </strong>{' '}
              belonging to this category.
            </span>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color='default'>
          Cancel
        </Button>
        <Button onClick={submit} color='primary' autoFocus>
          Delete Category
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => bindActionCreators({ deleteCategoryAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MenuCategoryDeleteDialog);
