import moment from 'moment';

export const Regex12HourClock = /^(1[0-2]|0?[1-9]):([0-5]?[0-9])( ?[aApP][mM])?$/; // NOTE: matches upper/lower am/PM

export const menuPlatformMap = (platform) => {
  switch (platform) {
    case 'eatclub':
      return {
        short: 'EC',
        long: 'EatClub',
      };
    case 'whitelabel':
      return {
        short: 'WL',
        long: 'White Label',
      };
    default:
      return {
        short: '',
        long: '',
      };
  }
};

export const menuTypeMap = (type) => {
  switch (type) {
    case 'dinein':
      return {
        short: 'DI',
        long: 'Dine In',
      };
    case 'takeaway':
      return {
        short: 'TA',
        long: 'Takeaway',
      };
    default:
      return {
        short: '',
        long: '',
      };
  }
};

export const menuPlatformOptions = [
  { name: 'EatClub', value: 'eatclub' },
  { name: 'White Label', value: 'whitelabel' },
];

export const menuTypeOptions = [
  { name: 'Takeaway', value: 'takeaway' },
  { name: 'Dine In', value: 'dinein' },
];

export const dialogContexts = {
  CREATE: 'Create',
  EDIT: 'Edit',
};

export const itemTypes = {
  PRODUCT: 'Product',
  BUNDLE: 'Bundle',
};

const generateTimes = () => {
  const timeStops = [];
  const startTime = moment().startOf('day');
  const endTime = moment().endOf('day');

  while (startTime <= endTime) {
    timeStops.push(startTime.format('hh:mm a'));
    startTime.add(15, 'minutes');
  }

  // Also add midnight
  timeStops.push(endTime.format('hh:mm a'));
  return timeStops;
};

export const timeIntervals = generateTimes();

export const isEmpty = (variable) => {
  if (variable === null || variable === undefined) {
    return true;
  }

  switch (typeof variable) {
    case 'object':
      return Object.keys(variable).length === 0;
    case 'array':
      return variable.length === 0;
    case 'string':
      return variable.length === 0 || !variable.trim();
    default:
      return !variable;
  }
};
