import * as type from './types';
import { devLog } from '../helpers/devLog';
import { parseJson, removeEmpty } from '../helpers/Helpers';
import {
  createProducts,
  disableProductForDate,
  disableProductUntilDate,
  removeProducts,
  restockProduct,
  updateProducts,
} from '../graphql/mutations';
import { getMenuItemImageUploadUrlAction } from './menuItemImageAction';
import { isEmpty } from '../constants/constants';
import { makeApiCall } from './actions';
import { syncMenu, syncMenuCacheAction } from './menusAction';
import store from '../store';
import { getItemInArray } from '../helpers/ArrayUtils';

export const createProductAction =
  (
    productId,
    productTitle,
    productDescription,
    price,
    imageLink,
    image,
    noDiscount,
    disableDate,
    disableUntilDate,
    restId,
    posId,
    options,
  ) =>
  (dispatch) => {
    dispatch({
      type: type.CREATE_PRODUCT,
      payload: {
        productId,
        productTitle,
        productDescription,
        price,
        imageLink,
        image,
        noDiscount,
        disableDate,
        disableUntilDate,
        restId,
        posId,
        options,
      },
    });
  };

export const updateProductAction =
  (
    objectId,
    productTitle,
    productDescription,
    price,
    imageLink,
    image,
    noDiscount,
    disableDate,
    disableUntilDate,
    restId,
    posId,
    importStatus,
  ) =>
  (dispatch) => {
    dispatch({
      type: type.UPDATE_PRODUCT,
      payload: {
        objectId,
        productTitle,
        productDescription,
        price,
        imageLink,
        image,
        noDiscount,
        disableDate,
        disableUntilDate,
        restId,
        posId,
        importStatus,
      },
    });
  };

export const deleteProductAction = (productId) => (dispatch) => {
  dispatch({
    type: type.DELETE_PRODUCT,
    payload: { productId },
  });
};

export const reviewProductAction = (productId) => (dispatch) => {
  const state = store.getState();
  const { products } = state;

  const product = getItemInArray(products.localData, 'objectId', productId);

  dispatch({
    type: type.REVIEW_PRODUCT,
    payload: { productId },
  });

  dispatch({
    type: type.BULK_REVIEW_OPTIONS,
    payload: { optionIds: product.optionIds },
  });
};

export const clearProductsAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_PRODUCTS,
  });
};

/**
 * TODO this should not be handled in redux
 */
export const addOptionToProductAction = (optionId) => (dispatch) => {
  dispatch({
    type: type.ADD_OPTION_TO_PRODUCT,
    payload: { optionId },
  });
};

/**
 * TODO this should not be handled in redux
 */
export const addOptionsToProductAction = (options) => (dispatch) => {
  dispatch({
    type: type.ADD_OPTIONS_TO_PRODUCT,
    payload: { options },
  });
};

export const addOptionsToProductsAction = (productIds, optionIds) => (dispatch) => {
  dispatch({
    type: type.ADD_OPTIONS_TO_PRODUCTS,
    payload: { productIds, optionIds },
  });
};

export const removeOptionFromProductAction = (optionId) => (dispatch) => {
  dispatch({
    type: type.REMOVE_OPTION_FROM_PRODUCT,
    payload: { optionId },
  });
};

export const removeOptionsFromProductsAction = (productIds, optionIds) => (dispatch) => {
  dispatch({
    type: type.REMOVE_OPTIONS_FROM_PRODUCTS,
    payload: { productIds, optionIds },
  });
};

export const clearProductOptionsAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_PRODUCT_OPTIONS,
  });
};

export const disableLocalProductForDateAction = (productId, disableDate) => (dispatch) => {
  dispatch({
    type: type.DISABLE_LOCAL_PRODUCT_FOR_DATE,
    payload: { productId, disableDate },
  });
};

export const disableLocalProductUntilDateAction = (productId, disableUntilDate) => (dispatch) => {
  dispatch({
    type: type.DISABLE_LOCAL_PRODUCT_UNTIL_DATE,
    payload: { productId, disableUntilDate },
  });
};

export const restockProductAction = (productId) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: `RESTOCK_PRODUCT_${productId}`,
  });

  dispatch({
    type: type.RESTOCK_PRODUCT_PENDING,
  });

  (async () => {
    try {
      const response = await makeApiCall(restockProduct, { productId });

      devLog('success', 'restock product', response.data.restockProduct);

      dispatch({
        type: type.RESTOCK_PRODUCT_SUCCESS,
        payload: response.data.restockProduct,
      });

      syncMenu(dispatch);
    } catch (error) {
      devLog('error', 'restock product', error);

      dispatch({
        type: type.SET_TOAST,
        payload: {
          id: `RESTOCK_PRODUCT_${new Date().getTime()}`,
          message: `Unable to restock product: ${error}`,
          type: 'error',
        },
      });

      dispatch({
        type: type.RESTOCK_PRODUCT_FAILURE,
        payload: `Unable to restock product: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: `RESTOCK_PRODUCT_${productId}`,
      });
    }
  })();
};

export const disableProductForDateAction = (productId, disableDate) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: `DISABLE_PRODUCT_FOR_DATE_${productId}`,
  });

  dispatch({
    type: type.DISABLE_PRODUCT_FOR_DATE_PENDING,
  });

  (async () => {
    try {
      const response = await makeApiCall(disableProductForDate, { productId, disableDate });

      devLog('success', 'disable product for date', response.data.disableProductForDate);

      dispatch({
        type: type.DISABLE_PRODUCT_FOR_DATE_SUCCESS,
        payload: response.data.disableProductForDate,
      });

      syncMenu(dispatch);
    } catch (error) {
      devLog('error', 'disable product for date', error);

      dispatch({
        type: type.SET_TOAST,
        payload: {
          id: `DISABLE_PRODUCT_FOR_DATE_${new Date().getTime()}`,
          message: `Unable to disable product for date: ${error}`,
          type: 'error',
        },
      });

      dispatch({
        type: type.DISABLE_PRODUCT_FOR_DATE_FAILURE,
        payload: `Unable to disable product for date: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: `DISABLE_PRODUCT_FOR_DATE_${productId}`,
      });
    }
  })();
};

export const disableProductUntilDateAction = (productId, disableUntilDate) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: `DISABLE_PRODUCT_UNTIL_DATE_${productId}`,
  });

  dispatch({
    type: type.DISABLE_PRODUCT_UNTIL_DATE_PENDING,
  });

  (async () => {
    try {
      const response = await makeApiCall(disableProductUntilDate, { productId, disableUntilDate });

      devLog('success', 'disable product until date', response.data.disableProductUntilDate);

      dispatch({
        type: type.DISABLE_PRODUCT_UNTIL_DATE_SUCCESS,
        payload: response.data.disableProductUntilDate,
      });

      syncMenu(dispatch);
    } catch (error) {
      devLog('error', 'disable product until date', error);

      dispatch({
        type: type.SET_TOAST,
        payload: {
          id: `DISABLE_PRODUCT_UNTIL_DATE_${new Date().getTime()}`,
          message: `Unable to disable product until date: ${error}`,
          type: 'error',
        },
      });

      dispatch({
        type: type.DISABLE_PRODUCT_UNTIL_DATE_FAILURE,
        payload: `Unable to disable product until date: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: `DISABLE_PRODUCT_UNTIL_DATE_${productId}`,
      });
    }
  })();
};

export const dbRemoveProducts = async (productIds, restaurantId, dispatch) => {
  try {
    const response = await makeApiCall(removeProducts, {
      restId: restaurantId,
      productIds,
    });

    devLog('success', 'remove product', response.data.removeProducts);

    return null;
  } catch (error) {
    devLog('error', 'unable to remove product', error);

    dispatch({
      type: type.SET_TOAST,
      payload: {
        id: `PRODUCT_SAVE_${new Date().getTime()}`,
        message: `Unable to remove product: ${error}`,
        type: 'error',
      },
    });
    return error;
  }
};

export const dbCreateProducts = async (products, restaurantId, dispatch) => {
  try {
    const response = await makeApiCall(createProducts, {
      products: await Promise.all(
        products.map(async (product) => {
          let imageLink = null;
          if (!isEmpty(product._image) && !product._deleted) {
            imageLink = await getMenuItemImageUploadUrlAction(product, restaurantId);
          }

          return removeEmpty({
            restId: product?.restId,
            productTitle: product?.productTitle,
            productDescription: product?.productDescription,
            imageLink,
            price: product?.price,
            disableDate: product?.disableDate,
            disableUntilDate: product?.disableUntilDate,
            noDiscount: product?.noDiscount,
            importStatus: product?.importStatus,
            optionIds: product?.optionIds,
          });
        }),
      ),
    });

    if (response?.data?.createProducts === undefined || response?.data?.createProducts === null) {
      throw new Error('Response is undefined');
    }

    devLog('success', 'create products', response.data.createProducts);

    return response?.data?.createProducts?.map((createdProduct, index) => {
      dispatch({
        type: type.BACKEND_SAVE_PRODUCT_SUCCESS,
        payload: {
          savedItem: createdProduct,
          itemObjectId: products[index]._objectId,
        },
      });

      // Also return the original objectId
      return { ...createdProduct, _objectId: products[index]._objectId };
    });
  } catch (error) {
    devLog('error', 'unable to create products', error);

    dispatch({
      type: type.SET_TOAST,
      payload: {
        id: `PRODUCTS_CREATE_${new Date().getTime()}`,
        message: `Unable to create products: ${error}`,
        type: 'error',
      },
    });
    return error;
  }
};

export const dbUpdateProducts = async (products, restaurantId, dispatch) => {
  try {
    const productsWithUpdates = (
      await Promise.all(
        products.map(async (product) => {
          let { imageLink } = product;
          if (!isEmpty(product._image) && !product._deleted) {
            imageLink = await getMenuItemImageUploadUrlAction(product, restaurantId);
          }

          return removeEmpty({
            objectId: product?.objectId,
            productTitle: product?.productTitle,
            productDescription: product?.productDescription,
            imageLink,
            price: product?.price,
            noDiscount: product?.noDiscount,
            importStatus: product?.importStatus,
            optionIds: product?.optionIds,
          });
        }),
      )
    ).filter((product) => Object.keys(product).length > 1);

    if (productsWithUpdates.length === 0) {
      return [];
    }

    const response = await makeApiCall(updateProducts, {
      restId: restaurantId,
      products: productsWithUpdates,
    });

    if (response?.data?.updateProducts === undefined || response?.data?.updateProducts === null) {
      throw new Error('Response is undefined');
    }

    devLog('success', 'update products', response.data.updateProducts);

    return response?.data?.updateProducts?.map((createdProduct, index) => {
      dispatch({
        type: type.BACKEND_SAVE_PRODUCT_SUCCESS,
        payload: {
          savedItem: createdProduct,
          itemObjectId: products[index]._objectId,
        },
      });

      // Also return the original objectId
      return { ...createdProduct, _objectId: products[index]._objectId };
    });
  } catch (error) {
    devLog('error', 'unable to update products', error);

    dispatch({
      type: type.SET_TOAST,
      payload: {
        id: `PRODUCTS_UPDATE_${new Date().getTime()}`,
        message: `Unable to update products: ${error}`,
        type: 'error',
      },
    });
    return error;
  }
};
