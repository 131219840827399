import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { Modal, CenteredContent, Button } from '@eatclub-apps/ec-component-library';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setRestaurantMenuVersionAction } from '../../actions/restaurantsAction';
import { setMenuVersionAction } from '../../actions/menuVersionAction';
import useStyles from './ChangeVersionModalStyles';

const ChangeVersionModal = ({
  isOpen,
  onClose,
  setRestaurantMenuVersion,
  setMenuVersion,
  activeRestaurant,
  menuVersion,
  posEnabled,
}) => {
  const classes = useStyles();
  const muiTheme = useTheme();
  const breakpointXsDown = useMediaQuery(muiTheme.breakpoints.down('xs'));

  const save = () => {
    setRestaurantMenuVersion(activeRestaurant.data?.objectId, menuVersion, posEnabled);
    setMenuVersion(menuVersion, posEnabled);

    onClose();
  };

  const getMenuVersion = () => {
    if (menuVersion === 0) {
      return 'Version 1.0 Menus';
    }

    if (menuVersion === 1 && !posEnabled) {
      return 'Version 2.0 Menus';
    }

    if (menuVersion === 1 && posEnabled) {
      return 'Integrated POS Menus';
    }
  };

  return (
    <Modal
      fullWidth={false}
      style={{
        container: { padding: '20px' },
        modal: { minWidth: breakpointXsDown ? '100px' : '650px', maxWidth: '650px' },
        content: { padding: '20px 20px' },
        header: { padding: 0 },
      }}
      isOpen={isOpen}
      onClose={onClose}
      showCloseText={false}
      showCloseButton={false}
    >
      <Box>
        <Box className={classes.modalHeader} mb='20px'>
          Are you sure you want to change the live menu to {getMenuVersion()}?
        </Box>

        <Box mt='8px'>
          <Box className={classes.modalSubheader}>
            This will change what menu is being displayed, and what customers order from within the
            customer app.
          </Box>
        </Box>

        <Box className={classes.buttonContainer}>
          <CenteredContent>
            <Button type='tertiary' onClick={onClose}>
              Cancel
            </Button>
          </CenteredContent>
          <Button
            type='primary'
            onClick={save}
            style={{
              container: {
                fontSize: '18px',
                width: breakpointXsDown && '100%',
              },
              button: {
                width: breakpointXsDown && '100%',
              },
            }}
          >
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  activeRestaurant: state.activeRestaurant,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setRestaurantMenuVersion: setRestaurantMenuVersionAction,
      setMenuVersion: setMenuVersionAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ChangeVersionModal);
