import { batch } from 'react-redux';
import * as type from './types';
import { devLog } from '../helpers/devLog';
import { createOptions, removeOptions, updateOptions } from '../graphql/mutations';
import { removeEmpty } from '../helpers/Helpers';
import { makeApiCall } from './actions';

export const createOptionAction =
  (
    objectId,
    optionTitle,
    optionDescription,
    minSelections,
    maxSelections,
    mandatory,
    restaurantId,
    posId,
    choiceIds,
  ) =>
  (dispatch) => {
    dispatch({
      type: type.CREATE_OPTION,
      payload: {
        objectId,
        optionTitle,
        optionDescription,
        minSelections,
        maxSelections,
        mandatory,
        restaurantId,
        posId,
        choiceIds,
      },
    });
  };

export const updateOptionAction =
  (
    objectId,
    title,
    description,
    minSelections,
    maxSelections,
    mandatory,
    restaurantId,
    posId,
    choiceIds,
    importStatus,
  ) =>
  (dispatch) => {
    dispatch({
      type: type.UPDATE_OPTION,
      payload: {
        objectId,
        title,
        description,
        minSelections,
        maxSelections,
        mandatory,
        restaurantId,
        posId,
        choiceIds,
        importStatus,
      },
    });
  };

export const deleteOptionAction = (optionId) => (dispatch) => {
  dispatch({
    type: type.DELETE_OPTION,
    payload: { optionId },
  });
};

export const clearOptionsAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_OPTIONS,
  });
};

export const addChoiceToOptionAction = (choiceId) => (dispatch) => {
  dispatch({
    type: type.ADD_CHOICE_TO_OPTION,
    payload: { choiceId },
  });
};

export const addChoicesToOptionAction = (choiceIds) => (dispatch) => {
  dispatch({
    type: type.ADD_CHOICES_TO_OPTION,
    payload: { choiceIds },
  });
};

export const removeChoiceFromOptionAction = (choiceId) => (dispatch) => {
  dispatch({
    type: type.REMOVE_CHOICE_FROM_OPTION,
    payload: { choiceId },
  });
};

export const dbRemoveOptions = async (optionIds, restaurantId, dispatch) => {
  try {
    const response = await makeApiCall(removeOptions, {
      restId: restaurantId,
      optionIds,
    });

    devLog('success', 'remove options', response.data.removeOptions);

    return null;
  } catch (error) {
    devLog('error', 'unable to remove options', error);

    dispatch({
      type: type.SET_TOAST,
      payload: {
        id: `OPTIONS_REMOVE_${new Date().getTime()}`,
        message: `Unable to remove options: ${error}`,
        type: 'error',
      },
    });
    return error;
  }
};

export const dbCreateOptions = async (options, restaurantId, dispatch) => {
  try {
    const response = await makeApiCall(createOptions, {
      options: options.map((option) =>
        removeEmpty({
          optionTitle: option?.optionTitle,
          optionDescription: option?.optionDescription,
          mandatory: option?.mandatory,
          minSelections: option?.minSelections,
          maxSelections: option?.maxSelections,
          restId: restaurantId,
          importStatus: option?.importStatus,
          choiceIds: option?.choiceIds,
        }),
      ),
    });

    if (response?.data?.createOptions === undefined || response?.data?.createOptions === null) {
      throw new Error('Response is undefined');
    }

    devLog('success', 'create options', response.data.createOptions);

    return response?.data?.createOptions?.map((createdOption, index) => {
      dispatch({
        type: type.BACKEND_SAVE_OPTION_SUCCESS,
        payload: {
          savedItem: createdOption,
          itemObjectId: options[index]._objectId,
        },
      });

      // Also return the original objectId
      return { ...createdOption, _objectId: options[index]._objectId };
    });
  } catch (error) {
    devLog('error', 'unable to create options', error);

    dispatch({
      type: type.SET_TOAST,
      payload: {
        id: `OPTIONS_CREATE_${new Date().getTime()}`,
        message: `Unable to create options: ${error}`,
        type: 'error',
      },
    });
    return error;
  }
};

export const dbUpdateOptions = async (options, restaurantId, dispatch) => {
  try {
    const response = await makeApiCall(updateOptions, {
      restId: restaurantId,
      options: options.map((option) =>
        removeEmpty({
          objectId: option?.objectId,
          optionTitle: option?.optionTitle,
          optionDescription: option?.optionDescription,
          mandatory: option?.mandatory,
          minSelections: option?.minSelections,
          maxSelections: option?.maxSelections,
          importStatus: option?.importStatus,
          choiceIds: option?.choiceIds,
        }),
      ),
    });

    if (response?.data?.updateOptions === undefined || response?.data?.updateOptions === null) {
      throw new Error('Response is undefined');
    }

    devLog('success', 'update options', response.data.updateOptions);

    return batch(() =>
      response?.data?.updateOptions?.map((updatedOption, index) => {
        dispatch({
          type: type.BACKEND_SAVE_OPTION_SUCCESS,
          payload: {
            savedItem: updatedOption,
            itemObjectId: options[index]._objectId,
          },
        });

        // Also return the original objectId
        return { ...updatedOption, _objectId: options[index]._objectId };
      }),
    );
  } catch (error) {
    devLog('error', 'unable to update options', error);

    dispatch({
      type: type.SET_TOAST,
      payload: {
        id: `OPTIONS_UPDATE_${new Date().getTime()}`,
        message: `Unable to update options: ${error}`,
        type: 'error',
      },
    });
    return error;
  }
};
