import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  InputAdornment,
  Box,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DateRange } from '@material-ui/icons';
import { createMenuItemAction } from '../../actions/menuItemsAction';
import MenuItemOptions from '../menuItemOptions/MenuItemOptions';
import {
  cancelMenuItemOptionsAction,
  clearInitialMenuItemOptionsAction,
} from '../../actions/menuItemOptionsAction';
import ImportOptions from '../importOptions/ImportOptions';
import MenuItemImageUpload from '../menuItemImageUpload/MenuItemImageUpload';

const MenuItemCreateDialog = ({
  cancelMenuItemOptionsAction,
  category,
  clearInitialMenuItemOptionsAction,
  createMenuItemAction,
  menuId,
  newMenuItemIndex,
  openDialog,
  setOpenDialog,
}) => {
  const closeDialog = () => {
    setOpenDialog(false);
  };

  const [save, setSave] = useState(false);

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');

  const [price, setPrice] = useState('');
  const [priceError, setPriceError] = useState('');

  const [desc, setDesc] = useState('');
  const [descError, setDescError] = useState('');

  const [imageLink, setImageLink] = useState('');
  const [image, setImage] = useState({});

  const [disableDate, setDisableDate] = useState(null);

  const [showDisableOption, setShowDisableOption] = useState(false);
  const [disableUntilDate, setDisableUntilDate] = useState(null);

  const [newMenuItemId, setNewMenuItemId] = useState(
    `${menuId.substring(0, 10)}-${new Date().getTime()}`,
  );

  const [noDiscount, setNoDiscount] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const formatNumber = (num) => (Math.round(num * 100) / 100).toFixed(2);

  const submit = (event) => {
    event.preventDefault();

    let formError = false;

    if (!name) {
      setNameError('Please enter a name.');
      formError = true;
    } else if (nameError) {
      setNameError('');
    }

    if (price === '') {
      setPriceError('Please enter a price.');
      formError = true;
    } else if (priceError) {
      setPriceError('');
    }

    if (formError) {
      return;
    }

    createMenuItemAction(
      name,
      Number(price),
      desc,
      newMenuItemIndex,
      category,
      menuId,
      newMenuItemId,
      imageLink,
      image,
      noDiscount,
      disableDate,
      disableUntilDate,
    );

    setSave(true);
    closeDialog();
  };

  return (
    <Dialog
      open={openDialog}
      onClose={closeDialog}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth
      maxWidth='lg'
      onEnter={() => {
        setNewMenuItemId(`${menuId.substring(0, 10)}-${new Date().getTime()}`);

        // Reset inputs when entering
        setName('');
        setPrice('');
        setDesc('');
        setImageLink('');
        setImage({});
        setNoDiscount(false);
        setDisableDate(null);
        setDisableUntilDate(null);

        setNameError('');
        setPriceError('');
        setDescError('');
        setSave(false);
      }}
      onExited={() => {
        if (!save) {
          // Revert menuItemOptions back to initial state
          // cancelMenuItemOptionsAction();
        }
        clearInitialMenuItemOptionsAction();
      }}
    >
      <DialogTitle id='alert-dialog-title'>Create Menu Item</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <form onSubmit={submit} id='create-menu-item-form'>
              <TextField
                autoFocus
                id='menu-item-name'
                label='Name'
                placeholder='eg. Mains, Entrees'
                variant='outlined'
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
                onBlur={(e) => setName(e.target.value.trim())}
                InputLabelProps={{ shrink: true }}
                margin='normal'
                required
                error={Boolean(nameError)}
                helperText={nameError}
              />

              <TextField
                id='menu-item-price'
                label='Price'
                placeholder='10.00'
                variant='outlined'
                fullWidth
                value={price}
                type='number'
                onChange={(e) => setPrice(e.target.value)}
                onBlur={(e) => setPrice(formatNumber(e.target.value))}
                InputLabelProps={{ shrink: true }}
                min={0}
                inputProps={{ min: 0, step: 1 }}
                InputProps={{
                  startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                }}
                margin='normal'
                required
                error={Boolean(priceError)}
                helperText={priceError}
              />

              <TextField
                multiline
                id='menu-item-desc'
                label='Description'
                placeholder="eg. Grandma's flakey pie crust filled with roast chicken, baby carrots, and spring peas."
                variant='outlined'
                fullWidth
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
                onBlur={(e) => setDesc(e.target.value.trim())}
                InputLabelProps={{ shrink: true }}
                margin='normal'
                error={Boolean(descError)}
                helperText={descError}
              />

              <MenuItemImageUpload
                itemId={newMenuItemId}
                imageLink={imageLink}
                setImageLink={setImageLink}
                setImage={setImage}
              />

              <Dialog open={showConfirmation}>
                <Box style={{ padding: '20px' }}>
                  <Box mb='20px'>
                    {' '}
                    Are you sure you want to make this item exempt from discounts? (typically only
                    used for special promotions)
                  </Box>
                  <Button
                    onClick={() => {
                      setShowConfirmation(false);
                      setNoDiscount(true);
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    onClick={() => {
                      setShowConfirmation(false);
                    }}
                  >
                    No
                  </Button>
                </Box>
              </Dialog>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={noDiscount}
                    onChange={(event) =>
                      event.target.checked === true
                        ? setShowConfirmation(true)
                        : setNoDiscount(false)
                    }
                    name='noDiscount'
                    color='primary'
                  />
                }
                label='Exempt from discounts'
              />
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showDisableOption}
                      onChange={(event) =>
                        event.target.checked === true
                          ? setShowDisableOption(true)
                          : setShowDisableOption(false)
                      }
                      name='showDisableOption'
                      color='primary'
                    />
                  }
                  label='Disable item'
                />
              </Box>

              {showDisableOption && (
                <TextField
                  id='menu-item-disable-until-date'
                  label='Disable until'
                  type='date'
                  value={disableUntilDate}
                  onChange={(e) => setDisableUntilDate(e.target.value)}
                  onBlur={(e) => setDisableUntilDate(e.target.value.trim())}
                  InputLabelProps={{ shrink: true }}
                  variant='outlined'
                  margin='normal'
                />
              )}
            </form>
          </Grid>
          <Grid item md={6} xs={12}>
            <MenuItemOptions menuItemId={newMenuItemId} create />
            <ImportOptions menuItemId={newMenuItemId} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color='default'>
          Cancel
        </Button>
        <Button onClick={submit} color='primary' type='submit' form='create-menu-item-form'>
          Create Item
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({ menuItems: state.menuItems });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      cancelMenuItemOptionsAction,
      clearInitialMenuItemOptionsAction,
      createMenuItemAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemCreateDialog);
