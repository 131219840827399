import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  selectLabelFix: {
    // fix for variant outline select label being cut off
    '& .MuiInputLabel-shrink + div .MuiOutlinedInput-notchedOutline legend': {
      width: ['80px', '!important'],
    },
  },
}));

export default useStyles;
