import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Box,
  ListItemAvatar,
  Avatar,
  Tooltip,
  Checkbox,
} from '@material-ui/core';
import { ShoppingCart, RemoveShoppingCart, Delete, Image } from '@material-ui/icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { Draggable } from 'react-beautiful-dnd';
import { ReactComponent as Icon } from '../../../../assets/two-bars-icon.svg';
import { removeBundleFromCategoryAction } from '../../../../actions/categoriesAction';
import {
  disableBundleForDateAction,
  disableLocalBundleForDateAction,
  disableLocalBundleUntilDateAction,
  restockBundleAction,
} from '../../../../actions/bundlesAction';
import { formatCurrency, pluralise, startsWith } from '../../../../helpers/Helpers';
import ReviewLabel from '../ReviewButton/ReviewLabel';
import useStyles from './BundleStyles';
import BundleEditDialog from './BundleEditDialog';
import { dialogContexts } from '../../../../constants/constants';

const MenuBundle = ({
  bundle,
  removeBundleFromCategory,
  disableBundleForDate,
  index,
  selected,
  onSelect,
  category,
  menuVersion,
  disabled,
  restockBundle,
  disableLocalBundleForDate,
  disableLocalBundleUntilDate,
}) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [isDisabled, setIsDisabled] = useState(
    moment(bundle?.disableDate, 'yyyy-MM-DD').isSame(moment(), 'day') ||
      moment(bundle?.disableUntilDate, 'yyyy-MM-DD').isAfter(moment(), 'day'),
  );

  useEffect(() => {
    setIsDisabled(
      moment(bundle?.disableDate, 'yyyy-MM-DD').isSame(moment(), 'day') ||
        moment(bundle?.disableUntilDate, 'yyyy-MM-DD').isAfter(moment(), 'day'),
    );
  }, [bundle, bundle?.disableDate, bundle?.disableUntilDate]);

  const handleDelete = () => {
    removeBundleFromCategory(bundle?.objectId, category.objectId);
  };

  const toggleIsDisabled = () => {
    if (isDisabled) {
      if (startsWith(bundle.objectId, 'bundle_')) {
        disableLocalBundleForDate(bundle.objectId, null);
        disableLocalBundleUntilDate(bundle.objectId, null);
      } else {
        restockBundle(bundle?.objectId);
      }
      return;
    }

    const newDisableDate = moment().format('yyyy-MM-DD');
    if (startsWith(bundle.objectId, 'bundle_')) {
      disableLocalBundleForDate(bundle.objectId, newDisableDate);
    } else {
      disableBundleForDate(bundle?.objectId, newDisableDate);
    }
  };

  return (
    <Draggable draggableId={`${category.objectId} ${bundle?.objectId}`} index={index}>
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={`${classes.draggableProduct} ${snapshot.isDragging ? 'dragging' : ''} ${
            snapshot.isDropAnimating ? 'animating' : ''
          }`}
          boxShadow={snapshot.isDragging ? 3 : 0}
        >
          <ListItem button onClick={() => setOpenDialog(true)}>
            <Checkbox
              style={{ color: disabled && 'rgba(0, 0, 0, 0.26)' }}
              color='primary'
              checked={selected}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => !disabled && onSelect(e.target.checked)}
            />
            <Tooltip title='Drag to re-order'>
              <ListItemIcon {...provided.dragHandleProps}>
                <Icon style={{ fill: 'rgba(0, 0, 0, 0.54)' }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemAvatar>
              {bundle?.imageLink ? (
                <Avatar alt='Image' src={bundle?.imageLink} />
              ) : (
                <Avatar>
                  <Image />
                </Avatar>
              )}
            </ListItemAvatar>
            <ListItemText
              primary={
                <Box display='flex' gridColumnGap='20px'>
                  <span>{bundle?.bundleTitle}</span>
                  <ReviewLabel importStatus={bundle?.importStatus} />
                </Box>
              }
              secondary={bundle?.bundleDescription}
              style={{ paddingRight: menuVersion.posEnabled ? '569px' : '404px' }}
            />
            <ListItemSecondaryAction style={{ pointerEvents: 'none' }}>
              <Box display='flex' gridColumnGap='15px' style={{ pointerEvents: 'none' }}>
                <Box
                  style={{
                    padding: '20px 0',
                    textAlign: 'left',
                    width: '100px',
                  }}
                >
                  {bundle?.bundleOptionIds?.length > 0 &&
                    `${bundle?.bundleOptionIds?.length} ${pluralise(
                      'option',
                      bundle?.bundleOptionIds?.length,
                    )}`}
                </Box>
                <span
                  style={{
                    padding: '20px 0',
                    textAlign: 'left',
                    width: '100px',
                  }}
                >
                  {formatCurrency(bundle?.price)}
                </span>
                <Button
                  variant='text'
                  size='medium'
                  color={isDisabled ? 'primary' : 'default'}
                  startIcon={isDisabled ? <RemoveShoppingCart /> : <ShoppingCart />}
                  className={`${classes.listButton} ${isDisabled ? 'listButtonDisabled' : ''}`}
                  onClick={toggleIsDisabled}
                  title='Toggle stock level'
                  style={{
                    fontSize: isDisabled ? '12px' : '14px',
                    pointerEvents: 'auto',
                  }}
                >
                  {isDisabled ? 'Out of Stock' : 'In Stock'}
                </Button>

                <Tooltip title='Remove item from menu'>
                  <IconButton
                    edge='end'
                    aria-label='delete'
                    onClick={handleDelete}
                    style={{
                      pointerEvents: 'auto',
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              </Box>
            </ListItemSecondaryAction>
          </ListItem>
          <BundleEditDialog
            openDialog={openDialog}
            dialogContext={dialogContexts.EDIT}
            setOpenDialog={setOpenDialog}
            bundle={bundle}
          />
        </Box>
      )}
    </Draggable>
  );
};

MenuBundle.propTypes = {
  bundle: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  menuVersion: state.menuVersion,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      removeBundleFromCategory: removeBundleFromCategoryAction,
      disableBundleForDate: disableBundleForDateAction,
      restockBundle: restockBundleAction,
      disableLocalBundleForDate: disableLocalBundleForDateAction,
      disableLocalBundleUntilDate: disableLocalBundleUntilDateAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MenuBundle);
