import {
  deleteItemsInArray,
  getItemInArray,
  insertItemInArrayAtIndex,
  updateItemInArray,
} from '../helpers/ArrayUtils';
import * as type from '../actions/types';

const initialState = {
  data: [],
  localData: [],
  deleted: [],
  fetching: false,
  error: false,
  errorMessage: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case type.FETCH_CHOICES_PENDING: {
      return {
        ...initialState,
        fetching: true,
      };
    }

    case type.FETCH_CHOICES_SUCCESS: {
      return {
        ...state,
        data: action.payload || [],
        localData: action.payload || [],
        fetching: false,
        error: false,
        errorMessage: '',
      };
    }

    case type.FETCH_CHOICES_FAILURE: {
      return {
        ...state,
        data: [],
        localData: [],
        deleted: [],
        fetching: false,
        error: true,
        errorMessage: action.payload,
      };
    }

    case type.CREATE_CHOICE: {
      const { choiceId, choiceTitle, price } = action.payload;

      const newChoice = {
        objectId: choiceId,
        choiceTitle,
        price,
        disableDate: null,
        disableUntilDate: null,
        posId: null,
        importStatus: null,
        _created: true,
      };

      const newChoices = [...state.localData, newChoice];

      return { ...state, localData: newChoices };
    }

    case type.UPDATE_CHOICE: {
      const { choiceId, choiceTitle, price, disableDate, disableUntilDate, importStatus } =
        action.payload;

      const newChoices = updateItemInArray(state.localData, 'objectId', choiceId, (choice) => ({
        ...choice,
        choiceTitle,
        price,
        disableDate,
        disableUntilDate,
        importStatus,
        _altered: true,
      }));

      return { ...state, localData: newChoices };
    }

    case type.DELETE_CHOICE: {
      const { choiceId } = action.payload;

      const deleted = getItemInArray(state.localData, 'objectId', choiceId);

      const newDeleted = { ...deleted, _deleted: true };

      const newChoices = deleteItemsInArray(state.localData, 'objectId', choiceId);

      let newDeletedItems = [...state.deleted];

      if (newDeleted?._created !== true) {
        newDeletedItems = insertItemInArrayAtIndex(state.deleted, 0, newDeleted);
      }

      return {
        ...state,
        localData: newChoices,
        deleted: newDeletedItems,
      };
    }

    case type.DISABLE_LOCAL_CHOICE_FOR_DATE: {
      const { choiceId, disableDate } = action.payload;

      const newChoices = updateItemInArray(state.localData, 'objectId', choiceId, (choice) => ({
        ...choice,
        disableDate,
      }));

      return {
        ...state,
        localData: newChoices,
      };
    }

    case type.DISABLE_LOCAL_CHOICE_UNTIL_DATE: {
      const { choiceId, disableUntilDate } = action.payload;

      const newChoices = updateItemInArray(state.localData, 'objectId', choiceId, (choice) => ({
        ...choice,
        disableUntilDate,
      }));

      return {
        ...state,
        localData: newChoices,
      };
    }

    case type.RESTOCK_CHOICE_SUCCESS: {
      const { objectId, disableDate, disableUntilDate } = action.payload;

      const newChoices = updateItemInArray(state.localData, 'objectId', objectId, (choice) => ({
        ...choice,
        disableDate,
        disableUntilDate,
      }));

      return {
        ...state,
        localData: newChoices,
      };
    }

    case type.DISABLE_CHOICE_FOR_DATE_SUCCESS: {
      const { objectId, disableDate } = action.payload;
      const newChoices = updateItemInArray(state.localData, 'objectId', objectId, (choice) => ({
        ...choice,
        disableDate,
      }));

      return {
        ...state,
        localData: newChoices,
      };
    }

    case type.DISABLE_CHOICE_UNTIL_DATE_SUCCESS: {
      const { objectId, disableUntilDate } = action.payload;
      const newChoices = updateItemInArray(state.localData, 'objectId', objectId, (choice) => ({
        ...choice,
        disableUntilDate,
      }));

      return {
        ...state,
        localData: newChoices,
      };
    }

    /*
     * BACKEND SAVES
     */

    case type.BACKEND_SAVE_CHOICE_SUCCESS: {
      const { savedItem, itemObjectId } = action.payload;

      const newChoices = state.localData.map((item) => {
        if (item.objectId === itemObjectId) {
          return savedItem;
        }

        return item;
      });

      return {
        ...state,
        data: newChoices,
        localData: newChoices,
      };
    }

    case type.BACKEND_SAVE_CHOICES_SUCCESS: {
      const newChoices = state.localData.map(({ _altered, _created, _objectId, ...rest }) => rest);

      return {
        ...state,
        data: newChoices,
        localData: newChoices,
        deleted: [],
      };
    }

    default: {
      return state;
    }
  }
}
