/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, TextField } from '@eatclub-apps/ec-component-library';
import {
  Box,
  Button as MuiButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { completeChangeRequestAction } from '../../actions/changeRequestsAction';
import theme from '../../EatClubTheme';
import { parseJson, toCapitalizedWords } from '../../helpers/Helpers';
import { SecondaryButton } from '../v2/components/Button/SecondaryButton';
import { setToastAction } from '../../actions/toastAction';

const ChangeRequestModal = ({
  isOpen,
  restaurantId,
  activeChangeRequests,
  completeChangeRequestAction,
  setToastAction,
  onClose,
  completeChangeRequest,
}) => {
  const tableRef = useRef();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [values, setValues] = useState([]);
  const [saving, setSaving] = useState(false);

  const currentChangeRequest = activeChangeRequests.data?.[currentIndex];
  const fetching = activeChangeRequests.fetchingRestaurants.includes(restaurantId);
  const posData = parseJson(currentChangeRequest?.posData);

  const isEmptyObject = (obj) => Object.keys(obj).length === 0;

  // Refresh input values
  useEffect(() => {
    if (!values[currentIndex] || isEmptyObject(values[currentIndex])) {
      const newValues = [...values];
      newValues[currentIndex] = parseJson(currentChangeRequest?.ourData);
      setValues(newValues);
    }
  }, [activeChangeRequests, currentIndex]);

  useEffect(() => {
    if (completeChangeRequest?.success && saving) {
      setSaving(false);

      if (currentIndex + 1 < activeChangeRequests.data?.length) {
        setCurrentIndex(currentIndex + 1);
      } else {
        onClose();
      }
    }
  }, [completeChangeRequest?.success]);

  useEffect(() => {
    setCurrentIndex(0);
    const newValues = [...values];
    newValues[currentIndex] = parseJson(currentChangeRequest?.ourData);
    setValues(newValues);
  }, [isOpen]);

  const fieldsToConfirm = Object.keys(posData).map((key) => ({
    id: key,
    label: toCapitalizedWords(key),
  }));

  const getItemType = (type) => {
    const itemTypeMap = {
      product: 'Menu item',
      option: 'Option',
      choice: 'Choice',
    };

    return itemTypeMap?.[type] || 'Item';
  };

  const onSave = () => {
    setSaving(true);

    completeChangeRequestAction(
      currentChangeRequest?.objectId,
      currentChangeRequest?.action === 'deleted' ? null : JSON.stringify(values[currentIndex]),
    );
  };
  const reviewItem = () => {
    const { restId, type, ourData } = activeChangeRequests.data?.[currentIndex];

    if (!ourData) {
      setToastAction(
        `REVIEW_ITEM_${new Date().getTime()}`,
        'Error: Unable to review item',
        'error',
      );

      return;
    }

    const parsedOurData = parseJson(ourData);

    let objectId = null;

    switch (type) {
      case 'bundle': {
        objectId = parsedOurData?.bundleId;
        break;
      }
      case 'bundleOption': {
        objectId = parsedOurData?.bundleOptionId;
        break;
      }
      case 'product': {
        objectId = parsedOurData?.productId;
        break;
      }
      case 'option': {
        objectId = parsedOurData?.optionId;
        break;
      }
      case 'choice': {
        objectId = parsedOurData?.choiceId;
        break;
      }
      default: {
        break;
      }
    }

    if (objectId) {
      window.open(
        `${window.origin}/restaurants/${restId}?type=${type}&objectId=${objectId}`,
        '_blank',
      );
    } else {
      setToastAction(
        `REVIEW_ITEM_${new Date().getTime()}`,
        'Error: Unable to review item',
        'error',
      );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      showCloseButton={false}
      style={{ content: { padding: '35px' } }}
    >
      {fetching && <Box>Loading...</Box>}
      {!fetching &&
        (activeChangeRequests.data === [] || activeChangeRequests.data.length === 0) &&
        'No change requests to display'}
      {!fetching && activeChangeRequests.data.length > 0 && (
        <Box>
          <Box mb='28px' width='100%' display='flex' justifyContent='space-between'>
            <Box style={theme.modalHeader}>Change request</Box>
            <Box style={theme.modalProgress}>
              {currentIndex + 1} of {activeChangeRequests.data.length}
            </Box>
          </Box>
          {currentChangeRequest.action === 'deleted' && (
            <Box>
              {currentChangeRequest?.posData && (
                <span>The following {getItemType(currentChangeRequest?.type)} was deleted.</span>
              )}
              <Table tableref={tableRef}>
                <TableHead>
                  <TableRow style={theme.smallHeading}>
                    <TableCell style={{ verticalAlign: 'baseline' }}>
                      {getItemType(currentChangeRequest?.type)}
                    </TableCell>
                    <TableCell style={{ verticalAlign: 'baseline' }}>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fieldsToConfirm.map((field) => (
                    <TableRow key={field.id}>
                      <TableCell style={{ verticalAlign: 'baseline' }}>{field.label}</TableCell>
                      <TableCell style={{ verticalAlign: 'baseline' }}>
                        {['choices', 'options', 'products', 'bundleOptions'].includes(field.id) ? (
                          <Box>
                            {posData?.[field.id] &&
                              posData?.[field.id].split('\n').map((text) => <Box>{text}</Box>)}
                          </Box>
                        ) : (
                          <Box>{posData?.[field.id]}</Box>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          )}
          {currentChangeRequest.action === 'created' && (
            <Box>
              {currentChangeRequest?.ourData && (
                <span>
                  The {getItemType(currentChangeRequest?.type)}{' '}
                  <b>{parseJson(currentChangeRequest?.ourData)?.name}</b> has been{' '}
                  {currentChangeRequest.action} in the POS, please review the{' '}
                  {getItemType(currentChangeRequest?.type)} in the menu.
                </span>
              )}
            </Box>
          )}
          {currentChangeRequest.action === 'updated' && (
            <Table tableref={tableRef}>
              <TableHead>
                <TableRow style={theme.smallHeading}>
                  <TableCell style={{ verticalAlign: 'baseline' }}>
                    {getItemType(currentChangeRequest?.type)}
                  </TableCell>
                  <TableCell style={{ verticalAlign: 'baseline' }}>Latest POS record</TableCell>
                  <TableCell style={{ verticalAlign: 'baseline' }}>Current EatClub menu</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fieldsToConfirm.map((field) => (
                  <TableRow key={field.id}>
                    <TableCell style={{ verticalAlign: 'baseline' }}>{field.label}</TableCell>
                    <TableCell style={{ verticalAlign: 'baseline' }}>
                      {['choices', 'options', 'products', 'bundleOptions'].includes(field.id) ? (
                        <Box>
                          {posData?.[field.id] &&
                            posData?.[field.id].split('\n').map((text) => <Box>{text}</Box>)}
                        </Box>
                      ) : (
                        <Box>{posData?.[field.id]}</Box>
                      )}
                    </TableCell>
                    <TableCell style={{ verticalAlign: 'baseline' }}>
                      {['choices', 'options', 'products', 'bundleOptions'].includes(field.id) ? (
                        <Box>
                          {values?.[currentIndex]?.[field.id] &&
                            values?.[currentIndex]?.[field.id]
                              .split('\n')
                              .map((text) => <Box>{text}</Box>)}
                        </Box>
                      ) : (
                        <TextField
                          value={values?.[currentIndex]?.[field.id]}
                          onChange={(value) => {
                            const newValues = [...values];
                            newValues[currentIndex][field.id] = value;
                            setValues(newValues);
                          }}
                          style={{ maxWidth: '500px' }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          <Box mt='60px' display='flex' justifyContent='space-between'>
            <MuiButton onClick={onClose}>Cancel</MuiButton>
            <Box display='flex' gridGap='30px'>
              <MuiButton
                disabled={currentIndex === 0}
                onClick={() => setCurrentIndex(currentIndex - 1)}
              >
                Back
              </MuiButton>
              {currentChangeRequest.action !== 'deleted' && (
                <SecondaryButton
                  style={{
                    textTransform: 'uppercase',
                    button: { fontWeight: 'bold' },
                  }}
                  onClick={() => reviewItem()}
                >
                  Review item
                </SecondaryButton>
              )}
              <SecondaryButton
                style={{
                  textTransform: 'uppercase',
                  button: { fontWeight: 'bold' },
                }}
                onClick={onSave}
              >
                Save & continue
              </SecondaryButton>
            </Box>
          </Box>
        </Box>
      )}
    </Modal>
  );
};

ChangeRequestModal.propTypes = {
  activeChangeRequests: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  activeChangeRequests: state.activeChangeRequests,
  completeChangeRequest: state.completeChangeRequest,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ completeChangeRequestAction, setToastAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChangeRequestModal);
