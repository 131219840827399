import React, { useState } from 'react';
import { Button, TextField, Paper, CircularProgress, FormHelperText, Box } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import useStyles from './CustomSignInStyles';
import { ReactComponent as LogoImage } from '../assets/logoEatClub.svg';
import { devLog } from '../helpers/devLog';

const CustomSignIn = ({ authSignedIn }) => {
  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [signinLoading, setSigninLoading] = useState(false);
  const [signinError, setSigninError] = useState('');

  const styles = {
    paper: {
      maxWidth: '500px',
      margin: '80px auto 0',
      padding: '40px',
    },
    form: {
      margin: '0 auto',
    },
    textfield: {
      marginBottom: '24px',
    },
    logo: {
      width: '200px',
      display: 'block',
      margin: '0 auto',
      marginBottom: '24px',
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email.trim() || !password) {
      return;
    }

    setSigninLoading(true);

    try {
      const user = await Auth.signIn(email.trim(), password);
      devLog('success', 'user', user);
    } catch (error) {
      devLog('error', 'user', error);
      setSigninError(error.message);
    } finally {
      setSigninLoading(false);
    }
  };

  if (authSignedIn) {
    return null;
  }

  return (
    <Paper style={styles.paper}>
      <form
        noValidate
        onSubmit={handleSubmit}
        style={styles.form}
        id='loginForm'
        className={classes.form}
      >
        <Box
          style={{
            marginBottom: '20px',
            maxWidth: '200px',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <LogoImage />
        </Box>

        <TextField
          autoFocus
          style={styles.textfield}
          label='Email'
          variant='outlined'
          fullWidth
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          style={styles.textfield}
          label='Password'
          variant='outlined'
          type='password'
          fullWidth
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        {Boolean(signinError) && (
          <FormHelperText error style={{ marginBottom: '1rem', marginTop: 0 }}>
            {signinError}
          </FormHelperText>
        )}

        <Button
          form='loginForm'
          disabled={signinLoading}
          size='large'
          variant='contained'
          color='primary'
          fullWidth
          type='submit'
          style={{ borderRadius: '20px' }}
        >
          {signinLoading ? <CircularProgress size={26} color='inherit' /> : 'Sign In'}
        </Button>
      </form>
    </Paper>
  );
};

export default CustomSignIn;
